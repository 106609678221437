import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Radio,
  Stack,
  Container,
  FormLabel,
  TextField,
  Typography,
  IconButton,
  RadioGroup,
  FormControl,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import {
  // Twitter,
  Facebook,
  // LinkedIn,
  EmailTwoTone,
  FmdGoodTwoTone,
  // LocalPhoneTwoTone,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import ReCAPTCHA from 'react-google-recaptcha';
import { primaryButtonStyle, sectionHeaderStyle } from '../../constants/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { publicContactValidationSchema } from '../../validations/publicContact';
import { useForm } from 'react-hook-form';
import HelperText from '../../widgets/HelperText';
import MuiPhoneNumber from 'material-ui-phone-number';
import { sendContactMessage, verifyCaptchaToken } from '../../api/public';
import notify from '../../utils/notify';
import { useSelector } from 'react-redux';

const ContactSection = ({ secondary }) => {
  const user = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const [focus, setFocus] = useState('');
  const windowLessThan600 = useMediaQuery('(max-width:600px)');

  const contactCaptchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState(false);
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const resolver = yupResolver(publicContactValidationSchema);
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();

  useEffect(() => {
    setValue('reason', 'GENERAL');
  }, []);

  useEffect(() => {
    if (isAuth && user) {
      setValue('reason', 'GENERAL');
      setValue('firstname', user.firstname);
      setValue('lastname', user.lastname);
      setValue('email', user.email);
    }
  }, [isAuth, user]);

  const verifyCaptcha = async () => {
    const token = contactCaptchaRef.current.getValue();
    if (!token) {
      setCaptchaError(true);
      return false;
    }

    const isTokenValid = await verifyCaptchaToken({ token });
    if (!isTokenValid) {
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    const isCaptchaVerified = await verifyCaptcha();
    if (!isCaptchaVerified) return;

    const res = await sendContactMessage(data);
    contactCaptchaRef.current.reset();
    if (res.status === 200) {
      notify.success(res.msg || 'Message sent successfully, we will get back to you soon');
      setValue('message', '');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ my: isAuth ? 2 : secondary ? 10 : 15 }}>
      <Stack width="100%" alignItems="center">
        {!secondary && (
          <>
            <Typography {...sectionHeaderStyle} mb={0}>
              <span style={{ color: '#128CCD' }}>Contact</span> Us
            </Typography>
            <Typography mb={4} textAlign="center">
              Any question or remarks? Just write us a message!
            </Typography>
          </>
        )}
        <Stack
          p={1}
          border={2}
          width="100%"
          bgcolor="#fff"
          minHeight={600}
          borderRadius={3}
          overflow="hidden"
          borderColor="grey.200"
          direction={{ lg: 'row', xs: 'column' }}>
          <Stack
            p={3}
            spacing={5}
            borderRadius={2}
            overflow="hidden"
            position="relative"
            bgcolor="secondary.main"
            justifyContent="space-around"
            width={{ lg: '35%', xs: '100%' }}>
            <Stack zIndex={2}>
              <Typography color="#fff" variant="h5" fontWeight={600}>
                Contact Information
              </Typography>
              <Typography color="#fff" fontSize="0.9rem" mt={1}>
                Fill up the form and ISBM representative will get back to you soon.
              </Typography>
            </Stack>

            <Stack spacing={2} zIndex={2}>
              {/* <Stack direction="row" spacing={1}>
                <LocalPhoneTwoTone sx={{ color: '#fff' }} />
                <Typography color="#fff" fontWeight={500}>
                  (012) 345-6789
                </Typography>
              </Stack> */}
              <Stack
                spacing={1}
                component="a"
                direction="row"
                sx={{ textDecoration: 'none' }}
                href="mailto:info@isbm-global.org">
                <EmailTwoTone sx={{ color: '#fff' }} />
                <Typography color="#fff" fontWeight={500}>
                  info@isbm-global.org
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <FmdGoodTwoTone sx={{ color: '#fff' }} />
                <Typography color="#fff" fontWeight={500}>
                  432 Firestone Dr,
                  <br />
                  Ashton, MD 20861, USA.
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} zIndex={2}>
              <IconButton
                component="a"
                rel="noreferer"
                target="_blank"
                href="https://www.facebook.com/groups/297484840869088">
                <Facebook sx={{ color: 'primary.light' }} />
              </IconButton>
              {/* <IconButton>
                <Twitter sx={{ color: 'primary.light' }} />
              </IconButton>
              <IconButton>
                <LinkedIn sx={{ color: 'primary.light' }} />
              </IconButton> */}
            </Stack>

            <Box
              width={300}
              right={-120}
              height={300}
              bottom={-120}
              boxShadow={2}
              borderRadius="50%"
              position="absolute"
              bgcolor="primary.dark"
            />
            <Box
              right={40}
              bottom={90}
              width={120}
              height={120}
              boxShadow={2}
              borderRadius="50%"
              position="absolute"
              sx={{ opacity: 0.6 }}
              bgcolor="primary.light"
            />
          </Stack>
          <Stack p={{ sm: 3, xs: 1 }} width={{ lg: '65%', xs: '100%' }}>
            <Stack
              flexWrap="wrap"
              direction="row"
              component="form"
              onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                error={errors?.firstname?.message}
                sx={{ px: 1, py: 2, width: { lg: '50%', xs: '100%' } }}>
                <TextField
                  size="small"
                  label="First Name"
                  variant="standard"
                  placeholder="John/Jane"
                  {...register('firstname')}
                  onBlur={() => setFocus('')}
                  error={errors?.firstname?.message}
                  onFocus={() => setFocus('firstname')}
                  InputLabelProps={{ shrink: watchAllFields.firstname || focus === 'firstname' }}
                />
                <HelperText>{errors?.firstname?.message}</HelperText>
              </FormControl>

              <FormControl
                error={errors?.lastname?.message}
                sx={{ px: 1, py: 2, width: { lg: '50%', xs: '100%' } }}>
                <TextField
                  size="small"
                  label="Last Name"
                  placeholder="Doe"
                  variant="standard"
                  {...register('lastname')}
                  onBlur={() => setFocus('')}
                  error={errors?.lastname?.message}
                  onFocus={() => setFocus('lastname')}
                  InputLabelProps={{ shrink: watchAllFields.lastname || focus === 'lastname' }}
                />
                <HelperText>{errors?.lastname?.message}</HelperText>
              </FormControl>

              <FormControl
                error={errors?.email?.message}
                sx={{ px: 1, py: 2, width: { lg: '50%', xs: '100%' } }}>
                <TextField
                  size="small"
                  label="Email"
                  variant="standard"
                  {...register('email')}
                  onBlur={() => setFocus('')}
                  error={errors?.email?.message}
                  placeholder="example@email.com"
                  onFocus={() => setFocus('email')}
                  InputLabelProps={{ shrink: watchAllFields.email || focus === 'email' }}
                />
                <HelperText>{errors?.email?.message}</HelperText>
              </FormControl>

              <FormControl
                error={errors?.contact?.message}
                sx={{ px: 1, py: 2, width: { lg: '50%', xs: '100%' } }}>
                <MuiPhoneNumber
                  size="small"
                  label="Contact"
                  variant="standard"
                  defaultCountry={'us'}
                  error={errors?.contact?.message}
                  onChange={(data) => setValue('contact', data)}
                  InputLabelProps={{ shrink: true }}
                  regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                />
                <HelperText>{errors?.contact?.message}</HelperText>
              </FormControl>

              <FormControl sx={{ px: 1, py: 2, width: '100%' }} error={errors?.reason?.message}>
                <FormLabel>Reason</FormLabel>
                <RadioGroup defaultValue="GENERAL" name="reason" row={!windowLessThan600}>
                  <FormControlLabel value="GENERAL" control={<Radio />} label="General" />
                  <FormControlLabel value="QUERY" control={<Radio />} label="Query" />
                  <FormControlLabel value="SUPPORT" control={<Radio />} label="Support" />
                  <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
                </RadioGroup>
                <HelperText>{errors?.reason?.message}</HelperText>
              </FormControl>

              <FormControl error={errors?.message?.message} sx={{ px: 1, py: 2, width: '100%' }}>
                <TextField
                  rows={4}
                  multiline
                  size="small"
                  label="Message"
                  variant="standard"
                  {...register('message')}
                  onBlur={() => setFocus('')}
                  error={errors?.message?.message}
                  onFocus={() => setFocus('message')}
                  InputLabelProps={{ shrink: watchAllFields.message || focus === 'message' }}
                />
                <HelperText>{errors?.message?.message}</HelperText>
              </FormControl>

              <Stack px={1} width="100%" alignItems={{ lg: 'right', xs: 'center' }}>
                <ReCAPTCHA
                  ref={contactCaptchaRef}
                  sitekey={SITE_KEY}
                  className="contact-recaptcha"
                  onChange={() => {
                    setCaptchaError(false);
                  }}
                />
                {captchaError && (
                  <Typography
                    sx={{
                      mt: '4px',
                      width: '80%',
                      fontSize: 12,
                      color: '#d32f2f',
                      textAlign: 'center',
                    }}>
                    Please verify you are not a robot
                  </Typography>
                )}
                <Box mt={1}>
                  <LoadingButton loading={isSubmitting} {...primaryButtonStyle} type="submit">
                    Submit
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ContactSection;
