import * as urls from '../constants/router-urls';
import { GroupTwoTone, StyleTwoTone, RssFeedTwoTone, EventNoteTwoTone } from '@mui/icons-material';

export default [
  {
    label: 'Feed',
    icon: <RssFeedTwoTone sx={{ fontSize: { lg: 24, xs: 22 } }} />,
    basePath: urls.USER_HOME,
    exactPath: urls.USER_HOME + '/post',
  },
  {
    label: 'Connections',
    icon: <GroupTwoTone sx={{ fontSize: { lg: 24, xs: 22 } }} />,
    basePath: urls.USER_CONNECTIONS,
    exactPath: urls.USER_CONNECTIONS + '/discover',
  },
  {
    label: 'Events',
    icon: <EventNoteTwoTone sx={{ fontSize: { lg: 24, xs: 22 } }} />,
    basePath: urls.USER_EVENTS,
    exactPath: urls.USER_EVENTS,
    unpublished: true,
  },
  {
    label: 'More',
    icon: <StyleTwoTone sx={{ fontSize: { lg: 24, xs: 22 } }} />,
    basePath: urls.USER_MENU,
    exactPath: urls.USER_MENU,
    unpublished: true,
  },
];
