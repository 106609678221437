import React, { useRef, useState, useEffect, useCallback } from 'react';
import List from '@editorjs/list';
import Images from '@editorjs/image';
import Headers from '@editorjs/header';
import Warnings from '@editorjs/warning';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createReactEditorJS } from 'react-editor-js';
import {
  Box,
  Menu,
  Chip,
  Input,
  Stack,
  colors,
  Tooltip,
  TextField,
  IconButton,
  Typography,
  FormControl,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import {
  InfoTwoTone,
  LockTwoTone,
  GroupTwoTone,
  CloseTwoTone,
  PublicTwoTone,
  AddAPhotoTwoTone,
  KeyboardArrowDownTwoTone,
  ErrorTwoTone,
} from '@mui/icons-material';

import Button from '../../widgets/Button';
import debounce from '../../utils/debounce';
import HelperText from '../../widgets/HelperText';
import { usePrompt } from '../../utils/routerHooks';
import { USER_HOME, USER_PROFILE } from '../../constants/router-urls';
import { createNewBlogSchema } from '../../validations/blog';
import PageWrapper from '../../components/global/PageWrapper';
import { deleteFile, updateFile, uploadFile } from '../../api/file';
import { fetchCategories, fetchKeywords, updateArticle } from '../../api/content';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import AlertDialog from '../../components/global/AlertDialog';

const Editor = createReactEditorJS();

const EditArticle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;

  const editorCore = useRef(null);
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const [body, setBody] = useState(null);
  const [imageState, setImageState] = useState('');
  const [isCreating, setIsCreating] = useState('');
  const [privacy, setPrivacy] = useState('PUBLIC');
  const [categories, setCategories] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [emptyEditor, setEmptyEditor] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [bodyImageCache, setBodyImageCache] = useState([]);
  const [initBodyImgCache, setInitBodyImgCache] = useState([]);
  const [showRemoveImgAlert, setShowRemoveImgAlert] = useState(false);
  const [showChangeImgAlert, setShowChangeImgAlert] = useState(false);
  const [removingFeaturedImage, setRemovingFeaturedImage] = useState(false);
  const user = useSelector((state) => state.auth.user);

  usePrompt(
    'This page is asking you to confirm that you want to leave — information you’ve entered may not be saved.',
    isBlocking,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handlePrivacyOptions = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const privacyOptions = [
    { label: 'Public', value: 'PUBLIC', icon: <PublicTwoTone /> },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: <LockTwoTone />,
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: <GroupTwoTone />,
    },
  ];

  const resolver = yupResolver(createNewBlogSchema(uploadedImage));
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const title = useWatch({ control, name: 'title' });
  const description = useWatch({ control, name: 'description' });
  const category = useWatch({ control, name: 'category' });
  const keywords = useWatch({ control, name: 'keywords' });
  const featuredImage = useWatch({ control, name: 'featuredImage' });

  useEffect(() => {
    if (data && data?.authorId === user?.id) {
      setPrivacy(data?.privacy);
      setValue('title', data?.title);
      setValue('description', data?.description);
      setValue('category', data?.category);
      setValue('keywords', data?.keywords?.split(','));
      setUploadedImage(data?.featuredImage);
      setImageState('UPLOADED');
      setBody(data?.body);
      const bodyImages = data?.body?.blocks
        ?.filter((block) => block?.type === 'image')
        ?.map((block) => block?.data?.file);
      setBodyImageCache(bodyImages);
      setInitBodyImgCache(bodyImages);
      setTimeout(() => {
        editorCore.current.render(data?.body);
      }, 1000);
    } else {
      setHasError(true);
    }
  }, []);

  const handler = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (
      featuredImage?.[0] ||
      title !== data?.title ||
      category !== data?.category ||
      description !== data?.description ||
      uploadedImage?.uid !== data?.featuredImage?.uid ||
      _.isEqual(body?.blocks, data?.body?.blocks) === false ||
      _.isEqual(bodyImageCache, initBodyImgCache) === false ||
      _.isEqual(keywords, data?.keywords?.split(',')) === false
    ) {
      setIsBlocking(true);
      window.addEventListener('beforeunload', handler);
      return () => {
        setIsBlocking(false);
        window.removeEventListener('beforeunload', handler);
      };
    }

    setIsBlocking(false);
    return () => {};
  }, [title, description, category, keywords, featuredImage, uploadedImage, bodyImageCache, body]);

  useEffect(() => {
    (async () => {
      const results1 = await fetchCategories();
      setCategories(results1);
      const results2 = await fetchKeywords();
      setKeywordList(results2);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const results = await fetchCategories({ searchKey: category });
      setCategories(results);
    })();
  }, [category]);

  const handleCategorySelection = (value) => {
    setValue('category', value);
  };

  const handleKeywordSelection = (value) => {
    setValue('keywords', value);
  };

  const onKeywordChange = async (value) => {
    const results = await fetchKeywords({ searchKey: value });
    setKeywordList(results);
  };

  const formatErrorMessage = (msg) => {
    const msgParts = msg?.split('[');
    return (
      msgParts?.[0]?.replace('s', '') +
      ' ' +
      (parseInt(msgParts[1]?.split(']')?.[0]) + 1) +
      ' ' +
      msgParts[1]?.split(']')?.[1]
    );
  };

  useEffect(() => {
    if (featuredImage?.[0]) {
      if (uploadedImage) {
        setShowChangeImgAlert(true);
      } else {
        uploadFeaturedImage();
      }
    }
  }, [featuredImage]);

  const uploadFeaturedImage = async () => {
    setImageState('UPLOADING');
    const result = await uploadFile(featuredImage[0], 'image');
    if (result) {
      setUploadedImage(result);
      setValue('featuredImage', undefined);
      setImageState('UPLOADED');
      await onUpdateArticle(data?.isPublished, true);
    } else {
      setImageState('FAILED');
      setUploadedImage(null);
    }
  };

  const removeFeaturedImage = async () => {
    setRemovingFeaturedImage(true);
    const result = await deleteFile(uploadedImage?.uid);
    setRemovingFeaturedImage(false);
    if (result) {
      setUploadedImage(null);
      setImageState('');
      await onUpdateArticle(data?.isPublished, true);
    }
  };

  const changeFeaturedImage = async () => {
    setImageState('UPLOADING');
    const result = await updateFile(uploadedImage?.uid, featuredImage[0], 'image');
    if (result) {
      setUploadedImage(result);
      await onUpdateArticle(data?.isPublished, true);
    }
    setValue('featuredImage', undefined);
    setImageState('UPLOADED');
  };

  const handleEditorChange = async () => {
    const data = await editorCore.current.save();
    setBody(data);
    if (body?.blocks?.length > 0 && emptyEditor) {
      setEmptyEditor(false);
    }
  };

  const onUpdateArticle = async (isPublished, imageMode) => {
    if (body?.blocks?.length === 0 && !imageMode) {
      setEmptyEditor(true);
      return;
    }
    if (!imageMode) {
      setIsCreating(isPublished ? 'Publishing' : 'Saving');
    }
    const formData = {
      body,
      title,
      description,
      category,
      keywords,
      isPublished,
      privacy,
      featuredImage: uploadedImage,
      bodyImageCache,
      imageMode,
    };
    const res = await updateArticle(data?.uid, formData);
    if (res?.status === 200 && !imageMode) {
      setIsBlocking(false);
      window.removeEventListener('beforeunload', handler);
      setTimeout(() => {
        switch (location?.state?.from) {
          case 'feed':
            navigate(`${USER_HOME}/news-&-blogs`, { replace: true });
            break;
          case 'timeline':
            navigate(`${USER_PROFILE}/${user?.username}/timeline`, { replace: true });
            break;
          default:
            navigate(`${USER_HOME}/news-&-blogs`, { replace: true });
        }
      }, 800);
    } else {
      setIsCreating('');
    }
  };

  const renderFeaturedImage = () => {
    switch (imageState) {
      case 'UPLOADING':
        return (
          <Stack maxWidth={600} alignItems="center" position="relative">
            <Box
              width="100%"
              maxWidth={250}
              component="img"
              borderRadius={1}
              sx={{ opacity: 0.5 }}
              src={URL.createObjectURL(featuredImage[0])}
            />
            <Stack
              width="100%"
              height="100%"
              position="absolute"
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          </Stack>
        );
      case 'UPLOADED':
        return (
          <Stack maxWidth={600} alignItems="center" position="relative">
            <Box
              width="100%"
              maxWidth={600}
              component="img"
              borderRadius={1}
              src={uploadedImage?.url}
            />
            {removingFeaturedImage ? (
              <Stack
                width="100%"
                height="100%"
                position="absolute"
                alignItems="center"
                justifyContent="center">
                <CircularProgress />
              </Stack>
            ) : (
              <Stack
                spacing={1}
                width="100%"
                height="100%"
                direction="row"
                position="absolute"
                alignItems="center"
                justifyContent="center">
                <Tooltip title="remove" placement="bottom" arrow>
                  <IconButton
                    size="small"
                    onClick={() => setShowRemoveImgAlert(true)}
                    sx={{ bgcolor: colors.grey[400], ':hover': { bgcolor: colors.grey[100] } }}>
                    <CloseTwoTone />
                  </IconButton>
                </Tooltip>
                <FormControl>
                  <label htmlFor="featured-image-file">
                    <Input
                      type="file"
                      id="featured-image-file"
                      sx={{ display: 'none' }}
                      {...register('featuredImage')}
                    />
                    <Tooltip title="change" placement="bottom" arrow>
                      <IconButton
                        size="small"
                        component="span"
                        sx={{ bgcolor: colors.grey[400], ':hover': { bgcolor: colors.grey[100] } }}>
                        <AddAPhotoTwoTone />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <HelperText>{errors?.featuredImage?.message}</HelperText>
                </FormControl>
              </Stack>
            )}
          </Stack>
        );
      default:
        return (
          <FormControl
            fullWidth
            error={!!errors?.featuredImage}
            sx={{ flex: 1, minWidth: '180px', maxWidth: 600 }}>
            <label htmlFor="featured-image-file">
              <Input
                type="file"
                id="featured-image-file"
                sx={{ display: 'none' }}
                {...register('featuredImage')}
              />
              <Button
                fullWidth
                component="span"
                variant="outlined"
                startIcon={<AddAPhotoTwoTone />}
                color={errors?.featuredImage ? 'error' : 'primary'}>
                Featured Image
              </Button>
            </label>
            <HelperText>{errors?.featuredImage?.message}</HelperText>
          </FormControl>
        );
    }
  };

  return (
    <PageWrapper isAuth>
      <AlertDialog
        title="Remove❓"
        isOpen={showRemoveImgAlert}
        onResolved={removeFeaturedImage}
        resolveLoading={removingFeaturedImage}
        onClose={() => setShowRemoveImgAlert(false)}
        message="Are you sure you want to remove this image? This action cannot be undone."
      />
      <AlertDialog
        title="Change❓"
        isOpen={showChangeImgAlert}
        onResolved={changeFeaturedImage}
        resolveLoading={imageState === 'UPLOADING'}
        onClose={() => setShowChangeImgAlert(false)}
        onRejected={() => setValue('featuredImage', undefined)}
        message="Are you sure you want to change this image? This action cannot be undone."
      />

      {hasError ? (
        <Stack p={2} spacing={1} alignItems="center">
          <ErrorTwoTone sx={{ fontSize: 60, color: '#D32F2F' }} />
          <Typography fontSize={15} fontWeight={600} color="error" textAlign="center">
            Something went wrong! Please try again later.
          </Typography>
        </Stack>
      ) : (
        <Stack width="100%">
          <Stack spacing={2} width="100%" maxWidth={600} margin="auto" mb={5}>
            <Typography fontSize={24} fontWeight={600}>
              Create new article
            </Typography>
            <Stack
              width="100%"
              direction="row"
              alignSelf="end"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between">
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                onClick={handlePrivacyOptions}
                sx={{ minWidth: 'fit-content', my: 1 }}
                endIcon={<KeyboardArrowDownTwoTone />}
                startIcon={privacyOptions.find((option) => option.value === privacy)?.icon}>
                {privacyOptions.find((option) => option.value === privacy)?.label}
              </Button>
              <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <Stack px={1} spacing={1} alignItems="start">
                  {privacyOptions.map((option) => (
                    <Button
                      fullWidth
                      size="small"
                      key={option.value}
                      startIcon={option.icon}
                      color={option.value === privacy ? 'primary' : 'secondary'}
                      sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                      onClick={() => {
                        setPrivacy(option.value);
                        handleClose();
                      }}>
                      {option.label}
                    </Button>
                  ))}
                </Stack>
              </Menu>
              <Stack direction="row" spacing={1}>
                {!data?.isPublished && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    sx={{ minWidth: 'fit-content' }}
                    loading={isCreating === 'Saving'}
                    disabled={isCreating === 'Publishing' || !isBlocking}
                    onClick={() => handleSubmit(() => onUpdateArticle(false))()}>
                    Save Changes
                  </Button>
                )}
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  sx={{ minWidth: 'fit-content' }}
                  loading={isCreating === 'Publishing'}
                  disabled={isCreating === 'Saving' || !isBlocking}
                  onClick={() => handleSubmit(() => onUpdateArticle(true))()}>
                  {data?.isPublished ? 'Publish Changes' : 'Publish'}
                </Button>
              </Stack>
            </Stack>
            {emptyEditor && (
              <HelperText>
                Article body cannot be empty. Please add some content to your article.
              </HelperText>
            )}

            <Stack
              spacing={2}
              sx={{
                opacity: isCreating ? 0.5 : 1,
                pointerEvents: isCreating ? 'none' : 'auto',
              }}>
              <Stack>
                <TextField
                  focused
                  fullWidth
                  type="text"
                  label="Title"
                  variant="standard"
                  {...register('title')}
                  error={!!errors?.title}
                  placeholder="Title of your article"
                  sx={{
                    maxWidth: 600,
                    '& .MuiInput-input': { fontSize: '2rem', fontWeight: 700 },
                  }}
                />
                <HelperText>{errors?.title?.message}</HelperText>
              </Stack>
              <Stack>
                <TextField
                  fullWidth
                  multiline
                  maxRows={5}
                  minRows={2}
                  type="text"
                  label="Short Description"
                  {...register('description')}
                  error={!!errors?.description}
                  sx={{ maxWidth: 600, '& .MuiInput-input': { fontSize: 18, fontWeight: 400 } }}
                  placeholder="A short description to let people know what your article is about"
                />
                <HelperText>{errors?.description?.message}</HelperText>
              </Stack>
              <Stack>
                <Stack direction="row" alignItems="start" spacing={0.5} mb={0.8}>
                  <InfoTwoTone sx={{ fontSize: 16 }} />
                  <Typography fontSize={12} color={colors.grey[700]}>
                    If you don&apos;t see your category in the list, you can manually add it by
                    typing.
                  </Typography>
                </Stack>
                <Autocomplete
                  freeSolo
                  options={
                    categories
                      ? categories.filter((option) => option !== null && option !== undefined)
                      : []
                  }
                  defaultValue={data?.category}
                  onInputChange={(e, val) => handleCategorySelection(val)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Category"
                      variant="outlined"
                      {...register('category')}
                      error={!!errors?.category}
                      placeholder="Select a category"
                    />
                  )}
                />
                <HelperText>{errors?.category?.message}</HelperText>
              </Stack>
              <Stack>
                <Stack direction="row" alignItems="start" spacing={0.5} mb={0.8}>
                  <InfoTwoTone sx={{ fontSize: 16 }} />
                  <Typography fontSize={12} color={colors.grey[700]}>
                    If you don&apos;t see your keyword in the list, you can manually add it by
                    typing. Also, don&apos;t forget to press enter after typing each keyword.
                  </Typography>
                </Stack>
                <Autocomplete
                  multiple
                  options={
                    keywordList
                      ? keywordList.filter((option) => option !== null && option !== undefined)
                      : []
                  }
                  defaultValue={data?.keywords?.split(',')}
                  freeSolo={keywords?.length >= 3 ? false : true}
                  onChange={(e, val) => handleKeywordSelection(val)}
                  getOptionDisabled={() => (keywords?.length >= 3 ? true : false)}
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        key={index}
                        size="small"
                        label={option}
                        variant="outlined"
                        {...getTagProps({ index })}
                        sx={{ height: 20, '& .MuiChip-label': { fontSize: 11 } }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Keywords"
                      variant="outlined"
                      error={!!errors?.keywords}
                      onChange={(e) => onKeywordChange(e?.target?.value)}
                      placeholder={keywords?.length > 0 ? '' : 'Select upto three keywords'}
                    />
                  )}
                />
                <HelperText>{errors?.keywords?.message}</HelperText>
                {Array.isArray(errors.keywords) &&
                  errors.keywords?.map((error, index) => (
                    <HelperText key={index}>{formatErrorMessage(error?.message)}</HelperText>
                  ))}
              </Stack>
              {renderFeaturedImage()}

              <Box width="100%" maxWidth={600} margin="auto">
                <Editor
                  holder="content-holder"
                  onInitialize={handleInitialize}
                  onChange={() => debounce(handleEditorChange)()}
                  tools={{
                    header: Headers,
                    image: {
                      class: Images,
                      config: {
                        field: 'image',
                        uploader: {
                          async uploadByFile(file) {
                            const data = await uploadFile(file, 'image');
                            setBodyImageCache((prev) => [...prev, data]);
                            return {
                              success: 1,
                              file: data,
                            };
                          },
                        },
                      },
                    },
                    list: {
                      class: List,
                      inlineToolbar: true,
                      config: {
                        defaultStyle: 'unordered',
                      },
                    },
                    warning: Warnings,
                  }}
                  placeholder="Start typing here. . . . .">
                  <Box id="content-holder" />
                </Editor>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default EditArticle;
