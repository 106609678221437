import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { brandColors } from '../constants/colors';
import PrivateNavbar from '../components/global/PrivateNavbar';
import PrivateSideNav from '../components/global/PrivateSideNav';
import PrivateRightFeed from '../components/global/PrivateRightFeed';

const PrivateLayout = ({ seo, disableSideNav, rightFeed }) => {
  return (
    <Box minHeight="100vh" bgcolor={brandColors.background}>
      <Helmet>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description} />
      </Helmet>
      <Stack width="100%">
        <PrivateNavbar />
        <Stack
          width="100%"
          direction="row"
          minHeight={{ sm: 'calc(100vh - 50px)', xs: 'calc(100vh - 100px)' }}>
          {!disableSideNav && (
            <Stack
              top="50px"
              position="sticky"
              height="calc(100vh - 50px)"
              width={{ xs: 0, sm: 70, lg: 300 }}>
              <PrivateSideNav />
            </Stack>
          )}
          <Stack flex={1}>
            <Outlet />
          </Stack>
          {rightFeed && (
            <Stack
              top={60}
              overflow="auto"
              position="sticky"
              height="calc(100vh - 70px)"
              width={{ xs: 70, md: 300 }}
              borderLeft="1px solid #e0e0e0"
              bgcolor={brandColors.background}
              display={{ md: 'block', xs: 'none' }}>
              <PrivateRightFeed />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PrivateLayout;
