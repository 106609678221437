import React, { useEffect, useState } from 'react';
import {
  GroupTwoTone,
  KeyboardArrowDownTwoTone,
  LockTwoTone,
  PublicTwoTone,
  RemoveTwoTone,
} from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { Stack, Typography, TextField, IconButton, Menu } from '@mui/material';

import Button from '../../widgets/Button';
import HelperText from '../../widgets/HelperText';
import PageWrapper from '../../components/global/PageWrapper';
import { publicationSchema } from '../../validations/publication';
import { usePrompt } from '../../utils/routerHooks';
import { createNewPublication } from '../../api/content';
import { USER_HOME, USER_PROFILE } from '../../constants/router-urls';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AddNewPublication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [privacy, setPrivacy] = useState('PUBLIC');
  const [isCreating, setIsCreating] = useState('');
  const [isBlocking, setIsBlocking] = useState(false);
  const [contentSaved, setContentSaved] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  usePrompt(
    'This page is asking you to confirm that you want to leave — information you’ve entered may not be saved.',
    isBlocking,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handlePrivacyOptions = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const privacyOptions = [
    { label: 'Public', value: 'PUBLIC', icon: <PublicTwoTone /> },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: <LockTwoTone />,
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: <GroupTwoTone />,
    },
  ];

  const resolver = yupResolver(publicationSchema);
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const title = useWatch({ control, name: 'title' });
  const description = useWatch({ control, name: 'description' });
  const abstract = useWatch({ control, name: 'abstract' });
  const url = useWatch({ control, name: 'url' });
  const authors = useWatch({ control, name: 'authors' });

  const handler = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (
      (title ||
        description ||
        abstract ||
        url ||
        (authors?.length > 0 &&
          authors.find((author) => author?.name || author?.email || author?.affiliation))) &&
      !contentSaved
    ) {
      setIsBlocking(true);
      window.addEventListener('beforeunload', handler);
      return () => {
        setIsBlocking(false);
        window.removeEventListener('beforeunload', handler);
      };
    }

    setIsBlocking(false);
    return () => {};
  }, [title, description, abstract, url, authors, contentSaved]);

  useEffect(() => {
    if (!isInitialRender) {
      if (authors?.length > 0) {
        setError('authors', {
          type: 'manual',
          message: '',
        });
      } else {
        setError('authors', {
          type: 'manual',
          message: 'please add at least one author',
        });
      }
    }
  }, [authors]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'authors',
  });

  const onCreatePublication = async (isPublished) => {
    setIsCreating(isPublished ? 'Publishing' : 'Saving');
    const formData = {
      title,
      description,
      abstract,
      url,
      authors,
      privacy,
      isPublished,
    };
    const res = await createNewPublication(formData);
    if (res?.status === 200) {
      setContentSaved(true);
      setTimeout(() => {
        switch (location?.state?.from) {
          case 'feed':
            navigate(`${USER_HOME}/publications`, { replace: true });
            break;
          case 'timeline':
            navigate(`${USER_PROFILE}/${user?.username}/timeline`, { replace: true });
            break;
          default:
            navigate(`${USER_HOME}/publications`, { replace: true });
        }
      }, 800);
    } else {
      setIsCreating('');
    }
  };

  return (
    <PageWrapper isAuth>
      <Stack spacing={2} width="100%" maxWidth={600} margin="auto">
        <Typography fontSize={24} fontWeight={600}>
          Add new publication
        </Typography>
        <Stack
          width="100%"
          direction="row"
          alignSelf="end"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between">
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={handlePrivacyOptions}
            sx={{ minWidth: 'fit-content', my: 1 }}
            endIcon={<KeyboardArrowDownTwoTone />}
            startIcon={privacyOptions.find((option) => option.value === privacy)?.icon}>
            {privacyOptions.find((option) => option.value === privacy)?.label}
          </Button>
          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <Stack px={1} spacing={1} alignItems="start">
              {privacyOptions.map((option) => (
                <Button
                  fullWidth
                  size="small"
                  key={option.value}
                  startIcon={option.icon}
                  color={option.value === privacy ? 'primary' : 'secondary'}
                  sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                  onClick={() => {
                    setPrivacy(option.value);
                    handleClose();
                  }}>
                  {option.label}
                </Button>
              ))}
            </Stack>
          </Menu>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{ minWidth: 'fit-content' }}
              loading={isCreating === 'Saving'}
              disabled={isCreating === 'Publishing'}
              onClick={() => handleSubmit(() => onCreatePublication(false))()}>
              Save As Draft
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={{ minWidth: 'fit-content' }}
              disabled={isCreating === 'Saving'}
              loading={isCreating === 'Publishing'}
              onClick={() => handleSubmit(() => onCreatePublication(true))()}>
              Publish
            </Button>
          </Stack>
        </Stack>

        <Stack
          spacing={2}
          width="100%"
          sx={{
            opacity: isCreating ? 0.5 : 1,
            pointerEvents: isCreating ? 'none' : 'auto',
          }}>
          <Stack>
            <TextField
              focused
              fullWidth
              type="text"
              label="Title"
              variant="standard"
              {...register('title')}
              error={!!errors?.title}
              placeholder="Title of your paper"
              sx={{ maxWidth: 600, '& .MuiInput-input': { fontSize: '2rem', fontWeight: 700 } }}
            />
            <HelperText>{errors?.title?.message}</HelperText>
          </Stack>
          <Stack>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              minRows={2}
              type="text"
              sx={{ maxWidth: 600 }}
              label="Short Description"
              {...register('description')}
              error={!!errors?.description}
              placeholder="A short description to let people know what your publication is about"
            />
            <HelperText>{errors?.description?.message}</HelperText>
          </Stack>
          <Stack>
            <TextField
              fullWidth
              multiline
              type="text"
              maxRows={20}
              minRows={10}
              label="Abstract"
              sx={{ maxWidth: 600 }}
              {...register('abstract')}
              error={!!errors?.abstract}
              placeholder="Abstract of your paper"
            />
            <HelperText>{errors?.abstract?.message}</HelperText>
          </Stack>
          <Stack>
            <TextField
              fullWidth
              type="text"
              label="URL"
              variant="standard"
              {...register('url')}
              error={!!errors?.url}
              sx={{ maxWidth: 600 }}
              placeholder="Link to your paper"
            />
            <HelperText>{errors?.url?.message}</HelperText>
          </Stack>
          <Stack spacing={1} width="100%">
            <Typography fontSize={20} fontWeight={600}>
              Authors
            </Typography>
            {fields.map((_, index) => (
              <Stack key={index} direction="row" flexWrap="wrap" alignItems="start">
                <Stack sx={{ width: { xs: '85%', md: 180 }, mr: 1, mt: 1 }}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Author Name"
                    placeholder="Author Name"
                    {...register(`authors[${index}].name`)}
                    error={!!errors?.authors?.[index]?.name}
                  />
                  <HelperText>{errors?.authors?.[index]?.name?.message}</HelperText>
                </Stack>
                <Stack sx={{ width: { xs: '85%', md: 180 }, mr: 1, mt: 1 }}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Author Email"
                    placeholder="Author Email"
                    {...register(`authors[${index}].email`)}
                    error={!!errors?.authors?.[index]?.email}
                  />
                  <HelperText>{errors?.authors?.[index]?.email?.message}</HelperText>
                </Stack>
                <Stack sx={{ width: { xs: '85%', md: 180 }, mr: 1, mt: 1 }}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Author Affiliation"
                    placeholder="Author Affiliation"
                    {...register(`authors[${index}].affiliation`)}
                    error={!!errors?.authors?.[index]?.affiliation}
                  />
                  <HelperText>{errors?.authors?.[index]?.affiliation?.message}</HelperText>
                </Stack>
                <IconButton
                  size="small"
                  color="error"
                  sx={{ mt: 1.5 }}
                  onClick={() => remove(index)}>
                  <RemoveTwoTone />
                </IconButton>
              </Stack>
            ))}
            <Stack>
              <Button
                variant="outlined"
                color={errors?.authors?.message ? 'error' : 'secondary'}
                onClick={() => {
                  setIsCreating();
                  append({ name: '' });
                }}>
                Add Author
              </Button>
              {errors?.authors?.message && <HelperText>{errors?.authors?.message}</HelperText>}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default AddNewPublication;
