import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PersonRemoveAlt1TwoTone } from '@mui/icons-material';
import { colors, Divider, Skeleton, Stack, Typography } from '@mui/material';

import UserCard from '../global/UserCard';
import Button from '../../widgets/Button';
import { fetchBlockedUsers, unblockUser } from '../../api/connection';
import AlertDialog from '../global/AlertDialog';
import word from '../../utils/word';

const BlockedUserTab = () => {
  const increaseBy = 20;
  const [isLoading, setIsLoading] = useState(0);
  const [limit, setLimit] = useState(increaseBy);
  const [selectedUser, setSelectedUser] = useState();
  const [isUnblocking, setIsUnblocking] = useState(false);
  const [unblockDialog, setUnblockDialog] = useState(false);
  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    (async () => {
      await fetchBlockedUsers({ limit });
      setIsLoading(1);
    })();
  }, []);

  useEffect(() => {
    if (connection?.fetchBlockedUsers) {
      (async () => {
        await fetchBlockedUsers({ limit });
      })();
    }
  }, [connection]);

  const handleUnblock = async () => {
    setIsUnblocking(true);
    await unblockUser(selectedUser?.uid);
    setIsUnblocking(false);
  };

  const handleLoadMore = async () => {
    setIsLoading(2);
    await fetchBlockedUsers({ limit: limit + increaseBy });
    setLimit(limit + increaseBy);
    setIsLoading(1);
  };

  return (
    <Stack>
      <AlertDialog
        title="Unblock❓"
        isOpen={unblockDialog}
        onResolved={handleUnblock}
        resolveLoading={isUnblocking}
        onClose={() => {
          setUnblockDialog(false);
          setTimeout(() => {
            setSelectedUser();
          }, 500);
        }}
        message={`Are you sure you want to unblock ${word.displayName(
          selectedUser?.firstname,
          selectedUser?.lastname,
          'this ',
        )}?`}
      />
      <Typography fontSize={18} fontWeight={700}>
        Blocked Users{' '}
        <span style={{ color: colors.grey[500] }}>({connection?.totalBlockedUsers})</span>
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" flexWrap="wrap">
        {isLoading === 0 ? (
          [...Array(limit)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))
        ) : connection?.blockedUsers?.length > 0 ? (
          connection?.blockedUsers?.map((item) => (
            <UserCard mb={1} key={item?.id} user={item}>
              <Button
                fullWidth
                size="small"
                color="warning"
                sx={{ mt: '5px' }}
                variant="outlined"
                loading={isUnblocking === item?.uid}
                startIcon={<PersonRemoveAlt1TwoTone />}
                onClick={() => {
                  setSelectedUser(item);
                  setUnblockDialog(true);
                }}>
                Unblock
              </Button>
            </UserCard>
          ))
        ) : (
          <Typography pb={2} fontSize={14} color={colors.grey[600]}>
            No blocked users
          </Typography>
        )}
        {isLoading === 2 &&
          [...Array(increaseBy)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))}
      </Stack>
      {connection?.totalBlockedUsers > limit && (
        <Stack mt={1} alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleLoadMore}
            loading={isLoading === 2}>
            Load More
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default BlockedUserTab;
