import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { fetchFeedContents } from '../../../api/content';
import { tempActions } from '../../../store/reducers/tempReducer';
import ArticleSkeleton from './ArticleSkeleton';
import ArticleItem from './ArticleItem';

const ArticlesTab = () => {
  const dispatch = useDispatch();
  const feed = useSelector((state) => state.feed);
  const tempState = useSelector((state) => state.temp);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    (async () => {
      setIsLoading(true);
      await fetchFeedContents({ type: 'ARTICLE', offset: 0 }, true);
      setIsLoading(false);
    })();
  }, []);

  const loadMoreArticles = async () => {
    const offset =
      Math.ceil(feed?.totalFeedArticles / feed?.limit) > feed?.currentArticlesOffset + 1
        ? feed?.currentArticlesOffset + 1
        : 0;
    await fetchFeedContents({ type: 'ARTICLE', offset });
    dispatch(tempActions.controller({ isFetching: false }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    dispatch(tempActions.controller({ isFetching: true }));
    loadMoreArticles();
  }, [tempState.shouldLoadMore]);

  return (
    <Stack width="100%" spacing={2} alignItems="center">
      {isLoading && feed?.feedArticles?.length === 0
        ? [...Array(5)].map((_, index) => <ArticleSkeleton key={index} />)
        : feed?.feedArticles?.map((article, index) => (
            <ArticleItem key={index} article={article} from="feed" />
          ))}
      {!isLoading && feed?.feedArticles?.length > 0 && <ArticleSkeleton />}
      {!isLoading && feed?.feedArticles?.length === 0 && (
        <Typography p={1} fontSize={14} color="textSecondary">
          No news or blogs to show
        </Typography>
      )}
    </Stack>
  );
};

export default ArticlesTab;
