import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from './reducers/rootReducer';

import { feedActions } from './reducers/feedReducer';
import { connectionActions } from './reducers/connectionReducer';
import { timelineActions } from './reducers/timelineReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['temp', 'connection'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export const resetStore = () => {
  store.dispatch(feedActions.reset());
  store.dispatch(timelineActions.reset());
  store.dispatch(connectionActions.reset());
};
