import * as yup from 'yup';
import { imageValidation } from '../utils/validation';

export const createNewBlogSchema = (imageUploaded) =>
  yup.object().shape({
    title: yup.string().required('please enter article title').min(5).max(128),
    description: yup.string().required('please provide short description').min(5).max(256),
    category: yup.string().required('please select category').min(3).max(64),
    keywords: yup.array().of(yup.string().min(3).max(64)).max(3, 'maximum 3 keywords are allowed'),
    featuredImage: imageUploaded ? imageValidation.optional : imageValidation.required,
  });
