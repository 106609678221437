import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Tooltip,
  TextField,
  IconButton,
  Typography,
  // InputAdornment,
} from '@mui/material';
import {
  MenuTwoTone,
  // SearchOffTwoTone,
  // ManageSearchTwoTone,
  ChevronRightTwoTone,
  PersonOutlineTwoTone,
} from '@mui/icons-material';

import navbarUrls from '../../constants/navbar-urls';
import { brandColors } from '../../constants/colors';
import { INDEX, SIGNIN, USER_HOME } from '../../constants/router-urls';
import Avatar from '../../widgets/Avatar';
import word from '../../utils/word';

const PublicNavbar = () => {
  const { pathname } = useLocation();
  const [drawer, setDrawer] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  // const [searchBoxWidth, setSearchBoxWidth] = useState(180);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const onPageScroll = () => {
    let containerElement;
    try {
      containerElement = document?.getElementsByClassName('app-scroll-container')?.[0];
      if (containerElement) {
        if (containerElement?.scrollTop > 119) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let containerElement;
    try {
      containerElement = document?.getElementsByClassName('app-scroll-container')?.[0];
      if (containerElement) {
        containerElement.addEventListener('scroll', onPageScroll);
      }
    } catch (e) {
      console.error(e);
    }
    return () => containerElement.removeEventListener('scroll', onPageScroll);
  }, []);

  useEffect(() => {
    setDrawer(false);
  }, [pathname]);

  useEffect(() => {
    if (!drawer) {
      setSearchActive(false);
    }
  }, [drawer]);

  return (
    <React.Fragment>
      {/* HEADER */}
      <Stack
        top={0}
        left={0}
        zIndex={5}
        width="100%"
        bgcolor="#fff"
        direction="row"
        alignItems="center"
        px={{ md: 5, xs: 4 }}
        justifyContent="space-between"
        position={{ lg: 'static', xs: 'sticky' }}
        height={{ lg: '120px', sm: '100px', xs: '80px' }}
        boxShadow={{ lg: 'none', xs: '0px 2px 2px rgba(0,0,0,0.2)' }}>
        {/* LOGO & TITLE */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Stack direction="row" alignItems="center">
            <Box
              pr={1}
              component="img"
              src="/assets/images/logo.png"
              height={{ lg: '75px', xs: '60px' }}
              borderRight={{ sm: `2px solid ${brandColors.secondary}`, xs: 'none' }}
            />
            <Typography
              pl={1}
              fontWeight="800"
              color={brandColors.secondary}
              display={{ sm: 'block', xs: 'none' }}
              fontSize={{ lg: '0.9rem', md: '0.8rem', sm: '0.75rem' }}>
              THE INTERNATIONAL SOCIETY OF
              <br />
              BANGLADESH-AFFILIATED
              <br />
              MICROBIOLOGISTS
            </Typography>
          </Stack>
        </Link>

        {/* SEARCH & LOGIN (ONLY VISIBLE ON SCREEN WIDTH LARGER THAN 900px) */}
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          display={{ md: 'flex', xs: 'none' }}>
          {/* <TextField
            size="small"
            placeholder="search"
            onBlur={() => setSearchBoxWidth(180)}
            onFocus={() => setSearchBoxWidth(240)}
            sx={{ width: searchBoxWidth, transition: 'all 0.3s ease' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ManageSearchTwoTone sx={{ width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          /> */}
          <Button
            startIcon={!isAuth && <PersonOutlineTwoTone />}
            endIcon={
              isAuth && (
                <Avatar
                  shape="circle"
                  src={user?.image}
                  size={{ xs: 30 }}
                  label={word.displayInitials(user?.firstname, user?.lastname)}
                />
              )
            }
            component={Link}
            to={isAuth ? USER_HOME : SIGNIN}
            sx={{ color: isAuth ? brandColors.secondary : brandColors.primary }}>
            {isAuth ? user?.firstname : 'Sign In'}
          </Button>
          <Button
            startIcon={<MenuTwoTone />}
            onClick={() => setDrawer(!drawer)}
            sx={{
              display: { lg: 'none', md: 'flex' },
              color: isAuth ? brandColors.secondary : brandColors.primary,
            }}>
            Menu
          </Button>
        </Stack>

        {/* SEARCH, LOGIN & MOBILE MENU (ONLY VISIBLE ON SCREEN WIDTH SMALLER THAN 900px) */}
        <Stack direction="row" alignItems="center" display={{ md: 'none', xs: 'flex' }}>
          {/* <Tooltip title={searchActive ? 'close search' : 'search'} placement="bottom" arrow>
            <IconButton color="primary" onClick={() => setSearchActive(!searchActive)}>
              {searchActive ? <SearchOffTwoTone /> : <ManageSearchTwoTone />}
            </IconButton>
          </Tooltip> */}
          <Tooltip title={isAuth ? 'feed' : 'sign in'} placement="bottom" arrow>
            <IconButton
              component={Link}
              to={isAuth ? USER_HOME : SIGNIN}
              sx={{ color: isAuth ? brandColors.secondary : brandColors.primary }}>
              {isAuth ? (
                <Avatar
                  shape="circle"
                  src={user?.image}
                  size={{ xs: 30 }}
                  label={word.displayInitials(user?.firstname, user?.lastname)}
                />
              ) : (
                <PersonOutlineTwoTone />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="menu" placement="bottom" arrow>
            <IconButton
              onClick={() => setDrawer(!drawer)}
              sx={{ color: isAuth ? brandColors.secondary : brandColors.primary }}>
              <MenuTwoTone />
            </IconButton>
          </Tooltip>
        </Stack>

        {/* MINI SEARCH PANEL (ONLY VISIBLE UNDER HEADER ON SCREEN WIDTH SMALLER THAN 900px) */}
        <Stack
          px={1}
          left={0}
          zIndex={1}
          width="100%"
          height="50px"
          bgcolor="#fff"
          direction="row"
          overflow="hidden"
          alignItems="center"
          position="absolute"
          top={{ sm: '100px', xs: '80px' }}
          display={{ md: 'none', xs: 'flex' }}
          sx={{
            transformOrigin: 'top',
            transform: searchActive ? 'scale(1,1)' : 'scale(1,0)',
            transition: 'all 0.3s ease',
          }}
          boxShadow="0px 2px 2px rgba(0,0,0,0.2)">
          <TextField fullWidth size="small" variant="standard" placeholder="search" />
        </Stack>
      </Stack>

      <Divider sx={{ display: { lg: 'block', xs: 'none' } }} />

      {/* NAV BAR (ONLY VISIBLE ON SCREEN WIDTH LARGER THAN 1200px) */}
      <Stack
        top={0}
        left={0}
        zIndex={5}
        spacing={2}
        width="100%"
        height="50px"
        bgcolor="#fff"
        direction="row"
        position="sticky"
        alignItems="center"
        justifyContent="center"
        display={{ lg: 'flex', xs: 'none' }}
        boxShadow="0px 2px 2px rgba(0,0,0,0.2)">
        {pathname !== INDEX && scrolled && (
          <Tooltip title="home" placement="bottom" arrow>
            <Link to={INDEX}>
              <Box
                component="img"
                src="/assets/images/logo.png"
                height={{ lg: '30px', xs: '60px' }}
              />
            </Link>
          </Tooltip>
        )}
        {navbarUrls.map((item) => (
          <Button
            to={item.path}
            component={Link}
            key={item.label}
            sx={{
              borderRadius: 0,
              fontWeight: 700,
              fontSize: '0.8rem',
              textTransform: 'capitalize',
              color: pathname === item.path ? brandColors.primary : '#171820',
              borderBottom: pathname === item.path ? `3px solid ${brandColors.primary}` : 'none',
            }}>
            {item.label}
          </Button>
        ))}
        {scrolled && (
          <Tooltip title={isAuth ? 'feed' : 'sign in'} placement="bottom" arrow>
            <IconButton
              component={Link}
              to={isAuth ? USER_HOME : SIGNIN}
              sx={{ color: isAuth ? brandColors.secondary : brandColors.primary }}>
              {isAuth ? (
                <Avatar
                  shape="circle"
                  src={user?.image}
                  size={{ xs: 30 }}
                  label={word.displayInitials(user?.firstname, user?.lastname)}
                />
              ) : (
                <PersonOutlineTwoTone />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {/* DRAWER NAV (ONLY VISIBLE ON SCREEN WIDTH SMALLER THAN 900px)*/}
      <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
        <Stack direction="row" sx={{ p: 1 }}>
          <IconButton onClick={() => setDrawer(false)}>
            <ChevronRightTwoTone />
          </IconButton>
        </Stack>
        <Divider />
        <Stack spacing={1} sx={{ width: '260px', px: 2, mt: 2 }} alignItems="start">
          <Button
            to={INDEX}
            component={Link}
            sx={{
              borderRadius: 0,
              fontWeight: 700,
              fontSize: '0.8rem',
              textTransform: 'capitalize',
              color: pathname === INDEX ? brandColors.primary : '#171820',
              borderBottom: pathname === INDEX ? `3px solid ${brandColors.primary}` : 'none',
            }}>
            Home
          </Button>
          {navbarUrls.map((item) => (
            <Button
              component={Link}
              key={item.label}
              to={item.path}
              sx={{
                borderRadius: 0,
                fontWeight: 700,
                fontSize: '0.8rem',
                textTransform: 'capitalize',
                color: pathname === item.path ? brandColors.primary : '#171820',
                borderBottom: pathname === item.path ? `3px solid ${brandColors.primary}` : 'none',
              }}>
              {item.label}
            </Button>
          ))}
        </Stack>
      </Drawer>
    </React.Fragment>
  );
};

export default PublicNavbar;
