import React from 'react';
import {
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
} from '@mui/material';
import Button from '../../widgets/Button';

const AlertDialog = ({
  title,
  isOpen,
  message,
  onClose,
  noReject,
  noResolve,
  onResolved,
  onRejected,
  rejectText,
  resolveText,
  hideButtons,
  rejectLoading,
  resolveLoading,
}) => {
  return (
    <MuiDialog open={isOpen} onClose={resolveLoading || rejectLoading ? null : onClose}>
      <DialogTitle>{title || 'Alert!'}</DialogTitle>
      <DialogContent>
        <Typography>{message || 'Are your sure?'}</Typography>
      </DialogContent>
      {!hideButtons && (
        <DialogActions>
          {!noReject && (
            <Button
              color="error"
              variant="outlined"
              loading={rejectLoading}
              disabled={resolveLoading}
              onClick={async () => {
                await onRejected?.();
                onClose();
              }}>
              {rejectText || 'No'}
            </Button>
          )}
          {!noResolve && (
            <Button
              id="confirmBtn"
              variant="contained"
              disabled={rejectLoading}
              loading={resolveLoading}
              onClick={async () => {
                await onResolved?.();
                onClose();
              }}>
              {resolveText || 'Yes'}
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default AlertDialog;
