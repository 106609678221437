import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#4562f7',
    },
    info: {
      main: '#4562f7',
    },
    secondary: {
      main: '#2B3E55',
    },
    custom: {
      main: '#E52842',
    },
    text: {
      primary: '#272739',
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: "'Poppins', sans-serif",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          boxShadow: 'none',
        },
      },
    },
  },
});
