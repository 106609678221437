import React, { useState } from 'react';
import { Card, Stack, colors, Divider, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PostAddTwoTone, BookmarkAddTwoTone } from '@mui/icons-material';

import Avatar from '../../../widgets/Avatar';
import Button from '../../../widgets/Button';
import PostCreationModal from './PostCreationModal';

import word from '../../../utils/word';
import { CREATE_ARTICLE, CREATE_PUBLICATION, USER_PROFILE } from '../../../constants/router-urls';

const PostCreationBox = () => {
  const user = useSelector((state) => state.auth.user);
  const [showPostCreationModal, setShowPostCreationModal] = useState(false);

  return (
    <React.Fragment>
      <PostCreationModal
        isOpen={showPostCreationModal}
        onClose={() => setShowPostCreationModal(false)}
      />

      <Card
        sx={{
          p: 2,
          boxShadow: 0,
          width: '100%',
          bgcolor: colors.grey[300],
          borderRadius: '4px 4px 0 0',
        }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{ textDecoration: 'none' }}
            to={`${USER_PROFILE}/${user.username}`}
            component={user?.flags?.isVisible ? Link : 'div'}>
            <Avatar
              src={user.image}
              label={word.displayInitials(user?.firstname, user?.lastname)}
            />
          </Box>
          <Stack
            px={2}
            flex={1}
            spacing={1}
            height={50}
            bgcolor="#fff"
            direction="row"
            borderRadius={1}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowPostCreationModal(true)}>
            <Typography fontSize={{ xs: 16, sm: 18 }} fontWeight={700} color="#404040">
              Share your thoughts
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            component={Link}
            startIcon={<BookmarkAddTwoTone />}
            to={{ pathname: CREATE_PUBLICATION, state: { from: 'feed' } }}
            sx={{ boxShadow: 0, textTransform: 'capitalize', color: colors.grey[900] }}>
            New Publication
          </Button>
          <Button
            size="small"
            component={Link}
            startIcon={<PostAddTwoTone />}
            to={{ pathname: CREATE_ARTICLE, state: { from: 'feed' } }}
            sx={{ boxShadow: 0, textTransform: 'capitalize', color: colors.grey[900] }}>
            News or Blog
          </Button>
        </Stack>
      </Card>
    </React.Fragment>
  );
};

export default PostCreationBox;
