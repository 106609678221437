import { MEETING_EVENTS, NEWS_BLOGS, PUBLICATIONS } from '../router-urls';

// list of placeholder news for microbiology research
export const featured = [
  {
    title: 'Latest Events',
    subtitle: 'Check out the latest events on ISBM!',
    image: '/assets/images/landing/event.jpg',
    link: MEETING_EVENTS,
  },
  {
    title: 'Latest Articles',
    subtitle: 'Read the latest articles on ISBM!',
    image: '/assets/images/landing/article.jpg',
    link: NEWS_BLOGS,
  },
  {
    title: 'Latest Publications',
    subtitle: 'Read the latest publications on ISBM!',
    image: '/assets/images/landing/publication.jpg',
    link: PUBLICATIONS,
  },
];

export const banners = [
  { image: '/assets/images/landing/banner01.jpg' },
  { image: '/assets/images/landing/banner02.jpg' },
  { image: '/assets/images/landing/banner03.jpg' },
  { image: '/assets/images/landing/banner04.jpg' },
  { image: '/assets/images/landing/banner05.jpg' },
];
