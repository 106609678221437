import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetchUserProfile: false,
  fetchConnections: false,
  connections: [],
  totalConnections: 0,
  fetchConnectionRequests: false,
  connectionRequests: [],
  totalRequests: 0,
  fetchSentRequests: false,
  sentRequests: [],
  totalSentRequests: 0,
  fetchConnectionRecommendation: false,
  connectionRecommendations: [],
  totalRecomendations: 0,
  fetchBlockedUsers: false,
  blockedUsers: [],
  totalBlockedUsers: 0,
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    controller: (state, action) => {
      return { ...state, ...action.payload };
    },
    setConnections: (state, action) => {
      return {
        ...state,
        fetchConnections: false,
        connections: action.payload.data,
        totalConnections: action.payload.totalEntries,
      };
    },
    setConnectionRequests: (state, action) => {
      return {
        ...state,
        fetchConnectionRequests: false,
        connectionRequests: action.payload.data,
        totalRequests: action.payload.totalEntries,
      };
    },
    setSentRequests: (state, action) => {
      return {
        ...state,
        fetchSentRequests: false,
        sentRequests: action.payload.data,
        totalSentRequests: action.payload.totalEntries,
      };
    },
    setConnectionRecommendations: (state, action) => {
      return {
        ...state,
        fetchConnectionRecommendation: false,
        connectionRecommendations: action.payload.data,
        totalRecomendations: action.payload.totalEntries,
      };
    },
    setBlockedUsers: (state, action) => {
      return {
        ...state,
        fetchBlockedUsers: false,
        blockedUsers: action.payload.data,
        totalBlockedUsers: action.payload.totalEntries,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const connectionActions = connectionSlice.actions;
export default connectionSlice.reducer;
