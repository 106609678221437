import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import connectionReducer from './connectionReducer';
import feedReducer from './feedReducer';
import timelineReducer from './timelineReducer';
import publicReducer from './publicReducer';
import tempReducer from './tempReducer';

export default combineReducers({
  auth: authReducer,
  connection: connectionReducer,
  feed: feedReducer,
  timeline: timelineReducer,
  public: publicReducer,
  temp: tempReducer,
});
