export default [
  {
    title: 'Resume Services',
    description:
      'Students, young professionals, and recent graduates require special attention for resume preparation. A good resume can sell your skills and potential. With our help, you can craft documents that highlight your achievements and demonstrate your future potential. This service is available to our registered members.',
  },
  {
    title: 'Career Coach',
    description:
      'Having a clearer idea of what you really want in your career and life will help you set your career purpose, We can help our members identify at least some of the key components for a successful and fulfilling career. Together, we will create a roadmap to help you achieve clarity and certainty about your career and future.',
  },
  {
    title: 'Certification Training',
    description:
      'we will introduce ISBM Career Certificates that prepare graduates for careers in fast-growing, high-demand technology fields. Programs will be designed by experts according to current trends and needs. Furthermore, trainees will receive support and tips on resumes, interviews, and job searches, which will help them find their desired job.',
  },
  {
    title: 'Job Alert & Posts',
    description:
      'We are working on a platform where our registered members can post the job. job seekers can create ISBM job alerts and can stay up-to-date with new job postings matching their preferences, We offer members the option to receive these alerts via email or app.',
  },
  {
    title: 'Networking',
    description:
      'Each member can create a profile that can be linked through a professional network focusing on career opportunities and growth. We will use this network to establish and maintain professional relationships, find employment or hire employees, and keep informed about industry trends.',
  },
];
