import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { INDEX, USER_HOME } from '../../constants/router-urls';

const NotFound = ({ isAuth }) => {
  return (
    <Stack alignItems="center" width="100%" spacing={2} mb={10}>
      <Box
        mt={10}
        width="35%"
        minWidth={320}
        component="img"
        src="/assets/images/404-banner.jpeg"
      />
      <Button size="large" component={Link} to={isAuth ? USER_HOME : INDEX}>
        Back to Home
      </Button>
    </Stack>
  );
};

export default NotFound;
