import React from 'react';
import { colors, Stack, Typography } from '@mui/material';

const Avatar = ({ src, size, label, shape, bgcolor, fontSize, fontWeight }) => {
  return (
    <Stack
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      width={size || 50}
      height={size || 50}
      borderRadius={shape === 'circle' ? '50%' : 1}
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'ease-in-out 300ms',
        bgcolor: bgcolor || colors.grey[600],
        backgroundImage: src?.includes('https://') ? `url(${src})` : null,
      }}>
      {!src?.includes('https://') && (
        <Typography color="#fff" fontSize={fontSize || 16} fontWeight={fontWeight || 700}>
          {(label || '')?.substring(0, 2)?.toUpperCase()}
        </Typography>
      )}
    </Stack>
  );
};

export default Avatar;
