import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArchiveTwoTone,
  BookmarkTwoTone,
  CancelPresentationTwoTone,
  Circle,
  DeleteForeverTwoTone,
  DriveFileRenameOutlineTwoTone,
  ErrorTwoTone,
  GroupTwoTone,
  LockResetTwoTone,
  LockTwoTone,
  MoreHoriz,
  OpenInNewTwoTone,
  PublicTwoTone,
  PublishTwoTone,
  ReportTwoTone,
  UnarchiveTwoTone,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  colors,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import Avatar from '../../widgets/Avatar';
import dateTime from '../../utils/dateTime';
import { EDIT_PUBLICATION, USER_HOME, USER_PROFILE } from '../../constants/router-urls';
import Button from '../../widgets/Button';
import { useSelector } from 'react-redux';
import AlertDialog from './AlertDialog';
import {
  deletePublication,
  publishPublication,
  updatePublication,
  updatePublicationArchiveStatus,
} from '../../api/content';
import word from '../../utils/word';

const PublicationRenderer = ({ isAuth, publication, loading, from }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isAuthor = publication?.author?.id === user?.id;

  const [isPublishing, setIsPublishing] = useState(false);
  const [privacy, setPrivacy] = useState(publication?.privacy);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [isPrivacyUpdating, setIsPrivacyUpdating] = useState(false);
  const [isArchiveStatusChanging, setIsArchiveStatusChanging] = useState(false);
  const [showPrivacyEditingModal, setShowPrivacyEditingModal] = useState(false);
  const [showArchiveStatusChangeAlert, setShowArchiveStatusChangeAlert] = useState(false);

  const [publicationOptionsAnchorEl, setPublicationOptionsAnchorEl] = useState(null);
  const publicationOptionsOpen = Boolean(publicationOptionsAnchorEl);
  const handlePublicationOptions = (event) => setPublicationOptionsAnchorEl(event.currentTarget);
  const handlePublicationOptionsClose = () => setPublicationOptionsAnchorEl(null);

  const privacyOptions = [
    {
      label: 'Public',
      value: 'PUBLIC',
      icon: { base: <PublicTwoTone />, small: <PublicTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: { base: <LockTwoTone />, small: <LockTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: { base: <GroupTwoTone />, small: <GroupTwoTone sx={{ height: 14, width: 14 }} /> },
    },
  ];

  const onPublish = async () => {
    setIsPublishing(true);
    await publishPublication(publication?.uid);
    setIsPublishing(false);
  };

  const onPrivacyUpdate = async () => {
    setIsPrivacyUpdating(true);
    const { title, description, abstract, url, authors, isPublished } = publication;
    const formData = {
      privacy,
      title,
      description,
      abstract,
      url,
      authors,
      isPublished,
    };
    const result = await updatePublication(publication?.uid, formData);
    if (result?.status === 200) {
      setShowPrivacyEditingModal(false);
    }
    setIsPrivacyUpdating(false);
  };

  const handleArchiveStatusChange = async () => {
    setIsArchiveStatusChanging(true);
    await updatePublicationArchiveStatus(publication?.uid, {
      isArchived: !publication?.isArchived,
    });
    setIsArchiveStatusChanging(false);
  };

  const onPublicationDelete = async () => {
    setIsDeleteLoading(true);
    const res = await deletePublication(publication?.uid);
    if (res?.status === 200) {
      switch (from) {
        case 'timeline':
          navigate(`${USER_PROFILE}/${user?.username}/timeline`, { replace: true });
          break;
        default:
          navigate(`${USER_HOME}/publications`, { replace: true });
          break;
      }
    }
    setIsDeleteLoading(false);
  };

  const RenderBody = () => {
    return loading ? (
      <Stack width="100%">
        <Stack
          width="50%"
          minWidth={240}
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, md: 1 }}>
          <Skeleton animation="wave" variant="circular" width={45} height={45} />
          <Stack>
            <Skeleton animation="wave" variant="text" width={120} height={18} />
            <Skeleton animation="wave" variant="text" width={180} height={16} />
          </Stack>
        </Stack>
        <Skeleton animation="wave" variant="text" width="80%" height={32} sx={{ mt: 2 }} />
        <Stack direction="row" flexWrap="wrap" alignItems="center">
          {Array(3)
            .fill()
            .map((_, index) => (
              <Skeleton
                width="20%"
                height={26}
                key={index}
                variant="text"
                animation="wave"
                sx={{ mr: 0.5, mt: 0.5 }}
              />
            ))}
        </Stack>
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 1 }} />
        <Skeleton animation="wave" variant="text" width="70%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="20%" height={26} sx={{ mt: 4 }} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 1 }} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="80%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="18%" height={26} sx={{ mt: 4 }} />
        <Stack spacing={2} sx={{ my: 1 }} width="100%">
          {Array(3)
            .fill()
            .map((_, index) => (
              <Stack key={index} direction="row" alignItems="start" width="100%">
                <Skeleton
                  width={16}
                  height={16}
                  animation="wave"
                  variant="circular"
                  sx={{ mr: 1, pt: 1 }}
                />
                <Stack width="100%">
                  <Skeleton animation="wave" variant="text" width="28%" height={20} />
                  <Skeleton animation="wave" variant="text" width="35%" height={20} />
                  <Skeleton animation="wave" variant="text" width="45%" height={20} />
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    ) : publication ? (
      <Stack width="100%">
        {publication?.isArchived ? (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <ArchiveTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              Archived
            </Typography>
          </Stack>
        ) : publication?.isPublished ? (
          <Stack width="80%" direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
            <Stack
              width={{ xs: 40, md: 50 }}
              sx={{ textDecoration: 'none' }}
              to={`${USER_PROFILE}/${publication?.author?.username}`}
              component={publication?.author?.flags?.isVisible ? Link : 'div'}>
              <Avatar
                shape="circle"
                size={{ xs: 40, md: 50 }}
                src={publication?.author?.image}
                label={word.displayInitials(
                  publication?.author?.firstname,
                  publication?.author?.lastname,
                )}
              />
            </Stack>
            <Stack width="80%">
              <Typography
                maxWidth="100%"
                fontWeight={600}
                overflow="hidden"
                width="fit-content"
                textOverflow="ellipsis"
                color={colors.grey[800]}
                fontSize={{ xs: 14, md: 16 }}
                sx={{ textDecoration: 'none' }}
                to={`${USER_PROFILE}/${publication?.author?.username}`}
                component={publication?.author?.flags?.isVisible ? Link : 'div'}>
                {word.displayName(publication?.author?.firstname, publication?.author?.lastname)}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.5} color={colors.grey[600]}>
                <Typography fontSize={{ xs: 12, md: 14 }}>
                  {dateTime.render(publication?.publishedAt)}
                </Typography>
                {
                  privacyOptions.find((option) => option.value === publication?.privacy)?.icon
                    ?.small
                }
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <BookmarkTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              In Drafts
            </Typography>
          </Stack>
        )}
        <Typography fontSize={38} fontWeight={700} lineHeight={1.2} mt={2}>
          {publication?.title}
        </Typography>
        <Stack direction="row" flexWrap="wrap" alignItems="center" color={colors.grey[600]}>
          {publication?.authors?.map((author, index) => (
            <Chip
              key={index}
              size="small"
              label={author?.name}
              sx={{ mr: 0.5, mt: 0.5, fontSize: 12, borderRadius: 1 }}
            />
          ))}
        </Stack>
        <Typography variant="body2" fontWeight={500} color={colors.grey[700]} mt={2} mb={1}>
          {publication?.description}
        </Typography>
        <Divider />
        <Stack alignItems="start">
          <Button
            component="a"
            color="primary"
            target="_blank"
            href={publication?.url}
            rel="noopener noreferrer"
            endIcon={<OpenInNewTwoTone />}
            sx={{ p: 0, textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            Visit Link
          </Button>
        </Stack>
        <Typography mt={4} fontSize={18} fontWeight={600}>
          Abstract
        </Typography>
        <pre>
          <Typography
            sx={{
              my: 1,
              fontWeight: 500,
              variant: 'body2',
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              wordBreak: 'break-word',
              color: colors.grey[700],
              WebkitBoxOrient: 'vertical',
            }}>
            {publication?.abstract}
          </Typography>
        </pre>
        <Divider />
        <Typography mt={4} fontSize={18} fontWeight={600}>
          Authors
        </Typography>
        <Stack color={colors.grey[600]} my={1} spacing={2}>
          {publication?.authors?.map((author, index) => (
            <Stack key={index} direction="row" alignItems="start">
              <Circle sx={{ pb: 0.5, pr: 1 }} />
              <Stack>
                <Typography variant="body2" fontWeight={600} color={colors.grey[700]}>
                  {author?.name}
                </Typography>
                <Typography
                  component="a"
                  variant="body2"
                  fontWeight={500}
                  color={colors.grey[600]}
                  sx={{ textDecoration: 'none' }}
                  href={`mailto:${author?.email}`}>
                  {author?.email}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {author?.affiliation}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    ) : (
      <Stack p={2} spacing={1} alignItems="center">
        <ErrorTwoTone sx={{ fontSize: 60, color: '#D32F2F' }} />
        <Typography fontSize={15} fontWeight={600} color="error" textAlign="center">
          Sorry, could not find the publication you are looking for!
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      width="100%"
      margin="auto"
      minWidth={300}
      position="relative"
      pb={isAuth ? 0 : 8}
      maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 800 }}>
      {/* publish alert */}
      <AlertDialog
        title="Publish❓"
        onResolved={onPublish}
        isOpen={showPublishAlert}
        resolveLoading={isPublishing}
        onClose={() => setShowPublishAlert(false)}
        message={`Are you sure you want to publish this? It will published with current timestamp and privacy settings (${
          privacyOptions.find((option) => option.value === publication?.privacy)?.label
        }). Once published, you can't undo this action. You may archive this in future.`}
      />

      {/* archive alert */}
      <AlertDialog
        title={publication?.isArchived ? 'Unarchive❓' : 'Archive❓'}
        onResolved={handleArchiveStatusChange}
        isOpen={showArchiveStatusChangeAlert}
        resolveLoading={isArchiveStatusChanging}
        onClose={() => setShowArchiveStatusChangeAlert(false)}
        message={`Are you sure you want to ${
          publication?.isArchived
            ? 'unarchive this? It will be visible again to selected audience'
            : 'archive this? Only you will be able to see archived publications.'
        }`}
      />

      {/* publication delete alert */}
      <AlertDialog
        title="Delete❓"
        isOpen={showDeleteAlert}
        onResolved={onPublicationDelete}
        resolveLoading={isDeleteLoading}
        onClose={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this publication?"
      />

      {/* privacy edit modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPrivacyEditingModal}
        onClose={isPrivacyUpdating ? null : () => setShowPrivacyEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack px={1} spacing={1} alignItems="start">
            {privacyOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                key={option.value}
                startIcon={option.icon.base}
                color={option.value === privacy ? 'primary' : 'secondary'}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  setPrivacy(option.value);
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="error"
              disabled={isPrivacyUpdating}
              onClick={() => setShowPrivacyEditingModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={onPrivacyUpdate}
              loading={isPrivacyUpdating}
              disabled={privacy === publication?.privacy}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* publication options button */}
      {isAuthor && (
        <IconButton
          size="small"
          sx={{
            top: 0,
            right: 0,
            zIndex: 3,
            position: 'absolute',
          }}
          onClick={handlePublicationOptions}>
          <MoreHoriz sx={{ color: colors.grey[700] }} />
        </IconButton>
      )}

      {/* publication options menu */}
      <Menu
        open={publicationOptionsOpen}
        anchorEl={publicationOptionsAnchorEl}
        onClose={handlePublicationOptionsClose}>
        <Stack px={1} spacing={1} alignItems="start">
          {isAuthor && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                publication?.isPublished
                  ? publication?.isArchived
                    ? () => {
                        // unarchive
                        handlePublicationOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                    : () => {
                        // archive
                        handlePublicationOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                  : () => {
                      // publish
                      handlePublicationOptionsClose();
                      setShowPublishAlert(true);
                    }
              }
              startIcon={
                publication?.isPublished ? (
                  publication?.isArchived ? (
                    <UnarchiveTwoTone />
                  ) : (
                    <ArchiveTwoTone />
                  )
                ) : (
                  <PublishTwoTone />
                )
              }
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {publication?.isPublished
                ? publication?.isArchived
                  ? 'Unarchive'
                  : 'Archive'
                : 'Publish'}
            </Button>
          )}

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    navigate(`${EDIT_PUBLICATION}/${publication?.slug}`, {
                      state: { data: publication, from: 'preview' },
                    });
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <DriveFileRenameOutlineTwoTone /> : <CancelPresentationTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Publication' : 'Hide Publication'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    setPrivacy(publication?.privacy);
                    setShowPrivacyEditingModal(true);
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <LockResetTwoTone /> : <BookmarkTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Privacy' : 'Save Publication'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="error"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    setShowDeleteAlert(true);
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <DeleteForeverTwoTone /> : <ReportTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Delete Publication' : 'Report Publication'}
          </Button>
        </Stack>
      </Menu>

      {/* Content Body */}
      <RenderBody />
    </Box>
  );
};

export default PublicationRenderer;
