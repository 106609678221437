import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const PublicationSkeleton = () => {
  return (
    <Stack
      width="100%"
      height={250}
      bgcolor="#fff"
      borderRadius={1}
      border="1px solid rgba(0,0,0,0.1)">
      <Stack p={2} height="100%" color="#fff" alignItems="start" justifyContent="space-evenly">
        <Skeleton animation="wave" variant="text" width="95%" height={32} />
        <Stack width="100%">
          <Skeleton animation="wave" variant="text" width={80} height={18} />
          <Skeleton animation="wave" variant="text" width="80%" height={18} />
          <Skeleton animation="wave" variant="text" width={120} height={18} />
        </Stack>
        <Stack width="100%">
          <Skeleton animation="wave" variant="text" width="100%" height={22} />
          <Skeleton animation="wave" variant="text" width="100%" height={22} />
          <Skeleton animation="wave" variant="text" width="95%" height={22} />
        </Stack>
        <Skeleton
          width={80}
          height={28}
          variant="text"
          animation="wave"
          sx={{ alignSelf: 'end' }}
        />
      </Stack>
    </Stack>
  );
};

export default PublicationSkeleton;
