import 'yup-phone';
import * as yup from 'yup';

export const publicContactValidationSchema = yup.object().shape({
  firstname: yup.string().required('please enter your firstname'),
  lastname: yup.string().required('please enter your lastname'),
  email: yup.string().email('please enter a valid email').required('please enter your email'),
  contact: yup.string().phone().required('please enter your contact number'),
  reason: yup.string().required('please select a reason'),
  message: yup.string().required('please enter your message'),
});
