import request from './request';
import { resetStore, store } from '../store';
import notify from '../utils/notify';
import { authActions } from '../store/reducers/authReducer';

export const loginWithGoogle = async (token) => {
  const response = await request.post('/auth/google-sign-in', { token });
  if (response?.status === 200) {
    store.dispatch(authActions.signin({ user: response?.user, token: response?.token }));
    notify.success(response?.msg || 'Sign in successful');
  }
};

export const login = async (data) => {
  const response = await request.post('/auth/sign-in', data);
  if (response?.status === 200) {
    store.dispatch(authActions.signin({ user: response?.user, token: response?.token }));
    notify.success(response?.msg || 'Sign in successful');
  } else {
    if (response?.flags?.isEmailVerified === false) {
      return 'EMAIL_NOT_VERIFIED';
    }
  }
};

export const updateAuthState = async () => {
  const response = await request.get('/auth/update');
  if (response?.status === 200) {
    store.dispatch(authActions.updateUser({ user: response?.user }));
  }
};

export const signup = async (data) => {
  const response = await request.post('/auth/sign-up', data);
  if (response?.status === 200) {
    return true;
  }
  return false;
};

export const verifyEmail = async (token) => {
  const response = await request.patch(`/auth/verify-email/${token}`);
  if (response?.status === 200) {
    return { isSuccessful: true };
  }
  return { isSuccessful: false, data: response?.data };
};

export const requestMailResendForEmailVerification = async (email) => {
  const response = await request.post('/auth/resend-verification-mail', { email });
  if (response?.status === 200) {
    return true;
  }
  return false;
};

export const signout = async () => {
  const response = await request.post('/auth/sign-out');
  if (response?.status === 200) {
    store.dispatch(authActions.logout());
    resetStore();
    notify.success(response?.msg || 'Sign out successful');
  }
};

export const requestPasswordReset = async (data) => {
  return await request.post('/auth/forgot-password', data);
};

export const resetPassword = async (token, data) => {
  const response = await request.patch(`/auth/reset-password/${token}`, data);
  if (response?.status === 200) {
    return true;
  }
  return false;
};

export const updatePassword = async (data) => {
  const response = await request.patch('/auth/update-password', data);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Password updated successfully');
    return true;
  }
  return false;
};
