import { Construction } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

const DevSign = ({ bgcolor }) => {
  return (
    <Stack alignItems="center" p={2} borderRadius={2} spacing={1} bgcolor={bgcolor || '#fff'}>
      <Construction sx={{ fontSize: 48 }} color="error" />
      <Typography color="error" fontSize={14} fontWeight={500} textAlign="center">
        {'<'} This section is currently under development, you may visit later to check updates.{' '}
        {'/>'}
      </Typography>
    </Stack>
  );
};

export default DevSign;
