import { useEffect } from 'react';
import { useSocket } from './hooks/useSocket';

import notify from './utils/notify';
import { CustomToasts } from './widgets/customToasts';

import { useDispatch, useSelector } from 'react-redux';
import { feedActions } from './store/reducers/feedReducer';

import {
  feedPostLikeHandler,
  feedPostUpdateHandler,
  timelinePostLikeHandler,
  timelinePostUpdateHandler,
} from './api/post';
import {
  fetchConnections,
  fetchConnectionRequests,
  connectionRequestHandler,
  connectionRequestResolver,
  connectionRemoveHandler,
  connectionBlockHandler,
  connectionUnblockHandler,
} from './api/connection';
import { timelineActions } from './store/reducers/timelineReducer';
import {
  articlePreviewUpdateHandler,
  feedArticleUpdateHandler,
  feedPublicationUpdateHandler,
  publicationPreviewUpdateHandler,
  timelineArticleUpdateHandler,
  timelinePublicationUpdateHandler,
} from './api/content';
import { NEWS_BLOGS, PUBLICATIONS } from './constants/router-urls';

const SocketIO = ({ children }) => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const location = window.location;
  const auth = useSelector((state) => state.auth);
  const feed = useSelector((state) => state.feed);
  const connection = useSelector((state) => state.connection);
  const timeline = useSelector((state) => state.timeline);
  const { isAuthenticated, user } = auth;

  /* joining rooms for the current user */
  // eslint-disable-next-line no-unused-vars
  const joinRooms = () => {
    socket.emit('join-room', `connection-request-for-${user?.uid}`);
    socket.emit('join-room', `connection-resolved-for-${user?.uid}`);
    socket.emit('join-room', `connection-removed-for-${user?.uid}`);
    socket.emit('join-room', `connection-blocked-for-${user?.uid}`);
    socket.emit('join-room', `connection-unblocked-for-${user?.uid}`);

    socket.emit('join-room', 'new-post-broadcast');
    socket.emit('join-room', `new-post-for-${user?.uid}`);
    socket.emit('join-room', 'post-update-broadcast');
    socket.emit('join-room', `post-update-for-${user?.uid}`);
    socket.emit('join-room', 'post-remove-broadcast');
    socket.emit('join-room', `post-remove-for-${user?.uid}`);
    socket.emit('join-room', 'post-like-broadcast');

    socket.emit('join-room', 'new-article-broadcast');
    socket.emit('join-room', `new-article-for-${user?.uid}`);
    socket.emit('join-room', 'article-update-broadcast');
    socket.emit('join-room', `article-update-for-${user?.uid}`);
    socket.emit('join-room', 'article-remove-broadcast');
    socket.emit('join-room', `article-remove-for-${user?.uid}`);

    socket.emit('join-room', 'new-publication-broadcast');
    socket.emit('join-room', `new-publication-for-${user?.uid}`);
    socket.emit('join-room', 'publication-update-broadcast');
    socket.emit('join-room', `publication-update-for-${user?.uid}`);
    socket.emit('join-room', 'publication-remove-broadcast');
    socket.emit('join-room', `publication-remove-for-${user?.uid}`);
  };

  /* app level listeners for any socket event from the server */
  useEffect(() => {
    /**
     * CONNECTIONS
     */
    /* triggers when there is a new connection request for the current user or an existing request is cancelled */
    socket.on('handle-connection-request', async (stream) => {
      connectionRequestHandler(stream, location);
      if (stream?.newRequest) {
        notify.custom(CustomToasts.newRequestToast(stream?.newRequest?.requestedByUser), {
          duration: 5 * 1000,
        });
      }
    });

    /* triggers when a connection request send by the current user is either accepted or rejected */
    socket.on('resolve-connection-request', async (stream) => {
      connectionRequestResolver(stream, location);
      if (stream?.newConnection) {
        notify.custom(CustomToasts.newConnectionToast(stream?.newConnection), {
          duration: 5 * 1000,
        });
      }
    });

    /* triggers when a connection disconnects with the current user */
    socket.on('handle-connection-disconnect', async (stream) => {
      connectionRemoveHandler(stream, location);
    });

    /* triggers when a user blocks the current user */
    socket.on('handle-block-user', async (stream) => {
      connectionBlockHandler(stream, location);
    });

    /* triggers when a user unblocks the current user */
    socket.on('handle-unblock-user', async (stream) => {
      connectionUnblockHandler(stream, location);
    });

    /**
     * POSTS
     */
    /* triggers when a new public post is created */
    socket.on('handle-post-broadcast', async (stream) => {
      dispatch(
        feedActions.setFeedPosts({
          data:
            stream?.author?.uid === user?.uid
              ? [stream, ...feed.feedPosts]
              : [...feed.feedPosts, stream],
          totalEntries: feed?.totalFeedPosts + 1,
        }),
      );
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelinePosts({
            data: [stream, ...timeline.timelinePosts],
            totalEntries: timeline?.totalTimelinePosts + 1,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newPostToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a new connection only or private post is created */
    socket.on('handle-post-multicast/unicast', async (stream) => {
      if (stream?.privacy !== 'PRIVATE') {
        dispatch(
          feedActions.setFeedPosts({
            data:
              stream?.author?.uid === user?.uid
                ? [stream, ...feed.feedPosts]
                : [...feed.feedPosts, stream],
            totalEntries: feed?.totalFeedPosts + 1,
          }),
        );
      }
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelinePosts({
            data: [stream, ...timeline.timelinePosts],
            totalEntries: timeline?.totalTimelinePosts + 1,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newPostToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a post is updated and its current privacy is public */
    socket.on('handle-post-update-broadcast', async (stream) => {
      const isSelfPost = stream?.updatedPost?.author?.uid === user?.uid;
      feedPostUpdateHandler(stream, isSelfPost);
      if (stream?.updatedPost?.author?.username === timeline?.username) {
        timelinePostUpdateHandler(stream);
      }
    });

    /* triggers when a post is updated and its current privacy is connections */
    socket.on('handle-post-update-multicast/unicast', async (stream) => {
      const isSelfPost = stream?.updatedPost?.author?.uid === user?.uid;
      feedPostUpdateHandler(stream, isSelfPost);
      if (stream?.updatedPost?.author?.username === timeline?.username) {
        timelinePostUpdateHandler(stream);
      }
    });

    /* triggers when a post is updated and its current privacy is private */
    socket.on('handle-post-remove-broadcast', async (stream) => {
      feedPostUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelinePostUpdateHandler(stream);
      }
    });

    /*
    triggers when a post is updated and its current privacy is connections
    but the current user is not connected with the author
    */
    socket.on('handle-post-remove-multicast/unicast', async (stream) => {
      feedPostUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelinePostUpdateHandler(stream);
      }
    });

    /* triggers when a like is added or removed from a post */
    socket.on('handle-post-like-broadcast', async (stream) => {
      feedPostLikeHandler(stream);
      timelinePostLikeHandler(stream);
    });

    /**
     * ARTICLES
     */
    /* triggers when a new public article is published */
    socket.on('handle-article-broadcast', async (stream) => {
      dispatch(
        feedActions.setFeedArticles({
          data:
            stream?.author?.uid === user?.uid
              ? [stream, ...feed.feedArticles]
              : [...feed.feedArticles, stream],
          totalEntries: feed?.totalFeedArticles + 1,
        }),
      );
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelineArticles({
            data: [stream, ...timeline.timelineArticles],
            totalEntries: timeline?.totalTimelineArticles + 1,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newArticleToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a new connection only or private article is published or a draft is created */
    socket.on('handle-article-multicast/unicast', async (stream) => {
      if (stream?.privacy !== 'PRIVATE' && stream?.isPublished) {
        dispatch(
          feedActions.setFeedArticles({
            data:
              stream?.author?.uid === user?.uid
                ? [stream, ...feed.feedArticles]
                : [...feed.feedArticles, stream],
            totalEntries: feed?.totalFeedArticles + 1,
          }),
        );
      }
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelineArticles({
            data: [stream, ...timeline.timelineArticles],
            totalEntries: timeline?.totalTimelineArticles,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newArticleToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a published article is updated and its current privacy is public */
    socket.on('handle-article-update-broadcast', async (stream) => {
      const isSelfArticle = stream?.updatedArticle?.author?.uid === user?.uid;
      feedArticleUpdateHandler(stream, isSelfArticle);
      if (stream?.updatedArticle?.author?.username === timeline?.username) {
        timelineArticleUpdateHandler(stream);
      }
      const forcePush = location?.pathname === `${NEWS_BLOGS}/${stream?.updatedArticle?.slug}`;
      articlePreviewUpdateHandler(stream, forcePush);
    });

    /* triggers when a published article is updated and its current privacy is connections */
    socket.on('handle-article-update-multicast/unicast', async (stream) => {
      const isSelfArticle = stream?.updatedArticle?.author?.uid === user?.uid;
      feedArticleUpdateHandler(stream, isSelfArticle);
      if (stream?.updatedArticle?.author?.username === timeline?.username) {
        timelineArticleUpdateHandler(stream);
      }
      const forcePush = location?.pathname === `${NEWS_BLOGS}/${stream?.updatedArticle?.slug}`;
      articlePreviewUpdateHandler(stream, forcePush);
    });

    /* triggers when a published article is updated and its current privacy is private */
    socket.on('handle-article-remove-broadcast', async (stream) => {
      feedArticleUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelineArticleUpdateHandler(stream);
      }
      articlePreviewUpdateHandler(stream);
    });

    /*
    triggers when a published article is updated and its current privacy is connections
    but the current user is not connected with the author
    */
    socket.on('handle-article-remove-multicast/unicast', async (stream) => {
      feedArticleUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelineArticleUpdateHandler(stream);
      }
      articlePreviewUpdateHandler(stream);
    });

    /**
     * PUBLICATIONS
     */
    /* triggers when a new public publication is published */
    socket.on('handle-publication-broadcast', async (stream) => {
      dispatch(
        feedActions.setFeedPublications({
          data:
            stream?.author?.uid === user?.uid
              ? [stream, ...feed.feedPublications]
              : [...feed.feedPublications, stream],
          totalEntries: feed?.totalFeedPublications + 1,
        }),
      );
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelinePublications({
            data: [stream, ...timeline.timelinePublications],
            totalEntries: timeline?.totalTimelinePublications + 1,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newPublicationToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a new connection only or private publication is published or a draft is created */
    socket.on('handle-publication-multicast/unicast', async (stream) => {
      if (stream?.privacy !== 'PRIVATE' && stream?.isPublished) {
        dispatch(
          feedActions.setFeedPublications({
            data:
              stream?.author?.uid === user?.uid
                ? [stream, ...feed.feedPublications]
                : [...feed.feedPublications, stream],
            totalEntries: feed?.totalFeedPublications + 1,
          }),
        );
      }
      if (stream?.author?.username === timeline?.username) {
        dispatch(
          timelineActions.setTimelinePublications({
            data: [stream, ...timeline.timelinePublications],
            totalEntries: timeline?.totalTimelinePublications,
          }),
        );
      }
      if (stream?.author?.uid !== user?.uid) {
        notify.custom(CustomToasts.newPublicationToast(stream?.author), { duration: 5 * 1000 });
      }
    });

    /* triggers when a published publication is updated and its current privacy is public */
    socket.on('handle-publication-update-broadcast', async (stream) => {
      const isSelfPublication = stream?.updatedPublication?.author?.uid === user?.uid;
      feedPublicationUpdateHandler(stream, isSelfPublication);
      if (stream?.updatedPublication?.author?.username === timeline?.username) {
        timelinePublicationUpdateHandler(stream);
      }
      const forcePush =
        location?.pathname === `${PUBLICATIONS}/${stream?.updatedPublication?.slug}`;
      publicationPreviewUpdateHandler(stream, forcePush);
    });

    /* triggers when a published publication is updated and its current privacy is connections */
    socket.on('handle-publication-update-multicast/unicast', async (stream) => {
      const isSelfPublication = stream?.updatedPublication?.author?.uid === user?.uid;
      feedPublicationUpdateHandler(stream, isSelfPublication);
      if (stream?.updatedPublication?.author?.username === timeline?.username) {
        timelinePublicationUpdateHandler(stream);
      }
      const forcePush =
        location?.pathname === `${PUBLICATIONS}/${stream?.updatedPublication?.slug}`;
      publicationPreviewUpdateHandler(stream, forcePush);
    });

    /* triggers when a published publication is updated and its current privacy is private */
    socket.on('handle-publication-remove-broadcast', async (stream) => {
      feedPublicationUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelinePublicationUpdateHandler(stream);
      }
      publicationPreviewUpdateHandler(stream);
    });

    /*
    triggers when a published publication is updated and its current privacy is connections
    but the current user is not connected with the author
    */
    socket.on('handle-publication-remove-multicast/unicast', async (stream) => {
      feedPublicationUpdateHandler(stream);
      if (stream?.author?.username === timeline?.username) {
        timelinePublicationUpdateHandler(stream);
      }
      publicationPreviewUpdateHandler(stream);
    });

    return () => {
      socket.off('handle-connection-request');
      socket.off('resolve-connection-request');
      socket.off('handle-connection-disconnect');
      socket.off('handle-block-user');
      socket.off('handle-unblock-user');

      socket.off('handle-post-broadcast');
      socket.off('handle-post-multicast/unicast');
      socket.off('handle-post-update-broadcast');
      socket.off('handle-post-update-multicast/unicast');
      socket.off('handle-post-remove-broadcast');
      socket.off('handle-post-remove-multicast/unicast');
      socket.off('handle-post-like-broadcast');

      socket.off('handle-article-broadcast');
      socket.off('handle-article-multicast/unicast');
      socket.off('handle-article-update-broadcast');
      socket.off('handle-article-update-multicast/unicast');
      socket.off('handle-article-remove-broadcast');
      socket.off('handle-article-remove-multicast/unicast');

      socket.off('handle-publication-broadcast');
      socket.off('handle-publication-multicast/unicast');
      socket.off('handle-publication-update-broadcast');
      socket.off('handle-publication-update-multicast/unicast');
      socket.off('handle-publication-remove-broadcast');
      socket.off('handle-publication-remove-multicast/unicast');
    };
  }, [socket]);

  /* 
  initial load (loading these from provider as these are shared between multiple components)
  also change state on auth */
  useEffect(() => {
    if (isAuthenticated && !location?.pathname?.includes('payment')) {
      (async () => {
        await fetchConnections();
        await fetchConnectionRequests();
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        joinRooms();
      }
    }, 5000);
  }, [socket]);

  /* triggering through redux so that
   other pages can listen to the changes from redux
   and execute API calls accordingly */
  useEffect(() => {
    if (!location?.pathname?.includes('payment')) {
      if (connection.fetchConnections) {
        (async () => {
          await fetchConnections();
        })();
      }
      if (connection.fetchConnectionRequests) {
        (async () => {
          await fetchConnectionRequests();
        })();
      }
    }
  }, [connection]);

  return children;
};

export default SocketIO;
