import React, { useEffect, useState } from 'react';
import { Alert, FormControl, IconButton, Menu, Stack, TextField } from '@mui/material';
import HelperText from '../../../widgets/HelperText';
import MuiPhoneNumber from 'material-ui-phone-number';
import Button from '../../../widgets/Button';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { editAccountInformationSchema } from '../../../validations/profile';
import { useForm } from 'react-hook-form';
import { LockOpenTwoTone, LockTwoTone } from '@mui/icons-material';
import { updateAccountInformation } from '../../../api/profile';

const AccountInformationForm = () => {
  const [focus, setFocus] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isAccountInformationChanged, setIsAccountInformationChanged] = useState(false);

  const resolver = yupResolver(editAccountInformationSchema);
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();
  const handleContactChange = (contactData) => setValue('contact', contactData);

  useEffect(() => {
    if (user) {
      reset({
        email: user.email,
        contact: user.contact,
        username: user.username,
      });
      setIsContactVisible(user?.flags?.isContactVisible || false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (
        user?.email !== watchAllFields.email ||
        user?.contact !== watchAllFields.contact ||
        user?.username !== watchAllFields.username ||
        user?.flags?.isContactVisible !== isContactVisible
      ) {
        setIsAccountInformationChanged(true);
      } else {
        setIsAccountInformationChanged(false);
      }
    }
  }, [watchAllFields, user]);

  const [contactOptionsAnchorEl, setContactOptionsAnchorEl] = useState(null);
  const contactOptionsOpen = Boolean(contactOptionsAnchorEl);
  const handleContactOptions = (event) => setContactOptionsAnchorEl(event.currentTarget);
  const handleContactOptionsClose = () => setContactOptionsAnchorEl(null);

  const contactOptions = [
    {
      value: false,
      label: 'Only Me',
      icon: <LockTwoTone />,
    },
    {
      value: true,
      label: 'ISBM Members',
      icon: <LockOpenTwoTone />,
    },
  ];

  const handleDiscard = () => {
    reset({
      email: user.email,
      contact: user.contact,
      username: user.username,
    });
    setIsContactVisible(user?.flags?.isContactVisible || false);
  };

  const onSubmit = async (data) => {
    await updateAccountInformation({ ...data, isContactVisible }, true);
  };

  return (
    <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth error={errors?.username?.message}>
        <Alert severity="warning" sx={{ mb: 2, fontSize: 12 }}>
          Changing your username may cause unwanted side effects!
        </Alert>
        <TextField
          size="small"
          label="Username"
          {...register('username')}
          onBlur={() => setFocus('')}
          error={errors?.username?.message}
          onFocus={() => setFocus('username')}
          InputLabelProps={{ shrink: watchAllFields.username || focus === 'username' }}
        />
        <HelperText>{errors?.email?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.email?.message}>
        <Alert severity="info" sx={{ mb: 2, fontSize: 12 }}>
          Updating your email will require you to verify your new email address on next login.
        </Alert>
        <TextField
          size="small"
          label="Email"
          {...register('email')}
          onBlur={() => setFocus('')}
          error={errors?.email?.message}
          onFocus={() => setFocus('email')}
          InputLabelProps={{ shrink: watchAllFields.email || focus === 'email' }}
        />
        <HelperText>{errors?.email?.message}</HelperText>
      </FormControl>
      <Stack>
        <Alert severity="info" sx={{ mb: 2, fontSize: 12 }}>
          You can control who can see your contact number by clicking on the privacy icon.
        </Alert>
        <Stack direction="row" alignItems="start" spacing={1}>
          <FormControl fullWidth error={errors?.contact?.message}>
            <MuiPhoneNumber
              size="small"
              label="Contact"
              variant="outlined"
              defaultCountry={'us'}
              value={user?.contact}
              defaultValue={user?.contact}
              onChange={handleContactChange}
              error={errors?.contact?.message}
              InputLabelProps={{ shrink: true }}
              regions={['america', 'europe', 'asia', 'oceania', 'africa']}
            />
            <HelperText>{errors?.contact?.message}</HelperText>
          </FormControl>
          <IconButton color="primary" onClick={handleContactOptions}>
            {contactOptions.find((option) => option.value === isContactVisible)?.icon}
          </IconButton>
          <Menu
            open={contactOptionsOpen}
            anchorEl={contactOptionsAnchorEl}
            onClose={handleContactOptionsClose}>
            <Stack px={1} spacing={1} alignItems="start">
              {contactOptions.map((option) => (
                <Button
                  fullWidth
                  size="small"
                  key={option.value}
                  startIcon={option.icon}
                  sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                  color={option.value === isContactVisible ? 'primary' : 'secondary'}
                  onClick={() => {
                    setIsContactVisible(option.value);
                    handleContactOptionsClose();
                  }}>
                  {option.label}
                </Button>
              ))}
            </Stack>
          </Menu>
        </Stack>
      </Stack>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleDiscard}
          disabled={!isAccountInformationChanged || isSubmitting}>
          Discard
        </Button>
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
          disabled={!isAccountInformationChanged}>
          Save Changes
        </Button>
      </Stack>
    </Stack>
  );
};

export default AccountInformationForm;
