import React from 'react';
import { Stack } from '@mui/material';

import Banner from '../../components/membership/Banner';
import MembershipSection from '../../components/home/MembershipSection';

const Membership = () => {
  return (
    <Stack width="100%" height="auto" minHeight="100vh" mb={15}>
      <Banner />
      <MembershipSection secondary />
    </Stack>
  );
};

export default Membership;
