import * as urls from './router-urls';

import Home from '../pages/public/Home';
import AboutISBM from '../pages/public/AboutISBM';
import Membership from '../pages/public/Membership';
import MeetingEvents from '../pages/public/MeetingEvents';
import NewsBlogs from '../pages/public/NewsBlogs';
import Publications from '../pages/public/Publications';
import ProfessionalCareer from '../pages/public/ProfessionalCareer';
import Resources from '../pages/public/Resources';
import SupportISBM from '../pages/public/SupportISBM';
import ContactUs from '../pages/public/ContactUs';

import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import VerifyEmail from '../pages/auth/VerifyEmail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

import ProfileCreationFlow from '../pages/user/ProfileCreationFlow';
import SelectPlan from '../pages/user/SelectPlan';
import PaymentSuccess from '../pages/user/PaymentSuccess';
import PaymentFailed from '../pages/user/PaymentFailed';

import UserFeed from '../pages/user/UserFeed';
import UserProfile from '../pages/user/UserProfile';
import Connections from '../pages/user/Connections';
import CreateNewArticle from '../pages/user/CreateNewArticle';
import AddNewPublication from '../pages/user/AddNewPublication';
import ArticlePreview from '../pages/user/ArticlePreview';
import AccountSettings from '../pages/user/AccountSettings';
import EditPublication from '../pages/user/EditPublication';
import PublicationPreview from '../pages/user/PublicationPreview';
import EventPreview from '../pages/user/EventPreview';
import EditArticle from '../pages/user/EditArticle';

export const routes = [
  /* FLAGGED (setting flag check to true on flagged pages cause infinite loop) */
  {
    isProtected: true,
    label: 'Complete Profile',
    path: urls.COMPLETE_PROFILE,
    Element: ProfileCreationFlow,
    seo: {
      title: 'Complete Profile | ISBM',
      description: 'Complete Profile',
    },
    disableSideNav: true,
  },
  {
    isProtected: true,
    label: 'Select Plan',
    path: urls.SELECT_PLAN,
    Element: SelectPlan,
    seo: {
      title: 'Select Plan | ISBM',
      description: 'Select Plan',
    },
    disableSideNav: true,
  },
  {
    isProtected: true,
    label: 'Payment Success',
    path: urls.PAYMENT_SUCCESS + '/:uid',
    Element: PaymentSuccess,
    seo: {
      title: 'Payment Success | ISBM',
      description: 'Payment Success',
    },
    disableSideNav: true,
  },
  {
    isProtected: true,
    label: 'Payment Failure',
    path: urls.PAYMENT_FAILURE + '/:uid',
    Element: PaymentFailed,
    seo: {
      title: 'Payment Failure | ISBM',
      description: 'Payment Failure',
    },
    disableSideNav: true,
  },

  /* PROTECTED */
  // * User
  {
    isProtected: true,
    flagCheck: true,
    label: 'Feed',
    path: urls.USER_HOME, // no tab route
    Element: UserFeed,
    seo: {
      title: 'Feed',
      description: 'User Feed',
    },
    rightFeed: true,
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Feed',
    path: urls.USER_HOME + '/:tab',
    Element: UserFeed,
    seo: {
      title: 'Feed',
      description: 'User Feed',
    },
    rightFeed: true,
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Profile',
    path: urls.USER_PROFILE + '/:username', // no tab route
    Element: UserProfile,
    seo: {
      title: 'Profile',
      description: 'User Profile',
    },
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Profile',
    path: urls.USER_PROFILE + '/:username' + '/:tab',
    Element: UserProfile,
    seo: {
      title: 'Profile',
      description: 'User Profile',
    },
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Settings & Privacy',
    path: urls.USER_SETTINGS,
    Element: AccountSettings,
    seo: {
      title: 'Settings & Privacy',
      description: 'Account Settings & Privacy',
    },
  },
  // * Connections
  {
    isProtected: true,
    flagCheck: true,
    label: 'Connections',
    path: urls.USER_CONNECTIONS, // no tab route
    Element: Connections,
    seo: {
      title: 'Connections',
      description: 'User connections',
    },
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Connections',
    path: urls.USER_CONNECTIONS + '/:tab',
    Element: Connections,
    seo: {
      title: 'Connections',
      description: 'User connections',
    },
  },
  // * Articles
  {
    isShared: true, // has both public and private routes
    flagCheck: true,
    label: 'News or Blog',
    path: urls.NEWS_BLOGS + '/:slug',
    Element: ArticlePreview,
    seo: {
      title: 'News or Blog',
      description: 'News Content or Blog Post',
    },
  },
  {
    isProtected: true,
    flagCheck: true,
    label: 'Create News or Blog',
    path: urls.CREATE_ARTICLE,
    Element: CreateNewArticle,
    seo: {
      title: 'Create News or Blog',
      description: 'Create new news content or blog post',
    },
  },
  {
    isProtected: true,
    flagCheck: false,
    label: 'Edit News or Blog',
    path: urls.EDIT_ARTICLE + '/:slug',
    Element: EditArticle,
    seo: {
      title: 'Edit News or Blog',
      description: 'Edit your news content or blog post',
    },
    isIndexedUrl: false,
  },
  // * Publications
  {
    isShared: true, // has both public and private routes
    flagCheck: true,
    label: 'Publication',
    path: urls.PUBLICATIONS + '/:slug',
    Element: PublicationPreview,
    seo: {
      title: 'Publication',
      description: 'Publication or Journal',
    },
  },
  {
    isProtected: true,
    flagCheck: false,
    label: 'Add Publication',
    path: urls.CREATE_PUBLICATION,
    Element: AddNewPublication,
    seo: {
      title: 'Add Publication',
      description: 'Add new publication',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    flagCheck: false,
    label: 'Edit Publication',
    path: urls.EDIT_PUBLICATION + '/:slug',
    Element: EditPublication,
    seo: {
      title: 'Edit Publication',
      description: 'Edit your publication',
    },
    isIndexedUrl: false,
  },
  // * Events
  {
    isShared: true, // has both public and private routes
    flagCheck: true,
    label: 'Meeting & Event',
    path: urls.MEETING_EVENTS + '/:slug',
    Element: EventPreview,
    seo: {
      title: 'Meeting & Event',
      description: 'Meeting or Event',
    },
  },

  /* PUBLIC */
  {
    isProtected: false,
    flagCheck: false,
    label: 'ISBM',
    path: urls.INDEX,
    Element: Home,
    seo: {
      title: 'ISBM | The International Society of Bangladesh-Affiliated Microbiologists',
      description:
        'Official Website of The International Society of Bangladesh-Affiliated Microbiologists',
    },
    isIndexedUrl: true,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'About ISBM',
    path: urls.ABOUT_ISBM,
    Element: AboutISBM,
    seo: {
      title: 'About | ISBM',
      description:
        'A voluntary, non-profit, and non-political organization of microbiology professionals, registered and headquartered in Maryland, USA.',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Membership',
    path: urls.MEMBERSHIP,
    Element: Membership,
    seo: {
      title: 'Membership | ISBM',
      description:
        'The ISBM supports its members to excel at all stages of their careers through networking, professional development, meetings, and leadership opportunities. The membership year for the society runs from January 1st to December 31st.',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Meetings & Events',
    path: urls.MEETING_EVENTS,
    Element: MeetingEvents,
    seo: {
      title: 'Meetings & Events | ISBM',
      description: 'This section is currently under development',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'News & Blogs',
    path: urls.NEWS_BLOGS,
    Element: NewsBlogs,
    seo: {
      title: 'News & Blogs | ISBM',
      description: 'This section is currently under development',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Publications',
    path: urls.PUBLICATIONS,
    Element: Publications,
    seo: {
      title: 'Publications | ISBM',
      description: 'This section is currently under development',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Professional Career',
    path: urls.PROFESSIONAL_CAREER,
    Element: ProfessionalCareer,
    seo: {
      title: 'Professional Career | ISBM',
      description:
        'ISBM provides opportunities for students to connect with mentors who can provide advice and help to prepare for their future careers. The ISBM will also create resources for the members to establish contact with worldwide employers. Our dashboard will feature updated job listings.',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Resources',
    path: urls.RESOURCES,
    Element: Resources,
    seo: {
      title: 'Resources | ISBM',
      description: 'This section is currently under development',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Support ISBM',
    path: urls.SUPPORT_ISBM,
    Element: SupportISBM,
    seo: {
      title: 'Support | ISBM',
      description:
        'Being a non-profit organization, we rely on the support of our members and volunteers to keep our missions ongoing. We are always looking for new members to join our team and help us grow.',
    },
    isIndexedUrl: false,
  },
  {
    isShared: true, // has both public and private routes
    flagCheck: true,
    label: 'Contact Us',
    path: urls.CONTACT,
    Element: ContactUs,
    seo: {
      title: 'Contact | ISBM',
      description: 'Contact ISBM',
    },
    isIndexedUrl: false,
  },

  /* AUTH */
  {
    isProtected: false,
    flagCheck: false,
    label: 'Sign In',
    path: urls.SIGNIN,
    Element: SignIn,
    seo: {
      title: 'Sign In | ISBM',
      description: 'Signin',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Sign Up',
    path: urls.SIGNUP,
    Element: SignUp,
    seo: {
      title: 'Sign Up | ISBM',
      description: 'Signup',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Verify Email',
    path: urls.VERIFY_EMAIL, // no token route
    Element: VerifyEmail,
    seo: {
      title: 'Verify Email | ISBM',
      description: 'Verify Email',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Verify Email',
    path: urls.VERIFY_EMAIL + '/:token',
    Element: VerifyEmail,
    seo: {
      title: 'Verify Email | ISBM',
      description: 'Verify Email',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Forgot Password',
    path: urls.FORGOT_PASSWORD,
    Element: ForgotPassword,
    seo: {
      title: 'Forgot Password | ISBM',
      description: 'Forgot Password',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Reset Password',
    path: urls.RESET_PASSWORD, // no token route
    Element: ResetPassword,
    seo: {
      title: 'Reset Password | ISBM',
      description: 'Reset Password',
    },
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    flagCheck: false,
    label: 'Reset Password',
    path: urls.RESET_PASSWORD + '/:token',
    Element: ResetPassword,
    seo: {
      title: 'Reset Password | ISBM',
      description: 'Reset Password',
    },
    isIndexedUrl: false,
  },
];
