import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { fetchFeedContents } from '../../../api/content';
import { tempActions } from '../../../store/reducers/tempReducer';
import PublicationSkeleton from './PublicationSkeleton';
import PublicationItem from './PublicationItem';

const PublicationsTab = () => {
  const dispatch = useDispatch();
  const feed = useSelector((state) => state.feed);
  const tempState = useSelector((state) => state.temp);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    (async () => {
      setIsLoading(true);
      await fetchFeedContents({ type: 'PUBLICATION', offset: 0 }, true);
      setIsLoading(false);
    })();
  }, []);

  const loadMorePublications = async () => {
    const offset =
      Math.ceil(feed?.totalFeedPublications / feed?.limit) > feed?.currentPublicationsOffset + 1
        ? feed?.currentPublicationsOffset + 1
        : 0;
    await fetchFeedContents({ type: 'PUBLICATION', offset });
    dispatch(tempActions.controller({ isFetching: false }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    dispatch(tempActions.controller({ isFetching: true }));
    loadMorePublications();
  }, [tempState.shouldLoadMore]);

  return (
    <Stack width="100%" spacing={2} alignItems="center">
      {isLoading && feed?.feedPublications?.length === 0
        ? [...Array(5)].map((_, index) => <PublicationSkeleton key={index} />)
        : feed?.feedPublications?.map((publication, index) => (
            <PublicationItem key={index} publication={publication} from="feed" />
          ))}
      {!isLoading && feed?.feedPublications?.length > 0 && <PublicationSkeleton />}
      {!isLoading && feed?.feedPublications?.length === 0 && (
        <Typography p={1} fontSize={14} color="textSecondary">
          No publications to show
        </Typography>
      )}
    </Stack>
  );
};

export default PublicationsTab;
