export default [
  {
    id: 5,
    uid: 'U4Lg1dFodxshWb2',
    title: 'ISBM Presents Webinar on International Women’s Day 2024',
    slug: 'isbm-presents-webinar-on-international-womens-day-2024-1690722248536',
    image: '/assets/images/event/event-flyer-5.png',
    video: null,
    attachment: null,
    startDate: '2024-03-02',
    endDate: '2024-03-02',
    startTime: null,
    endTime: null,
    timezone: null,
    type: null,
    host: null,
    isPublished: true,
    isArchived: false,
    isFinalized: true,
    isFinished: false,
    description: null,
    body: {
      blocks: [
        {
          id: 1,
          type: 'paragraph',
          data: {
            text: '<b>Joining Information:</b><br/>We invite you to join us at the following <b>Zoom</b> link<br/><a href="https://zoom.us/j/91347270438" target="_blank" rel="noreferrer">https://zoom.us/j/91347270438</a><br/><b>Meeting ID:</b> 91347270438 <br/><b>Passcode:</b> isbm',
          },
        },
      ],
    },
  },
  {
    id: 4,
    uid: 'dU4Lg1dFodxshWb',
    title: 'ISBM Executive Committee (2024-2025) Election',
    slug: 'isbm-executive-committee-2024-2025-election-1690722248536',
    image: null,
    video: null,
    attachment: null,
    startDate: '2023-11-04',
    endDate: '2023-11-04',
    startTime: null,
    endTime: null,
    timezone: null,
    type: null,
    host: null,
    isPublished: true,
    isArchived: false,
    isFinalized: false,
    isFinished: true,
    description: null,
    body: {},
  },
  {
    id: 3,
    uid: 'U4Lg1dFodxshWb2',
    title: 'Annual General Meeting (AGM), Mini-Symposium, and Meet & Greet (Virtual)',
    slug: 'annual-general-meeting-agm-mini-symposium-and-meet-greet-virtual-1690714473603',
    image: null,
    video: null,
    attachment: null,
    startDate: '2023-10-07',
    endDate: '2023-10-07',
    startTime: null,
    endTime: null,
    timezone: null,
    type: null,
    host: null,
    isPublished: true,
    isArchived: false,
    isFinalized: false,
    isFinished: true,
    description: null,
    body: {},
  },
  {
    id: 2,
    uid: '4Lg1dFodxshWb2Z',
    title: 'ISBM Webinar and Discussion on Dengue',
    slug: 'isbm-webinar-and-discussion-on-dengue-1690712897507',
    image: null,
    video: null,
    attachment: null,
    startDate: '2023-08-01',
    endDate: '2023-08-01',
    startTime: null,
    endTime: null,
    timezone: null,
    type: null,
    host: null,
    isPublished: true,
    isArchived: false,
    isFinalized: false,
    isFinished: true,
    description: null,
    body: {},
  },
  {
    id: 1,
    uid: '5dFLod1xshWdb2ZL',
    title: 'ISBM 1st Annual International e-Conference',
    slug: 'isbm-1st-annual-international-e-conference-1690711918637',
    image: '/assets/images/event/event-flyer-1.png',
    video: 'https://www.youtube.com/embed/ey-06tmivaY?rel=0',
    attachment: { url: '/assets/files/event-schdule-0001.pdf', name: 'Event Schedule' },
    host: 'ISBM',
    type: 'e-Conference',
    startDate: '2022-11-12',
    endDate: '2022-11-13',
    startTime: '0000-00-00T09:00:00',
    endTime: '0000-00-00T12:30:00',
    timezone: 'EST',
    isPublished: true,
    isArchived: false,
    isFinalized: true,
    isFinished: true,
    description:
      '1st ISBM International e-Conference will be held on November 12-13, 2022. ISBM EC and the Conference Steering Committee (below) have been working relentlessly to host a successful conference. As has been announced earlier, the conference will cover all areas of Microbiology, Immunology, Biotechnology, Bioinformatics, Genetic Engineering, Regulatory Sciences, Neurobiology, and Cancer Biology. Registration is FREE for all participants. There will be multiple research presentation awards for Undergraduate and Graduate Students, Postdoctoral scientists, and early career professionals.',
    body: {
      blocks: [
        {
          id: 1,
          type: 'paragraph',
          data: {
            text: 'World-renowned Microbiologists including Prof Rita R Colwell, Dr. Haruo Watanabe, Dr. Firdausi Qadri, and Prof Anwar Hossain have kindly agreed to speak at the conference. <b>(see the attached flyer for details)</b>',
          },
        },
        {
          id: 2,
          type: 'paragraph',
          data: {
            text: '<b>Abstract Submission Deadline:</b> October 28, 2022<br/><b>Registration and Abstract Submission:</b> <a href="https://form.jotform.com/222780792599271" target="_blank" rel="noreferrer">https://form.jotform.com/222780792599271</a>',
          },
        },
        {
          id: 3,
          type: 'paragraph',
          data: {
            text: '<b>Members of the Steering Committee:</b>',
          },
        },
        {
          id: 4,
          type: 'list',
          data: {
            style: 'unordered',
            items: [
              'Dr. Mizanur Rahman (Chair; Environmental Protection Agency, USA)',
              'Prof Dr. Sangita Ahmed (Co-Chair; Dhaka University, Bangladesh)',
              'Prof Dr. Muhammad Golam Morshed (University of British Columbia, Canada)',
              'Prof Dr. Salina Parveen (University of Maryland, USA)',
              'Prof Dr. Muhammad Manjurul Karim (Dhaka University, Bangladesh)',
              'Dr. Fokhrul Monowar Hossain (Louisiana State University, USA)',
              'Prof Dr. Abul Kalam Azad (Shahjalal University of Science and Technology, Bangladesh)',
              'Dr. Aftabul Haque (Sanofi, USA)',
              'Prof Dr. Anowar Khasru Parvez (Jahangir Nagar University, Bangladesh)',
              'Dr. Habib Bakht (Hilbert College, USA)',
              'Prof Dr. Nayeema Bulbul (North South University, Bangladesh)',
              'Dr. Aminul Islam (Washington State University, USA)',
              'Dr. Bijay Khajanchi (FDA, USA)',
              'Dr. Hossain Azam (University of the District of Columbia, USA)',
              'Prof Dr. Sharmin Sultana (Chittagong University, Bangladesh)',
              'Mr. Belal Hossain (Innovate Phytoceuticals, Canada)',
              'Ms. Syeda Antara Shabnam (Cambrex Corporation, USA)',
              'Dr. Niaz Rahim (Manitoba Provincial Government, Canada)',
              'Dr. Ruhul Amin (NIH, USA)',
              'Dr. Md Masud Alam (NIH, USA)',
              'Dr. Syeda Tasneem Towhid (Jagannath University, Bangladesh) ',
            ],
          },
        },
      ],
    },
  },
];
