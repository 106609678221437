import React, { useEffect, useState } from 'react';
import {
  Alert,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '../../../widgets/Button';
import { Link, useNavigate } from 'react-router-dom';
import { INDEX } from '../../../constants/router-urls';
import { requestPaymentAssistance } from '../../../api/financial';
import notify from '../../../utils/notify';
import HelperText from '../../../widgets/HelperText';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { paymentAssistanceValidationSchema } from '../../../validations/payment';

const PaymentAssistant = ({ invoiceId }) => {
  const navigate = useNavigate();
  const [isRequesting, setIsRequesting] = useState(false);
  const [showPaymentAssistantModal, setShowPaymentAssistantModal] = useState(false);

  const [focus, setFocus] = useState('');

  const resolver = yupResolver(paymentAssistanceValidationSchema);
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();

  useEffect(() => {
    setValue('reason', 'UNAVAILABLE');
  }, []);

  const onPaymentRequest = async (data) => {
    setIsRequesting(true);
    const isSuccessful = await requestPaymentAssistance({ invoiceId, ...data });
    if (isSuccessful) {
      notify.success('Your request has been submitted successfully.');
      navigate(INDEX, { replace: true });
    }
    setIsRequesting(false);
  };

  return (
    <Stack spacing={2} alignItems="center" maxWidth={600}>
      {/* payment assistance dialog */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPaymentAssistantModal}
        onClose={isRequesting ? null : () => setShowPaymentAssistantModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Request Payment Assistance
          </Typography>
          <Stack direction="row" spacing={0.5} alignItems="start" width="100%">
            <Stack alignItems="start" width="100%">
              <FormControl sx={{ px: 1, py: 2, width: '100%' }} error={errors?.reason?.message}>
                <FormLabel sx={{ pb: 1 }}>Reason for assistance?</FormLabel>
                <RadioGroup
                  name="reason"
                  onChange={(e) => setValue('reason', e.target.value)}
                  defaultValue={watchAllFields.reason || 'UNAVAILABLE'}>
                  <FormControlLabel
                    value="UNAVAILABLE"
                    control={<Radio />}
                    label="Unavailibility of supported card"
                  />
                  <FormControlLabel value="ERROR" control={<Radio />} label="Technical Issue" />
                  <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
                </RadioGroup>
                <HelperText>{errors?.reason?.message}</HelperText>
              </FormControl>
              {watchAllFields.reason === 'OTHER' && (
                <FormControl error={errors?.message?.message} sx={{ px: 1, py: 2, width: '100%' }}>
                  <TextField
                    rows={4}
                    multiline
                    size="small"
                    label="Message"
                    variant="standard"
                    {...register('message')}
                    onBlur={() => setFocus('')}
                    error={errors?.message?.message}
                    onFocus={() => setFocus('message')}
                    InputLabelProps={{ shrink: watchAllFields.message || focus === 'message' }}
                  />
                  <HelperText>{errors?.message?.message}</HelperText>
                </FormControl>
              )}
            </Stack>
          </Stack>
          <Stack mt={2} spacing={2}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                color="error"
                disabled={isRequesting}
                onClick={() => setShowPaymentAssistantModal(false)}>
                Cancel
              </Button>
              <Button loading={isRequesting} onClick={handleSubmit(onPaymentRequest)}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Alert severity="info" sx={{ width: '100%' }}>
        If you think you need help with your payment processing, you can request for payment
        assistance. This will open a ticket for you on ISBM&apos;s support system. Please note that
        this is not a guarantee that your payment will be processed. You will be contacted by a
        representative from ISBM to assist you with your payment.
      </Alert>
      <Alert severity="warning" sx={{ width: '100%' }}>
        We highly discourage you from sharing your payment details with anyone. Please do not share
        your payment details with anyone. ISBM will never ask you for your payment details.
      </Alert>
      <Stack direction="row" spacing={1}>
        <Button size="small" variant="outlined" disabled={isRequesting} component={Link} to={INDEX}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          loading={isRequesting}
          onClick={() => setShowPaymentAssistantModal(true)}>
          Ask for Assistance?
        </Button>
      </Stack>
    </Stack>
  );
};

export default PaymentAssistant;
