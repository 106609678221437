import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    refresh: (state, action) => {
      return { ...state, token: action.payload.token };
    },
    signin: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    },
    updateUser: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    },
    logout: (state) => {
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
