export const sectionHeaderStyle = {
  mb: 2,
  lineHeight: 1.2,
  fontWeight: 700,
  textAlign: 'center',
  fontSize: { md: '3rem', xs: '2.4rem' },
};

export const authFormTitleStyle = {
  mb: 2,
  lineHeight: 1.2,
  fontWeight: 700,
  textAlign: 'center',
  fontSize: { md: '2.2rem', xs: '1.8rem' },
};

export const primaryButtonStyle = {
  sx: {
    py: 2,
    px: 3,
    borderRadius: 2,
    boxShadow: 'none',
    minWidth: '140px',
    textTransform: 'capitalize',
  },
  variant: 'contained',
};
