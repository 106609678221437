import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { AddTwoTone, BusinessCenterTwoTone } from '@mui/icons-material';
import ExperienceCard from './ExperienceCard';
import ExperienceForm from './ExperienceForm';

const ExperiencesTab = ({ isAuth, data, setData }) => {
  const [isCreationFormOpen, setIsCreationFormOpen] = useState(false);

  return (
    <Stack width="100%" maxWidth={650} spacing={2} my={4}>
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <BusinessCenterTwoTone sx={{ pb: 0.4 }} />
          <Typography fontSize={20} fontWeight={700}>
            Experience
          </Typography>
        </Stack>
        {isAuth && !isCreationFormOpen && (
          <Button
            size="small"
            alignSelf="start"
            color="secondary"
            variant="outlined"
            startIcon={<AddTwoTone />}
            sx={{ minWidth: 'fit-content' }}
            onClick={() => setIsCreationFormOpen(true)}>
            Add More
          </Button>
        )}
      </Stack>

      {/* Experience Creation Form */}
      {isCreationFormOpen && (
        <ExperienceForm setData={setData} onClose={() => setIsCreationFormOpen(false)} />
      )}

      {/* Experience Cards */}
      {data?.map((exp, index) => (
        <ExperienceCard
          data={exp}
          key={index}
          isAuth={isAuth}
          setData={setData}
          isFirst={index === 0}
          isLast={index === data?.length - 1}
        />
      ))}
    </Stack>
  );
};

export default ExperiencesTab;
