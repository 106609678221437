import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ABOUT_ISBM } from '../../../constants/router-urls';
import { banners } from '../../../constants/placeholder/landing';

const Banner = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      borderRadius={2}
      bgcolor="#212121"
      overflow="hidden"
      position="relative"
      alignItems="center"
      justifyContent="start">
      <Stack width="100%">
        <Carousel
          interval={3500}
          autoPlay={true}
          centerMode={true}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          showIndicators={false}
          centerSlidePercentage={100}>
          {banners.map((item) => (
            <Box height="100%" component="img" key={item?.image} src={item?.image} />
          ))}
        </Carousel>
      </Stack>
      <Stack
        p={4}
        top={0}
        left={0}
        spacing={1}
        width="100%"
        height="100%"
        position="absolute"
        bgcolor="transparent"
        justifyContent={{ md: 'space-between', xs: 'end' }}
        sx={{ transition: 'all 0.2s ease', ':hover': { bgcolor: 'rgba(0,0,0,0.6)' } }}>
        <Stack spacing={1}>
          <Typography
            color="#fff"
            lineHeight={1.2}
            fontWeight={800}
            fontSize={{ md: '3.2rem', xs: '2.8rem' }}
            sx={{ textShadow: '2px 2px 3px rgba(0,0,0,0.3)' }}>
            ISBM
          </Typography>
          <Typography
            color="#fff"
            lineHeight={1.1}
            fontWeight={500}
            sx={{ textShadow: '2px 2px 2px rgba(0,0,0,0.3)' }}
            fontSize={{ md: '1.6rem', sm: '1.2rem', xs: '1.1rem' }}>
            THE INTERNATIONAL SOCIETY OF
            <br /> BANGLADESH-AFFILIATED MICROBIOLOGISTS
          </Typography>
        </Stack>
        <Stack width="100%" direction="row" flexWrap="wrap" justifyContent="space-between">
          <Typography
            my={1}
            color="#fff"
            minWidth={240}
            fontWeight={500}
            fontSize={{ sm: '1rem', xs: '0.9rem' }}>
            A voluntary, nonprofit, and nonpolitical organization of <br /> Bangladesh-affiliated
            microbiology professionals.
          </Typography>
          <Button
            size="large"
            to={ABOUT_ISBM}
            component={Link}
            variant="contained"
            endIcon={<ArrowForward />}
            sx={{ my: 1, py: 1.5, px: 3, borderRadius: 0 }}>
            Explore
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Banner;
