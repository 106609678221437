/* PUBLIC */
export const INDEX = '/';
export const SIGNIN = '/sign-in';
export const SIGNUP = '/sign-up';
export const VERIFY_EMAIL = '/verify-email';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ABOUT_ISBM = '/about';
export const MEMBERSHIP = '/membership';
export const MEETING_EVENTS = '/meetings-&-events';
export const NEWS_BLOGS = '/news-&-blogs';
export const PUBLICATIONS = '/publications';
export const PROFESSIONAL_CAREER = '/professional-career';
export const RESOURCES = '/resources';
export const SUPPORT_ISBM = '/support-isbm';
export const CONTACT = '/contact';

/* PRIVATE */
export const COMPLETE_PROFILE = '/complete-profile';
export const SELECT_PLAN = '/select-plan';
export const PAYMENT_SUCCESS = '/payment/success';
export const PAYMENT_FAILURE = '/payment/failed';

export const USER_HOME = '/feed';
export const USER_PROFILE = '/profile';
export const USER_CONNECTIONS = '/connections';
export const USER_EVENTS = '/events';
export const USER_MENU = '/menu';
export const USER_SETTINGS = '/settings';

export const CREATE_ARTICLE = '/news-&-blogs/add';
export const EDIT_ARTICLE = '/news-&-blogs/edit';
export const CREATE_PUBLICATION = '/publication/add';
export const EDIT_PUBLICATION = '/publication/edit';
