import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormHelperText,
  InputAdornment,
  colors,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { brandColors } from '../../constants/colors';
import { login, loginWithGoogle } from '../../api/auth';
import { authFormTitleStyle } from '../../constants/styles';
import { FORGOT_PASSWORD, SIGNUP, USER_HOME, VERIFY_EMAIL } from '../../constants/router-urls';
import { signInValidationSchema } from '../../validations/auth';

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const resolver = yupResolver(signInValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const error = await login(data);
    if (error === 'EMAIL_NOT_VERIFIED') {
      navigate(VERIFY_EMAIL, {
        state: { alternateState: 'SIGNIN_VERIFY_EMAIL', email: data.usernameOrEmail },
      });
    }
    setIsLoading(false);
  };

  const handleGoogleLogin = async (response) => {
    setIsLoading(true);
    await loginWithGoogle(response.credential);
    setIsLoading(false);
  };

  if (isAuth) {
    return <Navigate to={USER_HOME} replace={true} />;
  }

  return (
    <Stack width="100%" height="auto" minHeight="75vh" flexWrap="wrap" direction="row">
      <Stack
        p={3}
        width="45%"
        spacing={2}
        height="100%"
        minHeight="75vh"
        position="relative"
        alignItems="center"
        justifyContent="center"
        display={{ lg: 'flex', xs: 'none' }}>
        <Box
          top={20}
          left={20}
          height={80}
          component="img"
          position="absolute"
          src="/assets/images/pattern.svg"
        />
        <Box component="img" src="/assets/images/logo.png" width={64} />
        <Typography variant="h4" fontWeight={700}>
          Welcome to ISBM
        </Typography>
        <Typography textAlign="center" maxWidth={400}>
          Sign in to your account to explore the wide range of features ISBM has to offer or sign up
          now to start your journey as a valued member of ISBM.
        </Typography>
        <Box
          right={20}
          bottom={20}
          height={80}
          component="img"
          position="absolute"
          src="/assets/images/pattern.svg"
        />
      </Stack>
      <Stack
        p={3}
        spacing={3}
        color="#fff"
        alignItems="center"
        boxSizing="border-box"
        justifyContent="center"
        width={{ lg: '55%', xs: '100%' }}
        bgcolor={{ lg: brandColors.secondary, xs: brandColors.background }}>
        <Card sx={{ borderRadius: 4 }}>
          <Stack
            p={3}
            spacing={1}
            bgcolor="#fff"
            component="form"
            onSubmit={handleSubmit(onSubmit)}>
            <Typography {...authFormTitleStyle}>Sign In</Typography>
            <Stack>
              <TextField
                size="small"
                name="usernameOrEmail"
                label="Username or Email *"
                error={errors?.usernameOrEmail}
                {...register('usernameOrEmail')}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.usernameOrEmail?.message}
              </FormHelperText>
            </Stack>
            <Stack>
              <TextField
                size="small"
                name="password"
                label="Password *"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                error={errors?.password}
                {...register('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.password?.message}
              </FormHelperText>
            </Stack>
            <Link
              to={FORGOT_PASSWORD}
              style={{
                margin: '10px 0px',
                fontSize: '0.8rem',
                textDecoration: 'none',
                color: brandColors.primary,
              }}>
              Forgot password?
            </Link>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting || isLoading}>
              Sign In
            </LoadingButton>
            <Typography textAlign="center">or</Typography>
            <Stack width="100%" alignItems="center">
              {isLoading || isSubmitting ? (
                <Stack
                  spacing={1}
                  width="100%"
                  direction="row"
                  alignSelf="center"
                  alignItems="center"
                  justifyContent="center">
                  <Typography textAlign="center" fontSize={14} color={colors.grey[600]}>
                    Signing in . . .
                  </Typography>
                </Stack>
              ) : (
                <GoogleLogin size="large" text="continue_with" onSuccess={handleGoogleLogin} />
              )}
            </Stack>
            <Typography textAlign="center" fontSize="0.8rem" sx={{ pt: 3 }}>
              Don&apos;t have an account?{' '}
              <span>
                <Link
                  to={SIGNUP}
                  style={{
                    margin: '10px 0px',
                    textDecoration: 'none',
                    color: brandColors.primary,
                  }}>
                  Sign up
                </Link>
              </span>
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};

export default SignIn;
