import React from 'react';
import { Link } from 'react-router-dom';
import career from '../../constants/placeholder/career';
import { Stack, Button, Container, Typography } from '@mui/material';
import { primaryButtonStyle, sectionHeaderStyle } from '../../constants/styles';
import { PROFESSIONAL_CAREER } from '../../constants/router-urls';

const CareerSection = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 15 }}>
      <Stack alignItems="center" width="100%">
        <Typography {...sectionHeaderStyle}>
          <span style={{ color: '#128CCD' }}>Build & Grow</span> <br /> your career with us
        </Typography>
        <Button {...primaryButtonStyle} component={Link} to={PROFESSIONAL_CAREER}>
          Career & Development
        </Button>

        <Stack direction="row" flexWrap="wrap" width="100%" mt={2}>
          {career.map((careerResource, index) => (
            <Stack
              m={1}
              px={2}
              py={5}
              flex={1}
              border={2}
              key={index}
              minWidth={300}
              bgcolor="#fff"
              maxHeight={236}
              borderRadius={2}
              overflow="hidden"
              position="relative"
              borderColor="grey.200"
              transition="all 0.3s ease"
              sx={{
                transition: 'all 0.2s ease',
                ':hover': {
                  maxHeight: 500,
                  transform: 'scale(1.04, 1.04)',
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                },
              }}>
              <Typography variant="h6" fontWeight={600} borderBottom="3px solid #128CCC">
                {careerResource.title}
              </Typography>
              <Typography mt={1}>{careerResource.description}</Typography>
              <Stack
                width="100%"
                height="100%"
                position="absolute"
                sx={{
                  backgroundImage:
                    'linear-gradient(180deg, transparent 10%, rgba(255,255,255,1) 100%)',
                  ':hover': {
                    backgroundImage: 'none',
                  },
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default CareerSection;
