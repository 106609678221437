import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { PersonAddAlt1TwoTone, PersonRemoveAlt1TwoTone } from '@mui/icons-material';

import {
  sendConnectionRequest,
  cancelConnectionRequest,
  fetchConnectionRecommendation,
} from '../../api/connection';
// import notify from '../../utils/notify';
import UserCard from '../global/UserCard';
import Button from '../../widgets/Button';

const ConnectionRecommendationsTab = () => {
  const increaseBy = 20;
  const [isLoading, setIsLoading] = useState(0);
  const [limit, setLimit] = useState(increaseBy);
  const [isRequesting, setIsRequesting] = useState('');
  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    (async () => {
      await fetchConnectionRecommendation({ limit });
      setIsLoading(1);
    })();
  }, []);

  useEffect(() => {
    if (connection?.fetchConnectionRecommendation) {
      (async () => {
        await fetchConnectionRecommendation({ limit });
      })();
    }
  }, [connection]);

  const handleSendRequest = async (uid) => {
    setIsRequesting(uid);
    await sendConnectionRequest(uid);
    setIsRequesting('');
  };

  const handleCancelRequest = async (uid) => {
    setIsRequesting(uid);
    await cancelConnectionRequest(uid);
    setIsRequesting('');
  };

  const handleLoadMore = async () => {
    setIsLoading(2);
    await fetchConnectionRecommendation({ limit: limit + increaseBy });
    setLimit(limit + increaseBy);
    setIsLoading(1);
  };

  return (
    <Stack>
      <Typography fontSize={18} fontWeight={700}>
        Connection Recommendations
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" flexWrap="wrap">
        {isLoading === 0
          ? [...Array(limit)].map((_, index) => (
              <Stack
                mr={1}
                mb={1}
                p="5px"
                key={index}
                boxShadow={1}
                bgcolor="#fff"
                borderRadius={1}>
                <Skeleton
                  key={index}
                  width={120}
                  height={165.75}
                  animation="wave"
                  variant="rectangular"
                />
              </Stack>
            ))
          : connection?.connectionRecommendations?.map((item) => (
              <UserCard mb={1} key={item?.id} user={item}>
                <Button
                  fullWidth
                  size="small"
                  sx={{ mt: '5px' }}
                  variant="outlined"
                  loading={isRequesting === item?.uid}
                  color={item?.isRequested ? 'secondary' : 'primary'}
                  startIcon={
                    item?.isRequested ? <PersonRemoveAlt1TwoTone /> : <PersonAddAlt1TwoTone />
                  }
                  onClick={() => {
                    item?.isRequested
                      ? handleCancelRequest(item?.uid)
                      : handleSendRequest(item?.uid);
                  }}>
                  {item?.isRequested ? 'Cancel' : 'Request'}
                </Button>
              </UserCard>
            ))}
        {isLoading === 2 &&
          [...Array(increaseBy)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))}
      </Stack>
      {connection?.totalRecomendations > limit && (
        <Stack mt={1} alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleLoadMore}
            loading={isLoading === 2}>
            Load More
          </Button>
        </Stack>
      )}
      {(connection?.totalRecomendations === 0 || limit === increaseBy) && (
        <Stack
          spacing={1}
          width="100%"
          sx={{ mt: 4 }}
          height={165.75}
          alignItems="center"
          justifyContent="center">
          <Typography fontSize={14} fontWeight={500} textAlign="center" color="text.secondary">
            No more connection recommendation is available at the moment.
            <br />
            ISBM is a fairly new platform and we are still building our community.
            <br />
            Invite people to join ISBM and help us grow faster.
          </Typography>
          {/* <Button startIcon={<GroupTwoTone />} onClick={() => notify.success('Thank you!')}>
            Invite People
          </Button> */}
        </Stack>
      )}
    </Stack>
  );
};

export default ConnectionRecommendationsTab;
