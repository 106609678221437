import axios from 'axios';
import notify from '../utils/notify';
import { store, resetStore } from '../store';
import { authActions } from '../store/reducers/authReducer';

const baseURL = process.env.REACT_APP_API_BASEURL;

//create axios instance
export default () => {
  const instance = axios.create({
    baseURL,
    timeout: 30000,
  });

  //request interceptor to add the auth token header to requests always
  instance.interceptors.request.use(
    (config) => {
      //checking if the access token exist in localstorage
      const accessToken = store.getState()?.auth?.token;
      config.headers = {
        'Content-Type': config?.headers?.['Content-Type']
          ? config.headers['Content-Type']
          : 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };
      return config;
    },
    (error) => {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timeout');
        notify.error(
          error?.response?.data?.msg || 'Server took too long to respond, please try again later.',
        );
        return Promise.reject(error);
      }

      if (error.response) {
        console.error(error.response);
      } else if (error.request) {
        notify.error('Internal server error');
      } else {
        notify.error(error?.response?.data?.msg);
      }
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timeout');
        notify.error(
          error?.response?.data?.msg || 'Server took too long to respond, please try again later.',
        );
        return Promise.reject(error);
      }

      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true; //making sure that the refresh api gets called just once to refresh token

        return instance.get('/auth/refresh').then((res) => {
          if (res.status === 200) {
            //setting the new accessToken found to storage
            const { token } = res.data;
            store.dispatch(authActions.refresh({ token }));
            //returning the original request
            return instance(originalRequest);
          }
        });
      }
      if (error.response.status === 401) {
        //loggin user out
        store.dispatch(authActions.logout());
        resetStore();
        return Promise.reject(error);
      }
      if (error.response.status === 400) {
        notify.error(error?.response?.data?.msg || 'Bad Request!');
        return Promise.reject(error);
      }
      if (error.response.status === 404) {
        notify.error(error?.response?.data?.msg || 'Unknown Request!');
        return Promise.reject(error);
      }
      if (error.response.status === 429) {
        notify.error(error?.response?.data?.msg || 'Too many requests! Please try again later.');
        return Promise.reject(error);
      }
      if (error.response.status === 500) {
        notify.error(error?.response?.data?.msg || 'Something went wrong! Please try again later.');
        return Promise.reject(error);
      }
      if (error.response) {
        console.error(error.response);
        notify.error(error?.response?.data?.msg || 'Something went wrong! Please try again later.');
      } else if (error.request) {
        notify.error('Could not connect to server! Please try again later.');
      } else {
        notify.error('Something went wrong! Please try again later.');
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
