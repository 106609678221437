import { store } from '../store';
import { publicActions } from '../store/reducers/publicReducer';
import { tempActions } from '../store/reducers/tempReducer';
import request from './request';

export const fetchPublicContents = async (query, reset) => {
  const response = await request.get('/public-contents', query);
  if (response?.status === 200) {
    if (query?.type?.toUpperCase() === 'ARTICLE') {
      const currentArticles = store.getState()?.public?.publicArticles;
      store.dispatch(
        publicActions.setPublicArticles({
          data: reset ? response.data : [...currentArticles, ...response.data],
          offset: response?.offset || 0,
          totalEntries: response?.totalEntries || 0,
        }),
      );
    } else if (query?.type?.toUpperCase() === 'PUBLICATION') {
      const currentPublications = store.getState()?.public?.publicPublications;
      store.dispatch(
        publicActions.setPublicPublications({
          data: reset ? response.data : [...currentPublications, ...response.data],
          offset: response?.offset || 0,
          totalEntries: response?.totalEntries || 0,
        }),
      );
    }
  }
};

export const fetchContentBySlug = async (slug) => {
  const response = await request.get(`/public-content/${slug}`);
  if (response?.status === 200) {
    switch (response?.data?.type?.toUpperCase()) {
      case 'ARTICLE':
        store.dispatch(tempActions.controller({ article: response?.data }));
        break;
      case 'PUBLICATION':
        store.dispatch(tempActions.controller({ publication: response?.data }));
        break;
      default:
        break;
    }
    return response?.data || null;
  }
  store.dispatch(tempActions.controller({ publication: null, article: null }));
  return null;
};

export const sendContactMessage = async (data) => {
  const response = await request.post('/public-contact', data);
  return response;
};

export const verifyCaptchaToken = async (data) => {
  const response = await request.post('/public-contact/verify-captcha-token', data);
  if (response.status === 200) {
    return true;
  }
  return false;
};
