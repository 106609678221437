import React from 'react';
import { Typography } from '@mui/material';

const HelperText = ({ sx, allowOverflow, children }) => {
  return (
    <Typography
      sx={{
        mt: '4px',
        width: '80%',
        fontSize: 12,
        color: '#d32f2f',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: children ? 'block' : 'none',
        overflow: allowOverflow ? 'visible' : 'hidden',
        ':hover': allowOverflow
          ? 'none'
          : {
              width: 'unset',
              overflow: 'visible',
              whiteSpace: 'normal',
            },
        ...sx,
      }}>
      {children}
    </Typography>
  );
};

export default HelperText;
