import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Stack, Step, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';

import { createProfile, updateStudentMembershipStatus } from '../../api/profile';
import { PAYMENT_FAILURE, USER_HOME } from '../../constants/router-urls';
import PageWrapper from '../../components/global/PageWrapper';
import BasicInfo from '../../components/user/profile-creation-flow/BasicInfo';
import ProfileImage from '../../components/user/profile-creation-flow/ProfileImage';
import ContactDetails from '../../components/user/profile-creation-flow/ContactDetails';
import MembershipType from '../../components/user/profile-creation-flow/MembershipType';
import { uploadFile } from '../../api/file';
import { updateAuthState } from '../../api/auth';

const ProfileCreationFlow = () => {
  const navigate = useNavigate();
  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [step3Data, setStep3Data] = useState();
  const [step4Data, setStep4Data] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const flags = useSelector((state) => state.auth.user?.flags);

  useEffect(() => {
    if (flags?.isProfileCreated) {
      navigate(USER_HOME);
    }
  }, [flags]);

  useEffect(() => {
    if (user) {
      const { firstname, lastname, email, image } = user;
      setStep1Data({ firstname, lastname, email, ...step1Data });
      setStep3Data({ image, ...step3Data });
    }
  }, [user]);

  const handleSubmit = async () => {
    setIsLoading(true);
    let image = user?.image;
    // if step3Data.image is a file, upload it and get the url
    if (step3Data?.image instanceof File && !isImageUploaded) {
      const result = await uploadFile(step3Data.image, 'image');
      if (result?.url) {
        image = result.url;
        setImageUploaded(true);
      } else {
        setIsLoading(false);
        return;
      }
    }

    const formData = {
      ...step1Data,
      ...step2Data,
      ...step4Data,
      image,
      initiatePayment: step4Data.membershipType.price > 0,
    };
    const paymentInfo = await createProfile(formData);
    if (paymentInfo?.paymentUrl) {
      window.location.href = paymentInfo.paymentUrl;
      setIsLoading(false);
      return;
    }
    if (formData.membershipType.price == 0) {
      const res = await updateStudentMembershipStatus();
      if (res.status === 200) {
        await updateAuthState();
      } else {
        navigate(`${PAYMENT_FAILURE}/student-member`, { replace: true });
      }
    }
    setIsLoading(false);
  };

  const steps = [
    {
      label: 'Basic Information',
      description: 'Fill in your basic information',
      Element: <BasicInfo data={step1Data} setData={setStep1Data} next={() => setCurrentStep(1)} />,
    },
    {
      label: 'Contact Information',
      description: 'Fill in your contact information',
      Element: (
        <ContactDetails
          data={step2Data}
          setData={setStep2Data}
          back={() => setCurrentStep(0)}
          next={() => setCurrentStep(2)}
        />
      ),
    },
    {
      label: 'Profile Picture',
      description: 'Help people recognize you by uploading a photo of yourself',
      Element: (
        <ProfileImage
          data={step3Data}
          setData={setStep3Data}
          back={() => setCurrentStep(1)}
          next={() => setCurrentStep(3)}
        />
      ),
    },
    {
      label: 'Membership Type',
      description: 'Choose your desired membership type',
      Element: (
        <MembershipType
          data={step4Data}
          setData={isLoading ? null : setStep4Data}
          back={() => setCurrentStep(2)}
          next={handleSubmit}
          isLoading={isLoading}
        />
      ),
    },
  ];

  if (
    flags?.isProfileCreated &&
    user.membershipStatus === 'ACTIVE' &&
    user.paymentStatus === 'PAID'
  ) {
    return <Navigate to={USER_HOME} />;
  }

  return (
    <PageWrapper>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <Step>
          <Stack alignItems="center" spacing={1}>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: '0.8rem',
                bgcolor: 'error.main',
              }}>
              {currentStep + 1}
            </Avatar>
            <Stack>
              <Typography variant="h5" fontWeight={700} textAlign="center">
                {steps[currentStep].label}
              </Typography>
              <Typography textAlign="center">{steps[currentStep].description}</Typography>
            </Stack>
          </Stack>
        </Step>
      </Stack>
      <Stack>{steps[currentStep].Element}</Stack>
    </PageWrapper>
  );
};

export default ProfileCreationFlow;
