import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { fetchContentBySlug } from '../../api/public';
import PageWrapper from '../../components/global/PageWrapper';
import ArticleRenderer from '../../components/global/ArticleRenderer';
import { Helmet } from 'react-helmet';

const ArticlePreview = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const content = useSelector((state) => state.temp.article);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const fetchContent = async () => {
    await fetchContentBySlug(slug);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchContent();
  }, []);

  return (
    <PageWrapper isAuth={isAuth}>
      <Helmet>
        <title>{`ISBM | ${
          isLoading ? 'loading...' : content?.title || 'Article Not Found'
        }`}</title>
        <meta name="description" content={content?.description || ''} />
      </Helmet>
      <ArticleRenderer
        isAuth={isAuth}
        article={content}
        loading={isLoading}
        from={location?.state?.from}
      />
    </PageWrapper>
  );
};

export default ArticlePreview;
