import request from './request';
import { store } from '../store';
import notify from '../utils/notify';
import { feedActions } from '../store/reducers/feedReducer';
import { timelineActions } from '../store/reducers/timelineReducer';

export const fetchFeedPosts = async (query, reset) => {
  const response = await request.get('/posts', query);
  if (response?.status === 200) {
    const currentPosts = store.getState()?.feed?.feedPosts;
    store.dispatch(
      feedActions.setFeedPosts({
        data: reset ? response.data : [...currentPosts, ...response.data],
        offset: response?.offset || 0,
        totalEntries: response?.totalEntries || 0,
      }),
    );
  }
};

export const createPost = async (data) => {
  const response = await request.post('/posts', data);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Post added!');
  }
  return response;
};

export const updatePost = async (uid, data) => {
  const response = await request.patch(`/posts/${uid}`, data);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Post updated!');
  }
  return response;
};

export const deletePost = async (uid) => {
  const response = await request.delete(`/posts/${uid}`);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Post deleted!');
  }
  return response;
};

export const addRemovePostLike = async (uid) => {
  const response = await request.post(`/posts/like/${uid}`);
  return response;
};

export const feedPostUpdateHandler = (stream, isSelfPost) => {
  const feed = store.getState()?.feed;
  const posts = JSON.parse(JSON.stringify(feed?.feedPosts));
  let updatedPosts;
  if (stream?.updatedPost) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedPost;
    updatedPosts = posts?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      if (isSelfPost) {
        updatedPosts?.unshift(stream.updatedPost);
      } else {
        updatedPosts?.push(stream.updatedPost);
      }
      store.dispatch(
        feedActions.setFeedPosts({ data: updatedPosts, totalEntries: feed?.totalFeedPosts + 1 }),
      );
      return;
    }
  } else if (stream?.uid) {
    updatedPosts = posts?.filter((item) => item?.uid !== stream.uid);
    store.dispatch(
      feedActions.setFeedPosts({ data: updatedPosts, totalEntries: feed?.totalFeedPosts - 1 }),
    );
    return;
  } else {
    return;
  }
  store.dispatch(
    feedActions.setFeedPosts({ data: updatedPosts, totalEntries: feed?.totalFeedPosts }),
  );
};

export const timelinePostUpdateHandler = (stream) => {
  const timeline = store.getState()?.timeline;
  const posts = JSON.parse(JSON.stringify(timeline?.timelinePosts));
  let updatedPosts;
  if (stream?.updatedPost) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedPost;
    updatedPosts = posts?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      updatedPosts?.unshift(stream.updatedPost);
      store.dispatch(
        timelineActions.setTimelinePosts({
          data: updatedPosts,
          totalEntries: timeline?.totalTimelinePosts + 1,
        }),
      );
      return;
    }
  } else if (stream?.uid) {
    if (stream?.privacy && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      updatedPosts = posts?.map((item) => {
        if (item?.uid === stream.uid) {
          return {
            ...item,
            privacy: stream.privacy,
          };
        }
        return item;
      });
    } else {
      updatedPosts = posts?.filter((item) => item?.uid !== stream.uid);
      store.dispatch(
        timelineActions.setTimelinePosts({
          data: updatedPosts,
          totalEntries: timeline?.totalTimelinePosts - 1,
        }),
      );
      return;
    }
  } else {
    return;
  }
  store.dispatch(
    timelineActions.setTimelinePosts({
      data: updatedPosts,
      totalEntries: timeline?.totalTimelinePosts,
    }),
  );
};

export const feedPostLikeHandler = (stream) => {
  const { isLiked, postUid, ...liker } = stream;
  const feed = store.getState()?.feed;
  const updatedPosts = feed?.feedPosts?.map((item) => {
    if (item?.uid === postUid) {
      const postLikes = JSON.parse(JSON.stringify(item?.likes));
      if (isLiked) {
        postLikes?.unshift(liker);
      } else {
        const index = postLikes?.findIndex((like) => like?.uid === liker?.uid);
        if (index >= 0) {
          postLikes?.splice(index, 1);
        }
      }
      return {
        ...item,
        likes: postLikes,
      };
    }
    return item;
  });
  store.dispatch(
    feedActions.setFeedPosts({ data: updatedPosts, totalEntries: feed?.totalFeedPosts }),
  );
};

export const timelinePostLikeHandler = (stream) => {
  const { isLiked, postUid, ...liker } = stream;
  const timeline = store.getState()?.timeline;
  const updatedPosts = timeline?.timelinePosts?.map((item) => {
    if (item?.uid === postUid) {
      const postLikes = JSON.parse(JSON.stringify(item?.likes));
      if (isLiked) {
        postLikes?.unshift(liker);
      } else {
        const index = postLikes?.findIndex((like) => like?.uid === liker?.uid);
        if (index >= 0) {
          postLikes?.splice(index, 1);
        }
      }
      return {
        ...item,
        likes: postLikes,
      };
    }
    return item;
  });
  store.dispatch(
    timelineActions.setTimelinePosts({
      data: updatedPosts,
      totalEntries: timeline?.totalTimelinePosts,
    }),
  );
};
