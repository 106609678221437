import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Stack, Typography } from '@mui/material';

import { tempActions } from '../../store/reducers/tempReducer';
import { membershipTypes } from '../../constants/membership-type';
import { primaryButtonStyle, sectionHeaderStyle } from '../../constants/styles';
import { SIGNIN } from '../../constants/router-urls';

const MembershipSection = ({ secondary }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [country, setCountry] = useState('');

  useEffect(() => {
    fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const userCountry = data.country_name;
        if (userCountry?.toUpperCase() === 'BANGLADESH') {
          setCountry(userCountry?.toUpperCase());
        } else {
          setCountry('OTHERS');
        }
      })
      .catch((error) => {
        console.error('Error getting location:', error);
        setCountry('OTHERS');
      });
  }, []);

  const memberShipSteps = [
    {
      label: 'Get an ISBM account',
      description:
        'Sign up to create an ISBM account. You can alternatively choose to continue with your Google account to log in.',
    },
    {
      label: 'Create your profile',
      description:
        'Provide the required information and get your ISBM profile ready. This profile helps to introduce yourself and connect you to other ISBM members.',
    },
    {
      label: 'Choose membership',
      description:
        'Select your desired membership type from the list. Currently we are offering Student, General and Lifetime membership.',
    },
    {
      label: 'Complete payment',
      description:
        'Once you are done, follow the payment procedure and complete your membership payment based on the type of membership you selected.',
    },
  ];

  const memberShipBenifits = [
    {
      label: 'Discount on Registration Fees',
      description:
        'Members get up to a 25% discount on the registration fees of the Annual General Meeting.',
      image: '/assets/images/membership/coupons.png',
    },
    {
      label: 'Eligible for ISBM Fellowship and Grants',
      description:
        'Members are eligible for ISBM fellowships and grants to support their research.',
      image: '/assets/images/membership/graduation.png',
    },
    {
      label: 'Access to Job Board',
      description:
        'Members get access to job boards, get career advice to help plan their next career move.',
      image: '/assets/images/membership/job-seeker.png',
    },
    {
      label: 'Eligible for ISBM Awards',
      description:
        'Have access to the prestigious ISBM awards that recognize their contributions to research and education at the various stages of their professional life.',
      image: '/assets/images/membership/certificate.png',
    },
    {
      label: 'Enriched Network',
      description:
        'ISBM offers its members a unique platform to build a strong network, share expertise, learn, and collaborate in scientific areas or related disciplines.',
      image: '/assets/images/membership/networking.png',
    },
    {
      label: 'Access to Exclusive Online Events',
      description:
        'Members get access to exclusive members-only educational webinars, virtual journal clubs, and more.',
      image: '/assets/images/membership/badge.png',
    },
    {
      label: 'Opportunity to Expand Networking',
      description:
        'ISBM membership provides you with opportunities to expand your professional network and engage locally and globally.',
      image: '/assets/images/membership/global-network.png',
    },
    {
      label: 'Advocacy',
      description:
        'Help shape the future of microbial sciences. Advocate for policies that matter at the local and national levels. Work with ISBM to communicate microbial science to the public.',
      image: '/assets/images/membership/constitution.png',
    },
  ];

  const handleMembershipSelection = (type) => {
    dispatch(tempActions.selectMembershiType({ membershipType: type }));
    navigate(SIGNIN);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 15 }}>
      <Container maxWidth="lg">
        {/* MEMBERSHIP PROCESS */}
        <Stack width="100%" alignItems="center">
          {!secondary && (
            <Typography {...sectionHeaderStyle} mb={4}>
              <span style={{ color: '#128CCD' }}>Join</span> ISBM Today
            </Typography>
          )}
          <Stack
            border={2}
            width="100%"
            bgcolor="#fff"
            borderRadius={2}
            overflow="hidden"
            alignItems="center"
            borderColor="grey.200"
            justifyContent="center"
            direction={{ lg: 'row', xs: 'column' }}>
            <Box
              minWidth={180}
              component="img"
              width={{ lg: '45%', xs: '25%' }}
              src="/assets/images/membership/001.jpg"
            />
            <Stack width={{ lg: '65%', xs: '100%' }} py={2} spacing={2}>
              <Typography
                mt={2}
                px={1}
                lineHeight={1.2}
                fontWeight={600}
                textAlign="center"
                fontSize={{ md: '1.8rem', xs: '1.6rem' }}>
                How to be an ISBM member?
              </Typography>
              <Stack mt={1} mb={2} direction="row" justifyContent="center" flexWrap="wrap">
                {memberShipSteps.map((reason, index) => (
                  <Stack
                    m={1}
                    px={2}
                    py={2}
                    key={index}
                    spacing={2}
                    maxWidth={290}
                    borderRadius={2}
                    color="grey.800"
                    bgcolor="#f7f7f7"
                    sx={{
                      transition: 'all 0.2s ease',
                      ':hover': {
                        color: '#fff',
                        bgcolor: '#128CCD',
                        transform: 'scale(1.04, 1.04)',
                        boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                      },
                    }}>
                    <Typography variant="h6" fontWeight={600}>
                      {index + 1}. {reason.label}
                    </Typography>
                    <Typography fontWeight={400} fontSize="0.85rem">
                      {reason.description}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* MEMBERSHIP BENEFITS */}
        <Stack width="100%" alignItems="center" mt={15}>
          <Typography {...sectionHeaderStyle}>
            <span style={{ color: '#128CCD' }}>Benefits</span> Of Being An <br /> ISBM Member
          </Typography>
          {!secondary && (
            <Button {...primaryButtonStyle} component="a" href="#pricing">
              Become A Member
            </Button>
          )}
          <Stack
            mt={2}
            width="100%"
            flexWrap="wrap"
            direction="row"
            alignItems="center"
            justifyContent="center">
            {memberShipBenifits.map((benefit, index) => (
              <Stack
                m={1}
                px={2}
                py={5}
                flex={1}
                border={2}
                key={index}
                spacing={2}
                bgcolor="#fff"
                minWidth={300}
                minHeight={400}
                borderRadius={2}
                alignItems="center"
                borderColor="grey.200"
                justifyContent="center"
                sx={{
                  transition: 'all 0.2s ease',
                  ':hover': {
                    transform: 'scale(1.04, 1.04)',
                    boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                  },
                }}>
                <Box
                  height={100}
                  component="img"
                  src={benefit.image}
                  sx={{ filter: 'grayscale(100%)' }}
                />
                <Typography fontSize={32} lineHeight={1.2} fontWeight={600} textAlign="center">
                  {benefit.label}
                </Typography>
                <Typography textAlign="center">{benefit.description}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>

      {/* MEMBERSHIP PRICINGS */}
      <Stack width="100%" alignItems="center" pt={15} id="pricing">
        <Typography {...sectionHeaderStyle}>
          <span style={{ color: '#128CCD' }}>Membership</span>
          <br />
          Pricing
        </Typography>
        {!country ? (
          <Stack p={5} alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            width="100%"
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center">
            {membershipTypes[country].map((membershipType) => (
              <Stack
                p={2}
                m={1}
                width={300}
                minHeight={340}
                borderRadius={2}
                alignItems="center"
                key={membershipType.type}
                justifyContent="space-between"
                sx={{
                  color: '#272739',
                  cursor: 'pointer',
                  textAlign: 'center',
                  backgroundColor: '#e8e8e8',
                  transition: 'all 0.1s ease',
                  '&:hover': { backgroundColor: '#128CCD', color: '#fff' },
                }}
                onClick={() => handleMembershipSelection(membershipType.type)}>
                <Stack alignItems="center">
                  <Box width={50} component="img" src="/assets/images/award.png" borderRadius={2} />
                  <Typography variant="h6" fontWeight={700}>
                    {membershipType.title}
                  </Typography>
                  <Typography fontSize="0.9rem">{membershipType.description}</Typography>
                </Stack>
                <Typography variant="h3" fontWeight={700} pb={2}>
                  {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(membershipType.price)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default MembershipSection;
