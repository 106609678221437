import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import {
  // GroupTwoTone,
  PersonAddAltTwoTone,
  AddCircleOutlineTwoTone,
  PersonRemoveAlt1TwoTone,
} from '@mui/icons-material';

import {
  sendConnectionRequest,
  cancelConnectionRequest,
  fetchConnectionRecommendation,
} from '../../../api/connection';
// import notify from '../../../utils/notify';
import Button from '../../../widgets/Button';
import UserCard from '../../global/UserCard';
import { USER_CONNECTIONS } from '../../../constants/router-urls';

const ConnectionRecommendation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRequesting, setIsRequesting] = useState('');
  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    (async () => {
      await fetchConnectionRecommendation();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (connection?.fetchConnectionRecommendation) {
      (async () => {
        await fetchConnectionRecommendation();
      })();
    }
  }, [connection]);

  const handleSendRequest = async (uid) => {
    setIsRequesting(uid);
    await sendConnectionRequest(uid);
    setIsRequesting('');
  };

  const handleCancelRequest = async (uid) => {
    setIsRequesting(uid);
    await cancelConnectionRequest(uid);
    setIsRequesting('');
  };

  return (
    <Box>
      <Typography fontSize="1.2em" lineHeight={1.2} fontWeight={700}>
        Connection Recommendations
      </Typography>
      <Stack mb={2} p={1} width="100%" direction="row" overflow="auto" borderRadius="4px">
        {isLoading
          ? [...Array(15)].map((_, index) => (
              <Stack mr={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
                <Skeleton
                  key={index}
                  width={120}
                  height={155.75}
                  animation="wave"
                  variant="rectangular"
                />
              </Stack>
            ))
          : connection?.connectionRecommendations?.map((item) => (
              <UserCard key={item?.id} user={item}>
                <Button
                  fullWidth
                  size="small"
                  sx={{ mt: '5px' }}
                  variant="outlined"
                  loading={isRequesting === item?.uid}
                  color={item?.isRequested ? 'secondary' : 'primary'}
                  startIcon={
                    item?.isRequested ? <PersonRemoveAlt1TwoTone /> : <PersonAddAltTwoTone />
                  }
                  onClick={() => {
                    item?.isRequested
                      ? handleCancelRequest(item?.uid)
                      : handleSendRequest(item?.uid);
                  }}>
                  {item?.isRequested ? 'Cancel' : 'Request'}
                </Button>
              </UserCard>
            ))}
        {isLoading ? null : connection?.totalRecomendations > 0 ? (
          <Stack
            p="5px"
            key={0}
            boxShadow={1}
            bgcolor="#fff"
            height={165.75}
            minWidth="130px"
            component={Link}
            borderRadius={1}
            alignItems="center"
            justifyContent="center"
            to={`${USER_CONNECTIONS}/discover`}
            sx={{ textDecoration: 'none' }}>
            <AddCircleOutlineTwoTone color="primary" fontSize="large" />
            <Typography color="primary.main" fontSize={14} fontWeight={500} textAlign="center">
              See More
            </Typography>
          </Stack>
        ) : (
          <Stack
            p={1}
            spacing={1}
            width="100%"
            height={165.75}
            alignItems="center"
            justifyContent="center">
            <Typography
              fontSize={14}
              fontWeight={500}
              textAlign="center"
              color="text.secondary"
              sx={{ maxWidth: 500 }}>
              No connection recommendation is available at the moment. ISBM is a fairly new platform
              and we are still building our community. Invite people to join ISBM and help us grow
              faster.
            </Typography>
            {/* <Button startIcon={<GroupTwoTone />} onClick={() => notify.success('Thank you!')}>
              Invite People
            </Button> */}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ConnectionRecommendation;
