import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { sectionHeaderStyle } from '../../constants/styles';

const Testimonials = () => {
  const testimonials = [
    {
      name: 'Rita R. Colwell',
      image: '/assets/images/testimonial/rita.jpg',
      position: 'Distinguished University Professor',
      organization: 'University of Maryland, College Park, MD, USA.',
      description:
        'It is fascinating to see so many presenters, in one way or another, connected with Bangladesh in their research, education, and extension programs. Clearly, the ISBM is serving as a wonderful platform for those microbiologists whose origin is Bangladesh and others who have long professional ties with Bangladesh, all of whom are active in medical, environmental, and/or industrial microbiology. Each is productively contributing to a better future for us all. It is a privilege to have been and continue to be associated with researchers and so many friends in Bangladesh.',
    },
  ];
  return (
    <Container maxWidth="lg" sx={{ mt: 15 }}>
      <Stack width="100%" alignItems="center">
        <Typography {...sectionHeaderStyle}>
          <span style={{ color: '#128CCD' }}>Hear</span> From <br />
          Distinguished Microbiologists
        </Typography>

        <Stack mt={10} direction="row" justifyContent="center" flexWrap="wrap" width="100%">
          {testimonials.map((testimonial, index) => (
            <Stack
              m={1}
              px={2}
              py={5}
              border={2}
              key={index}
              spacing={1}
              flex={0.65}
              minWidth={300}
              bgcolor="#fff"
              borderRadius={2}
              position="relative"
              borderColor="grey.200"
              justifyContent="center"
              sx={{
                transition: 'all 0.2s ease',
                ':hover': {
                  transform: 'scale(1.04, 1.04)',
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                },
              }}>
              <Box
                p={1}
                top="0"
                left="50%"
                width={120}
                component="img"
                bgcolor="#F9F9FB"
                borderRadius="50%"
                position="absolute"
                src={testimonial.image}
                sx={{ transform: 'translate(-50%, -60%)' }}
              />
              <Typography fontWeight={450} textAlign="center">
                &quot; {testimonial.description} &quot;
              </Typography>
              <Typography fontSize={26} fontWeight={600} textAlign="center" color="#128CCC">
                {testimonial.name}
              </Typography>
              <Typography fontSize="0.8rem" textAlign="center">
                {testimonial.position}
                <br />
                {testimonial.organization}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Testimonials;
