import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tabs, Tab, colors, useMediaQuery } from '@mui/material';
import { BookTwoTone, NewspaperTwoTone, StickyNote2TwoTone } from '@mui/icons-material';

import PostsTab from './PostsTab';
import ArticlesTab from './ArticlesTab';
import PublicationsTab from './PublicationsTab';
import TabLabel from '../../../widgets/TabLabel';
import { USER_HOME } from '../../../constants/router-urls';
import ConnectionRecommendation from './ConnectionRecommendation';

const FeedTabs = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActivetab] = useState(0);
  const lessThanSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  /* tabs handling logic */
  const tabs = [
    {
      label: 'Posts',
      icon: <StickyNote2TwoTone />,
      element: <PostsTab />,
    },
    {
      label: 'Publications',
      icon: <BookTwoTone />,
      element: <PublicationsTab />,
    },
    {
      label: 'News & Blogs',
      icon: <NewspaperTwoTone />,
      element: <ArticlesTab />,
    },
  ];

  useEffect(() => {
    if (tab) {
      const index = tabs.findIndex(
        (t) => t?.label?.replace(/\s+/g, '-')?.toLowerCase() === tab?.toLowerCase(),
      );
      if (index !== -1) {
        setActivetab(index);
      } else {
        navigate(`${USER_HOME}/posts`, { replace: true });
      }
    } else {
      navigate(`${USER_HOME}/posts`, { replace: true });
    }
  }, [tab]);
  const currentTab = tabs[activeTab].element;

  const handleTabChange = (event, value) => {
    navigate(`${USER_HOME}/${tabs[value]?.label?.replace(/\s+/g, '-')?.toLowerCase()}`);
  };
  /* tabs handling logic */

  return (
    <Box width="100%">
      <Tabs
        centered
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          mb: 2,
          borderRadius: '0 0 5px 5px',
          backgroundColor: colors.grey[800],
        }}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            sx={{ textTransform: 'capitalize', color: '#fff' }}
            label={<TabLabel label={!lessThanSm && tab.label} startIcon={tab.icon} />}
          />
        ))}
      </Tabs>
      <ConnectionRecommendation />
      {currentTab}
    </Box>
  );
};

export default FeedTabs;
