import 'yup-phone';
import * as yup from 'yup';
import { passwordValidation, usernameValidation } from '../utils/validation';

const ENV = process.env.REACT_APP_ENV;

export const createProfileBasicInfoValidationSchema = yup.object().shape({
  salutation: yup.string().required('please select a prefix'),
  firstname: yup.string().required('please enter your first name'),
  middlename: yup.string(),
  lastname: yup.string().required('please enter your last name'),
  email: yup.string().email('please enter a valid email').required('please enter your email'),
  occupation: yup.string().required('please select an occupation'),
  organization: yup.string().required('please enter your organization'),
  designation: yup.string().required('please enter your designation'),
});

export const createProfileContactDetailsValidationSchema = yup.object().shape({
  addressLine1: yup.string().required('please enter your address'),
  addressLine2: yup.string(),
  city: yup.string().required('please enter your city'),
  state: yup.string().required('please enter your state'),
  zipCode: yup.string().required('please enter your postal/zip code'),
  country: yup.string().required('please enter your country'),
  contact: yup.string().phone().required('please enter your contact number'),
});

export const experienceValidationSchema = yup.object().shape({
  role: yup
    .string()
    .required('please enter role')
    .min(3, 'role must be at least 3 characters long'),
  institution: yup
    .string()
    .required('please enter institution')
    .min(3, 'institution must be at least 3 characters long'),
  from: yup.date().typeError('please enter a valid from date').required('please enter from date'),
  isCurrent: yup.boolean(),
  to: yup
    .mixed()
    .when('isCurrent', {
      is: true,
      then: yup.mixed().nullable().default(null),
    })
    .when('isCurrent', {
      is: (isCurrent) => isCurrent !== true,
      then: yup
        .date()
        .typeError('please enter a valid to date')
        .required('please enter to date')
        .min(yup.ref('from'), 'to date must be greater than from date'),
    }),
});

export const educationValidationSchema = yup.object().shape({
  institution: yup
    .string()
    .required('please enter institution')
    .min(3, 'institution must be at least 3 characters long'),
  degree: yup
    .string()
    .required('please enter degree')
    .min(3, 'degree must be at least 3 characters long'),
  major: yup
    .string()
    .required('please enter major')
    .min(3, 'major must be at least 3 characters long'),
  from: yup.date().typeError('please enter a valid from date').required('please enter from date'),
  isCurrent: yup.boolean(),
  to: yup
    .mixed()
    .when('isCurrent', {
      is: true,
      then: yup.mixed().nullable().default(null),
    })
    .when('isCurrent', {
      is: (isCurrent) => isCurrent !== true,
      then: yup
        .date()
        .typeError('please enter a valid to date')
        .required('please enter to date')
        .min(yup.ref('from'), 'to date must be greater than from date'),
    }),
});

export const certificateValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required('please enter title')
    .min(3, 'title must be at least 3 characters long'),
  institution: yup
    .string()
    .required('please enter institution')
    .min(3, 'institution must be at least 3 characters long'),
  url: yup.string().url('please enter a valid reference url').nullable().default(null),
});

export const editFullProfileValidationSchema = yup.object().shape({
  bio: yup.string().max(300, 'please keep your bio within 300 characters').nullable().default(null),
  salutation: yup.string().required('please select a prefix').nullable().default(null),
  firstname: yup.string().required('please enter your first name'),
  middlename: yup.string().nullable().default(null),
  lastname: yup.string().required('please enter your last name'),
  email: yup.string().email('please enter a valid email').required('please enter your email'),
  occupation: yup.string().required('please select an occupation').nullable().default(null),
  organization: yup.string().required('please enter your organization').nullable().default(null),
  designation: yup.string().required('please enter your designation').nullable().default(null),
  addressLine1: yup.string().required('please enter your address').nullable().default(null),
  addressLine2: yup.string().nullable().default(null),
  city: yup.string().required('please enter your city').nullable().default(null),
  state: yup.string().required('please enter your state').nullable().default(null),
  zipCode: yup.string().required('please enter your postal/zip code').nullable().default(null),
  country: yup.string().required('please enter your country').nullable().default(null),
  contact: yup.string().phone().required('please enter your contact number'),
});

export const editAccountInformationSchema = yup.object().shape({
  username:
    ENV === 'production'
      ? usernameValidation.required('please enter your username')
      : yup
          .string()
          .required('please enter your username')
          .min(8, 'username must be at least 8 characters long')
          .max(20, 'username must be max 20 character long'),
  email: yup.string().email('please enter a valid email').required('please enter your email'),
  contact: yup.string().phone().required('please enter your contact number'),
});

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('please enter your current password'),
  password:
    ENV === 'production'
      ? passwordValidation.required('please enter your new password')
      : yup
          .string()
          .required('please enter your new password')
          .min(8, 'password must be at least 8 character long')
          .notOneOf([yup.ref('currentPassword'), null], 'new password must be different'),
  rePassword: yup
    .string()
    .required('please confirm your new password')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
});
