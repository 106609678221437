// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';

// import { USER_HOME } from '../../constants/router-urls';

const PublicPage = ({ children }) => {
  // const isAuth = useSelector((state) => state.auth.isAuthenticated);

  // if (isAuth) {
  //   return <Navigate to={USER_HOME} replace={true} />;
  // } else {
  return children;
  // }
};

export default PublicPage;
