import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  Stack,
  Typography,
  colors,
} from '@mui/material';
import dateTime from '../../../utils/dateTime';
import { EDIT_ARTICLE, NEWS_BLOGS } from '../../../constants/router-urls';
import {
  ArchiveTwoTone,
  BookmarkTwoTone,
  CancelPresentationTwoTone,
  DeleteForeverTwoTone,
  DriveFileRenameOutlineTwoTone,
  GroupTwoTone,
  LockResetTwoTone,
  LockTwoTone,
  MoreHoriz,
  PublicTwoTone,
  PublishTwoTone,
  ReportTwoTone,
  UnarchiveTwoTone,
} from '@mui/icons-material';
import word from '../../../utils/word';
import { useSelector } from 'react-redux';
import AlertDialog from '../../global/AlertDialog';
import {
  deleteArticle,
  publishArticle,
  updateArticle,
  updateArticleArchiveStatus,
} from '../../../api/content';

const ArticleItem = ({ article, from }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isAuthor = article?.author?.id === user?.id;

  const [isPublishing, setIsPublishing] = useState(false);
  const [privacy, setPrivacy] = useState(article?.privacy);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [isPrivacyUpdating, setIsPrivacyUpdating] = useState(false);
  const [isArchiveStatusChanging, setIsArchiveStatusChanging] = useState(false);
  const [showPrivacyEditingModal, setShowPrivacyEditingModal] = useState(false);
  const [showArchiveStatusChangeAlert, setShowArchiveStatusChangeAlert] = useState(false);

  const [articleOptionsAnchorEl, setArticleOptionsAnchorEl] = useState(null);
  const articleOptionsOpen = Boolean(articleOptionsAnchorEl);
  const handleArticleOptions = (event) => setArticleOptionsAnchorEl(event.currentTarget);
  const handleArticleOptionsClose = () => setArticleOptionsAnchorEl(null);

  const privacyOptions = [
    {
      label: 'Public',
      value: 'PUBLIC',
      icon: { base: <PublicTwoTone />, small: <PublicTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: { base: <LockTwoTone />, small: <LockTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: { base: <GroupTwoTone />, small: <GroupTwoTone sx={{ height: 14, width: 14 }} /> },
    },
  ];

  const onPublish = async () => {
    setIsPublishing(true);
    await publishArticle(article?.uid);
    setIsPublishing(false);
  };

  const onPrivacyUpdate = async () => {
    setIsPrivacyUpdating(true);
    const { featuredImage } = article;
    const formData = { privacy, featuredImage, isPrivacyUpdateRequets: true };
    const result = await updateArticle(article?.uid, formData);
    if (result?.status === 200) {
      setShowPrivacyEditingModal(false);
    }
    setIsPrivacyUpdating(false);
  };

  const handleArchiveStatusChange = async () => {
    setIsArchiveStatusChanging(true);
    await updateArticleArchiveStatus(article?.uid, {
      isArchived: !article?.isArchived,
    });
    setIsArchiveStatusChanging(false);
  };

  const onArticleDelete = async () => {
    setIsDeleteLoading(true);
    await deleteArticle(article?.uid);
    setIsDeleteLoading(false);
  };

  return (
    <Box width="100%" height={250} borderRadius={1} overflow="hidden" position="relative">
      {/* publish alert */}
      <AlertDialog
        title="Publish❓"
        onResolved={onPublish}
        isOpen={showPublishAlert}
        resolveLoading={isPublishing}
        onClose={() => setShowPublishAlert(false)}
        message={`Are you sure you want to publish this? It will published with current timestamp and privacy settings (${
          privacyOptions.find((option) => option.value === article?.privacy)?.label
        }). Once published, you can't undo this action. You may archive this in future.`}
      />

      {/* archive alert */}
      <AlertDialog
        title={article?.isArchived ? 'Unarchive❓' : 'Archive❓'}
        isOpen={showArchiveStatusChangeAlert}
        onResolved={handleArchiveStatusChange}
        resolveLoading={isArchiveStatusChanging}
        onClose={() => setShowArchiveStatusChangeAlert(false)}
        message={`Are you sure you want to ${
          article?.isArchived
            ? 'unarchive this? It will be visible again to selected audience'
            : 'archive this? Only you will be able to see archived articles.'
        }`}
      />

      {/* article delete alert */}
      <AlertDialog
        title="Delete❓"
        isOpen={showDeleteAlert}
        onResolved={onArticleDelete}
        resolveLoading={isDeleteLoading}
        onClose={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this article?"
      />

      {/* privacy edit modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPrivacyEditingModal}
        onClose={isPrivacyUpdating ? null : () => setShowPrivacyEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack px={1} spacing={1} alignItems="start">
            {privacyOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                key={option.value}
                startIcon={option.icon.base}
                color={option.value === privacy ? 'primary' : 'secondary'}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  setPrivacy(option.value);
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="error"
              disabled={isPrivacyUpdating}
              onClick={() => setShowPrivacyEditingModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={onPrivacyUpdate}
              loading={isPrivacyUpdating}
              disabled={privacy === article?.privacy}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {isAuthor && (
        <IconButton
          size="small"
          sx={{
            top: 10,
            right: 10,
            zIndex: 3,
            position: 'absolute',
          }}
          onClick={handleArticleOptions}>
          <MoreHoriz sx={{ color: '#fff' }} />
        </IconButton>
      )}
      <Menu
        open={articleOptionsOpen}
        anchorEl={articleOptionsAnchorEl}
        onClose={handleArticleOptionsClose}>
        <Stack px={1} spacing={1} alignItems="start">
          {isAuthor && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                article?.isPublished
                  ? article?.isArchived
                    ? () => {
                        // unarchive
                        handleArticleOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                    : () => {
                        // archive
                        handleArticleOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                  : () => {
                      // publish
                      handleArticleOptionsClose();
                      setShowPublishAlert(true);
                    }
              }
              startIcon={
                article?.isPublished ? (
                  article?.isArchived ? (
                    <UnarchiveTwoTone />
                  ) : (
                    <ArchiveTwoTone />
                  )
                ) : (
                  <PublishTwoTone />
                )
              }
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {article?.isPublished ? (article?.isArchived ? 'Unarchive' : 'Archive') : 'Publish'}
            </Button>
          )}

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    navigate(`${EDIT_ARTICLE}/${article?.slug}`, {
                      state: { data: article, from },
                    });
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <DriveFileRenameOutlineTwoTone /> : <CancelPresentationTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Article' : 'Hide Article'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    setPrivacy(article?.privacy);
                    setShowPrivacyEditingModal(true);
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <LockResetTwoTone /> : <BookmarkTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Privacy' : 'Save Article'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="error"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    setShowDeleteAlert(true);
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <DeleteForeverTwoTone /> : <ReportTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Delete Article' : 'Report Article'}
          </Button>
        </Stack>
      </Menu>

      <Stack
        width="100%"
        height="100%"
        component={Link}
        to={{ pathname: `${NEWS_BLOGS}/${article?.slug}`, state: { from } }}
        sx={{
          cursor: 'pointer',
          ':hover': {
            boxShadow: 20,
            transition: 'ease-in-out 300ms',
            '& .article-image': {
              transform: 'scale(1.2, 1.2)',
            },
            '& .article-content': {
              bgcolor: 'rgba(0,0,0,0.7)',
            },
          },
        }}>
        <Box
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="#303030"
          position="absolute"
          className="article-image"
          sx={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transition: 'ease-in-out 300ms',
            backgroundImage: `url(${
              article?.featuredImage?.url || '/assets/images/publication-bg.jpg'
            })`,
          }}
        />
        <Stack
          p={2}
          top={0}
          left={0}
          zIndex={2}
          width="100%"
          height="100%"
          color="#fff"
          alignItems="start"
          position="absolute"
          bgcolor="rgba(0,0,0,0.45)"
          className="article-content"
          justifyContent="space-evenly">
          <Typography
            width="100%"
            variant="h6"
            fontWeight={700}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis">
            {article?.title}
          </Typography>
          {article?.isArchived ? (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <ArchiveTwoTone />
              <Typography fontSize={15} fontWeight={600}>
                Archived
              </Typography>
            </Stack>
          ) : article?.isPublished ? (
            <Stack width="100%" alignItems="start">
              <Typography
                width="100%"
                fontSize={12}
                color="grey.400"
                fontWeight={600}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis">
                Published by
                <br />
                {word.displayName(article?.author?.firstname, article?.author?.lastname)}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography fontSize={12} fontWeight={400} color={colors.grey[400]}>
                  {dateTime.render(article?.createdAt)}
                </Typography>
                {privacyOptions.find((option) => option.value === article?.privacy)?.icon?.small}
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <BookmarkTwoTone />
              <Typography fontSize={15} fontWeight={600}>
                In Drafts
              </Typography>
            </Stack>
          )}
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              WebkitLineClamp: 3,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {article?.description}
          </Typography>
          <Button size="small" sx={{ py: 0, alignSelf: 'end', color: '#fff' }}>
            Read More
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ArticleItem;
