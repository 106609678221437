import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 10,
  username: null,
  fetchTimelinePosts: false,
  fetchTimelineArticles: false,
  fetchTimelinePublications: false,
  timelinePosts: [],
  timelineArticles: [],
  timelinePublications: [],
  currentPostsOffset: 0,
  currentArticlesOffset: 0,
  currentPublicationsOffset: 0,
  totalTimelinePosts: 0,
  totalTimelineArticles: 0,
  totalTimelinePublications: 0,
  lastTimelineFilter: null,
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    controller: (state, action) => {
      return { ...state, ...action.payload };
    },
    setTimelinePosts: (state, action) => {
      return {
        ...state,
        fetchTimelinePosts: false,
        timelinePosts: action.payload.data,
        totalTimelinePosts: action.payload.totalEntries,
        currentPostsOffset: action.payload.offset || state.currentPostsOffset,
      };
    },
    setTimelineArticles: (state, action) => {
      return {
        ...state,
        fetchTimelineArticles: false,
        timelineArticles: action.payload.data,
        totalTimelineArticles: action.payload.totalEntries,
        currentArticlesOffset: action.payload.offset || state.currentArticlesOffset,
      };
    },
    setTimelinePublications: (state, action) => {
      return {
        ...state,
        fetchTimelinePublications: false,
        timelinePublications: action.payload.data,
        totalTimelinePublications: action.payload.totalEntries,
        currentPublicationsOffset: action.payload.offset || state.currentPublicationsOffset,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const timelineActions = timelineSlice.actions;
export default timelineSlice.reducer;
