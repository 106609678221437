import request from './request';
import { store } from '../store';
import { connectionActions } from '../store/reducers/connectionReducer';
import { USER_PROFILE } from '../constants/router-urls';

export const fetchConnections = async (query) => {
  const response = await request.get('/connection', query);
  if (response?.status === 200) {
    store.dispatch(
      connectionActions.setConnections({
        totalEntries: response?.totalEntries || 0,
        data: response?.data || [],
      }),
    );
  } else {
    store.dispatch(connectionActions.setConnections({ totalEntries: 0, data: [] }));
  }
};

export const fetchConnectionRecommendation = async (query) => {
  const response = await request.get('/connection/recommend', query);
  if (response?.status === 200) {
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: response?.totalEntries || 0,
        data: response?.data?.map((item) => ({ ...item, isRequested: false })) || [],
      }),
    );
  } else {
    store.dispatch(connectionActions.setConnectionRecommendations({ totalEntries: 0, data: [] }));
  }
};

export const fetchConnectionRequests = async (query) => {
  const response = await request.get('/connection/requests/received', query);
  if (response?.status === 200) {
    store.dispatch(
      connectionActions.setConnectionRequests({
        totalEntries: response?.totalEntries || 0,
        data: response?.data || [],
      }),
    );
  } else {
    store.dispatch(
      connectionActions.setConnectionRequests({
        totalEntries: 0,
        data: [],
      }),
    );
  }
};

export const fetchSentRequests = async (query) => {
  const response = await request.get('/connection/requests/sent', query);
  if (response?.status === 200) {
    store.dispatch(
      connectionActions.setSentRequests({
        totalEntries: response?.totalEntries || 0,
        data: response?.data || [],
      }),
    );
  } else {
    store.dispatch(connectionActions.setSentRequests({ totalEntries: 0, data: [] }));
  }
};

export const fetchBlockedUsers = async (query) => {
  const response = await request.get('/connection/blockList', query);
  if (response?.status === 200) {
    store.dispatch(
      connectionActions.setBlockedUsers({
        totalEntries: response?.totalEntries || 0,
        data: response?.data || [],
      }),
    );
  } else {
    store.dispatch(connectionActions.setBlockedUsers({ totalEntries: 0, data: [] }));
  }
};

export const sendConnectionRequest = async (uid) => {
  const response = await request.post('/connection', { uid });
  return response;
};

export const cancelConnectionRequest = async (uid) => {
  const response = await request.delete(`/connection/cancel/${uid}`);
  return response;
};

export const acceptConnectionRequest = async (uid) => {
  const response = await request.post('/connection/accept', { uid });
  return response;
};

export const rejectConnectionRequest = async (uid) => {
  const response = await request.delete(`/connection/reject/${uid}`);
  return response;
};

export const removeConnection = async (uid) => {
  const response = await request.delete(`/connection/remove/${uid}`);
  return response;
};

export const blockUser = async (uid) => {
  const response = await request.post('/connection/block', { uid });
  return response;
};

export const unblockUser = async (uid) => {
  const response = await request.delete(`/connection/unblock/${uid}`);
  return response;
};

export const connectionRequestHandler = async (stream, location) => {
  if (!stream) {
    store.dispatch(
      connectionActions.controller({
        fetchUserProfile: true,
        fetchSentRequests: true,
        fetchConnectionRequests: true,
        fetchConnectionRecommendation: true,
      }),
    );
    return;
  }
  const { newRequest, sentRequest } = stream;
  const connection = store.getState()?.connection;
  const connectionRecommendations = JSON.parse(
    JSON.stringify(connection?.connectionRecommendations),
  );
  const connectionRequests = JSON.parse(JSON.stringify(connection?.connectionRequests));
  const sentRequests = JSON.parse(JSON.stringify(connection?.sentRequests));
  if (newRequest) {
    const requestedByUser = newRequest?.requestedByUser;
    if (location?.pathname?.includes(`${USER_PROFILE}/${requestedByUser?.username}`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    store.dispatch(
      connectionActions.setConnectionRequests({
        totalEntries: connection?.totalRequests + 1,
        data: [newRequest, ...connectionRequests],
      }),
    );
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: connection?.totalRecomendations - 1,
        data: connectionRecommendations?.filter((item) => item?.uid !== requestedByUser?.uid),
      }),
    );
  } else if (sentRequest) {
    const isRequested = stream?.isRequested;
    const requestedToUser = sentRequest?.requestedToUser;
    if (location?.pathname?.includes(`${USER_PROFILE}/${requestedToUser?.username}`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    store.dispatch(
      connectionActions.setSentRequests({
        totalEntries: isRequested
          ? connection?.totalSentRequests + 1
          : connection?.totalSentRequests - 1,
        data: isRequested
          ? [sentRequest, ...sentRequests]
          : sentRequests?.filter((item) => item?.requestedToUser?.uid !== requestedToUser?.uid),
      }),
    );
    const updatedRecommendations = connectionRecommendations.map((item) => {
      if (item?.uid == requestedToUser?.uid) {
        return { ...item, isRequested };
      } else {
        return item;
      }
    });
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: isRequested
          ? connection?.totalRecomendations - 1
          : connection?.totalRecomendations + 1,
        data: updatedRecommendations,
      }),
    );
  }
};

export const connectionRequestResolver = async (stream, location) => {
  if (!stream) {
    store.dispatch(
      connectionActions.controller({
        fetchUserProfile: true,
        fetchSentRequests: true,
        fetchConnectionRequests: true,
        fetchConnectionRecommendation: true,
      }),
    );
    return;
  }
  const { newConnection, connectionRequest } = stream;
  const connection = store.getState()?.connection;
  const connections = JSON.parse(JSON.stringify(connection?.connections));
  const connectionRequests = JSON.parse(JSON.stringify(connection?.connectionRequests));
  const connectionRecommendations = JSON.parse(
    JSON.stringify(connection?.connectionRecommendations),
  );
  if (newConnection) {
    if (location?.pathname?.includes(`${USER_PROFILE}/${newConnection?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${newConnection?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    store.dispatch(
      connectionActions.setConnections({
        totalEntries: connection?.totalConnections + 1,
        data: [newConnection, ...connections],
      }),
    );
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: connection?.totalRecomendations - 1,
        data: connectionRecommendations?.filter((item) => item?.uid !== newConnection?.uid),
      }),
    );
  } else if (connectionRequest) {
    const isAccepted = stream?.isAccepted;
    if (location?.pathname?.includes(`${USER_PROFILE}/${connectionRequest?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${connectionRequest?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    if (isAccepted) {
      store.dispatch(
        connectionActions.setConnections({
          totalEntries: connection?.totalConnections + 1,
          data: [
            ...connections,
            connectionRequests?.find(
              (item) => item?.requestedByUser?.uid === connectionRequest?.uid,
            )?.requestedByUser,
          ],
        }),
      );
    } else {
      let isUpdated = false;
      let updatedRecommendations = connectionRecommendations.map((item) => {
        if (item?.uid == connectionRequest?.uid) {
          isUpdated = true;
          return { ...item, isRequested: false };
        } else {
          return item;
        }
      });
      if (!isUpdated) {
        updatedRecommendations = [connectionRequest, ...connectionRecommendations];
      }
      store.dispatch(
        connectionActions.setConnectionRecommendations({
          totalEntries: connection?.totalRecomendations + 1,
          data: updatedRecommendations,
        }),
      );
    }
    store.dispatch(
      connectionActions.setConnectionRequests({
        totalEntries: connection?.totalRequests - 1,
        data: connectionRequests?.filter(
          (item) => item?.requestedByUser?.uid !== connectionRequest?.uid,
        ),
      }),
    );
  }
};

export const connectionRemoveHandler = async (stream, location) => {
  if (!stream) {
    store.dispatch(
      connectionActions.controller({
        fetchUserProfile: true,
        fetchConnections: true,
        fetchSentRequests: true,
        fetchConnectionRequests: true,
        fetchConnectionRecommendation: true,
      }),
    );
    return;
  }
  const { removedConnection } = stream;
  if (removedConnection) {
    if (location?.pathname?.includes(`${USER_PROFILE}/${removedConnection?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${removedConnection?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    const connection = store.getState()?.connection;
    const connections = JSON.parse(JSON.stringify(connection?.connections));
    const connectionRecommendations = JSON.parse(
      JSON.stringify(connection?.connectionRecommendations),
    );
    store.dispatch(
      connectionActions.setConnections({
        totalEntries: connection?.totalConnections - 1,
        data: connections?.filter((item) => item?.uid !== removedConnection?.uid),
      }),
    );
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: connection?.totalRecomendations + 1,
        data: [
          ...connectionRecommendations,
          {
            ...connections?.find((item) => item?.uid === removedConnection?.uid),
            isRequested: false,
          },
        ],
      }),
    );
  }
};

export const connectionBlockHandler = async (stream, location) => {
  if (!stream) {
    store.dispatch(
      connectionActions.controller({
        fetchUserProfile: true,
        fetchBlockedUsers: true,
        fetchConnections: true,
        fetchSentRequests: true,
        fetchConnectionRequests: true,
        fetchConnectionRecommendation: true,
      }),
    );
    return;
  }
  const { blockedUser, blockedByUser } = stream;
  const connection = store.getState()?.connection;
  const connectionRecommendations = JSON.parse(
    JSON.stringify(connection?.connectionRecommendations),
  );
  const connections = JSON.parse(JSON.stringify(connection?.connections));
  const blockedUsers = JSON.parse(JSON.stringify(connection?.blockedUsers));
  const sentRequests = JSON.parse(JSON.stringify(connection?.sentRequests));
  const connectionRequests = JSON.parse(JSON.stringify(connection?.connectionRequests));
  if (blockedUser) {
    if (location?.pathname?.includes(`${USER_PROFILE}/${blockedUser?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${blockedUser?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    if (connectionRecommendations?.find((item) => item?.uid === blockedUser?.uid)) {
      store.dispatch(
        connectionActions.setConnectionRecommendations({
          totalEntries: connection?.totalRecomendations - 1,
          data: connectionRecommendations?.filter((item) => item?.uid !== blockedUser?.uid),
        }),
      );
    }
    if (connections?.find((item) => item?.uid === blockedUser?.uid)) {
      store.dispatch(
        connectionActions.setConnections({
          totalEntries: connection?.totalConnections - 1,
          data: connections?.filter((item) => item?.uid !== blockedUser?.uid),
        }),
      );
    }
    if (connectionRequests?.find((item) => item?.requestedByUser?.uid === blockedUser?.uid)) {
      store.dispatch(
        connectionActions.setConnectionRequests({
          totalEntries: connection?.totalRequests - 1,
          data: connectionRequests?.filter(
            (item) => item?.requestedByUser?.uid !== blockedUser?.uid,
          ),
        }),
      );
    }
    if (sentRequests?.find((item) => item?.requestedToUser?.uid === blockedUser?.uid)) {
      store.dispatch(
        connectionActions.setSentRequests({
          totalEntries: connection?.totalSentRequests - 1,
          data: sentRequests?.filter((item) => item?.requestedToUser?.uid !== blockedUser?.uid),
        }),
      );
    }
    store.dispatch(
      connectionActions.setBlockedUsers({
        totalEntries: connection?.totalBlockedUsers + 1,
        data: [blockedUser, ...blockedUsers],
      }),
    );
  } else if (blockedByUser) {
    if (location?.pathname?.includes(`${USER_PROFILE}/${blockedByUser?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${blockedByUser?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    if (connectionRecommendations?.find((item) => item?.uid === blockedByUser?.uid)) {
      store.dispatch(
        connectionActions.setConnectionRecommendations({
          totalEntries: connection?.totalRecomendations - 1,
          data: connectionRecommendations?.filter((item) => item?.uid !== blockedByUser?.uid),
        }),
      );
    }
    if (connections?.find((item) => item?.uid === blockedByUser?.uid)) {
      store.dispatch(
        connectionActions.setConnections({
          totalEntries: connection?.totalConnections - 1,
          data: connections?.filter((item) => item?.uid !== blockedByUser?.uid),
        }),
      );
    }
    if (connectionRequests?.find((item) => item?.requestedByUser?.uid === blockedByUser?.uid)) {
      store.dispatch(
        connectionActions.setConnectionRequests({
          totalEntries: connection?.totalRequests - 1,
          data: connectionRequests?.filter(
            (item) => item?.requestedByUser?.uid !== blockedByUser?.uid,
          ),
        }),
      );
    }
    if (sentRequests?.find((item) => item?.requestedToUser?.uid === blockedByUser?.uid)) {
      store.dispatch(
        connectionActions.setSentRequests({
          totalEntries: connection?.totalSentRequests - 1,
          data: sentRequests?.filter((item) => item?.requestedToUser?.uid !== blockedByUser?.uid),
        }),
      );
    }
  }
};

export const connectionUnblockHandler = async (stream, location) => {
  if (!stream) {
    store.dispatch(
      connectionActions.controller({
        fetchUserProfile: true,
        fetchBlockedUsers: true,
        fetchConnectionRecommendation: true,
      }),
    );
    return;
  }
  const { unblockedUser } = stream;
  if (unblockedUser) {
    if (location?.pathname?.includes(`${USER_PROFILE}/${unblockedUser?.username}/details`)) {
      store.dispatch(connectionActions.controller({ fetchUserProfile: true }));
    }
    if (location?.pathname?.includes(`${USER_PROFILE}/${unblockedUser?.username}/timeline`)) {
      store.dispatch(
        connectionActions.controller({
          fetchUserProfile: true,
          fetchTimelinePosts: true,
          fetchTimelineArticles: true,
          fetchTimelinePublications: true,
        }),
      );
    }
    const connection = store.getState()?.connection;
    const connectionRecommendations = JSON.parse(
      JSON.stringify(connection?.connectionRecommendations),
    );
    const blockedUsers = JSON.parse(JSON.stringify(connection?.blockedUsers));
    store.dispatch(
      connectionActions.setConnectionRecommendations({
        totalEntries: connection?.totalRecomendations + 1,
        data: [unblockedUser, ...connectionRecommendations],
      }),
    );
    if (blockedUsers?.find((item) => item?.uid === unblockedUser?.uid)) {
      store.dispatch(
        connectionActions.setBlockedUsers({
          totalEntries: connection?.totalBlockedUsers - 1,
          data: blockedUsers?.filter((item) => item?.uid !== unblockedUser?.uid),
        }),
      );
    }
  }
};
