import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { AddTwoTone, SchoolTwoTone } from '@mui/icons-material';
import EducationForm from './EducationForm';
import EducationCard from './EducationCard';

const EducationsTab = ({ isAuth, data, setData }) => {
  const [isCreationFormOpen, setIsCreationFormOpen] = useState(false);

  return (
    <Stack width="100%" maxWidth={650} spacing={2} my={4}>
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <SchoolTwoTone sx={{ pb: 0.4 }} />
          <Typography fontSize={20} fontWeight={700}>
            Education
          </Typography>
        </Stack>
        {isAuth && !isCreationFormOpen && (
          <Button
            size="small"
            alignSelf="start"
            color="secondary"
            variant="outlined"
            startIcon={<AddTwoTone />}
            sx={{ minWidth: 'fit-content' }}
            onClick={() => setIsCreationFormOpen(true)}>
            Add More
          </Button>
        )}
      </Stack>

      {/* Education Creation Form */}
      {isCreationFormOpen && (
        <EducationForm setData={setData} onClose={() => setIsCreationFormOpen(false)} />
      )}

      {/* Education Cards */}
      {data?.map((edu, index) => (
        <EducationCard
          data={edu}
          key={index}
          isAuth={isAuth}
          setData={setData}
          isFirst={index === 0}
          isLast={index === data?.length - 1}
        />
      ))}
    </Stack>
  );
};

export default EducationsTab;
