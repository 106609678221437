import React, { useEffect } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePaymentStatus } from '../../api/profile';
import { PAYMENT_FAILURE, USER_HOME } from '../../constants/router-urls';
import { updateAuthState } from '../../api/auth';
import { useSelector } from 'react-redux';

const PaymentSuccess = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleStatusUpdate = async () => {
    const res = await updatePaymentStatus(uid);
    if (res.status === 200) {
      await updateAuthState();
    } else {
      navigate(`${PAYMENT_FAILURE}/${uid}`, { replace: true });
    }
  };

  useEffect(() => {
    (async () => await handleStatusUpdate())();
  }, []);

  useEffect(() => {
    if (
      user.membershipStatus === 'ACTIVE' &&
      user.paymentStatus === 'PAID' &&
      (new Date(user.expiryDate) || new Date()) > new Date()
    ) {
      navigate(USER_HOME, { replace: true });
    }
  }, [user]);

  return (
    <Stack flex={1} width="100%" alignItems="center" justifyContent="center">
      <CircularProgress />
      <Typography mt={2} fontSize={22} fontWeight={500} textAlign="center">
        Updating payment status
      </Typography>
      <Typography fontSize={14} textAlign="center">
        Don&apos;t refresh the page or close this tab
      </Typography>
    </Stack>
  );
};

export default PaymentSuccess;
