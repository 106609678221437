import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import Avatar from '../../widgets/Avatar';
import { USER_PROFILE } from '../../constants/router-urls';
import word from '../../utils/word';

const UserCard = ({ user, link, children, ...rest }) => {
  return (
    <Stack
      mr={1}
      p="5px"
      boxShadow={1}
      minWidth={130}
      bgcolor="#fff"
      borderRadius={1}
      overflow="hidden"
      position="relative"
      {...rest}>
      <Avatar
        size={120}
        src={user?.image}
        label={word.displayInitials(user?.firstname, user?.lastname)}
      />
      <Stack
        p="5px"
        width={120}
        height={120}
        borderRadius={1}
        component={Link}
        position="absolute"
        justifyContent="end"
        to={link || `${USER_PROFILE}/${user?.username}`}
        sx={{
          textDecoration: 'none',
          backgroundImage: 'linear-gradient(180deg, transparent 30%, rgba(0,0,0,0.8) 100%)',
        }}>
        <Typography
          sx={{
            width: 110,
            fontSize: 12,
            color: '#fff',
            fontWeight: 600,
            overflow: 'hidden',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
          {word.displayName(user?.firstname, user?.lastname)}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default UserCard;
