import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArchiveTwoTone,
  BookmarkTwoTone,
  CancelPresentationTwoTone,
  Circle,
  DeleteForeverTwoTone,
  DriveFileRenameOutlineTwoTone,
  ErrorTwoTone,
  GroupTwoTone,
  LockResetTwoTone,
  LockTwoTone,
  MoreHoriz,
  PublicTwoTone,
  PublishTwoTone,
  ReportTwoTone,
  UnarchiveTwoTone,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  colors,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import Avatar from '../../widgets/Avatar';
import dateTime from '../../utils/dateTime';
import { EDIT_ARTICLE, USER_HOME, USER_PROFILE } from '../../constants/router-urls';
import word from '../../utils/word';
import { useSelector } from 'react-redux';
import {
  deleteArticle,
  publishArticle,
  updateArticle,
  updateArticleArchiveStatus,
} from '../../api/content';
import AlertDialog from './AlertDialog';
import Button from '../../widgets/Button';

const headingLevels = {
  1: { fontSize: 32, fontWeight: 700 },
  2: { fontSize: 28, fontWeight: 700 },
  3: { fontSize: 24, fontWeight: 700 },
  4: { fontSize: 20, fontWeight: 700 },
  5: { fontSize: 16, fontWeight: 700 },
  6: { fontSize: 14, fontWeight: 700 },
};

const ArticleRenderer = ({ isAuth, article, loading, from }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isAuthor = user?.username === article?.author?.username;

  const [isPublishing, setIsPublishing] = useState(false);
  const [privacy, setPrivacy] = useState(article?.privacy);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [isPrivacyUpdating, setIsPrivacyUpdating] = useState(false);
  const [isArchiveStatusChanging, setIsArchiveStatusChanging] = useState(false);
  const [showPrivacyEditingModal, setShowPrivacyEditingModal] = useState(false);
  const [showArchiveStatusChangeAlert, setShowArchiveStatusChangeAlert] = useState(false);

  const [articleOptionsAnchorEl, setArticleOptionsAnchorEl] = useState(null);
  const articleOptionsOpen = Boolean(articleOptionsAnchorEl);
  const handleArticleOptions = (event) => setArticleOptionsAnchorEl(event.currentTarget);
  const handleArticleOptionsClose = () => setArticleOptionsAnchorEl(null);

  const privacyOptions = [
    {
      label: 'Public',
      value: 'PUBLIC',
      icon: { base: <PublicTwoTone />, small: <PublicTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: { base: <LockTwoTone />, small: <LockTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: { base: <GroupTwoTone />, small: <GroupTwoTone sx={{ height: 14, width: 14 }} /> },
    },
  ];

  const onPublish = async () => {
    setIsPublishing(true);
    await publishArticle(article?.uid);
    setIsPublishing(false);
  };

  const onPrivacyUpdate = async () => {
    setIsPrivacyUpdating(true);
    const { featuredImage } = article;
    const formData = { privacy, featuredImage, isPrivacyUpdateRequets: true };
    const result = await updateArticle(article?.uid, formData);
    if (result?.status === 200) {
      setShowPrivacyEditingModal(false);
    }
    setIsPrivacyUpdating(false);
  };

  const handleArchiveStatusChange = async () => {
    setIsArchiveStatusChanging(true);
    await updateArticleArchiveStatus(article?.uid, {
      isArchived: !article?.isArchived,
    });
    setIsArchiveStatusChanging(false);
  };

  const onArticleDelete = async () => {
    setIsDeleteLoading(true);
    const res = await deleteArticle(article?.uid);
    if (res?.status === 200) {
      switch (from) {
        case 'timeline':
          navigate(`${USER_PROFILE}/${user?.username}/timeline`, {
            replace: true,
            state: { lastTimelineFilter: 'ARTICLES' },
          });
          break;
        default:
          navigate(`${USER_HOME}/news-&-blogs`, { replace: true });
          break;
      }
    }
    setIsDeleteLoading(false);
  };

  const RenderBodyItem = ({ block, index }) => {
    const blocks = article?.body?.blocks;

    switch (block.type) {
      case 'paragraph':
        return (
          <Box variant="body1" my={0.75}>
            {parse(block.data.text)}
          </Box>
        );
      case 'header':
        return (
          <Typography {...headingLevels[block.data.level]} my={0.75}>
            {block.data.text}
          </Typography>
        );
      case 'image':
        return (
          <Stack width="100%" alignItems="center">
            <Stack
              width="100%"
              p={block.data.withBackground ? 2 : 0}
              mt={blocks[index - 1]?.type === 'image' ? 0 : 4}
              mb={block.data.caption.replace('<br>', '') ? 0 : 4}
              alignItems={block.data.withBackground ? 'center' : 'start'}
              border={block.data.withBorder ? '1px solid #E3E3E3' : 'none'}
              bgcolor={block.data.withBackground ? '#EDF3F6' : 'transparent'}>
              <Box
                component="img"
                maxWidth="100%"
                id={block.data.file.uid}
                src={block.data.file.url}
                width={
                  block.data.stretched
                    ? '100%'
                    : document.getElementById(block.data.file.uid)?.naturalWidth
                }
              />
            </Stack>
            {block.data.caption.replace('<br>', '') && (
              <Typography variant="caption" color="textSecondary" mb={4}>
                {block.data.caption.replace('<br>', '')}
              </Typography>
            )}
          </Stack>
        );
      case 'list':
        return (
          <Stack spacing={1} my={0.75} width="100%">
            {block?.data?.items?.map((item, index) => (
              <Stack key={item} direction="row" alignItems="center" spacing={1} pl={1}>
                {block?.data?.style === 'unordered' ? (
                  <Circle sx={{ fontSize: 8 }} />
                ) : (
                  <Typography fontSize={16} fontWeight={700}>
                    {index + 1}.
                  </Typography>
                )}
                <Typography fontSize={16} fontWeight={600}>
                  {item.replace('<br>', '')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        );
      case 'warning':
        return (
          <Alert severity="warning" sx={{ width: '100%' }}>
            <b>{block.data.title}</b>
            <br />
            {block.data.message}
          </Alert>
        );
      default:
        return (
          <Typography variant="body1" my={0.75}>
            {block.data.text}
          </Typography>
        );
    }
  };

  const RenderBody = () => {
    const body = article?.body;

    return loading ? (
      <Stack width="100%">
        <Stack
          width="50%"
          minWidth={240}
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, md: 1 }}>
          <Skeleton animation="wave" variant="circular" width={45} height={45} />
          <Stack>
            <Skeleton animation="wave" variant="text" width={120} height={18} />
            <Skeleton animation="wave" variant="text" width={180} height={16} />
          </Stack>
        </Stack>
        <Skeleton
          width="100%"
          animation="wave"
          height="56.25vw"
          variant="rectangular"
          sx={{ maxHeight: 450, mt: 2 }}
        />
        <Skeleton animation="wave" variant="text" width="80%" height={32} sx={{ mt: 2 }} />
        <Skeleton animation="wave" variant="text" width="18%" height={16} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 1 }} />
        <Skeleton animation="wave" variant="text" width="70%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 4 }} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="80%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="14%" height={26} sx={{ mt: 4 }} />
        <Stack sx={{ my: 1 }} width="100%">
          {Array(3)
            .fill()
            .map((_, index) => (
              <Stack key={index} direction="row" alignItems="start" width="100%">
                <Skeleton
                  width={18}
                  height={18}
                  animation="wave"
                  variant="circular"
                  sx={{ mr: 1, pt: 1 }}
                />
                <Skeleton animation="wave" variant="text" width="18%" height={20} />
              </Stack>
            ))}
        </Stack>
      </Stack>
    ) : article ? (
      <Stack width="100%">
        {article?.isArchived ? (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <ArchiveTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              Archived
            </Typography>
          </Stack>
        ) : article?.isPublished ? (
          <Stack width="80%" direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
            <Stack
              width={{ xs: 40, md: 50 }}
              sx={{ textDecoration: 'none' }}
              to={`${USER_PROFILE}/${article?.author?.username}`}
              component={article?.author?.flags?.isVisible ? Link : 'div'}>
              <Avatar
                shape="circle"
                size={{ xs: 40, md: 50 }}
                src={article?.author?.image}
                label={word.displayInitials(article?.author?.firstname, article?.author?.lastname)}
              />
            </Stack>
            <Stack width="80%">
              <Typography
                maxWidth="100%"
                fontWeight={600}
                overflow="hidden"
                width="fit-content"
                textOverflow="ellipsis"
                color={colors.grey[800]}
                fontSize={{ xs: 14, md: 16 }}
                sx={{ textDecoration: 'none' }}
                to={`${USER_PROFILE}/${article?.author?.username}`}
                component={article?.author?.flags?.isVisible ? Link : 'div'}>
                {word.displayName(article?.author?.firstname, article?.author?.lastname)}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.5} color={colors.grey[600]}>
                <Typography fontSize={{ xs: 12, md: 14 }}>
                  {dateTime.render(article?.publishedAt)}
                </Typography>
                {privacyOptions.find((option) => option.value === article?.privacy)?.icon?.small}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <BookmarkTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              In Drafts
            </Typography>
          </Stack>
        )}
        <Box component="img" width="100%" src={article?.featuredImage?.url} mt={2} />
        <Typography fontSize={38} fontWeight={700} lineHeight={1.2} mt={2}>
          {article?.title}
        </Typography>
        <Stack alignItems="start">
          <Chip
            size="small"
            label={article?.category}
            sx={{ mr: 0.5, mt: 0.5, fontSize: 12, borderRadius: 1 }}
          />
        </Stack>
        <Typography variant="body2" fontWeight={500} color={colors.grey[700]} mt={2} mb={1}>
          {article?.description}
        </Typography>
        <Divider />
        <Stack mt={4} alignItems="start">
          {body?.blocks?.map((block, index) => (
            <RenderBodyItem key={block?.id} block={block} index={index} />
          ))}
        </Stack>
        <Divider />
        <Stack color={colors.grey[600]} my={1} spacing={2}>
          <Typography mt={4} fontSize={16} fontWeight={600}>
            Keywords
          </Typography>
          {article?.keywords?.split(',')?.map((keyword, index) => (
            <Stack key={index} direction="row" alignItems="start">
              <Circle sx={{ pb: 0.5, pr: 1 }} />
              <Typography variant="body2" fontWeight={600} color={colors.grey[700]}>
                {keyword}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    ) : (
      <Stack p={2} spacing={1} alignItems="center">
        <ErrorTwoTone sx={{ fontSize: 60, color: '#D32F2F' }} />
        <Typography fontSize={15} fontWeight={600} color="error" textAlign="center">
          Sorry, could not find the article you are looking for!
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      width="100%"
      margin="auto"
      minWidth={300}
      position="relative"
      pb={isAuth ? 0 : 8}
      maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 800 }}>
      {/* publish alert */}
      <AlertDialog
        title="Publish❓"
        onResolved={onPublish}
        isOpen={showPublishAlert}
        resolveLoading={isPublishing}
        onClose={() => setShowPublishAlert(false)}
        message={`Are you sure you want to publish this? It will published with current timestamp and privacy settings (${
          privacyOptions.find((option) => option.value === article?.privacy)?.label
        }). Once published, you can't undo this action. You may archive this in future.`}
      />

      {/* archive alert */}
      <AlertDialog
        title={article?.isArchived ? 'Unarchive❓' : 'Archive❓'}
        isOpen={showArchiveStatusChangeAlert}
        onResolved={handleArchiveStatusChange}
        resolveLoading={isArchiveStatusChanging}
        onClose={() => setShowArchiveStatusChangeAlert(false)}
        message={`Are you sure you want to ${
          article?.isArchived
            ? 'unarchive this? It will be visible again to selected audience'
            : 'archive this? Only you will be able to see archived articles.'
        }`}
      />

      {/* article delete alert */}
      <AlertDialog
        title="Delete❓"
        isOpen={showDeleteAlert}
        onResolved={onArticleDelete}
        resolveLoading={isDeleteLoading}
        onClose={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this article?"
      />

      {/* privacy edit modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPrivacyEditingModal}
        onClose={isPrivacyUpdating ? null : () => setShowPrivacyEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack px={1} spacing={1} alignItems="start">
            {privacyOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                key={option.value}
                startIcon={option.icon.base}
                color={option.value === privacy ? 'primary' : 'secondary'}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  setPrivacy(option.value);
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="error"
              disabled={isPrivacyUpdating}
              onClick={() => setShowPrivacyEditingModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={onPrivacyUpdate}
              loading={isPrivacyUpdating}
              disabled={privacy === article?.privacy}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {isAuthor && (
        <IconButton
          size="small"
          sx={{
            top: 0,
            right: 0,
            zIndex: 3,
            position: 'absolute',
          }}
          onClick={handleArticleOptions}>
          <MoreHoriz sx={{ color: colors.grey[700] }} />
        </IconButton>
      )}
      <Menu
        open={articleOptionsOpen}
        anchorEl={articleOptionsAnchorEl}
        onClose={handleArticleOptionsClose}>
        <Stack px={1} spacing={1} alignItems="start">
          {isAuthor && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                article?.isPublished
                  ? article?.isArchived
                    ? () => {
                        // unarchive
                        handleArticleOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                    : () => {
                        // archive
                        handleArticleOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                  : () => {
                      // publish
                      handleArticleOptionsClose();
                      setShowPublishAlert(true);
                    }
              }
              startIcon={
                article?.isPublished ? (
                  article?.isArchived ? (
                    <UnarchiveTwoTone />
                  ) : (
                    <ArchiveTwoTone />
                  )
                ) : (
                  <PublishTwoTone />
                )
              }
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {article?.isPublished ? (article?.isArchived ? 'Unarchive' : 'Archive') : 'Publish'}
            </Button>
          )}

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    navigate(`${EDIT_ARTICLE}/${article?.slug}`, {
                      state: { data: article, from },
                    });
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <DriveFileRenameOutlineTwoTone /> : <CancelPresentationTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Article' : 'Hide Article'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    setPrivacy(article?.privacy);
                    setShowPrivacyEditingModal(true);
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <LockResetTwoTone /> : <BookmarkTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Privacy' : 'Save Article'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="error"
            onClick={
              isAuthor
                ? () => {
                    handleArticleOptionsClose();
                    setShowDeleteAlert(true);
                  }
                : handleArticleOptionsClose
            }
            startIcon={isAuthor ? <DeleteForeverTwoTone /> : <ReportTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Delete Article' : 'Report Article'}
          </Button>
        </Stack>
      </Menu>

      <RenderBody />
    </Box>
  );
};

export default ArticleRenderer;
