import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { tempActions } from '../../store/reducers/tempReducer';
import { fetchPublicContents } from '../../api/public';
import PublicationSkeleton from '../../components/user/user-feed/PublicationSkeleton';
import PublicationItem from '../../components/user/user-feed/PublicationItem';

const Publications = () => {
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const publicState = useSelector((state) => state.public);
  const tempState = useSelector((state) => state.temp);
  const [isLoading, setIsLoading] = useState(false);
  const [caughtUp, setCaughtUp] = useState(false);

  const handleScroll = () => {
    try {
      const content = contentRef.current;
      if (content) {
        content.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    (async () => {
      setIsLoading(true);
      await fetchPublicContents({ type: 'PUBLICATION', offset: 0 }, true);
      setIsLoading(false);
    })();
  }, []);

  const loadMorePublications = async () => {
    const reachedEnd =
      Math.ceil(publicState?.totalPublicPublications / publicState?.limit) >
      publicState?.currentPublicationsOffset + 1
        ? false
        : true;
    if (reachedEnd) {
      setCaughtUp(true);
      dispatch(tempActions.controller({ isFetching: false }));
      return;
    }
    await fetchPublicContents({
      type: 'PUBLICATION',
      offset: publicState?.currentPublicationsOffset + 1,
    });
    dispatch(tempActions.controller({ isFetching: false }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    dispatch(tempActions.controller({ isFetching: true }));
    loadMorePublications();
  }, [tempState.shouldLoadMore]);

  return (
    <Box>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/publication/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            Publications on ISBM
          </Typography>
          <Typography maxWidth={650} color="grey.400" textAlign="center">
            Latest publications on ISBM are available under this section.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>

      <Container maxWidth="md" sx={{ my: 10 }} ref={contentRef}>
        <Stack width="100%" spacing={2} alignItems="center">
          {isLoading && publicState?.publicPublications?.length === 0
            ? [...Array(5)].map((_, index) => <PublicationSkeleton key={index} />)
            : publicState?.publicPublications?.map((publication, index) => (
                <PublicationItem key={index} publication={publication} />
              ))}
          {caughtUp && publicState?.publicPublications?.length > 0 ? (
            <Typography p={1} fontSize={14} color="textSecondary">
              You&apos;re all caught up
            </Typography>
          ) : (
            !isLoading && publicState?.publicPublications?.length > 0 && <PublicationSkeleton />
          )}
          {!isLoading && publicState?.publicPublications?.length === 0 && (
            <Typography p={1} fontSize={14} color="textSecondary">
              No publications found
            </Typography>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Publications;
