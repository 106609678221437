import React from 'react';
import { Button, Stack, Typography, colors } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { INDEX } from '../../constants/router-urls';
import PaymentAssistant from '../../components/user/payment/PaymentAssistant';

const PaymentFailed = () => {
  const { uid } = useParams();

  // useEffect(() => {
  //   if (
  //     user.membershipStatus === 'ACTIVE' &&
  //     user.paymentStatus === 'PAID' &&
  //     (new Date(user.expiryDate) || new Date()) > new Date()
  //   ) {
  //     navigate(USER_HOME, { replace: true });
  //   }
  // }, [user]);

  return uid === 'student-member' ? (
    <Stack
      p={1}
      flex={1}
      width="100%"
      alignItems="center"
      justifyContent="center"
      pt={{ lg: '20px', xs: '10px' }}>
      <Typography fontSize={18} fontWeight={500} color="error" textAlign="center">
        Something went wrong❗️
      </Typography>
      <Typography
        mb={4}
        mt={0.5}
        textAlign="center"
        color={colors.grey[700]}
        fontSize={{ xs: 14, md: 16 }}>
        Failed to update your membership status. Please try again later. If you think this is an
        error, please contact support.
      </Typography>
      <Stack direction="row" mt={2} spacing={1}>
        <Button size="small" variant="outlined" component={Link} to={INDEX}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack
      p={1}
      flex={1}
      width="100%"
      alignItems="center"
      justifyContent="center"
      pt={{ lg: '20px', xs: '10px' }}>
      <Typography fontSize={18} fontWeight={500} color="error" textAlign="center">
        Payment Failed❗️
      </Typography>
      <Typography
        mt={0.5}
        textAlign="center"
        color={colors.grey[700]}
        fontSize={{ xs: 14, md: 16 }}>
        Failed to process your payment. Please try again later. If you think this is an error,
        please contact support.
      </Typography>
      <Typography mb={4} fontSize={{ xs: 14, md: 16 }} color={colors.grey[700]} textAlign="center">
        Your Invoice Id is <b>{uid}</b>
      </Typography>
      <PaymentAssistant invoiceId={uid} />
    </Stack>
  );
};

export default PaymentFailed;
