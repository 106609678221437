import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/global/PageWrapper';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Popover,
  Stack,
  Switch,
  Typography,
  colors,
} from '@mui/material';
import AccountInformationForm from '../../components/user/account-settings/AccountInformationForm';
import Button from '../../widgets/Button';
import ChangePasswordForm from '../../components/user/account-settings/ChangePasswordForm';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/reducers/authReducer';
import { InfoTwoTone } from '@mui/icons-material';
import { membershipTypes } from '../../constants/membership-type';
import dateTime from '../../utils/dateTime';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const [accountVisibilityPopoverAnchorEl, setAccountVisibilityPopoverAnchorEl] = useState();
  const isAccountVisibilityPopoverOpen = Boolean(accountVisibilityPopoverAnchorEl);
  const handleAccountVisibilityPopoverClose = () => {
    setAccountVisibilityPopoverAnchorEl(null);
  };

  const [deactivateAccountPopoverAnchorEl, setDeactivateAccountPopoverAnchorEl] = useState();
  const isDeactivateAccountPopoverOpen = Boolean(deactivateAccountPopoverAnchorEl);
  const handleDeactivateAccountPopoverClose = () => {
    setDeactivateAccountPopoverAnchorEl(null);
  };

  useEffect(() => {
    fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const userCountry = data.country_name;
        if (userCountry?.toUpperCase() === 'BANGLADESH') {
          setCountry(userCountry?.toUpperCase());
        } else {
          setCountry('OTHERS');
        }
      })
      .catch((error) => {
        console.error('Error getting location:', error);
        setCountry('OTHERS');
      });
  }, []);

  const handleVisibilityChange = (e) => {
    const { checked } = e.target;
    dispatch(
      authActions.updateUser({
        user: {
          ...user,
          flags: {
            ...user.flags,
            isVisible: checked,
          },
        },
      }),
    );
  };

  return (
    <PageWrapper isAuth>
      <Box
        width="100%"
        margin="auto"
        minWidth={300}
        maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 600 }}>
        <Divider sx={{ mb: 3 }}>
          <Typography fontSize={20} fontWeight={600} color={colors.grey[600]}>
            Membership Status
          </Typography>
        </Divider>
        {!country ? (
          <Stack p={5} alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            width="100%"
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center">
            {membershipTypes[country]
              .filter((membershipType) => membershipType.type === `${user?.membershipType} MEMBER`)
              .map((membershipType) => (
                <Stack
                  p={2}
                  width={300}
                  borderRadius={2}
                  alignItems="center"
                  key={membershipType.type}
                  justifyContent="space-between"
                  sx={{
                    color: '#272739',
                    textAlign: 'center',
                    backgroundColor: '#e8e8e8',
                    transition: 'all 0.1s ease',
                    '&:hover': { backgroundColor: '#128CCD', color: '#fff' },
                  }}>
                  <Stack alignItems="center">
                    <Box
                      width={50}
                      component="img"
                      src="/assets/images/award.png"
                      borderRadius={2}
                    />
                    <Typography variant="h6" fontWeight={700}>
                      {membershipType.title}
                    </Typography>
                    <Typography fontSize="0.9rem">{membershipType.description}</Typography>
                  </Stack>
                  <Typography variant="h3" fontWeight={700} py={2}>
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(membershipType.price)}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        )}
        {user?.membershipType !== 'LIFE' && (
          <Alert severity="info" sx={{ mt: 5, fontSize: 12 }}>
            Your current membership is expiring on{' '}
            <b>{dateTime.render(user?.expiryDate, 'MMM DD, YYYY')}</b>
          </Alert>
        )}
        <Divider sx={{ my: 3 }}>
          <Typography fontSize={20} fontWeight={600} color={colors.grey[600]}>
            Account Information
          </Typography>
        </Divider>
        <AccountInformationForm />
        {!user?.flags?.isOAuth && (
          <Divider sx={{ mt: 5, mb: 3 }}>
            <Typography fontSize={20} fontWeight={600} color={colors.grey[600]}>
              Change Password
            </Typography>
          </Divider>
        )}
        {!user?.flags?.isOAuth && !showChangePasswordForm && (
          <Alert severity="info" sx={{ mb: 2, fontSize: 12 }}>
            If you feel that your password is compromised, you can change it here. It is always a
            good idea to change your password periodically.
          </Alert>
        )}
        {!user?.flags?.isOAuth &&
          (showChangePasswordForm ? (
            <ChangePasswordForm onClose={() => setShowChangePasswordForm(false)} />
          ) : (
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => setShowChangePasswordForm(true)}>
              Change Password
            </Button>
          ))}
        <Stack
          sx={{
            mt: 5,
            opacity: 0.2,
            position: 'relative',
          }}>
          <Stack
            sx={{
              top: 0,
              left: 0,
              zIndex: 2,
              width: '100%',
              height: '100%',
              position: 'absolute',
              alignItems: 'center',
              cursor: 'not-allowed',
              justifyContent: 'center',
              backdropFilter: 'blur(3px)',
            }}>
            <Typography fontWeight={800} fontSize={{ xs: 28, md: 44 }}>
              Coming Soon!
            </Typography>
          </Stack>
          <Divider
            sx={{
              mb: 3,
              background: 'error',
              '&::before, &::after': {
                borderColor: '#D32F2F',
              },
            }}>
            <Typography fontSize={20} fontWeight={600} color="error">
              Data Privacy
            </Typography>
          </Divider>
          <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="start">
              <Typography color={colors.grey[700]}>Account Visibility</Typography>
              <InfoTwoTone
                sx={{ cursor: 'pointer' }}
                onClick={(e) => setAccountVisibilityPopoverAnchorEl(e.currentTarget)}
              />
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={isAccountVisibilityPopoverOpen}
                anchorEl={accountVisibilityPopoverAnchorEl}
                onClose={handleAccountVisibilityPopoverClose}
                id={isAccountVisibilityPopoverOpen ? 'account-visibility-popover' : undefined}>
                <Typography sx={{ p: 2, fontSize: 14 }}>
                  Turning it off will hide your profile from ISBM community!
                </Typography>
              </Popover>
            </Stack>
            <FormControlLabel
              label={user?.flags?.isVisible ? 'On' : 'Off'}
              control={
                <Switch
                  onChange={handleVisibilityChange}
                  defaultChecked={user?.flags?.isVisible === true}
                />
              }
            />
          </Stack>
          <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="start">
              <Typography color={colors.grey[700]}>Deactivate Account</Typography>
              <InfoTwoTone
                sx={{ cursor: 'pointer' }}
                onClick={(e) => setDeactivateAccountPopoverAnchorEl(e.currentTarget)}
              />
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={isDeactivateAccountPopoverOpen}
                anchorEl={deactivateAccountPopoverAnchorEl}
                onClose={handleDeactivateAccountPopoverClose}
                id={isDeactivateAccountPopoverOpen ? 'deactivate-account-popover' : undefined}>
                <Typography sx={{ p: 2, fontSize: 14 }}>
                  Temporarily deactivate your account. You can reactivate it anytime simply by
                  signing in.
                </Typography>
              </Popover>
            </Stack>
            <Button size="small" color="error" variant="outlined">
              Deactivate
            </Button>
          </Stack>
          <Stack my={2}>
            <Button size="large" variant="contained" color="error">
              Request For Complete Data Erase
            </Button>
          </Stack>
        </Stack>
      </Box>
    </PageWrapper>
  );
};

export default AccountSettings;
