import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  Box,
  Chip,
  Stack,
  Accordion,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  colors,
  Divider,
} from '@mui/material';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { sectionHeaderStyle } from '../../constants/styles';
import { coreValues, missions } from '../../constants/placeholder/objectives';
import {
  advisoryMembers,
  elecCommission,
  executiveMembers,
} from '../../constants/placeholder/profiles';

const AboutISBM = () => {
  const contentRef = useRef(null);
  const matches800 = useMediaQuery('(max-width:800px)');
  const matches600 = useMediaQuery('(max-width:600px)');

  const handleScroll = () => {
    try {
      const content = contentRef.current;
      if (content) {
        content.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // if there is #privacy-and-terms in the url, scroll to that section
    if (window.location.hash === '#privacy-and-terms') {
      try {
        const element = document.getElementById('privacy-and-terms');
        if (element) {
          element.scrollIntoView();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [window.location.hash]);

  return (
    <Box>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/about/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            The International Society Of Bangladesh Affiliated Microbiologists
          </Typography>
          <Typography maxWidth={400} color="grey.400" textAlign="center">
            A voluntary, nonprofit, and nonpolitical organization of microbiology professionals,
            registered and headquartered in Maryland, USA.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Container maxWidth="lg" sx={{ mt: 10 }} ref={contentRef}>
        <Typography {...sectionHeaderStyle}>Our Story</Typography>
        <Stack direction={{ lg: 'row', xs: 'column' }} width="100%" spacing={4}>
          <Typography fontSize={18} fontWeight={500} textAlign="justify">
            The International Society of Bangladesh-affiliated Microbiologists (ISBM) is a
            voluntary, nonprofit, and nonpolitical organization of microbiology professionals,
            registered and headquartered in Maryland, USA. It is a common platform for all
            Bangladesh-affiliated Microbiologists and microbiology professionals including students
            who originated or descended from Bangladesh or are foreign microbiology professionals
            directly or indirectly involved in microbiology research, education, training, and
            extension services in Bangladesh.
          </Typography>
          <Typography fontSize={18} fontWeight={500} textAlign="justify">
            ISBM strives to provide training, mentoring, and organize various professional
            development programs to enhance foundational skills required to succeed as a student and
            early career professional. ISBM is open to the opportunity to work closely with other
            national and international scientific organizations through networking for professional
            development education, research, and training.
          </Typography>
        </Stack>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          Our Core Values
        </Typography>
        <Stack
          width="100%"
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center">
          {coreValues.map((item) => (
            <Accordion key={item.title} sx={{ maxWidth: 650 }}>
              <AccordionSummary
                id="panel1a-header"
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    width={25}
                    component="img"
                    src={item.image}
                    sx={{ filter: 'grayscale(100%)' }}
                  />
                  <Typography variant="h6" fontWeight={600}>
                    {item.title}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          Our Objectives
        </Typography>
        <Stack
          width="100%"
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center">
          {missions.map((item) => (
            <Stack
              spacing={2}
              key={item.title}
              sx={{
                m: 1,
                p: 2,
                width: 310,
                height: 270,
                color: '#fff',
                borderRadius: 2,
                bgcolor: '#4562f7',
                transition: 'all 0.2s ease',
                ':hover': {
                  transform: 'scale(1.04, 1.04)',
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                },
              }}>
              <Box component="img" src={item.image} width={45} sx={{ filter: 'grayscale(100%)' }} />
              <Typography variant="h5" fontWeight={700}>
                {item.title}
              </Typography>

              <Typography>{item.description}</Typography>
            </Stack>
          ))}
        </Stack>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          History Of ISBM
        </Typography>
        <Stack direction={{ md: 'column', lg: 'row', xs: 'column' }} width="100%" spacing={4}>
          <Typography fontSize={18} fontWeight={500} textAlign="justify">
            Founded in May 2020 by a group of Microbiologists in North America, the International
            Society of Bangladesh-affiliated Microbiologists (ISBM) aimed to establish a global
            outreach among Bangladesh-affiliated microbiologists, and professionals and students for
            promoting the essential roles of microbiologists in research, education, and public
            health in the modern world. <br /> <br /> With the intense suffering brought about by
            the COVID-19 infection worldwide, and to the people of Bangladesh, ISBM was created to
            demonstrate that Bangladeshi microbiologists are also serving as essential frontliners,
            directly or indirectly involved in the management of the pandemic. While the pandemic
            has clearly shown the importance of microbiologists particularly in testing,
            surveillance, and research, it has also identified gaps in understanding, training, and
            collaboration among the professionals.
          </Typography>
          <Typography fontSize={18} fontWeight={500} textAlign="justify">
            ISBM further aims to consolidate the intellectual abilities and professional expertise
            among Bangladesh-affiliated Microbiologists residing in different parts of the World,
            through sharing of critical scientific knowledge and expertise. ISBM promises to focus
            on ensuring quality and standards in various Microbiology related professions and
            providing career-related support to its fellow members. <br /> <br /> In line with its
            core values, in the past year, ISBM has demonstrated its commitment by organizing
            scientific webinars on contemporary issues, as well as career development seminars.
            These events have generated tremendous interest and enthusiasm among the community
            members.
          </Typography>
        </Stack>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          Executive Committee
        </Typography>
        <Divider />
        <Carousel
          autoPlay={false}
          swipeable={true}
          centerMode={true}
          showArrows={true}
          showStatus={false}
          emulateTouch={true}
          infiniteLoop={false}
          showIndicators={false}
          centerSlidePercentage={matches800 ? 100 : matches600 ? 50 : 28}>
          {executiveMembers.map((member, index) => (
            <Box
              key={index}
              height="96%"
              borderRadius={2}
              sx={{ mx: 2, mt: 1.3 }}
              bgcolor={colors.blueGrey[100]}
              boxShadow="1px 1px 2px rgba(0,0,0,0.1)">
              <Box p={2} width="100%" overflow="hidden">
                {member.image ? (
                  <Box
                    height={260}
                    overflow="hidden"
                    borderRadius="50%"
                    position="relative"
                    bgcolor={colors.blueGrey[100]}>
                    <Box
                      top="0px"
                      left="0px"
                      width="100%"
                      component="img"
                      src={member.image}
                      position="absolute"
                      bgcolor={colors.blueGrey[100]}
                    />
                  </Box>
                ) : (
                  <Box
                    height={260}
                    overflow="hidden"
                    borderRadius="4px"
                    position="relative"
                    bgcolor={colors.blueGrey[100]}>
                    <Box
                      top="0px"
                      left="0px"
                      width="100%"
                      component="img"
                      position="absolute"
                      sx={{ opacity: 0.6 }}
                      bgcolor={colors.blueGrey[100]}
                      src="/assets/images/avatar.png"
                    />
                  </Box>
                )}
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title={member.name?.length > 22 ? member.name : ''}>
                  <Typography
                    variant="h6"
                    lineHeight={1.1}
                    fontWeight={700}
                    textAlign="center"
                    whiteSpace="nowrap"
                    sx={{
                      mb: 1,
                      mt: 2,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {member.name}
                  </Typography>
                </Tooltip>
                <Chip
                  size="small"
                  sx={{ mb: 1.5 }}
                  label={member.role}
                  color={member.level}
                  variant={member.variant || 'filled'}
                />
                <Typography pb={3}>{member.designation}</Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          Advisory Board
        </Typography>
        <Divider />
        <Carousel
          autoPlay={false}
          swipeable={true}
          centerMode={true}
          showArrows={true}
          showStatus={false}
          emulateTouch={true}
          infiniteLoop={false}
          showIndicators={false}
          centerSlidePercentage={matches800 ? 100 : matches600 ? 50 : 28}>
          {advisoryMembers.map((member, index) => (
            <Box
              key={index}
              height="96%"
              bgcolor="white"
              borderRadius={2}
              sx={{ mx: 2, mt: 1 }}
              boxShadow="1px 1px 2px rgba(0,0,0,0.1)">
              <Box p={2} width="100%" overflow="hidden">
                {member.image ? (
                  <Box
                    height={260}
                    bgcolor="white"
                    overflow="hidden"
                    borderRadius="50%"
                    position="relative">
                    <Box
                      top="0px"
                      left="0px"
                      width="100%"
                      component="img"
                      bgcolor="white"
                      src={member.image}
                      position="absolute"
                    />
                  </Box>
                ) : (
                  <Box
                    height={260}
                    bgcolor="white"
                    overflow="hidden"
                    borderRadius="4px"
                    position="relative">
                    <Box
                      top="0px"
                      left="0px"
                      width="100%"
                      component="img"
                      bgcolor="white"
                      position="absolute"
                      sx={{ opacity: 0.6 }}
                      src="/assets/images/avatar.png"
                    />
                  </Box>
                )}
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title={member.name?.length > 22 ? member.name : ''}>
                  <Typography
                    variant="h6"
                    lineHeight={1.1}
                    fontWeight={700}
                    textAlign="center"
                    whiteSpace="nowrap"
                    sx={{
                      mb: 1,
                      mt: 2,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {member.name}
                  </Typography>
                </Tooltip>
                <Typography pb={3}>{member.designation}</Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }}>
          Election Commission
        </Typography>
        <Divider />
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {elecCommission.map((member, index) => (
            <Box
              key={index}
              width={320}
              height={280}
              sx={{ m: 2 }}
              bgcolor="white"
              borderRadius={2}
              boxShadow="1px 1px 2px rgba(0,0,0,0.1)">
              <Box p={2} width="100%" height="100%">
                {member.image ? (
                  <Box width={100} component="img" src={member.image} borderRadius="50%" />
                ) : (
                  <Box
                    width={100}
                    component="img"
                    borderRadius="50%"
                    sx={{ opacity: 0.6 }}
                    src="/assets/images/avatar.png"
                  />
                )}
                <Tooltip
                  arrow
                  placement="bottom-start"
                  title={member.name?.length > 22 ? member.name : ''}>
                  <Typography
                    variant="h6"
                    lineHeight={1.1}
                    fontWeight={700}
                    whiteSpace="nowrap"
                    sx={{
                      mb: 1,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {member.name}
                  </Typography>
                </Tooltip>
                <Chip
                  size="small"
                  sx={{ mb: 1.5 }}
                  label={member.role}
                  color={member.level}
                  variant={member.variant || 'filled'}
                />
                <Typography pb={3}>{member.designation}</Typography>
              </Box>
            </Box>
          ))}
        </Stack>
        <Typography {...sectionHeaderStyle} sx={{ mt: 10 }} id="privacy-and-terms">
          Privacy Policy & Terms of Use
        </Typography>
        <Stack width="100%" spacing={4} mb={10}>
          <Typography fontSize={18} fontWeight={500} textAlign="justify">
            International Society of Bangladesh-Affiliated Microbiologists (ISBM) collects
            information from the members for various purposes including but not limited to
            membership registration, website sign-in, conference, and webinar registration. This
            Privacy Policy sets, and Terms of Use set forth the information we collect about you,
            how we use this information, and the choices you have about how we use such information.
          </Typography>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              A. What We Collect and How We Use It
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                1. Membership
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                When you become an ISBM member, we collect information about you including (but not
                limited to) your name, your employer’s name, your work address (including your
                citizenship and country location), telephone number, and your email address. We may
                ask members to voluntarily provide additional information in their membership
                profile, such as information about their educational background, certifications,
                areas of expertise, and other professional and philanthropic activities. We do not
                collect your payment information under any circumstances. Payment processiong on
                ISBM is securely handled by Stripe. We strongly discourage you from sharing your
                payment information with anyone.Members are allowed to edit their profile at any
                time to change, add or remove or update personal information.
                <br />
                <br /> ISBM requires your personal information for verification of membership
                eligibility, and membership administration, provides member benefits, and
                disseminates information related to ISBM, events, content, and other benefits or
                opportunities associated with your ISBM membership.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                2. Live Events and Web Conferences
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                ISBM may collect information for ISBM-hosted events, such as your name and contact
                information, payment information, employer information, photograph, speaking
                qualifications and experience, travel and accommodations information, business
                information, educational information, purchasing preferences, and any special
                accommodations you may need. We may also make and store a recording of your voice
                and likeness in certain instances. ISBM won’t release your information to a third
                party unless it is authorized by you and is necessary for any ISBM-hosted event.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                3. ISBM Travel Awards, Research Awards, and Recognition
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                In addition to collecting your personal and business information as mentioned above,
                ISBM may collect other information such as prior travel awards, gender, educational
                background, employment information, professional activities, and other funding
                sources, nominee’s contact information, educational degrees, a description of the
                nominee’s accomplishments and reference sources, etc.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                4. Publications
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                You may redirect to the third-party website while using the ISBM website or reading
                an email from ISBM. However, ISBM is not responsible or liable for content provided
                by any third-party websites or personal information they may happen to gather from
                you.
                <br />
                <br />
                You may need to create a “profile” with ISBM which involves providing ISBM with your
                full name, email address, and the country in which you live to get access to ISBM
                publications. ISBM will not share this information with any third party other than
                to store the information in ISBM databases.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                5. ISBM Training and Certification
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                If you participate in ISBM-hosted training and certification tests, you may sign up
                directly through ISBM in which case we may collect your name, contact information,
                employment information, qualifications, education, experience, references, and
                academic degrees, from you. You may, alternatively, sign up for training – or be
                signed up for training – by or through a third party such as one of our training
                partners, or your own employer. We may also use independent contractors to conduct
                the training and third parties to provide the training venue. Your personal
                information will be stored in the ISBM database and may also be shared with our
                training partners, trainers, and/or the venue hosting the event (to verify your
                identity when you arrive).
                <br />
                <br /> Training partners, trainers, training venue hosts, exam hosting services, or
                anyone involved in these services will provide written consent that they will not
                share your information with others and not use your personal information other than
                to provide you with ISBM products and services.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                6. What Happens if You Don’t Share Your Data
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                Some personal information is necessary so that ISBM can provide you with the
                services you have purchased or are entitled to as ISBM-paid members so that we know
                it is you and not someone else.
              </Typography>
            </Stack>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={600} textAlign="justify">
                7. Use of ISBM Website
              </Typography>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                ISBM website collects certain information automatically and stores it in log files.
                The information may include internet protocol (IP) addresses, the region or general
                location where your computer or device is accessing the internet, browser type,
                operating system, and other usage information about the use of the ISBM website,
                including a history of the pages you view. We use this information to help us design
                our site to better suit our users’ needs. We may also use your IP address to help
                diagnose problems with our server and to administer our website, analyze trends, and
                track visitor movements that assist us in identifying visitor preferences.
              </Typography>
            </Stack>
          </Stack>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              B. Cookies and web beacons
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                This website uses Google Analytics, a web analytics service provided by Google, Inc.
                (”Google”). Google Analytics uses ”cookies,” which are text files placed on your
                computer, to help the website analyze how users use the site. The information
                generated by the cookie about your use of the website (including your IP address)
                will be transmitted to and stored by Google on servers in the United States. Google
                will use this information for the purposes of evaluating your use of the website,
                compiling reports on website activity for website operators, and providing other
                services relating to website activity and internet usage. Google may also transfer
                this information to third parties where required to do so by law, or where such
                third parties process the information on Google’s behalf. Google will not associate
                your IP address with any other data held by Google. You may refuse the use of
                cookies by selecting the appropriate settings on your browser, however, please note
                that if you do this you may not be able to use the full functionality of this
                website. By using the ISBM website, you consent to the processing of data about you
                by Google in the manner and for the purposes set out above.
                <br />
                <br />
                Users can opt out of Google’s use of cookies by visiting Google’s Ads Settings.
                Alternatively, users can choose to opt out of a third-party vendor’s use of cookies
                by visiting the Network Advertising Initiative opt-out page.
              </Typography>
            </Stack>
          </Stack>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              C. When and How We Share Your Information
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                The personal information provided by you is stored in the database hosted by third
                parties located in the United States. However, the third party doesn’t use or have
                access to your personal information for any purpose other than storage and
                retrieval. ISBM may use resources from third parties to communicate with you by
                email or mail for various purposes. We do not otherwise reveal your personal data to
                third parties for their independent use unless:
              </Typography>
              <Stack width="100%">
                <ol>
                  <li>You request or authorize it</li>
                  <li>
                    It’s in connection with ISBM-hosted and co-sponsored conferences and events as
                    described above
                  </li>
                  <li>
                    The information is provided to comply with the law enforcement agreement we have
                    with you or to protect our rights, property, or safety or the rights, property,
                    or safety of our employees or others
                  </li>
                  <li>
                    The information is provided to third parties who perform functions on behalf of
                    ISBM
                  </li>
                  <li>To address emergencies or natural calamities</li>
                  <li>
                    To address disputes, claims, or to persons demonstrating legal authority to act
                    on your behalf
                  </li>
                  <li>Through the ISBM Member Directory</li>
                </ol>
              </Stack>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                ISBM may in the future gather aggregated data, not personally identifiable, about
                our members and site visitors and disclose it to our partners, service providers,
                advertisers, and/or other third parties for marketing or promotional purposes.
                <br />
                <br />
                The ISBM website may use interfaces with social media sites such as Facebook,
                LinkedIn, Twitter, and others. If you choose to ”like” or share information from the
                ISBM website through these services, you should review the privacy policy of that
                service. If you are a member of a social media site, the interfaces may allow the
                social media site to connect your site visit to your personal data.
              </Typography>
            </Stack>
          </Stack>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              D. Member Directory
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                ISBM makes member information for professional and networking purposes available
                through its member directory to other ISBM members using this site. Members are
                welcome to have their information shared in the Member Directory.
              </Typography>
            </Stack>
          </Stack>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              E. Your Privacy Rights
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                You have the right to confirm that ISBM is processing your personal data, or to have
                access to the personal data ISBM may have about you. You may also request
                information about the purpose of the processing; the categories of personal data
                concerned; who else outside ISBM might have received the data from ISBM; what the
                source of the information was (if you didn’t provide it directly to ISBM); You have
                a right to correct (rectify) and edit inaccurate and/or incomplete data about you
                that is maintained by ISBM. You may request that ISBM erase that data or cease
                processing it, and cease using your data for direct marketing purposes.
              </Typography>
            </Stack>
          </Stack>

          <Stack width="100%" spacing={3}>
            <Typography fontSize={18} fontWeight={700} textAlign="justify">
              G. Data Storage and Retention
            </Typography>
            <Stack width="100%" spacing={1}>
              <Typography fontSize={18} fontWeight={500} textAlign="justify">
                Your personal data is stored by ISBM on the service provider database which is
                managed by ISBM in the U.S. ISBM retains data for the duration of the customer’s or
                member’s business relationship with ASM. ISBM reserves the right to amend the
                Privacy Notice and Conditions of Use at any time, for any reason, without notice to
                you, other than the posting of the amended Privacy Notice and Conditions of Use at
                this site. We may email periodic reminders of our notices and terms and conditions
                and will email ISBM members. You should also check the ISBM website frequently to
                see the current Privacy Notice and Terms of Use that are in effect and any changes
                that may have been made to it.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default AboutISBM;
