import * as yup from 'yup';

export const publicationSchema = yup.object().shape({
  title: yup.string().required('please enter publication title').min(5).max(128),
  description: yup.string().required('please provide short description').min(5).max(256),
  abstract: yup.string().required('please provide publication abstract').min(5),
  url: yup
    .string()
    .url(
      // eslint-disable-next-line quotes
      "please provide a valid url that matches 'https://my.publication.url' or 'http://my.publication.url'",
    )
    .required('please provide publication url'),
  authors: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('please enter author name').nullable().default(''),
        email: yup.string().email('please provide a valid email address').nullable().default(''),
        affiliation: yup.string().nullable().default(''),
      }),
    )
    .required('please provide publication authors')
    .min(1, 'please add at least one author'),
});
