import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack, Typography } from '@mui/material';

import PostItem from '../user-feed/PostItem';
import { fetchProfile } from '../../../api/profile';
import PostSkeleton from '../user-feed/PostSkeleton';
import { tempActions } from '../../../store/reducers/tempReducer';

const TimelinePostsTab = ({ username }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [caughtUp, setCaughtUp] = useState(false);
  const tempState = useSelector((state) => state.temp);
  const timeline = useSelector((state) => state.timeline);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [query, setQuery] = useState({ offset: 0, limit: 10, filter: 'POSTS' });

  const fetchPosts = async (reset) => {
    await fetchProfile(username, 'timeline', query, reset);
    dispatch(tempActions.controller({ isFetching: false }));
    setLoading(false);
  };

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    fetchPosts(true);
    setIsInitialMount(false);

    return () => setIsInitialMount(true);
  }, []);

  useEffect(() => {
    if (isInitialMount) return;
    dispatch(tempActions.controller({ isFetching: true }));
    fetchPosts();
  }, [query]);

  useEffect(() => {
    if (timeline?.fetchTimelinePosts) {
      setQuery((prev) => ({ ...prev, offset: 0 }));
      dispatch(tempActions.controller({ shouldLoadMore: false }));
      fetchPosts(true);
    }
  }, [timeline]);

  const loadMorePosts = () => {
    const reachedEnd =
      timeline?.totalTimelinePosts / query?.limit > timeline?.currentPostsOffset + 1 ? false : true;
    if (reachedEnd) {
      setCaughtUp(true);
      dispatch(tempActions.controller({ isFetching: false }));
      return;
    }
    setQuery((prev) => ({ ...prev, offset: timeline?.currentPostsOffset + 1 }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    loadMorePosts();
  }, [tempState.shouldLoadMore]);

  return (
    <Stack width="100%" pt={2} spacing={2} alignItems="center">
      {loading && timeline?.timelinePosts?.length === 0
        ? [...Array(5)].map((_, index) => <PostSkeleton key={index} />)
        : timeline?.timelinePosts?.map((post, index) => <PostItem key={index} post={post} />)}
      {caughtUp && timeline?.timelinePosts?.length > 0 ? (
        <Typography p={1} fontSize={14} color="textSecondary">
          You&apos;re all caught up
        </Typography>
      ) : (
        !loading && timeline?.timelinePosts?.length > 0 && <PostSkeleton />
      )}
      {!loading && timeline?.timelinePosts?.length === 0 && (
        <Typography p={1} fontSize={14} color="textSecondary">
          No posts found
        </Typography>
      )}
    </Stack>
  );
};

export default TimelinePostsTab;
