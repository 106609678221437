import dayjs from 'dayjs';

export default {
  now: (format) => {
    try {
      return dayjs().format(format || 'YYYY-MM-DD HH:mm:ss');
    } catch (e) {
      return null;
    }
  },

  render: (timestamp, format) => {
    try {
      if (!dayjs(timestamp).isValid()) {
        return null;
      }
      return dayjs(timestamp).format(format || 'hh:mm A DD, MMM YYYY');
    } catch (e) {
      return '';
    }
  },

  format: (timestamp, format) => {
    try {
      if (!dayjs(timestamp).isValid()) {
        return null;
      }
      return dayjs(timestamp).format(format || 'YYYY-MM-DD');
    } catch (e) {
      return null;
    }
  },

  remainingTime: (timestamp, unit = 'days') => {
    try {
      const now = dayjs();
      const target = dayjs(timestamp);
      const diff = target.diff(now, unit);
      return diff;
    } catch (e) {
      return null;
    }
  },

  elapsedTime: (timestamp, { short }) => {
    try {
      const now = dayjs();
      const then = dayjs(timestamp);
      const diff = now.diff(then, 'second');
      if (diff < 30) {
        return 'just now';
      } else if (diff < 60) {
        return `${diff}s`;
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}${
          short ? 'm' : `minute${Math.floor(diff / 60) > 1 ? 's' : ''} ago`
        }`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}${
          short ? 'h' : `hour${Math.floor(diff / 3600) > 1 ? 's' : ''} ago`
        }`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}${
          short ? 'd' : `day${Math.floor(diff / 86400) > 1 ? 's' : ''} ago`
        }`;
      } else if (diff < 2629743) {
        return `${Math.floor(diff / 604800)}${
          short ? 'w' : `week${Math.floor(diff / 604800) > 1 ? 's' : ''} ago`
        }`;
      } else if (diff < 31556926) {
        return `${Math.floor(diff / 2629743)}${
          short ? 'mo' : `month${Math.floor(diff / 2629743) > 1 ? 's' : ''} ago`
        }`;
      } else {
        return `${Math.floor(diff / 31556926)}${
          short ? 'y' : `year${Math.floor(diff / 31556926) > 1 ? 's' : ''} ago`
        }`;
      }
    } catch (error) {
      return '';
    }
  },
};
