import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Menu, Stack, Typography, colors } from '@mui/material';
import {
  BookTwoTone,
  NewspaperTwoTone,
  StickyNote2TwoTone,
  KeyboardArrowDownTwoTone,
  AddCircleTwoTone,
  BookmarkAddTwoTone,
  PostAddTwoTone,
} from '@mui/icons-material';
import Button from '../../../widgets/Button';
import TimelinePostsTab from './TimelinePostsTab';
import { useDispatch, useSelector } from 'react-redux';
import TimelineArticlesTab from './TimelineArticlesTab';
import TimelinePublicationsTab from './TimelinePublicationsTab';
import { timelineActions } from '../../../store/reducers/timelineReducer';
import PostCreationModal from '../user-feed/PostCreationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_ARTICLE, CREATE_PUBLICATION } from '../../../constants/router-urls';

const TimelineTab = ({ isAuth, username }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState('POSTS');
  const timeline = useSelector((state) => state.timeline);

  const [showPostCreationModal, setShowPostCreationModal] = useState(false);

  const [filterOptionsAnchorEl, setFilterOptionsAnchorEl] = useState(null);
  const filterOptionsOpen = Boolean(filterOptionsAnchorEl);
  const handleFilterOptions = (event) => setFilterOptionsAnchorEl(event.currentTarget);
  const handleFilterOptionsClose = () => setFilterOptionsAnchorEl(null);

  const [createOptionsAnchorEl, setCreateOptionsAnchorEl] = useState(null);
  const createOptionsOpen = Boolean(createOptionsAnchorEl);
  const handleCreateOptions = (event) => setCreateOptionsAnchorEl(event.currentTarget);
  const handleCreateOptionsClose = () => setCreateOptionsAnchorEl(null);

  useEffect(() => {
    setActiveFilter(timeline.lastTimelineFilter || location?.state?.lastTimelineFilter || 'POSTS');
  }, []);

  const filterOptions = [
    { label: 'Posts', value: 'POSTS', icon: <StickyNote2TwoTone /> },
    {
      label: 'Publications',
      value: 'PUBLICATIONS',
      icon: <BookTwoTone />,
    },
    {
      label: 'News & Blogs',
      value: 'ARTICLES',
      icon: <NewspaperTwoTone />,
    },
  ];

  const createOptions = [
    {
      label: 'New Posts',
      icon: <StickyNote2TwoTone />,
      callBack: () => setShowPostCreationModal(true),
    },
    {
      label: 'New Publication',
      icon: <BookmarkAddTwoTone />,
      callBack: () => navigate(CREATE_PUBLICATION, { state: { from: 'timeline' } }),
    },
    {
      label: 'News & Blog',
      icon: <PostAddTwoTone />,
      callBack: () => navigate(CREATE_ARTICLE, { state: { from: 'timeline' } }),
    },
  ];

  const renderTimelineFilterView = () => {
    switch (activeFilter) {
      case 'POSTS':
        return <TimelinePostsTab isAuth={isAuth} username={username} />;
      case 'ARTICLES':
        return <TimelineArticlesTab isAuth={isAuth} username={username} />;
      case 'PUBLICATIONS':
        return <TimelinePublicationsTab isAuth={isAuth} username={username} />;
      default:
        return <TimelinePostsTab isAuth={isAuth} username={username} />;
    }
  };

  return (
    <Box>
      <PostCreationModal
        isOpen={showPostCreationModal}
        onClose={() => setShowPostCreationModal(false)}
      />
      <Stack my={1} direction="row" justifyContent="space-between" alignItems="end">
        <Stack spacing={0.5}>
          <Typography fontSize={12} fontWeight={500} color={colors.grey[700]}>
            Showing
          </Typography>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={handleFilterOptions}
            sx={{ minWidth: 'fit-content' }}
            endIcon={<KeyboardArrowDownTwoTone />}
            startIcon={filterOptions.find((option) => option.value === activeFilter)?.icon}>
            {filterOptions.find((option) => option.value === activeFilter)?.label}
          </Button>
          {/* Filter Options */}
          <Menu
            open={filterOptionsOpen}
            anchorEl={filterOptionsAnchorEl}
            onClose={handleFilterOptionsClose}>
            <Stack px={1} spacing={1} alignItems="start">
              {filterOptions.map((option) => (
                <Button
                  fullWidth
                  size="small"
                  key={option.value}
                  startIcon={option.icon}
                  color={option.value === activeFilter ? 'primary' : 'secondary'}
                  sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                  onClick={() => {
                    dispatch(timelineActions.controller({ lastTimelineFilter: option.value }));
                    setActiveFilter(option.value);
                    handleFilterOptionsClose();
                  }}>
                  {option.label}
                </Button>
              ))}
            </Stack>
          </Menu>
        </Stack>
        <IconButton onClick={handleCreateOptions}>
          <AddCircleTwoTone />
        </IconButton>
        {/* Create Options */}
        <Menu
          open={createOptionsOpen}
          anchorEl={createOptionsAnchorEl}
          onClose={handleCreateOptionsClose}>
          <Stack px={1} spacing={1} alignItems="start">
            {createOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                color="secondary"
                key={option.label}
                startIcon={option.icon}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  option.callBack();
                  handleCreateOptionsClose();
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
        </Menu>
      </Stack>
      <Divider />
      {renderTimelineFilterView()}
    </Box>
  );
};

export default TimelineTab;
