import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Stack,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  colors,
} from '@mui/material';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';
import * as urls from '../../constants/router-urls';
import DevSign from './DevSign';
import Button from '../../widgets/Button';

const PublicFooter = () => {
  const navigate = useNavigate();
  const [showDonateModal, setShowDonateModal] = useState(false);

  return (
    <Box width="100%" bgcolor="#272739">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showDonateModal}
        onClose={() => setShowDonateModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack mt={2} spacing={2} alignItems="center">
            <DevSign />
            <Typography fontSize={18} fontWeight={500} color={colors.grey[700]} textAlign="center">
              Meanwhile you can make a contribution to ISBM by communicating with us through the
              contact page.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowDonateModal(false);
                navigate(urls.CONTACT);
              }}>
              Contact Us?
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Container maxWidth="xl" sx={{ py: 5 }}>
        <Stack
          mx="auto"
          direction="row"
          flexWrap="wrap"
          maxWidth="fit-content"
          alignItems="flex-start">
          <Stack px="3vw" py={4} spacing={2} alignItems="flex-start">
            <Stack direction="row" alignItems="center">
              <Box component="img" src="/assets/images/logo.png" width="60px" height="60px" />
              <Typography variant="h5" fontWeight={700} letterSpacing={2} ml={1} color="#fff">
                ISBM
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{ borderRadius: 0, py: 1.5, px: 8 }}
              onClick={() => setShowDonateModal(true)}>
              Donate
            </Button>
          </Stack>

          <Stack px="3vw" py={4} spacing={2}>
            <Typography fontSize="0.9rem" fontWeight={600} color="#fff">
              THE INTERNATIONAL SOCIETY OF
              <br />
              BANGLADESH-AFFILIATED
              <br />
              MICROBIOLOGISTS
            </Typography>
            <Typography fontSize="0.8rem" color="#fff">
              432 Firestone Dr,
              <br />
              Ashton, MD 20861, USA.
            </Typography>
            {/* <Typography fontSize="0.8rem" color="#fff">
              (012) 345-6780
            </Typography> */}
            <Typography
              color="#fff"
              component="a"
              fontSize="0.8rem"
              sx={{ textDecoration: 'none' }}
              href="mailto:info@isbm-global.org">
              info@isbm-global.org
            </Typography>
            <Stack direction="row" spacing={2} pt={1}>
              <Facebook sx={{ color: '#fff', cursor: 'pointer' }} />
              <Twitter sx={{ color: '#fff', cursor: 'pointer' }} />
              <LinkedIn sx={{ color: '#fff', cursor: 'pointer' }} />
            </Stack>
          </Stack>

          <Stack px="3vw" py={4} spacing={1}>
            <Typography fontSize="0.9rem" fontWeight={600} color="#fff" mb={1}>
              CONNECT WITH ISBM
            </Typography>
            <Link
              to={urls.MEMBERSHIP}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Membership
            </Link>
            <Link
              to={urls.MEETING_EVENTS}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Meetings & Events
            </Link>
            <Link
              to={urls.PUBLICATIONS}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Publications
            </Link>
            <Link
              to={urls.NEWS_BLOGS}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              News & Blogs
            </Link>
            <Link
              to={urls.PROFESSIONAL_CAREER}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Professional Career
            </Link>
            <Link
              to={urls.RESOURCES}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Resources
            </Link>
            <Link
              to={urls.CONTACT}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Contact Us
            </Link>
          </Stack>

          <Stack px="3vw" py={4} spacing={1}>
            <Typography fontSize="0.9rem" fontWeight={600} color="#fff" mb={1}>
              SUPPORT ISBM
            </Typography>
            <Link
              to={urls.SUPPORT_ISBM}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Volunteer
            </Link>
            <Link
              to={urls.SUPPORT_ISBM}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Donate to ISBM
            </Link>
            <Link
              to={urls.SUPPORT_ISBM}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Community welfare
            </Link>
            <Link
              to={urls.SUPPORT_ISBM}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Advertise with ISBM
            </Link>
          </Stack>

          <Stack px="3vw" py={4} spacing={1}>
            <Typography fontSize="0.9rem" fontWeight={600} color="#fff" mb={1}>
              ABOUT US
            </Typography>
            <Link
              to={urls.ABOUT_ISBM}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Learn about ISBM
            </Link>
            <Link
              to={urls.ABOUT_ISBM + '#privacy-and-terms'}
              style={{ fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>
              Privacy Policy & Terms of Use
            </Link>
          </Stack>
        </Stack>
        <Divider sx={{ bgcolor: '#fff' }} />
        <Typography
          mt={2}
          color="#fff"
          textAlign="center"
          fontSize={{ md: '1rem', sm: '0.9rem', xs: '0.8rem' }}>
          © Copyright {new Date().getFullYear()} The International Society of Bangladesh-Affiliated
          Microbiologists
        </Typography>
      </Container>
    </Box>
  );
};

export default PublicFooter;
