import request from './request';
import { store } from '../store';
import notify from '../utils/notify';
import { feedActions } from '../store/reducers/feedReducer';
import { timelineActions } from '../store/reducers/timelineReducer';
import { tempActions } from '../store/reducers/tempReducer';

export const fetchCategories = async (query) => {
  const response = await request.get('/contents/categories', query);
  if (response?.status === 200) {
    return response?.data || [];
  } else {
    return [];
  }
};

export const fetchKeywords = async (query) => {
  const response = await request.get('/contents/keywords', query);
  if (response?.status === 200) {
    return response?.data || [];
  } else {
    return [];
  }
};

export const fetchFeedContents = async (query, reset) => {
  const response = await request.get('/contents', query);
  if (response?.status === 200) {
    if (query?.type?.toUpperCase() === 'ARTICLE') {
      const currentArticles = store.getState()?.feed?.feedArticles;
      store.dispatch(
        feedActions.setFeedArticles({
          data: reset ? response.data : [...currentArticles, ...response.data],
          offset: response?.offset || 0,
          totalEntries: response?.totalEntries || 0,
        }),
      );
    } else if (query?.type?.toUpperCase() === 'PUBLICATION') {
      const currentPublications = store.getState()?.feed?.feedPublications;
      store.dispatch(
        feedActions.setFeedPublications({
          data: reset ? response.data : [...currentPublications, ...response.data],
          offset: response?.offset || 0,
          totalEntries: response?.totalEntries || 0,
        }),
      );
    }
  }
};

export const createNewArticle = async (formData) => {
  const response = await request.post('/contents/article', formData);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Article created successfully');
  }
  return response;
};

export const createNewPublication = async (formData) => {
  const response = await request.post('/contents/publication', formData);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Publication created successfully');
  }
  return response;
};

export const updateArticle = async (uid, formData) => {
  const response = await request.patch(`/contents/article/${uid}`, formData);
  if (response?.status === 200 && !formData?.imageMode) {
    notify.success(response?.msg || 'Article updated successfully');
  }
  return response;
};

export const updatePublication = async (uid, formData) => {
  const response = await request.patch(`/contents/publication/${uid}`, formData);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Publication updated successfully');
  }
  return response;
};

export const deleteArticle = async (uid) => {
  const response = await request.delete(`/contents/article/${uid}`);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Article deleted!');
  }
  return response;
};

export const deletePublication = async (uid) => {
  const response = await request.delete(`/contents/publication/${uid}`);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Publication deleted!');
  }
  return response;
};

export const publishArticle = async (uid) => {
  const response = await request.patch(`/contents/article/publish/${uid}`);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Article published successfully');
  }
  return response;
};

export const publishPublication = async (uid) => {
  const response = await request.patch(`/contents/publication/publish/${uid}`);
  if (response?.status === 200) {
    notify.success(response?.msg || 'Publication published successfully');
  }
  return response;
};

export const updateArticleArchiveStatus = async (uid, formData) => {
  const response = await request.patch(`/contents/article/archive-status/${uid}`, formData);
  if (response?.status === 200) {
    notify.success(
      response?.msg || `Article ${formData?.isArchived ? 'archived' : 'unarchoved'} successfully`,
    );
  }
  return response;
};

export const updatePublicationArchiveStatus = async (uid, formData) => {
  const response = await request.patch(`/contents/publication/archive-status/${uid}`, formData);
  if (response?.status === 200) {
    notify.success(
      response?.msg ||
        `Publication ${formData?.isArchived ? 'archived' : 'unarchoved'} successfully`,
    );
  }
  return response;
};

export const feedPublicationUpdateHandler = (stream, isSelfPublication) => {
  const feed = store.getState()?.feed;
  const publications = JSON.parse(JSON.stringify(feed?.feedPublications));
  let updatedPublications;
  if (stream?.updatedPublication) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedPublication;
    updatedPublications = publications?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      if (isSelfPublication) {
        updatedPublications?.unshift(stream?.updatedPublication);
      } else {
        updatedPublications?.push(stream?.updatedPublication);
      }
      store.dispatch(
        feedActions.setFeedPublications({
          data: updatedPublications,
          totalEntries: feed?.totalFeedPublications + 1,
        }),
      );
      return;
    }
  } else if (stream?.uid) {
    updatedPublications = publications?.filter((item) => item?.uid !== stream?.uid);
    store.dispatch(
      feedActions.setFeedPublications({
        data: updatedPublications,
        totalEntries: feed?.totalFeedPublications - 1,
      }),
    );
    return;
  } else {
    return;
  }
  store.dispatch(
    feedActions.setFeedPublications({
      data: updatedPublications,
      totalEntries: feed?.totalFeedPublications,
    }),
  );
};

export const timelinePublicationUpdateHandler = (stream) => {
  const timeline = store.getState()?.timeline;
  const publications = JSON.parse(JSON.stringify(timeline?.timelinePublications));
  let updatedPublications;
  if (stream?.updatedPublication) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedPublication;
    updatedPublications = publications?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      updatedPublications?.unshift(stream?.updatedPublication);
      store.dispatch(
        timelineActions.setTimelinePublications({
          data: updatedPublications,
          totalEntries: timeline?.totalTimelinePublications + 1,
        }),
      );
      return;
    }
  } else if (stream?.uid) {
    if (stream?.privacy && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      updatedPublications = publications?.map((item) => {
        if (item?.uid === stream.uid) {
          return {
            ...item,
            privacy: stream.privacy,
          };
        }
        return item;
      });
    } else if (stream?.archived && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      updatedPublications = publications?.map((item) => {
        if (item?.uid === stream.uid) {
          return {
            ...item,
            isArchived: stream.archived,
          };
        }
        return item;
      });
    } else {
      updatedPublications = publications?.filter((item) => item?.uid !== stream?.uid);
      store.dispatch(
        timelineActions.setTimelinePublications({
          data: updatedPublications,
          totalEntries: timeline?.totalTimelinePublications - 1,
        }),
      );
      return;
    }
  } else {
    return;
  }
  store.dispatch(
    timelineActions.setTimelinePublications({
      data: updatedPublications,
      totalEntries: timeline?.totalTimelinePublications,
    }),
  );
};

export const publicationPreviewUpdateHandler = (stream, forcePush) => {
  const tempState = store.getState()?.temp;
  const publication = JSON.parse(JSON.stringify(tempState?.publication));
  if (stream?.updatedPublication) {
    const { uid, ...rest } = stream.updatedPublication;
    let isUpdateSuccess = false;
    if (publication?.uid === uid) {
      store.dispatch(
        tempActions.controller({
          publication: {
            ...publication,
            ...rest,
          },
        }),
      );
      isUpdateSuccess = true;
    }
    if (!isUpdateSuccess && forcePush) {
      store.dispatch(
        tempActions.controller({
          publication: stream.updatedPublication,
        }),
      );
    }
  } else if (publication?.uid === stream?.uid) {
    if (stream?.privacy && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      store.dispatch(
        tempActions.controller({
          publication: {
            ...publication,
            privacy: stream.privacy,
          },
        }),
      );
    } else if (stream?.archived && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      store.dispatch(
        tempActions.controller({
          publication: {
            ...publication,
            isArchived: stream.archived,
          },
        }),
      );
    } else {
      store.dispatch(
        tempActions.controller({
          publication: null,
        }),
      );
    }
  } else {
    return;
  }
};

export const feedArticleUpdateHandler = (stream, isSelfArticle) => {
  const feed = store.getState()?.feed;
  const articles = JSON.parse(JSON.stringify(feed?.feedArticles));
  let updatedArticles;
  if (stream?.updatedArticle) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedArticle;
    updatedArticles = articles?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      if (isSelfArticle) {
        updatedArticles?.unshift(stream?.updatedArticle);
      } else {
        updatedArticles?.push(stream?.updatedArticle);
      }
      store.dispatch(
        feedActions.setFeedArticles({
          data: updatedArticles,
          totalEntries: feed?.totalFeedArticles + 1,
        }),
      );
      return;
    }
  } else if (stream?.uid) {
    updatedArticles = articles?.filter((item) => item?.uid !== stream?.uid);
    store.dispatch(
      feedActions.setFeedArticles({
        data: updatedArticles,
        totalEntries: feed?.totalFeedArticles - 1,
      }),
    );
    return;
  } else {
    return;
  }
  store.dispatch(
    feedActions.setFeedArticles({
      data: updatedArticles,
      totalEntries: feed?.totalFeedArticles,
    }),
  );
};

export const timelineArticleUpdateHandler = (stream) => {
  const timeline = store.getState()?.timeline;
  const articles = JSON.parse(JSON.stringify(timeline?.timelineArticles));
  let updatedArticles;
  if (stream?.updatedArticle) {
    let updateSuccess = false;
    const { uid, ...rest } = stream.updatedArticle;
    updatedArticles = articles?.map((item) => {
      if (item?.uid === uid) {
        updateSuccess = true;
        return {
          ...item,
          ...rest,
        };
      }
      return item;
    });
    if (!updateSuccess) {
      updatedArticles?.unshift(stream?.updatedArticle);
      store.dispatch(
        timelineActions.setTimelineArticles({
          data: updatedArticles,
          totalEntries: timeline?.totalTimelineArticles + 1,
        }),
      );
      return;
    }
  } else if (stream?.uid) {
    if (stream?.privacy && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      updatedArticles = articles?.map((item) => {
        if (item?.uid === stream.uid) {
          return {
            ...item,
            privacy: stream.privacy,
          };
        }
        return item;
      });
    } else if (stream?.archived && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      updatedArticles = articles?.map((item) => {
        if (item?.uid === stream.uid) {
          return {
            ...item,
            isArchived: stream.archived,
          };
        }
        return item;
      });
    } else {
      updatedArticles = articles?.filter((item) => item?.uid !== stream?.uid);
      store.dispatch(
        timelineActions.setTimelineArticles({
          data: updatedArticles,
          totalEntries: timeline?.totalTimelineArticles - 1,
        }),
      );
      return;
    }
  } else {
    return;
  }
  store.dispatch(
    timelineActions.setTimelineArticles({
      data: updatedArticles,
      totalEntries: timeline?.totalTimelineArticles,
    }),
  );
};

export const articlePreviewUpdateHandler = (stream, forcePush) => {
  const tempState = store.getState()?.temp;
  const article = JSON.parse(JSON.stringify(tempState?.article));
  if (stream?.updatedArticle) {
    const { uid, ...rest } = stream.updatedArticle;
    let isUpdateSuccess = false;
    if (article?.uid === uid) {
      store.dispatch(
        tempActions.controller({
          article: {
            ...article,
            ...rest,
          },
        }),
      );
      isUpdateSuccess = true;
    }
    if (!isUpdateSuccess && forcePush) {
      store.dispatch(
        tempActions.controller({
          article: stream.updatedArticle,
        }),
      );
    }
  } else if (article?.uid === stream?.uid) {
    if (stream?.privacy && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      store.dispatch(
        tempActions.controller({
          article: {
            ...article,
            privacy: stream.privacy,
          },
        }),
      );
    } else if (stream?.archived && store.getState()?.auth?.user?.uid === stream?.author?.uid) {
      store.dispatch(
        tempActions.controller({
          article: {
            ...article,
            isArchived: stream.archived,
          },
        }),
      );
    } else {
      store.dispatch(
        tempActions.controller({
          article: null,
        }),
      );
    }
  } else {
    return;
  }
};
