import React, { useRef } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { sectionHeaderStyle } from '../../constants/styles';
import youtubeLinks from '../../constants/placeholder/youtubeLinks';

const Resources = () => {
  const contentRef = useRef(null);

  const handleScroll = () => {
    try {
      const content = contentRef.current;
      if (content) {
        content.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/resource/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            Resources
          </Typography>
          <Typography maxWidth={650} color="grey.400" textAlign="center">
            ISBM offers video resources of various events and news under this section.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Box sx={{ margin: '5% auto' }} width="65vw" minWidth={320} ref={contentRef}>
        <Typography {...sectionHeaderStyle}>Video Gallery</Typography>
        <Stack direction="row" flexWrap="wrap" width="100%" justifyContent="center">
          {youtubeLinks.map((link, index) => (
            <Stack key={index} minWidth={300} width="32.33%" m=".5%">
              <div
                style={{
                  height: 0,
                  width: '100%',
                  position: 'relative',
                  backgroundColor: 'lime',
                  paddingBottom: '56.25%',
                }}>
                <iframe
                  src={link}
                  width="100%"
                  height="100%"
                  allowFullScreen
                  title="isbm-video-resource"
                  style={{ top: '0', left: '0', position: 'absolute', backgroundColor: 'black' }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Resources;
