import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowDownward } from '@mui/icons-material';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';

import career from '../../constants/placeholder/career';
import grants from '../../constants/placeholder/grants';
import { sectionHeaderStyle } from '../../constants/styles';
import { SIGNIN } from '../../constants/router-urls';

const ProfessionalCareer = () => {
  const contentRef = useRef(null);

  const handleScroll = () => {
    const content = contentRef.current;
    if (content) {
      content.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/career/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            Professional & Career Resources
          </Typography>
          <Typography maxWidth={650} color="grey.400" textAlign="center">
            ISBM provides opportunities for students to connect with mentors who can provide advice
            and help to prepare for their future careers. The ISBM will also create resources for
            the members to establish contact with worldwide employers. Our dashboard will feature
            updated job listings.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Container sx={{ mt: 10 }} maxWidth="lg" ref={contentRef}>
        <Typography {...sectionHeaderStyle}>Awards & Grants</Typography>
        <Stack
          mt={4}
          width="100%"
          direction="row"
          flexWrap="wrap"
          alignItems="stretch"
          justifyContent="center">
          {grants.map((grant, index) => (
            <Stack
              m={1}
              p={3}
              spacing={2}
              key={index}
              width="100%"
              to={SIGNIN}
              minWidth={300}
              maxWidth={450}
              minHeight={550}
              component={Link}
              borderRadius={2}
              bgcolor="#171820"
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: 'center', textDecoration: 'none' }}>
              <Box width={50} component="img" src="/assets/images/award.png" borderRadius={2} />
              <Typography variant="h4" fontWeight={700} color="#fff">
                {grant?.title}
              </Typography>
              <Typography color="#f7f7f7">
                {grant?.description ? `${grant?.description}` : ''}
              </Typography>
              <Typography color="#fff" variant="h4" fontWeight={800}>
                {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  grant?.amount,
                )}{' '}
                × {grant?.numberOfAward}
              </Typography>
              <Typography color="#f7f7f7" fontSize="0.9rem">
                {grant?.extra ? `+ ${grant?.extra}` : ''}
              </Typography>
              <Typography color="#f7f7f7" fontSize="1.2rem" fontWeight={500}>
                {grant?.frequency?.toUpperCase()}
              </Typography>
              <Typography color="#f7f7f7" fontSize="0.8rem">
                {grant.eligibility ? `* ${grant.eligibility}` : ''}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Container>

      <Container sx={{ my: 10 }} maxWidth="lg">
        <Typography {...sectionHeaderStyle}>Career Resources</Typography>
        <Stack direction="row" flexWrap="wrap" width="100%" mt={2}>
          {career.map((careerResource, index) => (
            <Stack
              m={1}
              px={2}
              py={5}
              flex={1}
              border={2}
              key={index}
              minWidth={300}
              bgcolor="#fff"
              maxHeight={236}
              borderRadius={2}
              overflow="hidden"
              position="relative"
              borderColor="grey.200"
              transition="all 0.3s ease"
              sx={{
                transition: 'all 0.2s ease',
                ':hover': {
                  maxHeight: 500,
                  transform: 'scale(1.04, 1.04)',
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                },
              }}>
              <Typography variant="h6" fontWeight={600} borderBottom="3px solid #128CCC">
                {careerResource.title}
              </Typography>
              <Typography mt={1}>{careerResource.description}</Typography>
              <Stack
                width="100%"
                height="100%"
                position="absolute"
                sx={{
                  backgroundImage:
                    'linear-gradient(180deg, transparent 10%, rgba(255,255,255,1) 100%)',
                  ':hover': {
                    backgroundImage: 'none',
                  },
                }}
              />
            </Stack>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default ProfessionalCareer;
