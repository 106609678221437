import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  shouldLoadMore: false,
  membershipType: null,
  publication: null,
  article: null,
};

const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    controller: (state, action) => {
      return { ...state, ...action.payload };
    },
    selectMembershiType: (state, action) => {
      return { ...state, membershipType: action.payload.membershipType };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const tempActions = tempSlice.actions;
export default tempSlice.reducer;
