import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { COMPLETE_PROFILE, SELECT_PLAN } from '../../constants/router-urls';

const UserFlagCheck = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const flags = user?.flags;

  if (!flags?.isProfileCreated) {
    return <Navigate to={COMPLETE_PROFILE} />;
  }

  if (
    user?.paymentStatus === 'UNPAID' ||
    user?.membershipStatus === 'INACTIVE' ||
    (user?.expiryDate || new Date()) <= new Date()
  ) {
    return <Navigate to={SELECT_PLAN} />;
  }

  return children;
};

export default UserFlagCheck;
