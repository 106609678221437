import React from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { brandColors } from '../constants/colors';
import PublicNavbar from '../components/global/PublicNavbar';
import PublicFooter from '../components/global/PublicFooter';

const PublicLayout = ({ seo }) => {
  return (
    <Box minHeight="100vh" bgcolor={brandColors.background}>
      <Helmet>
        <title>{seo?.title}</title>
        <meta name="description" content={seo?.description} />
      </Helmet>
      <PublicNavbar />
      <Outlet />
      <PublicFooter />
    </Box>
  );
};

export default PublicLayout;
