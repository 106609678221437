import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import HelperText from '../../../widgets/HelperText';
import { createProfileBasicInfoValidationSchema } from '../../../validations/profile';

const BasicInfo = ({ data, next, setData }) => {
  const [focus, setFocus] = useState('');

  const resolver = yupResolver(createProfileBasicInfoValidationSchema);
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    setData(data);
    next();
  };

  return (
    <Stack
      mx="auto"
      spacing={2}
      height="100%"
      sx={{ mt: 5 }}
      minHeight="60vh"
      component="form"
      alignItems="center"
      justifyContent="center"
      width={{ md: 650, xs: 310 }}
      onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2} width="100%">
        <FormControl sx={{ width: 120 }} error={errors?.salutation?.message}>
          <InputLabel size="small" error={errors?.salutation?.message}>
            Prefix
          </InputLabel>
          <Select
            size="small"
            label="Prefix"
            {...register('salutation')}
            onBlur={() => setFocus('')}
            defaultValue={data?.salutation}
            error={errors?.salutation?.message}
            onFocus={() => setFocus('salutation')}
            InputLabelProps={{ shrink: watchAllFields.salutation || focus === 'salutation' }}>
            <MenuItem value="Dr.">Dr.</MenuItem>
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
          </Select>
          <HelperText allowOverflow={!errors?.firstname?.message}>
            {errors?.salutation?.message}
          </HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.firstname?.message}>
          <TextField
            size="small"
            label="First Name"
            placeholder="John/Jane"
            {...register('firstname')}
            onBlur={() => setFocus('')}
            error={errors?.firstname?.message}
            onFocus={() => setFocus('firstname')}
            InputLabelProps={{ shrink: watchAllFields.firstname || focus === 'firstname' }}
          />
          <HelperText>{errors?.firstname?.message}</HelperText>
        </FormControl>
      </Stack>

      <FormControl fullWidth error={errors?.middlename?.message}>
        <TextField
          size="small"
          label="Middle Name"
          placeholder="Michael/Emily"
          {...register('middlename')}
          error={errors?.middlename?.message}
          onBlur={() => setFocus('')}
          onFocus={() => setFocus('middlename')}
          InputLabelProps={{ shrink: watchAllFields.middlename || focus === 'middlename' }}
        />
        <HelperText>{errors?.middlename?.message}</HelperText>
      </FormControl>

      <FormControl fullWidth error={errors?.lastname?.message}>
        <TextField
          size="small"
          label="Last Name"
          placeholder="Doe"
          {...register('lastname')}
          onBlur={() => setFocus('')}
          error={errors?.lastname?.message}
          onFocus={() => setFocus('lastname')}
          InputLabelProps={{ shrink: watchAllFields.lastname || focus === 'lastname' }}
        />
        <HelperText>{errors?.lastname?.message}</HelperText>
      </FormControl>

      {/* <FormControl fullWidth error={errors?.email?.message}>
        <TextField
          size="small"
          label="Email"
          {...register('email')}
          onBlur={() => setFocus('')}
          error={errors?.email?.message}
          placeholder="example@email.com"
          onFocus={() => setFocus('email')}
          InputLabelProps={{ shrink: watchAllFields.email || focus === 'email' }}
        />
        <HelperText>{errors?.email?.message}</HelperText>
      </FormControl> */}

      <FormControl fullWidth error={errors?.occupation?.message}>
        <InputLabel size="small" error={errors?.occupation?.message}>
          Occupation
        </InputLabel>
        <Select
          size="small"
          label="Occupation"
          {...register('occupation')}
          onBlur={() => setFocus('')}
          defaultValue={data?.occupation}
          error={errors?.occupation?.message}
          onFocus={() => setFocus('occupation')}
          InputLabelProps={{ shrink: watchAllFields.occupation || focus === 'occupation' }}>
          <MenuItem value="Student">Student</MenuItem>
          <MenuItem value="Employed">Employed</MenuItem>
          <MenuItem value="Retired">Retired</MenuItem>
        </Select>
        <HelperText>{errors?.occupation?.message}</HelperText>
      </FormControl>

      <Stack direction="row" spacing={2} width="100%">
        <FormControl fullWidth error={errors?.organization?.message}>
          <TextField
            size="small"
            placeholder="XYZ Institute"
            onBlur={() => setFocus('')}
            {...register('organization')}
            error={errors?.organization?.message}
            onFocus={() => setFocus('organization')}
            label={watchAllFields?.occupation === 'Student' ? 'Institution' : 'Organization'}
            InputLabelProps={{ shrink: watchAllFields.organization || focus === 'organization' }}
          />
          <HelperText allowOverflow={!errors?.designation?.message}>
            {errors?.organization?.message}
          </HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.designation?.message}>
          <TextField
            size="small"
            {...register('designation')}
            onBlur={() => setFocus('')}
            error={errors?.designation?.message}
            onFocus={() => setFocus('designation')}
            label={watchAllFields?.occupation === 'Student' ? 'Major' : 'Designation'}
            InputLabelProps={{ shrink: watchAllFields.designation || focus === 'designation' }}
            placeholder={
              watchAllFields?.occupation === 'Student' ? 'Microbiology' : 'Research Associate'
            }
          />
          <HelperText>{errors?.designation?.message}</HelperText>
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" width="100%">
        <Button type="submit" variant="contained">
          next
        </Button>
      </Stack>
    </Stack>
  );
};

export default BasicInfo;
