import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CancelTwoTone, DoneAllTwoTone } from '@mui/icons-material';
import {
  Box,
  Stack,
  colors,
  Button,
  Tooltip,
  Divider,
  Skeleton,
  IconButton,
  Typography,
} from '@mui/material';

import Avatar from '../../widgets/Avatar';
import dateTime from '../../utils/dateTime';
import { USER_CONNECTIONS, USER_PROFILE } from '../../constants/router-urls';
import { acceptConnectionRequest, rejectConnectionRequest } from '../../api/connection';
import word from '../../utils/word';

const PrivateRightFeed = () => {
  const [reRenderET, setReRenderET] = useState(false);
  const [isHandlingRequest, setIsHandlingRequest] = useState('');
  const [isRequestsLoading, setIsRequestsLoading] = useState(true);
  const [isConnectionsLoading, setIsConnectionsLoading] = useState(true);
  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    const interval = setInterval(() => {
      setReRenderET(reRenderET ? false : true);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!connection?.fetchConnectionRequests) {
      setIsRequestsLoading(false);
    }
    if (!connection?.fetchConnections) {
      setIsConnectionsLoading(false);
    }
  }, [connection]);

  const handleAccept = async (uid) => {
    setIsHandlingRequest(uid);
    await acceptConnectionRequest(uid);
    setIsHandlingRequest('');
  };

  const handleReject = async (uid) => {
    setIsHandlingRequest(uid);
    await rejectConnectionRequest(uid);
    setIsHandlingRequest('');
  };

  return (
    <Stack width="100%" spacing={2} p={2}>
      <Typography fontSize={14} fontWeight={700}>
        Connection Requests
      </Typography>
      <Stack spacing={1}>
        {isRequestsLoading ? (
          [...Array(3)].map((_, index) => (
            <Stack
              p={1}
              spacing={1}
              key={index}
              bgcolor="#fff"
              direction="row"
              borderRadius={1}
              alignItems="start"
              border="1px solid #e0e0e0">
              <Skeleton animation="wave" variant="rectangle" width={50} height={50} />
              <Stack>
                <Skeleton animation="wave" variant="text" width={190} height={14} />
                <Stack direction="row" justifyContent="end" spacing={0.5}>
                  <Box sx={{ flex: 1 }}>
                    <Skeleton animation="wave" variant="text" width={25} height={12} />
                  </Box>
                  <Skeleton animation="wave" variant="circular" width={32} height={32} />
                  <Skeleton animation="wave" variant="circular" width={32} height={32} />
                </Stack>
              </Stack>
            </Stack>
          ))
        ) : connection?.connectionRequests?.length > 0 ? (
          connection?.connectionRequests?.map(
            (item, index) =>
              index < 3 && (
                <Stack
                  p={1}
                  spacing={1}
                  key={item.id}
                  bgcolor="#fff"
                  direction="row"
                  borderRadius={1}
                  alignItems="start"
                  border="1px solid #e0e0e0">
                  <Box
                    sx={{ textDecoration: 'none' }}
                    to={`${USER_PROFILE}/${item?.requestedByUser?.username}`}
                    component={item?.requestedByUser?.flags?.isVisible ? Link : 'div'}>
                    <Avatar
                      src={item?.requestedByUser?.image}
                      label={word.displayInitials(
                        item?.requestedByUser?.firstname,
                        item?.requestedByUser?.lastname,
                      )}
                    />
                  </Box>
                  <Stack>
                    <Box
                      sx={{ textDecoration: 'none' }}
                      to={`${USER_PROFILE}/${item?.requestedByUser?.username}`}
                      component={item?.requestedByUser?.flags?.isVisible ? Link : 'div'}>
                      <Typography
                        sx={{
                          width: 190,
                          fontSize: 14,
                          lineHeight: 1,
                          fontWeight: 700,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          color: colors.grey[800],
                          textOverflow: 'ellipsis',
                        }}>
                        {word.displayName(
                          item?.requestedByUser?.firstname,
                          item?.requestedByUser?.lastname,
                        )}
                      </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="end" spacing={0.5}>
                      <Typography
                        flex={1}
                        fontSize={12}
                        color={reRenderET ? colors.grey[600] : colors.grey[600]}>
                        {dateTime.elapsedTime(item?.createdAt, {
                          short: true,
                        })}
                      </Typography>
                      {isHandlingRequest === item?.requestedByUser?.uid ? (
                        <Typography pt={2} fontSize={12} color={colors.grey[600]}>
                          ...processing
                        </Typography>
                      ) : (
                        <>
                          <Tooltip title="Reject">
                            <IconButton
                              color="error"
                              onClick={() => handleReject(item?.requestedByUser?.uid)}>
                              <CancelTwoTone sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Accept">
                            <IconButton
                              color="success"
                              onClick={() => handleAccept(item?.requestedByUser?.uid)}>
                              <DoneAllTwoTone sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              ),
          )
        ) : (
          <Typography fontSize={14} fontWeight={600} color={colors.grey[600]}>
            No new requests
          </Typography>
        )}
        {isRequestsLoading ? (
          <Skeleton
            width={50}
            height={20}
            variant="text"
            animation="wave"
            sx={{ alignSelf: 'end' }}
          />
        ) : connection?.totalRequests > 3 ? (
          <Button
            component={Link}
            to={`${USER_CONNECTIONS}/requests`}
            sx={{ width: 'max-content', color: colors.grey[900], alignSelf: 'end' }}>
            See All
          </Button>
        ) : null}
      </Stack>
      <Divider />
      <Typography fontSize={14} fontWeight={700}>
        Connections
      </Typography>
      <Stack spacing={1}>
        {isConnectionsLoading ? (
          [...Array(5)].map((_, index) => (
            <Stack key={index} direction="row" alignItems="center" spacing={1}>
              <Skeleton animation="wave" variant="circular" width={35} height={35} />
              <Skeleton animation="wave" variant="text" width={140} height={14} />
            </Stack>
          ))
        ) : connection?.connections?.length > 0 ? (
          connection?.connections?.map(
            (item, index) =>
              index < 30 && (
                <Stack key={item?.id} direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    size={35}
                    shape="circle"
                    fontSize={14}
                    src={item?.image}
                    label={word.displayInitials(item?.firstname, item?.lastname)}
                  />
                  <Typography
                    sx={{
                      width: 200,
                      fontSize: 14,
                      fontWeight: 600,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      color: colors.grey[800],
                      textOverflow: 'ellipsis',
                    }}>
                    {word.displayName(item?.firstname, item?.lastname)}
                  </Typography>
                </Stack>
              ),
          )
        ) : (
          <Typography fontSize={14} fontWeight={600} color={colors.grey[600]}>
            No connections available
          </Typography>
        )}
        {isConnectionsLoading ? (
          <Skeleton
            width={50}
            height={20}
            variant="text"
            animation="wave"
            sx={{ alignSelf: 'end' }}
          />
        ) : connection?.totalConnections > 30 ? (
          <Button
            component={Link}
            to={`${USER_CONNECTIONS}/my-network`}
            sx={{ width: 'max-content', color: colors.grey[900], alignSelf: 'end' }}>
            See All
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default PrivateRightFeed;
