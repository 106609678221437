export default {
  capitalize: (string) => {
    try {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } catch (e) {
      return '';
    }
  },

  displayName: (firstname, lastname, prefix = '', suffix = '') => {
    let name = '';
    if (firstname) {
      name += firstname;
      if (lastname) {
        name += ' ' + lastname;
      }
    } else {
      if (lastname) {
        name += lastname;
      } else {
        name = prefix + 'ISBM Member' + suffix;
      }
    }
    return name;
  },

  displayInitials: (firstname, lastname) => {
    let initials = '';
    if (firstname) {
      initials += firstname.charAt(0);
      if (lastname) {
        initials += lastname.charAt(0);
      }
    } else {
      if (lastname) {
        initials += lastname.charAt(0);
      } else {
        initials = 'IM';
      }
    }
    return initials;
  },
};
