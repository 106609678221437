import React, { useEffect, useState } from 'react';
import { Box, Stack, Container, useMediaQuery } from '@mui/material';

import Banner from './landing-section/Banner';
import Featured from './landing-section/Featured';

const LandingSection = () => {
  const [boxOffset, setBoxOffset] = useState(0);
  const windowLessThan600 = useMediaQuery('(max-width:600px)');
  const windowLessThan1200 = useMediaQuery('(max-width:1200px)');

  useEffect(() => {
    setBoxOffset('211px');
    if (windowLessThan1200) {
      setBoxOffset('140px');
    }
    if (windowLessThan600) {
      setBoxOffset('120px');
    }
  }, [windowLessThan600, windowLessThan1200]);

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <Stack width="100%" direction={{ xl: 'row', xs: 'column' }}>
        <Box
          minHeight="500px"
          px={{ xl: '5px', xs: '0px' }}
          py={{ xl: '0px', xs: '5px' }}
          width={{ xl: '70%', xs: '100%' }}
          sx={{
            height: `calc(100vh - ${boxOffset})`,
          }}>
          <Banner />
        </Box>
        <Box
          px={{ xl: '5px', xs: '0px' }}
          py={{ xl: '0px', xs: '5px' }}
          width={{ xl: '30%', xs: '100%' }}
          minHeight={{ xl: '500px', xs: 'unset' }}
          sx={{
            height: windowLessThan1200 ? 'auto' : { xl: `calc(100vh - ${boxOffset})`, xs: 'auto' },
          }}>
          <Featured />
        </Box>
      </Stack>
    </Container>
  );
};

export default LandingSection;
