import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { featured } from '../../../constants/placeholder/landing';

const Featured = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      spacing="10px"
      direction={{ xl: 'column', md: 'row', xs: 'column' }}>
      {featured.map((item) => (
        <Stack
          to={item.link}
          key={item.title}
          borderRadius={2}
          component={Link}
          overflow="hidden"
          position="relative"
          alignItems="center"
          justifyContent="center"
          height={{ xl: '33.33%', xs: '200px' }}
          width={{ xl: '100%', md: '33%', xs: '100%' }}
          sx={{
            transition: 'all 0.2s ease',
            ':hover': {
              boxShadow: '2px 2px 5px rgba(0,0,0,0.6)',
            },
          }}>
          <Box
            top={0}
            left={0}
            width="100%"
            height="100%"
            component="img"
            minWidth={460}
            minHeight={320}
            src={item.image}
            borderRadius={2}
            position="absolute"
          />
          <Stack
            px={2}
            py={1}
            top={0}
            left={0}
            spacing={2}
            width="100%"
            height="100%"
            borderRadius={2}
            position="absolute"
            alignItems="center"
            bgcolor="transparent"
            justifyContent="center"
            sx={{
              transition: 'all 0.2s ease',
              ':hover': {
                bgcolor: 'rgba(0,0,0,0.6)',
              },
            }}>
            <Stack
              flexGrow={1}
              width="100%"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-around">
              <Stack spacing={1.2} alignSelf="flex-start">
                <Typography
                  variant="h5"
                  color="#fff"
                  fontWeight={800}
                  sx={{ textShadow: '2px 2px 3px rgba(0,0,0,0.3)' }}>
                  {item.title}
                </Typography>
                <Typography
                  color="#fff"
                  fontWeight={500}
                  fontSize="0.75rem"
                  sx={{ textShadow: '2px 2px 2px rgba(0,0,0,0.3)' }}>
                  {item.subtitle}
                </Typography>
              </Stack>
              <Button
                sx={{
                  color: '#fff',
                  fontWeight: 700,
                  bgcolor: '#212121',
                  fontSize: '0.75rem',
                  alignSelf: 'flex-end',
                  textTransform: 'capitalize',
                  ':hover': { bgcolor: '#000' },
                }}
                endIcon={<ArrowForward sx={{ fill: '#fff' }} />}>
                Learn More
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default Featured;
