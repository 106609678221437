import React from 'react';

import PageWrapper from '../../components/global/PageWrapper';
import ConnectionTabs from '../../components/connections/ConnectionTabs';

const Connections = () => {
  return (
    <PageWrapper isAuth>
      <ConnectionTabs />
    </PageWrapper>
  );
};

export default Connections;
