import React, { useState } from 'react';
import {
  Card,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { brandColors } from '../../constants/colors';
import { authFormTitleStyle } from '../../constants/styles';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { SIGNIN, USER_HOME } from '../../constants/router-urls';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordValidationSchema } from '../../validations/auth';
import { resetPassword } from '../../api/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import notify from '../../utils/notify';
import { useSelector } from 'react-redux';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const resolver = yupResolver(resetPasswordValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const onSubmit = async (data) => {
    const isSuccessful = await resetPassword(token, { password: data.password });
    if (isSuccessful) {
      notify.success('Password reset successful! Please sign in with your new password.');
      navigate(SIGNIN);
    }
  };

  if (isAuth) {
    return <Navigate to={USER_HOME} replace={true} />;
  }

  return (
    <Stack
      p={2}
      width="100%"
      height="auto"
      minHeight="75vh"
      alignItems="center"
      justifyContent="center">
      <Stack maxWidth={600} width="100%">
        <Card sx={{ borderRadius: 4 }}>
          <Stack
            p={3}
            spacing={1}
            bgcolor="#fff"
            component="form"
            onSubmit={handleSubmit(onSubmit)}>
            <Typography {...authFormTitleStyle}>Reset Password</Typography>

            <Stack>
              <TextField
                size="small"
                name="New password"
                label="New Password *"
                type={showPassword ? 'text' : 'password'}
                error={errors?.password}
                {...register('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: brandColors.error, maxWidth: 400 }}>
                {errors?.password?.message}
              </FormHelperText>
            </Stack>
            <Stack>
              <TextField
                size="small"
                name="rePassword"
                label="Confirm Password *"
                autoComplete="confirm-password"
                type={showRePassword ? 'text' : 'password'}
                error={errors?.rePassword}
                {...register('rePassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowRePassword(!showRePassword)}>
                        {showRePassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.rePassword?.message}
              </FormHelperText>
            </Stack>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Reset Password
            </LoadingButton>

            <Typography textAlign="center" fontSize="0.8rem" sx={{ pt: 3 }}>
              <span>
                <Link
                  to={SIGNIN}
                  style={{
                    margin: '10px 0px',
                    textDecoration: 'none',
                    color: brandColors.primary,
                  }}>
                  Back to Sign In
                </Link>
              </span>
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
