import request from './request';
import { store } from '../store';
import notify from '../utils/notify';
import { authActions } from '../store/reducers/authReducer';
import { timelineActions } from '../store/reducers/timelineReducer';

export const createProfile = async (data, updateFlow) => {
  const response = await request.patch('/profile/update', data);
  if (response?.status === 200) {
    store.dispatch(authActions.updateUser({ user: response?.updatedUser }));
    notify.success(response?.msg || 'Profile update successful');
    return updateFlow ? true : response.paymentSession;
  }
  return null;
};

export const updateAccountInformation = async (data) => {
  const response = await request.patch('/profile/update-account-information', data);
  if (response?.status === 200) {
    store.dispatch(authActions.updateUser({ user: response?.updatedUser }));
    notify.success(response?.msg || 'Account information updated successfully!');
  }
};

export const fetchProfile = async (username, tab, query, reset) => {
  const response = await request.get(`/profile/${username}/${tab}`, query);
  if (response?.status === 200) {
    let currentPosts;
    let currentArticles;
    let currentPublications;
    switch (tab) {
      case 'timeline':
        switch (query?.filter) {
          case 'POSTS':
            currentPosts = store.getState()?.timeline?.timelinePosts;
            store.dispatch(
              timelineActions.setTimelinePosts({
                data: reset ? response.data?.results : [...currentPosts, ...response.data.results],
                offset: response?.data?.offset || 0,
                totalEntries: response?.data?.totalEntries || 0,
              }),
            );
            break;
          case 'ARTICLES':
            currentArticles = store.getState()?.timeline?.timelineArticles;
            store.dispatch(
              timelineActions.setTimelineArticles({
                data: reset
                  ? response.data?.results
                  : [...currentArticles, ...response.data.results],
                offset: response?.data?.offset || 0,
                totalEntries: response?.data?.totalEntries || 0,
              }),
            );
            break;
          case 'PUBLICATIONS':
            currentPublications = store.getState()?.timeline?.timelinePublications;
            store.dispatch(
              timelineActions.setTimelinePublications({
                data: reset
                  ? response.data?.results
                  : [...currentPublications, ...response.data.results],
                offset: response?.data?.offset || 0,
                totalEntries: response?.data?.totalEntries || 0,
              }),
            );
            break;
        }
        break;
      case 'details':
        break;
      default:
        break;
    }
  }
  return response;
};

export const addNewExperience = async (data) => {
  const response = await request.post('/profile/add-experience', data);
  return response;
};

export const editExperience = async (data, action) => {
  const response = await request.patch(`/profile/update-experience/${action}`, data);
  return response;
};

export const deleteExperience = async (id) => {
  const response = await request.delete(`/profile/delete-experience/${id}`);
  return response;
};

export const addNewEducation = async (data) => {
  const response = await request.post('/profile/add-education', data);
  return response;
};

export const editEducation = async (data, action) => {
  const response = await request.patch(`/profile/update-education/${action}`, data);
  return response;
};

export const deleteEducation = async (id) => {
  const response = await request.delete(`/profile/delete-education/${id}`);
  return response;
};

export const addNewCertificate = async (data) => {
  const response = await request.post('/profile/add-certificate', data);
  return response;
};

export const editCertificate = async (data, action) => {
  const response = await request.patch(`/profile/update-certificate/${action}`, data);
  return response;
};

export const deleteCertificate = async (id) => {
  const response = await request.delete(`/profile/delete-certificate/${id}`);
  return response;
};

export const proceedToPayment = async (data) => {
  const response = await request.post('/profile/initiate-payment', data);
  if (response?.status === 200) {
    return response.paymentSession;
  }
  return null;
};

export const updatePaymentStatus = async (uid) => {
  const response = await request.patch(`/profile/payment/${uid}`);
  return response;
};

export const updateStudentMembershipStatus = async () => {
  const response = await request.patch('/profile/membership/student');
  return response;
};
