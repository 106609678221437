import * as yup from 'yup';
import { PASSWORD_REGEX, USERNAME_REGEX } from '../constants/regex';

const IMAGE_SIZE_LIMIT_IN_BYTES = 5 * 1024 * 1024; // 5 mb in bytes
const SUPPORTED_FILE_FORMATS = ['image/svg', 'image/jpg', 'image/jpeg', 'image/png'];

export const usernameValidation = yup
  .string()
  .matches(
    USERNAME_REGEX,
    'username must be 8 to 20 characters long, should not contain _ or . at the begining or at the end',
  )
  .transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  });

export const passwordValidation = yup
  .string()
  .matches(
    PASSWORD_REGEX,
    'password must be at least 8 characters long, must contain one lowercase letter, one uppercase letter, one number and a special character',
  )
  .transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  });

export const imageValidation = {
  optional: yup
    .mixed()
    .notRequired()
    .default(null)
    .test('fileSize', 'the file is too large, keep it within 5MB', (file) =>
      file?.length ? file?.[0]?.size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'this file type is not allowed, use a jpg, jpeg, png or svg file instead',
      (file) => (file?.length ? SUPPORTED_FILE_FORMATS.includes(file?.[0]?.type) : true),
    ),
  required: yup
    .mixed()
    .test('file', 'this file is required', (file) => (file.length ? file.length > 0 : false))
    .test('fileSize', 'the file is too large, keep it within 5MB', (file) =>
      file.length ? file[0].size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'this file type is not allowed, use a jpg, jpeg, png or svg file instead',
      (file) => (file.length ? SUPPORTED_FILE_FORMATS.includes(file[0].type) : true),
    ),
};
