import React, { useState } from 'react';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import {
  ArchiveTwoTone,
  AttachmentTwoTone,
  BookmarkTwoTone,
  CancelPresentationTwoTone,
  Circle,
  DeleteForeverTwoTone,
  DriveFileRenameOutlineTwoTone,
  ErrorTwoTone,
  GroupTwoTone,
  LockResetTwoTone,
  LockTwoTone,
  MoreHoriz,
  PublicTwoTone,
  PublishTwoTone,
  ReportTwoTone,
  UnarchiveTwoTone,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  colors,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { EDIT_PUBLICATION } from '../../constants/router-urls';
import Button from '../../widgets/Button';
import AlertDialog from './AlertDialog';
import { sectionHeaderStyle } from '../../constants/styles';
import dateTime from '../../utils/dateTime';

const EventRenderer = ({ isAuth, event, loading }) => {
  const isAuthor = false;
  const navigate = useNavigate();

  const [isPublishing, setIsPublishing] = useState(false);
  const [privacy, setPrivacy] = useState(event?.privacy);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [isPrivacyUpdating, setIsPrivacyUpdating] = useState(false);
  const [isArchiveStatusChanging, setIsArchiveStatusChanging] = useState(false);
  const [showPrivacyEditingModal, setShowPrivacyEditingModal] = useState(false);
  const [showArchiveStatusChangeAlert, setShowArchiveStatusChangeAlert] = useState(false);

  const [publicationOptionsAnchorEl, setPublicationOptionsAnchorEl] = useState(null);
  const publicationOptionsOpen = Boolean(publicationOptionsAnchorEl);
  const handlePublicationOptions = (event) => setPublicationOptionsAnchorEl(event.currentTarget);
  const handlePublicationOptionsClose = () => setPublicationOptionsAnchorEl(null);

  const privacyOptions = [
    {
      label: 'Public',
      value: 'PUBLIC',
      icon: { base: <PublicTwoTone />, small: <PublicTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: { base: <LockTwoTone />, small: <LockTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: { base: <GroupTwoTone />, small: <GroupTwoTone sx={{ height: 14, width: 14 }} /> },
    },
  ];

  const onPublish = async () => {
    setIsPublishing(true);
    // await publishPublication(event?.uid);
    setIsPublishing(false);
  };

  const onPrivacyUpdate = async () => {
    setIsPrivacyUpdating(true);
    // const { title, description, abstract, url, authors, isPublished } = event;
    // const formData = {
    //   privacy,
    //   title,
    //   description,
    //   abstract,
    //   url,
    //   authors,
    //   isPublished,
    // };
    // const result = await updatePublication(event?.uid, formData);
    // if (result?.status === 200) {
    //   setShowPrivacyEditingModal(false);
    // }
    setIsPrivacyUpdating(false);
  };

  const handleArchiveStatusChange = async () => {
    setIsArchiveStatusChanging(true);
    // await updatePublicationArchiveStatus(event?.uid, {
    //   isArchived: !event?.isArchived,
    // });
    setIsArchiveStatusChanging(false);
  };

  const onDelete = async () => {
    setIsDeleteLoading(true);
    // const res = await deletePublication(event?.uid);
    // if (res?.status === 200) {
    //   switch (from) {
    //     case 'timeline':
    //       navigate(`${USER_PROFILE}/${user?.username}/timeline`, { replace: true });
    //       break;
    //     default:
    //       navigate(`${USER_HOME}/publications`, { replace: true });
    //       break;
    //   }
    // }
    setIsDeleteLoading(false);
  };

  const headingLevels = {
    1: { fontSize: 32, fontWeight: 700 },
    2: { fontSize: 28, fontWeight: 700 },
    3: { fontSize: 24, fontWeight: 700 },
    4: { fontSize: 20, fontWeight: 700 },
    5: { fontSize: 16, fontWeight: 700 },
    6: { fontSize: 14, fontWeight: 700 },
  };

  const renderDate = (startDate, endDate) => {
    const start = dateTime.render(startDate, 'MMM,DD,YYYY');
    const end = dateTime.render(endDate, 'MMM,DD,YYYY');
    const startYear = start.split(',')[2];
    const endYear = end.split(',')[2];
    const startMonth = start.split(',')[0];
    const endMonth = end.split(',')[0];
    const startDay = start.split(',')[1];
    const endDay = end.split(',')[1];
    if (start === end) {
      return `${startMonth} ${startDay}, ${startYear}`;
    }

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        return `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
      }
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
    }

    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  };

  const renderTime = (startTime, endTime) => {
    const start = dateTime.render(startTime, 'hh:mm A');
    const end = dateTime.render(endTime, 'hh:mm A');
    if (start === end) {
      return start;
    }
    return `${start} - ${end}`;
  };

  const RenderBodyItem = ({ block, index }) => {
    const blocks = event?.body?.blocks;

    switch (block.type) {
      case 'paragraph':
        return (
          <Box variant="body1" my={0.75}>
            {parse(block.data.text)}
          </Box>
        );
      case 'header':
        return (
          <Typography {...headingLevels[block.data.level]} my={0.75}>
            {block.data.text}
          </Typography>
        );
      case 'image':
        return (
          <Stack width="100%" alignItems="center">
            <Stack
              width="100%"
              p={block.data.withBackground ? 2 : 0}
              mt={blocks[index - 1]?.type === 'image' ? 0 : 4}
              mb={block.data.caption.replace('<br>', '') ? 0 : 4}
              alignItems={block.data.withBackground ? 'center' : 'start'}
              border={block.data.withBorder ? '1px solid #E3E3E3' : 'none'}
              bgcolor={block.data.withBackground ? '#EDF3F6' : 'transparent'}>
              <Box
                component="img"
                maxWidth="100%"
                id={block.data.file.uid}
                src={block.data.file.url}
                width={
                  block.data.stretched
                    ? '100%'
                    : document.getElementById(block.data.file.uid)?.naturalWidth
                }
              />
            </Stack>
            {block.data.caption.replace('<br>', '') && (
              <Typography variant="caption" color="textSecondary" mb={4}>
                {block.data.caption.replace('<br>', '')}
              </Typography>
            )}
          </Stack>
        );
      case 'list':
        return (
          <Stack spacing={1} my={0.75} width="100%">
            {block?.data?.items?.map((item, index) => (
              <Stack key={item} direction="row" alignItems="center" spacing={1} pl={1}>
                {block?.data?.style === 'unordered' ? (
                  <Circle sx={{ fontSize: 8 }} />
                ) : (
                  <Typography fontSize={16} fontWeight={700}>
                    {index + 1}.
                  </Typography>
                )}
                <Typography fontSize={16} fontWeight={600}>
                  {item.replace('<br>', '')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        );
      case 'warning':
        return (
          <Alert severity="warning" sx={{ width: '100%' }}>
            <b>{block.data.title}</b>
            <br />
            {block.data.message}
          </Alert>
        );
      default:
        return (
          <Typography variant="body1" my={0.75}>
            {block.data.text}
          </Typography>
        );
    }
  };

  const RenderBody = () => {
    const body = event?.body;

    return loading ? (
      <Stack width="100%">
        <Stack
          width="50%"
          minWidth={240}
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, md: 1 }}>
          <Skeleton animation="wave" variant="circular" width={45} height={45} />
          <Stack>
            <Skeleton animation="wave" variant="text" width={120} height={18} />
            <Skeleton animation="wave" variant="text" width={180} height={16} />
          </Stack>
        </Stack>
        <Skeleton animation="wave" variant="text" width="80%" height={32} sx={{ mt: 2 }} />
        <Stack direction="row" flexWrap="wrap" alignItems="center">
          {Array(3)
            .fill()
            .map((_, index) => (
              <Skeleton
                width="20%"
                height={26}
                key={index}
                variant="text"
                animation="wave"
                sx={{ mr: 0.5, mt: 0.5 }}
              />
            ))}
        </Stack>
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 1 }} />
        <Skeleton animation="wave" variant="text" width="70%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="20%" height={26} sx={{ mt: 4 }} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} sx={{ mt: 1 }} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="100%" height={20} />
        <Skeleton animation="wave" variant="text" width="80%" height={20} sx={{ mb: 1 }} />
        <Divider />
        <Skeleton animation="wave" variant="text" width="18%" height={26} sx={{ mt: 4 }} />
        <Stack spacing={2} sx={{ my: 1 }} width="100%">
          {Array(3)
            .fill()
            .map((_, index) => (
              <Stack key={index} direction="row" alignItems="start" width="100%">
                <Skeleton
                  width={16}
                  height={16}
                  animation="wave"
                  variant="circular"
                  sx={{ mr: 1, pt: 1 }}
                />
                <Stack width="100%">
                  <Skeleton animation="wave" variant="text" width="28%" height={20} />
                  <Skeleton animation="wave" variant="text" width="35%" height={20} />
                  <Skeleton animation="wave" variant="text" width="45%" height={20} />
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Stack>
    ) : event ? (
      <Stack width="100%">
        {/* {event?.isArchived ? (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <ArchiveTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              Archived
            </Typography>
          </Stack>
        ) : event?.isPublished ? (
          <Stack width="80%" direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
            <Stack
              width={{ xs: 40, md: 50 }}
              sx={{ textDecoration: 'none' }}
              to={`${USER_PROFILE}/${event?.author?.username}`}
              component={article?.author?.flags?.isVisible ? Link : 'div'}>
              <Avatar
                shape="circle"
                size={{ xs: 40, md: 50 }}
                src={event?.author?.image}
                label={word.displayInitials(event?.author?.firstname, event?.author?.lastname)}
              />
            </Stack>
            <Stack width="80%">
              <Typography
                maxWidth="100%"
                fontWeight={600}
                overflow="hidden"
                width="fit-content"
                textOverflow="ellipsis"
                color={colors.grey[800]}
                fontSize={{ xs: 14, md: 16 }}
                sx={{ textDecoration: 'none' }}
                to={`${USER_PROFILE}/${event?.author?.username}`}
                component={event?.author?.flags?.isVisible ? Link : 'div'}>
                {word.displayName(event?.author?.firstname, event?.author?.lastname)}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0.5} color={colors.grey[600]}>
                <Typography fontSize={{ xs: 12, md: 14 }}>
                  {dateTime.render(event?.publishedAt)}
                </Typography>
                {privacyOptions.find((option) => option.value === event?.privacy)?.icon?.small}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <BookmarkTwoTone />
            <Typography fontSize={15} fontWeight={600}>
              In Drafts
            </Typography>
          </Stack>
        )} */}
        <Stack alignItems="center">
          <Typography {...sectionHeaderStyle} sx={{ color: '#128CCD' }}>
            {event?.title}
          </Typography>
          {event?.image && (
            <Box
              width="100%"
              component="img"
              borderRadius={1}
              src={event?.image}
              border="1px solid rgba(0,0,0,0.2)"
            />
          )}
          {event?.video && (
            <Stack width="60%" minWidth={300} mt={5}>
              <div
                style={{
                  height: 0,
                  width: '100%',
                  position: 'relative',
                  backgroundColor: 'lime',
                  paddingBottom: '56.25%',
                }}>
                <iframe
                  width="100%"
                  height="100%"
                  allowFullScreen
                  src={event?.video}
                  title="event-trailer"
                  style={{ top: '0', left: '0', position: 'absolute', backgroundColor: 'black' }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            </Stack>
          )}
          <Stack py={5} spacing={1} justifyContent="end">
            <Typography color={colors.grey[900]} fontSize={24} fontWeight={600} lineHeight={1.1}>
              {event?.title}
            </Typography>
            <Typography>{event?.description}</Typography>
            <Stack spacing={0.5}>
              {event?.host && (
                <Typography>
                  <b>Host:</b> {event?.host}
                </Typography>
              )}
              {event.type && (
                <Typography>
                  <b>Event Type:</b> {event.type}
                </Typography>
              )}
              {event?.startDate && (
                <Typography>
                  <b>Date:</b> {renderDate(event?.startDate, event?.endDate)}
                </Typography>
              )}
              {event?.startTime && (
                <Typography>
                  <b>Time:</b> {renderTime(event?.startTime, event?.endTime)}
                </Typography>
              )}
              {event?.attachment && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={event?.attachment?.url}
                  style={{
                    color: '#128CCD',
                    textDecoration: 'none',
                  }}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <AttachmentTwoTone />
                    <Typography>
                      <b>{event?.attachment?.name}</b>
                    </Typography>
                  </Stack>
                </a>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack mt={4} alignItems="start">
          {body?.blocks?.map((block, index) => (
            <RenderBodyItem key={block?.id} block={block} index={index} />
          ))}
        </Stack>
      </Stack>
    ) : (
      <Stack p={2} spacing={1} alignItems="center">
        <ErrorTwoTone sx={{ fontSize: 60, color: '#D32F2F' }} />
        <Typography fontSize={15} fontWeight={600} color="error" textAlign="center">
          Sorry, could not find the event you are looking for!
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      width="100%"
      margin="auto"
      minWidth={300}
      position="relative"
      pb={isAuth ? 0 : 8}
      maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 800 }}>
      {/* publish alert */}
      <AlertDialog
        title="Publish❓"
        onResolved={onPublish}
        isOpen={showPublishAlert}
        resolveLoading={isPublishing}
        onClose={() => setShowPublishAlert(false)}
        message={`Are you sure you want to publish this? It will published with current timestamp and privacy settings (${
          privacyOptions.find((option) => option.value === event?.privacy)?.label
        }). Once published, you can't undo this action. You may archive this in future.`}
      />

      {/* archive alert */}
      <AlertDialog
        title={event?.isArchived ? 'Unarchive❓' : 'Archive❓'}
        onResolved={handleArchiveStatusChange}
        isOpen={showArchiveStatusChangeAlert}
        resolveLoading={isArchiveStatusChanging}
        onClose={() => setShowArchiveStatusChangeAlert(false)}
        message={`Are you sure you want to ${
          event?.isArchived
            ? 'unarchive this? It will be visible again to selected audience'
            : 'archive this? Only you will be able to see archived publications.'
        }`}
      />

      {/* event delete alert */}
      <AlertDialog
        title="Delete❓"
        onResolved={onDelete}
        isOpen={showDeleteAlert}
        resolveLoading={isDeleteLoading}
        onClose={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this event?"
      />

      {/* privacy edit modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPrivacyEditingModal}
        onClose={isPrivacyUpdating ? null : () => setShowPrivacyEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack px={1} spacing={1} alignItems="start">
            {privacyOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                key={option.value}
                startIcon={option.icon.base}
                color={option.value === privacy ? 'primary' : 'secondary'}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  setPrivacy(option.value);
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="error"
              disabled={isPrivacyUpdating}
              onClick={() => setShowPrivacyEditingModal(false)}>
              Cancel
            </Button>
            <Button
              onClick={onPrivacyUpdate}
              loading={isPrivacyUpdating}
              disabled={privacy === event?.privacy}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* event options button */}
      {isAuthor && (
        <IconButton
          size="small"
          sx={{
            top: 0,
            right: 0,
            zIndex: 3,
            position: 'absolute',
          }}
          onClick={handlePublicationOptions}>
          <MoreHoriz sx={{ color: colors.grey[700] }} />
        </IconButton>
      )}

      {/* event options menu */}
      <Menu
        open={publicationOptionsOpen}
        anchorEl={publicationOptionsAnchorEl}
        onClose={handlePublicationOptionsClose}>
        <Stack px={1} spacing={1} alignItems="start">
          {isAuthor && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                event?.isPublished
                  ? event?.isArchived
                    ? () => {
                        // unarchive
                        handlePublicationOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                    : () => {
                        // archive
                        handlePublicationOptionsClose();
                        setShowArchiveStatusChangeAlert(true);
                      }
                  : () => {
                      // publish
                      handlePublicationOptionsClose();
                      setShowPublishAlert(true);
                    }
              }
              startIcon={
                event?.isPublished ? (
                  event?.isArchived ? (
                    <UnarchiveTwoTone />
                  ) : (
                    <ArchiveTwoTone />
                  )
                ) : (
                  <PublishTwoTone />
                )
              }
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {event?.isPublished ? (event?.isArchived ? 'Unarchive' : 'Archive') : 'Publish'}
            </Button>
          )}

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    navigate(`${EDIT_PUBLICATION}/${event?.slug}`, {
                      state: { data: event, from: 'preview' },
                    });
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <DriveFileRenameOutlineTwoTone /> : <CancelPresentationTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Publication' : 'Hide Publication'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    setPrivacy(event?.privacy);
                    setShowPrivacyEditingModal(true);
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <LockResetTwoTone /> : <BookmarkTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Edit Privacy' : 'Save Publication'}
          </Button>

          <Button
            fullWidth
            size="small"
            color="error"
            onClick={
              isAuthor
                ? () => {
                    handlePublicationOptionsClose();
                    setShowDeleteAlert(true);
                  }
                : handlePublicationOptionsClose
            }
            startIcon={isAuthor ? <DeleteForeverTwoTone /> : <ReportTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            {isAuthor ? 'Delete Publication' : 'Report Publication'}
          </Button>
        </Stack>
      </Menu>

      {/* Content Body */}
      <RenderBody />
    </Box>
  );
};

export default EventRenderer;
