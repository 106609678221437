import { MoreHoriz } from '@mui/icons-material';
import { Divider, IconButton, Skeleton, Stack } from '@mui/material';

const PostSkeleton = () => {
  return (
    <Stack
      p={2}
      spacing={2}
      width="100%"
      bgcolor="#fff"
      borderRadius={1}
      border="1px solid rgba(0,0,0,0.1)">
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Stack direction="row" spacing={1}>
          <Skeleton
            width={50}
            height={50}
            animation="wave"
            variant="rectangular"
            sx={{ borderRadius: 1 }}
          />
          <Stack>
            <Skeleton animation="wave" variant="text" width={140} height={14} />
            <Skeleton animation="wave" variant="text" width={100} height={12} />
          </Stack>
        </Stack>
        <IconButton size="small">
          <MoreHoriz />
        </IconButton>
      </Stack>
      <Stack width="100%">
        <Skeleton animation="wave" variant="text" width="100%" height={14} />
        <Skeleton animation="wave" variant="text" width="100%" height={14} />
        <Skeleton animation="wave" variant="text" width="80%" height={14} />
      </Stack>
      <Divider />
      <Skeleton
        width={100}
        height={24}
        animation="wave"
        variant="rectangular"
        sx={{ borderRadius: 1 }}
      />
    </Stack>
  );
};

export default PostSkeleton;
