import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 10,
  fetchPublicArticles: false,
  fetchPublicPublications: false,
  publicArticles: [],
  publicPublications: [],
  currentArticlesOffset: 0,
  currentPublicationsOffset: 0,
  totalPublicArticles: 0,
  totalPublicPublications: 0,
};

const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    controller: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPublicArticles: (state, action) => {
      return {
        ...state,
        fetchPublicArticles: false,
        publicArticles: action.payload.data,
        totalPublicArticles: action.payload.totalEntries,
        currentArticlesOffset: action.payload.offset || state.currentArticlesOffset,
      };
    },
    setPublicPublications: (state, action) => {
      return {
        ...state,
        fetchPublicPublications: false,
        publicPublications: action.payload.data,
        totalPublicPublications: action.payload.totalEntries,
        currentPublicationsOffset: action.payload.offset || state.currentPublicationsOffset,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const publicActions = publicSlice.actions;
export default publicSlice.reducer;
