import { FormControl, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import HelperText from '../../../widgets/HelperText';
import Button from '../../../widgets/Button';
import { useForm } from 'react-hook-form';
import { certificateValidationSchema } from '../../../validations/profile';
import { yupResolver } from '@hookform/resolvers/yup';
import { addNewCertificate, editCertificate } from '../../../api/profile';

const CertificateForm = ({ editMode, values, onClose, setData }) => {
  const [focus, setFocus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resolver = yupResolver(certificateValidationSchema);
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: editMode
      ? {
          title: values?.title,
          institution: values?.institution,
          url: values?.url,
          details: values?.details,
        }
      : {},
  });

  const watchAllFields = watch();

  const onSubmit = async (data) => {
    if (editMode) {
      setIsSubmitting(true);
      const res = await editCertificate({
        ...data,
        certificateId: values.id,
      });
      if (res.status === 200) {
        setData(res.updatedCertificate, 'update-certificate');
        reset({
          title: '',
          institution: '',
          url: '',
          details: '',
        });
        onClose();
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
      const res = await addNewCertificate(data);
      if (res.status === 200) {
        setData(res.newCertificate, 'add-certificate');
        reset({
          title: '',
          institution: '',
          details: '',
          url: '',
        });
        onClose();
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Stack
      p={1.5}
      width="100%"
      spacing={1.5}
      component="form"
      borderRadius={1}
      border="1px solid #bbb"
      onSubmit={handleSubmit(onSubmit)}>
      <Typography mb={1} fontSize={16} fontWeight={500}>
        {editMode ? 'Update' : 'Add New'} Certificate or Achievement
      </Typography>
      <FormControl fullWidth error={errors?.title?.message}>
        <TextField
          size="small"
          label="Title"
          {...register('title')}
          onBlur={() => setFocus('')}
          error={errors?.title?.message}
          onFocus={() => setFocus('title')}
          placeholder="XYZ Certificate of Excellence"
          InputLabelProps={{ shrink: watchAllFields.title || focus === 'title' }}
        />
        <HelperText>{errors?.title?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.institution?.message}>
        <TextField
          size="small"
          label="Institution"
          placeholder="XYZ Lab"
          onBlur={() => setFocus('')}
          {...register('institution')}
          error={errors?.institution?.message}
          onFocus={() => setFocus('institution')}
          InputLabelProps={{ shrink: watchAllFields.institution || focus === 'institution' }}
        />
        <HelperText>{errors?.institution?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.url?.message}>
        <TextField
          size="small"
          {...register('url')}
          label="Reference URL"
          onBlur={() => setFocus('')}
          error={errors?.url?.message}
          onFocus={() => setFocus('url')}
          placeholder="https://example.com"
          InputLabelProps={{ shrink: watchAllFields.url || focus === 'url' }}
        />
        <HelperText>{errors?.url?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.details?.message}>
        <TextField
          rows={4}
          multiline
          size="small"
          label="Details"
          {...register('details')}
          onBlur={() => setFocus('')}
          error={errors?.details?.message}
          onFocus={() => setFocus('details')}
          placeholder="Explain your contribution or achievement..."
          InputLabelProps={{ shrink: watchAllFields.details || focus === 'details' }}
        />
        <HelperText allowOverflow={!errors?.details?.message}>
          {errors?.details?.message}
        </HelperText>
      </FormControl>
      <Stack pt={1} direction="row" spacing={1} justifyContent="flex-end">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={isSubmitting}
          onClick={() => {
            reset({
              title: '',
              institution: '',
              url: '',
              details: '',
            });
            onClose();
          }}
          sx={{ minWidth: 'fit-content' }}>
          Cancel
        </Button>
        <Button
          size="small"
          type="submit"
          color="secondary"
          variant="contained"
          loading={isSubmitting}
          sx={{ minWidth: 'fit-content' }}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default CertificateForm;
