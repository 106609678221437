import 'yup-phone';
import * as yup from 'yup';

export const paymentAssistanceValidationSchema = yup.object().shape({
  reason: yup.string().required('please select a reason'),
  message: yup.string().when('reason', {
    is: 'OTHER',
    then: yup.string().required('please describe your reason'),
  }),
});
