import React from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../components/global/PageWrapper';
import EventRenderer from '../../components/global/EventRenderer';
import { Helmet } from 'react-helmet';
import events from '../../constants/placeholder/events';
import { useSelector } from 'react-redux';

const EventPreview = () => {
  const { slug } = useParams();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const event = events.find((item) => item.slug === slug);

  return (
    <PageWrapper isAuth={isAuth}>
      <Helmet>
        <title>{`ISBM | ${event?.title || 'Event Not Found'}`}</title>
        <meta name="description" content={event?.description || ''} />
      </Helmet>
      <EventRenderer event={event} isAuth={isAuth} />
    </PageWrapper>
  );
};

export default EventPreview;
