export const coreValues = [
  {
    title: 'Accountability',
    image: '/assets/images/about/001.png',
    description:
      'We demonstrate our accountability by taking responsibility for our own actions and decisions. Our members strictly maintain discipline and integrity',
  },
  {
    title: 'Respect',
    image: '/assets/images/about/002.png',
    description:
      'We hold high esteem and show a positive attitude in our activities that expresses a sense of admiration for good attributes',
  },
  {
    title: 'Transparency',
    image: '/assets/images/about/003.png',
    description:
      'We believe in transparency that creates trust and builds a platform in which members share their ideas and views honestly',
  },
  {
    title: 'Quality',
    image: '/assets/images/about/004.png',
    description:
      'It is important for us to maintain quality in all aspects of our activities throughout our lifespan. We are happy to have all our members participate in our activities and to make them vibrant and quality filled',
  },
  {
    title: 'Collaboration',
    image: '/assets/images/about/005.png',
    description:
      'We promote collaborative work that brings opportunity to everybody to contribute their best ideas and efforts',
  },
];

export const missions = [
  {
    title: 'Professional Platform',
    image: '/assets/images/about/006.png',
    description:
      'Develop a globally connected interactive hub of Bangladesh-affiliated microbiologists',
  },
  {
    title: 'Effective bridging',
    image: '/assets/images/about/009.png',
    description:
      'Promote bridging among academia, government, industries, laboratories, hospitals, and other professional organizations',
  },
  {
    title: 'Career development program',
    image: '/assets/images/about/007.png',
    description: 'Provide mentorship to develop skills for students and young microbiologists',
  },
  {
    title: 'Professional networking',
    image: '/assets/images/about/010.png',
    description:
      'Promote networking for idea generation, research collaboration, job search, and community service.',
  },
  {
    title: 'Workshops and seminars',
    image: '/assets/images/about/011.png',
    description: 'Training and information sharing on education, research, and technologies.',
  },
  {
    title: 'Open for collaboration',
    image: '/assets/images/about/008.png',
    description:
      'Work with other national and international organizations for fellowships, scholarships, research, and travel grants.',
  },
];
