export default [
  'https://www.youtube.com/embed/5qVJaoTFjbU',
  'https://www.youtube.com/embed/tFR5kVaDyAc',
  'https://www.youtube.com/embed/euvz0-9weQE',
  'https://www.youtube.com/embed/6iNNcaCuzs8',
  'https://www.youtube.com/embed/3ADoLB-m_ug',
  'https://www.youtube.com/embed/yUO6phrnh6o',
  'https://www.youtube.com/embed/JU_CEOBCOZo',
  'https://www.youtube.com/embed/fQi_T3XDlp8',
  'https://www.youtube.com/embed/-Nzu2o-wnP0',
  'https://www.youtube.com/embed/phyReOr8kWI',
  'https://www.youtube.com/embed/zy-SrYKYvyk',
  'https://www.youtube.com/embed/WHG2PQCWAAg',
  'https://www.youtube.com/embed/XMI9CLdJens',
  'https://www.youtube.com/embed/qCJx-_NLvY4',
  'https://www.youtube.com/embed/D_T6fK6biU8',
  'https://www.youtube.com/embed/8p8JxfRyH8k',
  'https://www.youtube.com/embed/dUjSf7TdeaU',
  'https://www.youtube.com/embed/Lh6tNAC7Fwg',
  'https://www.youtube.com/embed/h_09M5rU-BY',
  'https://www.youtube.com/embed/HyUlJBJyzOo',
];
