import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const resetScroll = () => {
    let containerElement;
    try {
      containerElement = document?.getElementsByClassName('app-scroll-container')?.[0];
      if (containerElement) {
        containerElement.scrollTop = 0;
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    resetScroll();
  }, [pathname]);

  return null;
}
