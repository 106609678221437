export const membershipTypes = {
  BANGLADESH:
    process.env.REACT_APP_ENV === 'production'
      ? [
          {
            type: 'STUDENT MEMBER',
            title: 'Student Member',
            description:
              'Students enrolled in higher education (BS/MS/MPhil/Ph.D.) in microbiology or microbiology-related discipline in an accredited university are eligible for student membership.',
            duration: '12 months',
            price: 0,
          },
          {
            productId: 'prod_ObF1CEKyGcBrfa',
            priceId: 'price_1No2XnE2QhJ9pXlEzKQCToQe',
            type: 'POST-DOC MEMBER',
            title: 'Post-Doc Member',
            description:
              'Post-Doctoral researchers in microbiology or related fields are eligible for post-doc membership.',
            duration: '12 months',
            price: 5,
          },
          {
            productId: 'prod_OFd5aiL1wKLPgN',
            priceId: 'price_1NT7pME2QhJ9pXlEJrQbJzHc',
            type: 'GENERAL MEMBER',
            title: 'General Member',
            description:
              'Any Bangladesh-affiliated microbiologist with a formal degree or diploma (BS/MS/MPhil/Ph.D.) in microbiology from an accredited university is eligible for general membership.',
            duration: '12 months',
            price: 10,
          },
          {
            productId: 'prod_OFd58mQGylZkSF',
            priceId: 'price_1NT7pDE2QhJ9pXlExQIPvjZl',
            type: 'LIFE MEMBER',
            title: 'Life Member',
            description:
              'Any general member can become a life member of ISBM by paying a one-time fee as set by the society.',
            duration: 'Life time',
            price: 150,
          },
        ]
      : [
          {
            type: 'STUDENT MEMBER',
            title: 'Student Member',
            description:
              'Students enrolled in higher education (BS/MS/MPhil/Ph.D.) in microbiology or microbiology-related discipline in an accredited university are eligible for student membership.',
            duration: '12 months',
            price: 0,
          },
          {
            productId: 'prod_ObDjmMGWF5qJO7',
            priceId: 'price_1No1HuE2QhJ9pXlE22cW7cw4',
            type: 'POST-DOC MEMBER',
            title: 'Post-Doc Member',
            description:
              'Post-Doctoral researchers in microbiology or related fields are eligible for post-doc membership.',
            duration: '12 months',
            price: 5,
          },
          {
            productId: 'prod_OEsq1hut3GK7J3',
            priceId: 'price_1NSP4vE2QhJ9pXlErz1WLIzl',
            type: 'GENERAL MEMBER',
            title: 'General Member',
            description:
              'Any Bangladesh-affiliated microbiologist with a formal degree or diploma (BS/MS/MPhil/Ph.D.) in microbiology from an accredited university is eligible for general membership.',
            duration: '12 months',
            price: 10,
          },
          {
            productId: 'prod_OEsrCvlvHvR1tW',
            priceId: 'price_1NSP61E2QhJ9pXlE9QVnry5u',
            type: 'LIFE MEMBER',
            title: 'Life Member',
            description:
              'Any general member can become a life member of ISBM by paying a one-time fee as set by the society.',
            duration: 'Life time',
            price: 150,
          },
        ],
  OTHERS:
    process.env.REACT_APP_ENV === 'production'
      ? [
          {
            type: 'STUDENT MEMBER',
            title: 'Student Member',
            description:
              'Students enrolled in higher education (BS/MS/MPhil/Ph.D.) in microbiology or microbiology-related discipline in an accredited university are eligible for student membership.',
            duration: '12 months',
            price: 0,
          },
          {
            productId: 'prod_ObF2OP0pvF5drh',
            priceId: 'price_1No2XzE2QhJ9pXlElx0Qy5BN',
            type: 'POST-DOC MEMBER',
            title: 'Post-Doc Member',
            description:
              'Post-Doctoral researchers in microbiology or related fields are eligible for post-doc membership.',
            duration: '12 months',
            price: 20,
          },
          {
            productId: 'prod_OFd5rZbxX5L73B',
            priceId: 'price_1NT7orE2QhJ9pXlE8V4JnHVX',
            type: 'GENERAL MEMBER',
            title: 'General Member',
            description:
              'Any Bangladesh-affiliated microbiologist with a formal degree or diploma (BS/MS/MPhil/Ph.D.) in microbiology from an accredited university is eligible for general membership.',
            duration: '12 months',
            price: 40,
          },
          {
            productId: 'prod_OFd4Rpwn9kOpmm',
            priceId: 'price_1NT7ofE2QhJ9pXlEcNvT0alq',
            type: 'LIFE MEMBER',
            title: 'Life Member',
            description:
              'Any general member can become a life member of ISBM by paying a one-time fee as set by the society.',
            duration: 'Life time',
            price: 500,
          },
        ]
      : [
          {
            type: 'STUDENT MEMBER',
            title: 'Student Member',
            description:
              'Students enrolled in higher education (BS/MS/MPhil/Ph.D.) in microbiology or microbiology-related discipline in an accredited university are eligible for student membership.',
            duration: '12 months',
            price: 0,
          },
          {
            productId: 'prod_ObDiCsQ7pLgKBm',
            priceId: 'price_1No1GhE2QhJ9pXlEIUEFDQbj',
            type: 'POST-DOC MEMBER',
            title: 'Post-Doc Member',
            description:
              'Post-Doctoral researchers in microbiology or related fields are eligible for post-doc membership.',
            duration: '12 months',
            price: 20,
          },
          {
            productId: 'prod_OEsuaWAKtDTH58',
            priceId: 'price_1NSP8JE2QhJ9pXlEW4v6JHlw',
            type: 'GENERAL MEMBER',
            title: 'General Member',
            description:
              'Any Bangladesh-affiliated microbiologist with a formal degree or diploma (BS/MS/MPhil/Ph.D.) in microbiology from an accredited university is eligible for general membership.',
            duration: '12 months',
            price: 40,
          },
          {
            productId: 'prod_OEsuG17HAK6Uw9',
            priceId: 'price_1NSP99E2QhJ9pXlE6Y8bZ9mt',
            type: 'LIFE MEMBER',
            title: 'Life Member',
            description:
              'Any general member can become a life member of ISBM by paying a one-time fee as set by the society.',
            duration: 'Life time',
            price: 500,
          },
        ],
};
