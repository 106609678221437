import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSocketContext } from '../SocketContext';

export const useSocket = () => {
  const socket = useSocketContext();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuth) {
      if (!socket.connected) {
        socket.connect();
      }
    } else {
      if (socket.connected) {
        socket.disconnect();
      }
    }
    return () => {
      socket.disconnect();
    };
  }, [socket, isAuth]);

  return socket;
};
