import axiosInstance from './axios';

const axios = axiosInstance();

export default {
  get: async function (url, query) {
    try {
      const response = await axios.get(url, { params: query });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  post: async function (url, body) {
    try {
      const response = await axios.post(url, { ...body });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  patch: async function (url, body, query) {
    try {
      const response = await axios.patch(url, { ...body }, { params: query });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  delete: async function (url) {
    try {
      const response = await axios.delete(url);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  postWithFile: async function (url, formData, uploadEvent) {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: uploadEvent,
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },

  patchWithFile: async function (url, formData, query, uploadEvent) {
    try {
      const response = await axios.patch(url, formData, {
        params: query,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: uploadEvent,
      });

      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
};
