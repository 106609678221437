import React, { useEffect, useState } from 'react';
import { Box, Divider, LinearProgress } from '@mui/material';
import ExperiencesTab from './ExperiencesTab';
import EducationsTab from './EducationsTab';
import CertificatesTab from './CertificatesTab';
import { fetchProfile } from '../../../api/profile';

const DetailsTab = ({ isAuth, username }) => {
  const [tabData, setTabData] = useState();
  const [loading, setLoading] = useState(true);

  const fetchTabData = async () => {
    const res = await fetchProfile(username, 'details');
    if (res?.status === 200) {
      setTabData(res?.data);
    } else {
      setTabData();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (username) {
      fetchTabData();
    }
  }, [username]);

  const setData = (data, option) => {
    switch (option) {
      case 'add-experience':
        setTabData((prev) => ({
          ...prev,
          experiences: [data, ...prev.experiences],
        }));
        break;
      case 'update-experience':
        setTabData((prev) => ({
          ...prev,
          experiences: prev.experiences.map((exp) => (exp.id === data.id ? data : exp)),
        }));
        break;
      case 'delete-experience':
        setTabData((prev) => ({
          ...prev,
          experiences: prev.experiences.filter((exp) => exp.id !== data),
        }));
        break;
      case 'reorder-experience':
        setTabData((prev) => ({
          ...prev,
          experiences: data,
        }));
        break;
      case 'add-education':
        setTabData((prev) => ({
          ...prev,
          educations: [data, ...prev.educations],
        }));
        break;
      case 'update-education':
        setTabData((prev) => ({
          ...prev,
          educations: prev.educations.map((edu) => (edu.id === data.id ? data : edu)),
        }));
        break;
      case 'delete-education':
        setTabData((prev) => ({
          ...prev,
          educations: prev.educations.filter((edu) => edu.id !== data),
        }));
        break;
      case 'reorder-education':
        setTabData((prev) => ({
          ...prev,
          educations: data,
        }));
        break;
      case 'add-certificate':
        setTabData((prev) => ({
          ...prev,
          certificates: [data, ...prev.certificates],
        }));
        break;
      case 'update-certificate':
        setTabData((prev) => ({
          ...prev,
          certificates: prev.certificates.map((cert) => (cert.id === data.id ? data : cert)),
        }));
        break;
      case 'delete-certificate':
        setTabData((prev) => ({
          ...prev,
          certificates: prev.certificates.filter((cert) => cert.id !== data),
        }));
        break;
      case 'reorder-certificate':
        setTabData((prev) => ({
          ...prev,
          certificates: data,
        }));
        break;
      default:
        break;
    }
  };

  return loading ? (
    <Box p={4}>
      <LinearProgress />
    </Box>
  ) : (
    <Box>
      <>
        <ExperiencesTab
          isAuth={isAuth}
          loading={loading}
          setData={setData}
          data={tabData?.experiences}
        />
        <Divider />
      </>
      <>
        <EducationsTab
          isAuth={isAuth}
          loading={loading}
          setData={setData}
          data={tabData?.educations}
        />
        <Divider />
      </>
      <>
        <CertificatesTab
          isAuth={isAuth}
          loading={loading}
          setData={setData}
          data={tabData?.certificates}
        />
        <Divider />
      </>
    </Box>
  );
};

export default DetailsTab;
