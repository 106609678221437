import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/global/PageWrapper';
import { Avatar, Box, CircularProgress, Stack, Step, Typography } from '@mui/material';
import { membershipTypes } from '../../constants/membership-type';
import { proceedToPayment, updateStudentMembershipStatus } from '../../api/profile';
import Button from '../../widgets/Button';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_FAILURE, USER_HOME } from '../../constants/router-urls';
import { useSelector } from 'react-redux';
import { updateAuthState } from '../../api/auth';
import dateTime from '../../utils/dateTime';

const SelectPlan = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const userCountry = data.country_name;
        if (userCountry?.toUpperCase() === 'BANGLADESH') {
          setCountry(userCountry?.toUpperCase());
        } else {
          setCountry('OTHERS');
        }
      })
      .catch((error) => {
        console.error('Error getting location:', error);
        setCountry('OTHERS');
      });
  }, []);

  useEffect(() => {
    if (
      user.membershipType == 'LIFE' ||
      (user.paymentStatus === 'PAID' &&
        user.membershipStatus === 'ACTIVE' &&
        dateTime.remainingTime(user.expiryDate) > 30)
    ) {
      navigate(USER_HOME, { replace: true });
    }
  }, [user]);

  const allowedOptions = (options) => {
    if (user?.membershipType !== 'STUDENT') {
      return options.filter((option) => option.type !== 'STUDENT MEMBER');
    }
    return options;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (data?.price > 0) {
      const paymentInfo = await proceedToPayment({ membershipType: data });
      if (paymentInfo?.paymentUrl) {
        window.location.href = paymentInfo.paymentUrl;
      }
    } else if (data?.price === 0) {
      const res = await updateStudentMembershipStatus();
      if (res.status === 200) {
        await updateAuthState();
      } else {
        navigate(`${PAYMENT_FAILURE}/student-member`, { replace: true });
      }
    }
    setIsLoading(false);
  };

  return (
    <PageWrapper>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <Step>
          <Stack alignItems="center" spacing={1}>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: '0.8rem',
                bgcolor: 'error.main',
              }}
            />
            <Stack>
              <Typography variant="h5" fontWeight={700} textAlign="center">
                Membership Type
              </Typography>
              <Typography textAlign="center">Choose your desired membership type</Typography>
            </Stack>
          </Stack>
        </Step>
      </Stack>
      <Stack>
        <Box>
          {!country ? (
            <Stack p={5} alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Stack
              mt={10}
              width="100%"
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center">
              {allowedOptions(membershipTypes[country]).map((membershipType) => (
                <Stack
                  p={2}
                  m={1}
                  width={300}
                  minHeight={340}
                  borderRadius={2}
                  alignItems="center"
                  key={membershipType.type}
                  justifyContent="space-between"
                  sx={{
                    color: '#272739',
                    cursor: 'pointer',
                    textAlign: 'center',
                    backgroundColor: '#e8e8e8',
                    transition: 'all 0.1s ease',
                    '&:hover': { backgroundColor: '#4562f7', color: '#fff' },
                    border: data?.type === membershipType.type ? '3px solid #4562f7' : 'unset',
                  }}
                  onClick={isLoading ? null : () => setData(membershipType)}>
                  <Stack alignItems="center">
                    <Box
                      width={50}
                      component="img"
                      src="/assets/images/award.png"
                      borderRadius={2}
                    />
                    <Typography variant="h6" fontWeight={700}>
                      {membershipType.title}
                    </Typography>
                    <Typography fontSize="0.9rem">{membershipType.description}</Typography>
                  </Stack>
                  <Typography variant="h3" fontWeight={700} pb={2}>
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(membershipType.price)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
          <Stack mt={10} direction="row" spacing={1} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!data || !country}
              loading={isLoading}>
              Proceed to Payment
            </Button>
          </Stack>
        </Box>
      </Stack>
    </PageWrapper>
  );
};

export default SelectPlan;
