import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Stack, Typography } from '@mui/material';

import { ABOUT_ISBM } from '../../constants/router-urls';
import { sectionHeaderStyle } from '../../constants/styles';
import { coreValues } from '../../constants/placeholder/objectives';

const AboutSection = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 15 }}>
      <Stack width="100%" alignItems="center">
        <Typography {...sectionHeaderStyle}>
          <span style={{ color: '#128CCD' }}>About</span> ISBM
        </Typography>
        <Typography
          fontWeight={400}
          fontSize="0.9rem"
          sx={{ width: '100%', textAlign: 'justify', maxWidth: '900px' }}>
          ISBM Founded in May 2020 in the midst of the devastating COVID-19 pandemic by a group of
          Microbiologists and related professionals in North America, the International Society of
          Bangladesh-affiliated Microbiologists (ISBM) aimed to unite Bangladeshi microbiologists
          and professionals so that they could present the importance of microbiologists&apos; role
          in scientific development to the World. With the intense suffering brought about by the
          COVID-19 infection worldwide, and also to the people of Bangladesh, ISBM was created to
          prove that Bangladeshi microbiologists could be one of the essential front-liners,
          directly or indirectly involved in the management of the pandemic. While the pandemic has
          clearly shown the necessity of microbiologists in activities like testing, surveillance,
          and research, it has also identified gaps in understanding, training, and collaboration
          among the professionals...{' '}
          <span>
            <Link
              to={ABOUT_ISBM}
              style={{
                color: '#128CCD',
                fontWeight: 600,
                cursor: 'pointer',
                textDecoration: 'none',
              }}>
              Read More
            </Link>
          </span>
        </Typography>

        <Stack
          pt={4}
          width="100%"
          flexWrap="wrap"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}>
          {coreValues.map((item) => (
            <Stack
              m={1}
              p={2}
              flex={1}
              border={2}
              spacing={1.5}
              bgcolor="#fff"
              direction="row"
              minWidth="300px"
              borderRadius={2}
              key={item.title}
              borderColor="grey.200"
              sx={{
                transition: 'all 0.2s ease',
                ':hover': {
                  transform: 'scale(1.04, 1.04)',
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                },
              }}>
              <Stack>
                <Stack direction="row" spacing={1}>
                  <Box
                    width={25}
                    component="img"
                    src={item.image}
                    sx={{ filter: 'grayscale(100%)' }}
                  />
                  <Typography fontWeight={600} variant="h6" lineHeight={1.3}>
                    {item.title}
                  </Typography>
                </Stack>
                <Typography mt={1}>{item.description}</Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default AboutSection;
