import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Stack, TextField } from '@mui/material';
import HelperText from '../../widgets/HelperText';

const CustomDatePicker = ({
  Controller,
  control,
  name,
  label,
  value,
  errors,
  minDate,
  maxDate,
  disabled,
  allowHelperTextOverflow,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            label={label}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            value={value || null}
            onChange={(date) => field.onChange(date)}
            renderInput={(params) => (
              <Stack width="100%">
                <TextField {...params} fullWidth size="small" error={errors?.[name]} />
                <HelperText allowOverflow={allowHelperTextOverflow}>
                  {errors?.[name]?.message}
                </HelperText>
              </Stack>
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
