import * as urls from '../constants/router-urls';

export default [
  {
    label: 'About ISBM',
    path: urls.ABOUT_ISBM,
  },
  {
    label: 'Membership',
    path: urls.MEMBERSHIP,
  },
  {
    label: 'Meetings & Events',
    path: urls.MEETING_EVENTS,
  },
  {
    label: 'Publications',
    path: urls.PUBLICATIONS,
  },
  {
    label: 'News & blogs',
    path: urls.NEWS_BLOGS,
  },
  {
    label: 'Professional Career',
    path: urls.PROFESSIONAL_CAREER,
  },
  {
    label: 'Resources',
    path: urls.RESOURCES,
  },
  {
    label: 'Support ISBM',
    path: urls.SUPPORT_ISBM,
  },
  {
    label: 'Contact Us',
    path: urls.CONTACT,
  },
];
