import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colors, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { CancelTwoTone, DoneAllTwoTone, PersonRemoveAlt1TwoTone } from '@mui/icons-material';

import {
  fetchSentRequests,
  cancelConnectionRequest,
  acceptConnectionRequest,
  rejectConnectionRequest,
  fetchConnectionRequests,
} from '../../api/connection';
import UserCard from '../global/UserCard';
import Button from '../../widgets/Button';

const ConnectionRequestsTab = () => {
  const increaseConnReqBy = 20;
  const increaseSentReqBy = 20;
  const [isConnReqLoading, setIsConnReqLoading] = useState(0);
  const [isSentReqLoading, setIsSentReqLoading] = useState(0);
  const [connReqLimit, setLimit] = useState(increaseConnReqBy);
  const [sentReqLimit, setSentLimit] = useState(increaseSentReqBy);
  const [isHandlingRequest, setIsHandlingRequest] = useState(1);
  const [isCancelling, setIsCancelling] = useState('');

  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    (async () => {
      await fetchConnectionRequests({ limit: connReqLimit });
      setIsConnReqLoading(1);
      await fetchSentRequests({ limit: sentReqLimit });
      setIsSentReqLoading(1);
    })();
  }, []);

  useEffect(() => {
    if (connection?.fetchConnectionRequests) {
      (async () => {
        await fetchConnectionRequests({ limit: connReqLimit });
      })();
    }
    if (connection?.fetchSentRequests) {
      (async () => {
        await fetchSentRequests({ limit: sentReqLimit });
      })();
    }
  }, [connection]);

  const handleAccept = async (uid) => {
    setIsHandlingRequest(uid);
    await acceptConnectionRequest(uid);
    setIsHandlingRequest('');
  };

  const handleReject = async (uid) => {
    setIsHandlingRequest(uid);
    await rejectConnectionRequest(uid);
    setIsHandlingRequest('');
  };

  const handleLoadMoreConnReq = async () => {
    setIsConnReqLoading(2);
    await fetchConnectionRequests({ limit: connReqLimit + increaseConnReqBy });
    setLimit(connReqLimit + increaseConnReqBy);
    setIsConnReqLoading(1);
  };

  const handleCancelRequest = async (uid) => {
    setIsCancelling(uid);
    await cancelConnectionRequest(uid);
    setIsCancelling('');
  };

  const handleLoadMoreSentReq = async () => {
    setIsSentReqLoading(2);
    await fetchSentRequests({ limit: sentReqLimit + increaseSentReqBy });
    setSentLimit(sentReqLimit + increaseSentReqBy);
    setIsSentReqLoading(1);
  };

  return (
    <Stack>
      <Typography fontSize={18} fontWeight={700}>
        Connection Requests
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" flexWrap="wrap">
        {isConnReqLoading === 0 ? (
          [...Array(connReqLimit)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))
        ) : connection?.connectionRequests?.length > 0 ? (
          connection?.connectionRequests?.map((item) => (
            <UserCard mb={1} key={item?.id} user={item?.requestedByUser}>
              {isHandlingRequest === item?.requestedByUser?.uid ? (
                <Typography pt={2} fontSize={12} color={colors.grey[600]} textAlign="center">
                  ...processing
                </Typography>
              ) : (
                <>
                  <Button
                    fullWidth
                    size="small"
                    color="success"
                    sx={{ mt: '5px' }}
                    variant="outlined"
                    startIcon={<DoneAllTwoTone />}
                    onClick={() => handleAccept(item?.requestedByUser?.uid)}>
                    Accept
                  </Button>
                  <Button
                    fullWidth
                    size="small"
                    color="error"
                    sx={{ mt: '5px' }}
                    variant="outlined"
                    startIcon={<CancelTwoTone />}
                    onClick={() => handleReject(item?.requestedByUser?.uid)}>
                    Reject
                  </Button>
                </>
              )}
            </UserCard>
          ))
        ) : (
          <Typography pb={2} fontSize={14} color={colors.grey[600]}>
            No connection requests
          </Typography>
        )}
        {isConnReqLoading === 2 &&
          [...Array(increaseConnReqBy)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))}
      </Stack>
      {connection?.totalRequests > connReqLimit && (
        <Stack mt={1} alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleLoadMoreConnReq}
            loading={isConnReqLoading === 2}>
            Load More
          </Button>
        </Stack>
      )}
      <Typography fontSize={18} fontWeight={700} mt={4}>
        Sent Requests
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" flexWrap="wrap">
        {isSentReqLoading === 0 ? (
          [...Array(sentReqLimit)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))
        ) : connection?.sentRequests?.length > 0 ? (
          connection?.sentRequests?.map((item) => (
            <UserCard mb={1} key={item?.id} user={item?.requestedToUser}>
              <Button
                fullWidth
                size="small"
                color="secondary"
                sx={{ mt: '5px' }}
                variant="outlined"
                startIcon={<PersonRemoveAlt1TwoTone />}
                loading={isCancelling === item?.requestedToUser?.uid}
                onClick={() => handleCancelRequest(item?.requestedToUser?.uid)}>
                Cancel
              </Button>
            </UserCard>
          ))
        ) : (
          <Typography pb={2} fontSize={14} color={colors.grey[600]}>
            No sent requests
          </Typography>
        )}
        {isSentReqLoading === 2 &&
          [...Array(increaseSentReqBy)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))}
      </Stack>
      {connection?.totalSentRequests > sentReqLimit && (
        <Stack mt={1} alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleLoadMoreSentReq}
            loading={isSentReqLoading === 2}>
            Load More
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default ConnectionRequestsTab;
