import React from 'react';
import { brandColors } from '../../constants/colors';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

const Banner = () => {
  return (
    <Container maxWidth="lg">
      <Stack direction={{ md: 'row', xs: 'column' }} spacing={4} mt={4} alignItems="center">
        <Box
          flex={1}
          component="img"
          borderRadius={2}
          width={{ md: '40%', xs: '80%' }}
          sx={{ filter: 'grayscale(100%)' }}
          src="/assets/gifs/connecting.webp"
        />
        <Stack flex={1} spacing={2}>
          <Typography variant="h3" fontWeight={700}>
            <span style={{ color: brandColors.primary }}>Join</span> <br /> ISBM Today
          </Typography>
          <Typography fontSize="1rem" fontWeight={400}>
            The ISBM supports its members to excel at all stages of their careers through
            networking, professional development, meetings, and leadership opportunities. The
            membership year for the society runs from January 1st to December 31st.
          </Typography>
          <Button
            component="a"
            href="#pricing"
            variant="contained"
            sx={{ py: 2, maxWidth: 170, textTransform: 'capitalize', borderRadius: 2 }}>
            Become A Member
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Banner;
