import React, { useState } from 'react';
import { FormControl, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import HelperText from '../../../widgets/HelperText';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema } from '../../../validations/profile';
import Button from '../../../widgets/Button';
import { updateAuthState, updatePassword } from '../../../api/auth';

const ChangePasswordForm = ({ onClose }) => {
  const [focus, setFocus] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const resolver = yupResolver(changePasswordSchema);
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();

  const handleDiscard = () => {
    reset({
      currentPassword: '',
      password: '',
      rePassword: '',
    });
    onClose();
  };

  const onSubmit = async (data) => {
    const isSuccessful = await updatePassword({
      currentPassword: data.currentPassword,
      password: data.password,
    });
    if (isSuccessful) {
      await updateAuthState();
      reset({
        currentPassword: '',
        password: '',
        rePassword: '',
      });
      onClose();
    }
  };

  return (
    <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth error={errors?.currentPassword?.message}>
        <TextField
          size="small"
          label="Current Password *"
          onBlur={() => setFocus('')}
          {...register('currentPassword')}
          error={errors?.currentPassword?.message}
          onFocus={() => setFocus('currentPassword')}
          type={showCurrentPassword ? 'text' : 'password'}
          InputLabelProps={{
            shrink: watchAllFields.currentPassword || focus === 'currentPassword',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                  {showCurrentPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <HelperText>{errors?.currentPassword?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.password?.message}>
        <TextField
          size="small"
          label="New Password *"
          onBlur={() => setFocus('')}
          {...register('password')}
          error={errors?.password?.message}
          onFocus={() => setFocus('password')}
          type={showNewPassword ? 'text' : 'password'}
          InputLabelProps={{
            shrink: watchAllFields.password || focus === 'password',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <HelperText>{errors?.password?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.rePassword?.message}>
        <TextField
          size="small"
          label="Confirm New Password *"
          onBlur={() => setFocus('')}
          {...register('rePassword')}
          error={errors?.rePassword?.message}
          onFocus={() => setFocus('rePassword')}
          type={showRePassword ? 'text' : 'password'}
          InputLabelProps={{
            shrink: watchAllFields.rePassword || focus === 'rePassword',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowRePassword(!showRePassword)}>
                  {showRePassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <HelperText>{errors?.rePassword?.message}</HelperText>
      </FormControl>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleDiscard}
          disabled={isSubmitting}>
          Discard
        </Button>
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}>
          Save Changes
        </Button>
      </Stack>
    </Stack>
  );
};

export default ChangePasswordForm;
