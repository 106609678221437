export const howYouCanHelp = [
  {
    title: 'Guide ISBM',
    description:
      'You can serve ISBM and the community by being part of the Executive Committee and different program-based committees (i.e., Education, Awards, Media, Website, Blogs).',
  },
  {
    title: 'Future Leader',
    description:
      'ISBM offers several opportunities to build a scientific community in your respective institutions. Start a student and postdoctoral chapter at your institution or apply to be an ISBM Student Ambassador.',
  },
  {
    title: 'Microbiology Advocates',
    description:
      'ISBM strives to work in collaboration with scientists, educators, media personalities, stakeholders, and the public across multiple sectors to advance microbial science and create professional opportunities and advance the field through dialogues between the members of parliament, ministers, and government officials in Bangladesh.  Be a Microbiology Advocate for carrying out these responsibilities. ',
  },
  {
    title: 'Be an Educator',
    description:
      'Participate in training and educational activities designed to help members and students to enhance their career and professional development.',
  },
  {
    title: 'Communicate Science',
    description:
      'Write interesting topics on microbiology and publish in ISBM media (website, Facebook, email, and newsletter), create lectures for non-microbiology audiences, and communicate with general peo  ple to raise public health awareness. ',
  },
];
