import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tempActions } from './store/reducers/tempReducer';

const ScrollContainer = ({ children }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const tempState = useSelector((state) => state.temp);

  const handleScroll = () => {
    if (
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight
    ) {
      dispatch(tempActions.controller({ shouldLoadMore: true }));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (tempState.isFetching) return;
    dispatch(tempActions.controller({ shouldLoadMore: false }));
  }, [tempState.isFetching]);

  return (
    <div
      ref={containerRef}
      className="app-scroll-container"
      style={{ overflow: 'auto', height: '100vh' }}>
      {children}
    </div>
  );
};

export default ScrollContainer;
