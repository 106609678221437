import word from '../utils/word';
import Avatar from './Avatar';
import { Stack, Typography, colors } from '@mui/material';

export const CustomToasts = {
  newConnectionToast: function (user) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar src={user?.image} label={word.displayInitials(user?.firstname, user?.lastname)} />
        <Stack>
          <Typography
            sx={{
              width: 200,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: colors.grey[800],
              textOverflow: 'ellipsis',
            }}>
            {word.displayName(user?.firstname, user?.lastname)}
          </Typography>
          <Typography fontSize={12} color={colors.grey[600]}>
            Accepted your connection request
          </Typography>
        </Stack>
      </Stack>
    );
  },

  newRequestToast: function (user) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar src={user?.image} label={word.displayInitials(user?.firstname, user?.lastname)} />
        <Stack>
          <Typography
            sx={{
              width: 200,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: colors.grey[800],
              textOverflow: 'ellipsis',
            }}>
            {word.displayName(user?.firstname, user?.lastname)}
          </Typography>
          <Typography fontSize={12} color={colors.grey[600]}>
            Wants to connect with you
          </Typography>
        </Stack>
      </Stack>
    );
  },

  newPostToast: function (user) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar src={user?.image} label={word.displayInitials(user?.firstname, user?.lastname)} />
        <Stack>
          <Typography
            sx={{
              width: 200,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: colors.grey[800],
              textOverflow: 'ellipsis',
            }}>
            {word.displayName(user?.firstname, user?.lastname)}
          </Typography>
          <Typography fontSize={12} color={colors.grey[600]}>
            Added a new post
          </Typography>
        </Stack>
      </Stack>
    );
  },

  newPublicationToast: function (user) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar src={user?.image} label={word.displayInitials(user?.firstname, user?.lastname)} />
        <Stack>
          <Typography
            sx={{
              width: 200,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: colors.grey[800],
              textOverflow: 'ellipsis',
            }}>
            {word.displayName(user?.firstname, user?.lastname)}
          </Typography>
          <Typography fontSize={12} color={colors.grey[600]}>
            Added a new publication
          </Typography>
        </Stack>
      </Stack>
    );
  },

  newArticleToast: function (user) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar src={user?.image} label={word.displayInitials(user?.firstname, user?.lastname)} />
        <Stack>
          <Typography
            sx={{
              width: 200,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: colors.grey[800],
              textOverflow: 'ellipsis',
            }}>
            {word.displayName(user?.firstname, user?.lastname)}
          </Typography>
          <Typography fontSize={12} color={colors.grey[600]}>
            Added a new article
          </Typography>
        </Stack>
      </Stack>
    );
  },
};
