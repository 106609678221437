import { FormControl, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HelperText from '../../../widgets/HelperText';
import CustomDatePicker from '../../global/CustomDatePicker';
import Button from '../../../widgets/Button';
import { Controller, useForm } from 'react-hook-form';
import { experienceValidationSchema } from '../../../validations/profile';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckBox, CheckBoxOutlineBlankTwoTone } from '@mui/icons-material';
import { addNewExperience, editExperience } from '../../../api/profile';
import dateTime from '../../../utils/dateTime';

const ExperienceForm = ({ editMode, values, onClose, setData }) => {
  const [focus, setFocus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const resolver = yupResolver(experienceValidationSchema);
  const {
    reset,
    watch,
    control,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: editMode
      ? {
          role: values?.role,
          institution: values?.institution,
          from: values?.from,
          to: values?.to,
          details: values?.details,
          isCurrent: values?.isCurrent,
        }
      : {},
  });

  const watchAllFields = watch();

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    if (watchAllFields.isCurrent) {
      setValue('to', null);
      delete errors.to;
    } else {
      if (!isInitialRender) {
        setError('to', {
          type: 'manual',
          message: 'please enter to date',
        });
      }
    }
  }, [watchAllFields.isCurrent]);

  const onSubmit = async (data) => {
    if (editMode) {
      setIsSubmitting(true);
      const res = await editExperience({
        ...data,
        from: dateTime.format(data.from),
        to: dateTime.format(data.to),
        experienceId: values.id,
      });
      if (res.status === 200) {
        setData(res.updatedExperience, 'update-experience');
        reset({
          role: '',
          institution: '',
          from: null,
          to: null,
          details: '',
          isCurrent: false,
        });
        onClose();
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
      const res = await addNewExperience({
        ...data,
        from: dateTime.format(data.from),
        to: dateTime.format(data.to),
      });
      if (res.status === 200) {
        setData(res.newExperience, 'add-experience');
        reset({
          role: '',
          institution: '',
          from: null,
          to: null,
          details: '',
          isCurrent: false,
        });
        onClose();
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Stack
      p={1.5}
      width="100%"
      spacing={1.5}
      component="form"
      borderRadius={1}
      border="1px solid #bbb"
      onSubmit={handleSubmit(onSubmit)}>
      <Typography mb={1} fontSize={16} fontWeight={500}>
        {editMode ? 'Update' : 'Add New'} Experience
      </Typography>
      <FormControl fullWidth error={errors?.role?.message}>
        <TextField
          size="small"
          label="Role"
          {...register('role')}
          onBlur={() => setFocus('')}
          error={errors?.role?.message}
          placeholder="Lead Microbiologist"
          onFocus={() => setFocus('role')}
          InputLabelProps={{ shrink: watchAllFields.role || focus === 'role' }}
        />
        <HelperText>{errors?.role?.message}</HelperText>
      </FormControl>
      <FormControl fullWidth error={errors?.institution?.message}>
        <TextField
          size="small"
          label="Institution"
          placeholder="XYZ Lab"
          onBlur={() => setFocus('')}
          {...register('institution')}
          error={errors?.institution?.message}
          onFocus={() => setFocus('institution')}
          InputLabelProps={{ shrink: watchAllFields.institution || focus === 'institution' }}
        />
        <HelperText>{errors?.institution?.message}</HelperText>
      </FormControl>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
        <CustomDatePicker
          name="from"
          label="From"
          errors={errors}
          control={control}
          Controller={Controller}
          value={watchAllFields.from}
          allowHelperTextOverflow={!errors?.to?.message}
        />
        <CustomDatePicker
          name="to"
          label="To"
          errors={errors}
          control={control}
          Controller={Controller}
          value={watchAllFields.to}
          disabled={watchAllFields.isCurrent}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {watchAllFields.isCurrent ? (
          <CheckBox
            sx={{ cursor: 'pointer', fontSize: 20 }}
            onClick={() => setValue('isCurrent', !watchAllFields.isCurrent)}
          />
        ) : (
          <CheckBoxOutlineBlankTwoTone
            sx={{ cursor: 'pointer', fontSize: 20 }}
            onClick={() => setValue('isCurrent', !watchAllFields.isCurrent)}
          />
        )}

        <Typography variant="body2" color="text.secondary">
          I currently work here
        </Typography>
      </Stack>
      <FormControl fullWidth error={errors?.details?.message}>
        <TextField
          rows={4}
          multiline
          size="small"
          label="Details"
          {...register('details')}
          onBlur={() => setFocus('')}
          error={errors?.details?.message}
          onFocus={() => setFocus('details')}
          placeholder="Explain your role and responsibilities here..."
          InputLabelProps={{ shrink: watchAllFields.details || focus === 'details' }}
        />
        <HelperText allowOverflow={!errors?.details?.message}>
          {errors?.details?.message}
        </HelperText>
      </FormControl>
      <Stack pt={1} direction="row" spacing={1} justifyContent="flex-end">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          disabled={isSubmitting}
          onClick={() => {
            reset({
              role: '',
              institution: '',
              from: null,
              to: null,
              details: '',
              isCurrent: false,
            });
            onClose();
          }}
          sx={{ minWidth: 'fit-content' }}>
          Cancel
        </Button>
        <Button
          size="small"
          type="submit"
          color="secondary"
          variant="contained"
          loading={isSubmitting}
          sx={{ minWidth: 'fit-content' }}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default ExperienceForm;
