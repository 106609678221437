import * as yup from 'yup';
import { passwordValidation, usernameValidation } from '../utils/validation';

const ENV = process.env.REACT_APP_ENV;

export const signInValidationSchema = yup.object().shape({
  usernameOrEmail: yup.string().required('please enter your username or email'),
  password: yup.string().required('please enter your password'),
});

export const signUpValidationSchema = yup.object().shape({
  username:
    ENV === 'production'
      ? usernameValidation.required('please enter your username')
      : yup
          .string()
          .required('please enter your username')
          .min(8, 'username must be at least 8 characters long')
          .max(20, 'username must be max 20 character long'),
  email: yup.string().email('please enter a valid email').required('please enter your email'),
  firstname: yup.string().required('please enter your firstname'),
  lastname: yup.string().required('please enter your lastname'),
  password:
    ENV === 'production'
      ? passwordValidation.required('please enter your password')
      : yup
          .string()
          .required('please enter your password')
          .min(8, 'password must be at least 8 character long'),
  rePassword: yup
    .string()
    .required('please confirm your password')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
});

export const forgetPasswordValidationSchema = yup.object().shape({
  usernameOrEmail: yup.string().required('please enter your username or email'),
});

export const resetPasswordValidationSchema = yup.object().shape({
  password:
    ENV === 'production'
      ? passwordValidation.required('please enter your password')
      : yup
          .string()
          .required('please enter your password')
          .min(8, 'password must be at least 8 character long'),
  rePassword: yup
    .string()
    .required('please confirm your password')
    .oneOf([yup.ref('password'), null], 'passwords must match'),
});
