import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PersonRemoveAlt1TwoTone } from '@mui/icons-material';
import { colors, Divider, Skeleton, Stack, Typography } from '@mui/material';

import UserCard from '../global/UserCard';
import Button from '../../widgets/Button';
import { fetchConnections, removeConnection } from '../../api/connection';
import AlertDialog from '../global/AlertDialog';
import word from '../../utils/word';

const MyNetworkTab = () => {
  const increaseBy = 20;
  const [isLoading, setIsLoading] = useState(0);
  const [limit, setLimit] = useState(increaseBy);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState();
  const [disconnectDialog, setDisconnectDialog] = useState(false);
  const connection = useSelector((state) => state.connection);

  useEffect(() => {
    (async () => {
      await fetchConnections({ limit });
      setIsLoading(1);
    })();
  }, []);

  useEffect(() => {
    if (connection?.fetchConnections) {
      (async () => {
        await fetchConnections({ limit });
      })();
    }
  }, [connection]);

  const handleDisconnect = async () => {
    setIsDisconnecting(true);
    await removeConnection(selectedConnection?.uid);
    setIsDisconnecting(false);
  };

  const handleLoadMore = async () => {
    setIsLoading(2);
    await fetchConnections({ limit: limit + increaseBy });
    setLimit(limit + increaseBy);
    setIsLoading(1);
  };

  return (
    <Stack>
      <AlertDialog
        title="Disconnect❓"
        isOpen={disconnectDialog}
        onResolved={handleDisconnect}
        resolveLoading={isDisconnecting}
        onClose={() => {
          setDisconnectDialog(false);
          setTimeout(() => {
            setSelectedConnection();
          }, 500);
        }}
        message={`Are you sure you want to remove ${word.displayName(
          selectedConnection?.firstname,
          selectedConnection?.lastname,
          'this ',
        )} from your network?`}
      />
      <Typography fontSize={18} fontWeight={700}>
        All Connections{' '}
        <span style={{ color: colors.grey[500] }}>({connection?.totalConnections})</span>
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" flexWrap="wrap">
        {isLoading === 0 ? (
          [...Array(limit)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))
        ) : connection?.connections?.length > 0 ? (
          connection?.connections?.map((item) => (
            <UserCard mb={1} key={item?.id} user={item}>
              <Button
                fullWidth
                size="small"
                color="error"
                sx={{ mt: '5px' }}
                variant="outlined"
                startIcon={<PersonRemoveAlt1TwoTone />}
                onClick={() => {
                  setSelectedConnection(item);
                  setDisconnectDialog(true);
                }}>
                Disconnect
              </Button>
            </UserCard>
          ))
        ) : (
          <Typography pb={2} fontSize={14} color={colors.grey[600]}>
            No connections available
          </Typography>
        )}
        {isLoading === 2 &&
          [...Array(increaseBy)].map((_, index) => (
            <Stack mr={1} mb={1} p="5px" key={index} boxShadow={1} bgcolor="#fff" borderRadius={1}>
              <Skeleton
                key={index}
                width={120}
                height={165.75}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
          ))}
      </Stack>
      {connection?.totalConnections > limit && (
        <Stack mt={1} alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleLoadMore}
            loading={isLoading === 2}>
            Load More
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default MyNetworkTab;
