import React from 'react';
import theme from './theme/theme';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { persistor, store } from './store';
import RootRoutes from './routes/RootRoutes';
import ScrollContainer from './ScrollContainer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { SocketProvider } from './SocketContext';
import './styles.css';

const AppRoot = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SocketProvider>
              <ScrollContainer>
                <RootRoutes />
              </ScrollContainer>
            </SocketProvider>
            <Toaster />
          </ThemeProvider>
        </Provider>
      </PersistGate>
    </GoogleOAuthProvider>
  );
};

export default AppRoot;
