import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack, Typography } from '@mui/material';

import PostItem from './PostItem';
import PostSkeleton from './PostSkeleton';
import { fetchFeedPosts } from '../../../api/post';
import { tempActions } from '../../../store/reducers/tempReducer';

const PostsTab = () => {
  const dispatch = useDispatch();
  const feed = useSelector((state) => state.feed);
  const tempState = useSelector((state) => state.temp);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    (async () => {
      setIsLoading(true);
      await fetchFeedPosts({ offset: 0 }, true);
      setIsLoading(false);
    })();
  }, []);

  const loadMorePosts = async () => {
    const offset =
      Math.ceil(feed?.totalFeedPosts / feed?.limit) > feed?.currentPostsOffset + 1
        ? feed?.currentPostsOffset + 1
        : 0;
    await fetchFeedPosts({ offset });
    dispatch(tempActions.controller({ isFetching: false }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    dispatch(tempActions.controller({ isFetching: true }));
    loadMorePosts();
  }, [tempState.shouldLoadMore]);

  return (
    <Stack width="100%" spacing={2} alignItems="center">
      {isLoading && feed?.feedPosts?.length === 0
        ? [...Array(5)].map((_, index) => <PostSkeleton key={index} />)
        : feed?.feedPosts?.map((post, index) => <PostItem key={index} post={post} />)}
      {!isLoading && feed?.feedPosts?.length > 0 && <PostSkeleton />}
      {!isLoading && feed?.feedPosts?.length === 0 && (
        <Typography p={1} fontSize={14} color="textSecondary">
          No posts to show
        </Typography>
      )}
    </Stack>
  );
};

export default PostsTab;
