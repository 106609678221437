import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormHelperText,
  InputAdornment,
  colors,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { brandColors } from '../../constants/colors';
import { signup, loginWithGoogle } from '../../api/auth';
import { USER_HOME, SIGNIN, VERIFY_EMAIL } from '../../constants/router-urls';
import { authFormTitleStyle } from '../../constants/styles';
import { signUpValidationSchema } from '../../validations/auth';

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const resolver = yupResolver(signUpValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-unused-vars
    const { rePassword, ...rest } = data;
    const isSignupSuccessful = await signup(rest);
    if (isSignupSuccessful) {
      navigate(VERIFY_EMAIL, {
        state: { alternateState: 'SIGNUP_SUCCESS', email: rest.email },
      });
    }
  };

  const handleGoogleLogin = async (response) => {
    setIsLoading(true);
    await loginWithGoogle(response.credential);
    setIsLoading(false);
  };

  if (isAuth) {
    return <Navigate to={USER_HOME} replace={true} />;
  }
  return (
    <Stack
      p={2}
      width="100%"
      height="auto"
      minHeight="75vh"
      alignItems="center"
      justifyContent="center">
      <Card sx={{ borderRadius: 4 }}>
        <Stack
          py={3}
          px={2}
          spacing={1}
          bgcolor="#fff"
          component="form"
          onSubmit={handleSubmit(onSubmit)}>
          <Typography {...authFormTitleStyle}>Sign Up</Typography>
          <Stack direction="row" flexWrap="wrap">
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="username"
                label="Username *"
                error={errors?.username}
                {...register('username')}
              />
              <FormHelperText sx={{ color: brandColors.error, maxWidth: 400 }}>
                {errors?.username?.message}
              </FormHelperText>
            </Stack>
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="email"
                label="Email *"
                error={errors?.email}
                {...register('email')}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.email?.message}
              </FormHelperText>
            </Stack>
          </Stack>

          <Stack direction="row" flexWrap="wrap">
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="firstname"
                label="Firstname *"
                error={errors?.firstname}
                {...register('firstname')}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.firstname?.message}
              </FormHelperText>
            </Stack>
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="lastname"
                label="Lastname *"
                error={errors?.lastname}
                {...register('lastname')}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.lastname?.message}
              </FormHelperText>
            </Stack>
          </Stack>

          <Stack direction="row" flexWrap="wrap">
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="password"
                label="Password *"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                error={errors?.password}
                {...register('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: brandColors.error, maxWidth: 400 }}>
                {errors?.password?.message}
              </FormHelperText>
            </Stack>
            <Stack m={1} minWidth={280} flex={1}>
              <TextField
                size="small"
                name="rePassword"
                label="Confirm Password *"
                autoComplete="confirm-password"
                type={showRePassword ? 'text' : 'password'}
                error={errors?.rePassword}
                {...register('rePassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowRePassword(!showRePassword)}>
                        {showRePassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.rePassword?.message}
              </FormHelperText>
            </Stack>
          </Stack>

          <Stack pt={2} px={1}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting || isLoading}>
              Sign Up
            </LoadingButton>
          </Stack>
          <Typography textAlign="center">or</Typography>
          <Stack width="100%" alignItems="center">
            {isLoading || isSubmitting ? (
              <Stack
                spacing={1}
                width="100%"
                direction="row"
                alignSelf="center"
                alignItems="center"
                justifyContent="center">
                <Typography textAlign="center" fontSize={14} color={colors.grey[600]}>
                  Signing {isSubmitting ? 'up' : 'in'} . . .
                </Typography>
              </Stack>
            ) : (
              <GoogleLogin size="large" text="continue_with" onSuccess={handleGoogleLogin} />
            )}
          </Stack>
          <Typography textAlign="center" fontSize="0.8rem" sx={{ pt: 3 }}>
            Already have an account?{' '}
            <span>
              <Link
                to={SIGNIN}
                style={{
                  margin: '10px 0px',
                  textDecoration: 'none',
                  color: brandColors.primary,
                }}>
                Sign in
              </Link>
            </span>
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

export default SignUp;
