import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 10,
  fetchFeedPosts: false,
  fetchFeedArticles: false,
  fetchFeedPublications: false,
  feedPosts: [],
  feedArticles: [],
  feedPublications: [],
  currentPostsOffset: 0,
  currentArticlesOffset: 0,
  currentPublicationsOffset: 0,
  totalFeedPosts: 0,
  totalFeedArticles: 0,
  totalFeedPublications: 0,
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    controller: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFeedPosts: (state, action) => {
      return {
        ...state,
        fetchFeedPosts: false,
        feedPosts: action.payload.data,
        totalFeedPosts: action.payload.totalEntries,
        currentPostsOffset: action.payload.offset || state.currentPostsOffset,
      };
    },
    setFeedArticles: (state, action) => {
      return {
        ...state,
        fetchFeedArticles: false,
        feedArticles: action.payload.data,
        totalFeedArticles: action.payload.totalEntries,
        currentArticlesOffset: action.payload.offset || state.currentArticlesOffset,
      };
    },
    setFeedPublications: (state, action) => {
      return {
        ...state,
        fetchFeedPublications: false,
        feedPublications: action.payload.data,
        totalFeedPublications: action.payload.totalEntries,
        currentPublicationsOffset: action.payload.offset || state.currentPublicationsOffset,
      };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const feedActions = feedSlice.actions;
export default feedSlice.reducer;
