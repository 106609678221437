import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, Stack, Tab, Tabs } from '@mui/material';
import {
  GroupTwoTone,
  PersonOffTwoTone,
  PersonSearchTwoTone,
  PersonAddAlt1TwoTone,
} from '@mui/icons-material';

import MyNetworkTab from './MyNetworkTab';
import TabLabel from '../../widgets/TabLabel';
import BlockedUserTab from './BlockedUsersTab';
import ConnnectionRequestsTab from './ConnectionRequestsTab';
import { USER_CONNECTIONS } from '../../constants/router-urls';
import ConnectionRecommendationsTab from './ConnectionRecommendationsTab';

const ConnectionTabs = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActivetab] = useState(0);

  /* tabs handling logic */
  const tabs = [
    {
      label: 'Discover',
      icon: <PersonSearchTwoTone />,
      element: <ConnectionRecommendationsTab />,
    },
    {
      label: 'Requests',
      icon: <PersonAddAlt1TwoTone />,
      element: <ConnnectionRequestsTab />,
    },
    {
      label: 'My Network',
      icon: <GroupTwoTone />,
      element: <MyNetworkTab />,
    },
    {
      label: 'Blocked',
      icon: <PersonOffTwoTone />,
      element: <BlockedUserTab />,
    },
  ];

  useEffect(() => {
    if (tab) {
      const index = tabs.findIndex(
        (t) => t?.label?.replace(/\s+/g, '-')?.toLowerCase() === tab?.toLowerCase(),
      );
      if (index !== -1) {
        setActivetab(index);
      } else {
        navigate(`${USER_CONNECTIONS}/discover`, { replace: true });
      }
    } else {
      navigate(`${USER_CONNECTIONS}/discover`, { replace: true });
    }
  }, [tab]);
  const currentTab = tabs[activeTab].element;

  const handleTabChange = (event, value) => {
    navigate(`${USER_CONNECTIONS}/${tabs[value]?.label?.replace(/\s+/g, '-')?.toLowerCase()}`);
  };
  /* tabs handling logic */

  return (
    <Stack>
      <Tabs
        value={activeTab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleTabChange}
        sx={{ mb: 2, color: '#fff', bgcolor: colors.grey[800], maxWidth: { xs: '90.5vw' } }}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            sx={{ textTransform: 'capitalize', color: '#fff' }}
            label={<TabLabel startIcon={tab.icon} label={tab.label} />}
          />
        ))}
      </Tabs>
      {currentTab}
    </Stack>
  );
};

export default ConnectionTabs;
