import React from 'react';
import { Stack, Typography } from '@mui/material';

const TabLabel = ({ startIcon, endIcon, topIcon, bottomIcon, label, spacing = 1 }) => {
  return (
    <Stack alignItems="center" spacing={spacing}>
      {topIcon}
      <Stack direction="row" spacing={spacing}>
        {startIcon}
        <Typography>{label}</Typography>
        {endIcon}
      </Stack>
      {bottomIcon}
    </Stack>
  );
};

export default TabLabel;
