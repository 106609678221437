import React, { useEffect, useState } from 'react';
import { Alert, Card, FormHelperText, Stack, TextField, Typography, colors } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { brandColors } from '../../constants/colors';
import { authFormTitleStyle } from '../../constants/styles';
import { Link, Navigate } from 'react-router-dom';
import { SIGNIN, USER_HOME } from '../../constants/router-urls';
import { useForm, useWatch } from 'react-hook-form';
import Button from '../../widgets/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgetPasswordValidationSchema } from '../../validations/auth';
import debounce from '../../utils/debounce';
import { requestPasswordReset } from '../../api/auth';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
  const [isOAuth, setIsOAuth] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [fieldImputValue, setFieldImputValue] = useState('');
  const [requestingResend, setRequestingResend] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const resolver = yupResolver(forgetPasswordValidationSchema);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const usernameOrEmail = useWatch({ name: 'usernameOrEmail', control });

  useEffect(() => {
    if (usernameOrEmail !== fieldImputValue) {
      setIsEmailSent(false);
    } else {
      setIsEmailSent(true);
    }
  }, [usernameOrEmail]);

  const handleResendEmail = async () => {
    const res = await requestPasswordReset({ usernameOrEmail });
    if (res?.status === 200) {
      if (res?.isOAuth) {
        setIsOAuth(true);
        setIsEmailSent(false);
      } else {
        setIsOAuth(false);
        setIsEmailSent(true);
      }
    } else {
      setIsOAuth(false);
      setIsEmailSent(false);
    }
    setRequestingResend(false);
  };

  const onResend = () => {
    setRequestingResend(true);
    debounce(handleResendEmail, 10000)();
  };

  const onSubmit = async (data) => {
    setFieldImputValue(data.usernameOrEmail);
    const res = await requestPasswordReset(data);
    if (res?.status === 200) {
      if (res?.isOAuth) {
        setIsOAuth(true);
        setIsEmailSent(false);
      } else {
        setIsOAuth(false);
        setIsEmailSent(true);
      }
    } else {
      setIsOAuth(false);
      setIsEmailSent(false);
    }
  };

  if (isAuth) {
    return <Navigate to={USER_HOME} replace={true} />;
  }

  return (
    <Stack
      p={2}
      width="100%"
      height="auto"
      minHeight="75vh"
      alignItems="center"
      justifyContent="center">
      <Stack maxWidth={600} width="100%">
        <Card sx={{ borderRadius: 4 }}>
          <Stack
            p={3}
            spacing={1}
            bgcolor="#fff"
            component="form"
            onSubmit={handleSubmit(onSubmit)}>
            <Typography {...authFormTitleStyle}>Forgot Password</Typography>
            {isOAuth && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                Seems like you used your Google account to sign up. Please continue with Google to
                access. If you still face difficulty signing in or lost access to your Google
                account, please contact us.
              </Alert>
            )}
            {isEmailSent && (
              <>
                <Alert severity="success" sx={{ mb: 2 }}>
                  Password reset link has been sent to your email.
                </Alert>
                <Typography mb={2} fontSize={14} color={colors.grey[700]}>
                  Didn&apos;t receive the email yet?
                  <span>
                    <Button
                      size="small"
                      variant="text"
                      sx={{ ml: 0.5 }}
                      loading={requestingResend}
                      onClick={() => handleSubmit(onResend)()}>
                      Resend Email
                    </Button>
                  </span>
                </Typography>
              </>
            )}

            <Stack>
              <TextField
                size="small"
                name="usernameOrEmail"
                label="Username or Email *"
                error={errors?.usernameOrEmail}
                {...register('usernameOrEmail')}
                disabled={isSubmitting || requestingResend}
              />
              <FormHelperText sx={{ color: brandColors.error }}>
                {errors?.usernameOrEmail?.message}
              </FormHelperText>
            </Stack>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isEmailSent && fieldImputValue === usernameOrEmail}>
              Submit
            </LoadingButton>

            <Typography textAlign="center" fontSize="0.8rem" sx={{ pt: 3 }}>
              <span>
                <Link
                  to={SIGNIN}
                  style={{
                    margin: '10px 0px',
                    textDecoration: 'none',
                    color: brandColors.primary,
                  }}>
                  Back to Sign In
                </Link>
              </span>
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
