import React, { useEffect, useState } from 'react';
import {
  colors,
  Divider,
  Stack,
  Typography,
  Skeleton,
  Tooltip,
  Drawer,
  Box,
  Menu,
  Chip,
} from '@mui/material';

import Avatar from '../../../widgets/Avatar';
import {
  AllInclusiveTwoTone,
  BusinessCenterTwoTone,
  CancelTwoTone,
  DoneAllTwoTone,
  DriveFileRenameOutlineTwoTone,
  EmailTwoTone,
  KeyboardArrowDownTwoTone,
  KeyboardArrowUpTwoTone,
  LockOpenTwoTone,
  LockTwoTone,
  PersonAddAlt1TwoTone,
  PersonAddDisabledTwoTone,
  PersonOffTwoTone,
  PersonOutlineTwoTone,
  PersonRemoveAlt1TwoTone,
  PersonSearchTwoTone,
  PersonTwoTone,
  PhoneTwoTone,
  SchoolTwoTone,
} from '@mui/icons-material';
import Button from '../../../widgets/Button';
import { useParams } from 'react-router-dom';
import UserProfileForm from './UserProfileForm';
import { fetchProfile } from '../../../api/profile';
import {
  blockUser,
  unblockUser,
  removeConnection,
  sendConnectionRequest,
  acceptConnectionRequest,
  cancelConnectionRequest,
  rejectConnectionRequest,
} from '../../../api/connection';
import word from '../../../utils/word';
import { useSelector } from 'react-redux';
import AlertDialog from '../../global/AlertDialog';

const UserBioSkeleton = () => {
  return (
    <Stack
      p={2}
      spacing={2}
      width="100%"
      color="#fff"
      bgcolor={colors.grey[800]}
      borderRadius="4px 4px 0 0"
      justifyContent="space-between">
      <Stack spacing={1}>
        <Skeleton
          width={100}
          height={100}
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: 1, bgcolor: colors.grey[400] }}
        />
        <Stack>
          <Skeleton
            width={180}
            height={32}
            variant="text"
            animation="wave"
            sx={{ bgcolor: colors.grey[400] }}
          />
          <Skeleton
            width={280}
            height={18}
            variant="text"
            animation="wave"
            sx={{ bgcolor: colors.grey[400] }}
          />
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: '#fff' }} />
      <Stack alignItems="end">
        <Skeleton
          width="60%"
          height={16}
          variant="text"
          animation="wave"
          sx={{ bgcolor: colors.grey[400] }}
        />
        <Skeleton
          width="80%"
          height={16}
          variant="text"
          animation="wave"
          sx={{ bgcolor: colors.grey[400] }}
        />
        <Skeleton
          width="35%"
          height={16}
          variant="text"
          animation="wave"
          sx={{ bgcolor: colors.grey[400] }}
        />
      </Stack>
    </Stack>
  );
};

const UserBio = ({ isAuth, disableTabs }) => {
  const { username } = useParams();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [blockDialog, setBlockDialog] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [disconnectDialog, setDisconnectDialog] = useState(false);
  const [isHandlingRequest, setIsHandlingRequest] = useState('');
  const [editProfileDrawer, setEditProfileDrawer] = useState(false);
  const connection = useSelector((state) => state.connection);

  const fetchUserProfile = async () => {
    const res = await fetchProfile(username);
    if (res?.status === 200) {
      setUserData(res?.user);
      disableTabs(false);
    } else {
      setUserData();
      disableTabs(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (username) {
      fetchUserProfile();
    }
  }, [username]);

  useEffect(() => {
    if (connection?.fetchUserProfile) {
      fetchUserProfile();
    }
  }, [connection]);

  const [respondOptionsAnchorEl, setRespondOptionsAnchorEl] = useState(null);
  const respondOptionsOpen = Boolean(respondOptionsAnchorEl);
  const handleRespondOptions = (event) => setRespondOptionsAnchorEl(event.currentTarget);
  const handleRespondOptionsClose = () => setRespondOptionsAnchorEl(null);

  const displayName = (salutation, firstname, middlename, lastname) => {
    let name = '';
    if (salutation) {
      name += salutation + ' ';
    }
    if (firstname) {
      name += firstname;
      if (middlename) {
        name += ' ' + middlename;
      }
      if (lastname) {
        name += ' ' + lastname;
      }
    } else if (middlename) {
      name += middlename;
      if (lastname) {
        name += ' ' + lastname;
      }
    } else if (lastname) {
      name += lastname;
    } else {
      name += 'ISBM Member';
    }
    return name;
  };

  const membershipTypeColors = {
    STUDENT: { bgcolor: colors.grey[700], color: '#fff' },
    'POST-DOC': { bgcolor: colors.yellow[700], color: colors.grey[800] },
    GENERAL: { bgcolor: colors.blue[700], color: '#fff' },
    LIFE: { bgcolor: colors.green[700], color: '#fff' },
  };

  const renderOccupation = () => {
    switch (userData?.profile?.occupation) {
      case 'Student':
        return (
          <Stack direction="row" spacing={0.5} alignItems="start">
            <SchoolTwoTone sx={{ pb: 0.5 }} />
            <Typography fontSize={14} fontWeight={500}>
              Studies {userData?.profile?.designation + ' at ' + userData?.profile?.organization}
            </Typography>
          </Stack>
        );
      case 'Employed':
        return (
          <Stack direction="row" spacing={0.5} alignItems="start">
            <BusinessCenterTwoTone sx={{ pb: 0.5 }} />
            <Typography fontSize={14} fontWeight={500}>
              {userData?.profile?.designation + ' at ' + userData?.profile?.organization}
            </Typography>
          </Stack>
        );
      case 'Retired':
        return (
          <Stack direction="row" spacing={0.5} alignItems="start">
            <AllInclusiveTwoTone sx={{ pb: 0.5 }} />
            <Typography fontSize={14} fontWeight={500}>
              Retired {userData?.profile?.designation + ' from ' + userData?.profile?.organization}
            </Typography>
          </Stack>
        );
      default:
        return null;
    }
  };

  const handleSendRequest = async () => {
    setIsRequesting(true);
    await sendConnectionRequest(userData?.uid);
    setIsRequesting(false);
  };

  const handleCancelRequest = async () => {
    setIsRequesting(true);
    await cancelConnectionRequest(userData?.uid);
    setIsRequesting(false);
  };

  const handleAccept = async () => {
    handleRespondOptionsClose();
    setIsHandlingRequest('accepting');
    await acceptConnectionRequest(userData?.uid);
    setIsHandlingRequest('');
  };

  const handleReject = async () => {
    handleRespondOptionsClose();
    setIsHandlingRequest('rejecting');
    await rejectConnectionRequest(userData?.uid);
    setIsHandlingRequest('');
  };

  const handleDisconnect = async () => {
    setIsDisconnecting(true);
    await removeConnection(userData?.uid);
    setIsDisconnecting(false);
  };

  const handleBlock = async () => {
    setIsBlocking(true);
    await blockUser(userData?.uid);
    setIsBlocking(false);
  };

  const handleUnblock = async () => {
    setIsBlocking(true);
    await unblockUser(userData?.uid);
    setIsBlocking(false);
  };

  return loading ? (
    <UserBioSkeleton />
  ) : !userData ? (
    <Stack p={2} spacing={1} alignItems="center">
      <PersonSearchTwoTone sx={{ fontSize: 60 }} />
      <Typography fontSize={15} fontWeight={600} color={colors.grey[500]} textAlign="center">
        User not found!
      </Typography>
    </Stack>
  ) : (
    <Stack
      p={2}
      spacing={2}
      width="100%"
      color="#fff"
      overflow="hidden"
      bgcolor={colors.grey[800]}
      borderRadius="4px 4px 0 0"
      justifyContent="space-between">
      <Drawer anchor="bottom" open={editProfileDrawer}>
        <UserProfileForm
          data={userData?.profile}
          onRefresh={fetchUserProfile}
          onClose={() => setEditProfileDrawer(false)}
        />
      </Drawer>

      <Stack spacing={1}>
        <Stack width="100%" direction="row" alignItems="start" justifyContent="space-between">
          <Avatar
            size={100}
            src={userData?.profile?.image}
            label={word.displayInitials(userData?.profile?.firstname, userData?.profile?.lastname)}
          />
          {isAuth && (
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{ minWidth: 'fit-content' }}
              onClick={() => setEditProfileDrawer(true)}
              startIcon={<DriveFileRenameOutlineTwoTone />}>
              Edit profile
            </Button>
          )}
        </Stack>
        <Stack alignItems="start">
          <Typography
            width="100%"
            lineHeight={1.1}
            overflow="hidden"
            fontSize={{ xs: 22, sm: 24, md: 24 }}
            fontWeight={{ xs: 600, sm: 700, md: 700 }}>
            {displayName(
              userData?.profile?.salutation,
              userData?.profile?.firstname,
              userData?.profile?.middlename,
              userData?.profile?.lastname,
            )}
          </Typography>
          <Chip
            size="small"
            label={`${word.capitalize(userData?.membershipType)} Member`}
            sx={{ my: '5px', ...membershipTypeColors[userData?.membershipType] }}
          />
          {isAuth && (
            <Typography mb={0.8} fontSize={13} fontWeight={400} color={colors.grey[300]}>
              #{userData?.serialNumber}
            </Typography>
          )}
          {renderOccupation()}
        </Stack>
      </Stack>

      {isExpanded && (
        <Stack>
          {(userData?.profile?.addressLine1 || userData?.profile?.addressLine2) && (
            <>
              <Typography fontSize={15} fontWeight={600}>
                Address
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                {userData?.profile?.addressLine1 || ''}
                {userData?.profile?.addressLine2 ? ', ' + userData?.profile?.addressLine2 : ''}
                {userData?.profile?.city ? ', ' + userData?.profile?.city : ''}
                {userData?.profile?.state ? ', ' + userData?.profile?.state : ''}
                {userData?.profile?.country ? ', ' + userData?.profile?.country : ''}
                {userData?.profile?.zipCode ? ', ' + userData?.profile?.zipCode : ''}
              </Typography>
            </>
          )}
          <Typography mt={1} fontSize={15} fontWeight={600}>
            Contact Informaton
          </Typography>
          <Stack
            component="a"
            spacing={0.5}
            direction="row"
            alignItems="center"
            href={`mailto:${userData?.profile?.email}`}
            sx={{ textDecoration: 'none', color: '#fff' }}>
            <EmailTwoTone sx={{ pb: 0.6 }} />
            <Typography fontSize={13} fontWeight={500}>
              {userData?.profile?.email || ''}
            </Typography>
          </Stack>
          {userData?.profile?.contact && (isAuth || userData?.flags?.isContactVisible) && (
            <Stack direction="row" alignItems="center" sx={{ color: '#fff' }}>
              <Stack
                component="a"
                spacing={0.5}
                direction="row"
                alignItems="center"
                href={`tel:${userData?.profile?.contact}`}
                sx={{ textDecoration: 'none', color: '#fff' }}>
                <PhoneTwoTone sx={{ pb: 0.6 }} />
                <Typography fontSize={13} fontWeight={500}>
                  {userData?.profile?.contact || ''}
                </Typography>
              </Stack>
              {isAuth ? (
                userData?.flags?.isContactVisible ? (
                  <Tooltip arrow placement="bottom" title="visible to members of ISBM">
                    <LockOpenTwoTone sx={{ py: 0.75 }} />
                  </Tooltip>
                ) : (
                  <Tooltip arrow placement="bottom" title="only you can see this">
                    <LockTwoTone sx={{ py: 0.75 }} />
                  </Tooltip>
                )
              ) : null}
            </Stack>
          )}
        </Stack>
      )}
      <Stack alignItems="end" mt={isExpanded ? 1 : 0}>
        <Button
          size="small"
          variant="text"
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{ minWidth: 'fit-content', color: '#fff' }}
          endIcon={isExpanded ? <KeyboardArrowUpTwoTone /> : <KeyboardArrowDownTwoTone />}>
          Show {isExpanded ? 'less' : 'more'}
        </Button>
      </Stack>

      {userData?.profile?.bio && <Divider sx={{ borderColor: '#fff' }} />}
      {userData?.profile?.bio && (
        <Box width="85%" minWidth={300} alignSelf="end">
          <Typography
            fontSize={12}
            fontWeight={400}
            textAlign="right"
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
            {userData?.profile?.bio || ''}
          </Typography>
        </Box>
      )}
      {!isAuth && <Divider sx={{ borderColor: '#fff' }} />}
      {!isAuth && (
        <Stack direction="row" alignItems="center">
          <AlertDialog
            title="Disconnect❓"
            isOpen={disconnectDialog}
            onResolved={handleDisconnect}
            resolveLoading={isDisconnecting}
            onClose={() => setDisconnectDialog()}
            message={`Are you sure you want to remove ${word.displayName(
              userData?.firstname,
              userData?.lastname,
              'this',
            )} from your network?`}
          />
          {!userData?.isBlocked &&
            (userData?.isConnected ? (
              <Button
                fullWidth
                size="small"
                color="error"
                sx={{ mt: '5px' }}
                variant="contained"
                loading={isDisconnecting}
                onClick={() => setDisconnectDialog(true)}
                startIcon={<PersonRemoveAlt1TwoTone />}>
                Disconnect
              </Button>
            ) : userData?.hasRequested ? (
              <>
                <Button
                  fullWidth
                  size="small"
                  color="secondary"
                  sx={{ mt: '5px' }}
                  variant="contained"
                  onClick={handleRespondOptions}
                  startIcon={<PersonOutlineTwoTone />}>
                  Respond
                </Button>
                <Menu
                  open={respondOptionsOpen}
                  anchorEl={respondOptionsAnchorEl}
                  onClose={handleRespondOptionsClose}>
                  <Stack px={2} spacing={2} alignItems="start">
                    <Button
                      fullWidth
                      size="small"
                      color="success"
                      sx={{ mt: '5px' }}
                      onClick={handleAccept}
                      startIcon={<DoneAllTwoTone />}
                      loading={isHandlingRequest === 'accepting'}>
                      Accept Request
                    </Button>
                    <Button
                      fullWidth
                      size="small"
                      color="error"
                      sx={{ mt: '5px' }}
                      onClick={handleReject}
                      startIcon={<CancelTwoTone />}
                      loading={isHandlingRequest === 'rejecting'}>
                      Reject Request
                    </Button>
                  </Stack>
                </Menu>
              </>
            ) : (
              <Button
                fullWidth
                size="small"
                sx={{ mt: '5px' }}
                variant="contained"
                loading={isRequesting}
                color={userData?.isRequested ? 'secondary' : 'primary'}
                onClick={userData?.isRequested ? handleCancelRequest : handleSendRequest}
                startIcon={
                  userData?.isRequested ? <PersonAddDisabledTwoTone /> : <PersonAddAlt1TwoTone />
                }>
                {userData?.isRequested ? 'Cancel Request' : 'Send Request'}
              </Button>
            ))}
          {!userData?.isBlocked && <Box px={0.5} />}
          <AlertDialog
            isOpen={blockDialog}
            resolveLoading={isBlocking}
            onClose={() => setBlockDialog(false)}
            title={`${userData?.isBlocked ? 'Unblock' : 'Block'}❓`}
            onResolved={userData?.isBlocked ? handleUnblock : handleBlock}
            message={`Are you sure you want to ${
              userData?.isBlocked ? 'unblock' : 'block'
            } ${word.displayName(userData?.firstname, userData?.lastname, 'this')}? ${
              userData?.isBlocked
                ? ''
                : 'This will remove your existing connection or pending request with the user.'
            }`}
          />
          <Button
            fullWidth
            size="small"
            sx={{ mt: '5px' }}
            variant="contained"
            onClick={() => setBlockDialog(true)}
            color={userData?.isBlocked ? 'warning' : 'secondary'}
            disabled={isRequesting || isHandlingRequest || isDisconnecting}
            startIcon={userData?.isBlocked ? <PersonTwoTone /> : <PersonOffTwoTone />}>
            {userData?.isBlocked ? 'Unblock User' : 'Block User'}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default UserBio;
