import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { routes } from '../constants/routes';
import NotFound from '../pages/global/NotFound';
import PublicLayout from '../layouts/PublicLayout';
import PrivateLayout from '../layouts/PrivateLayout';
import PublicPage from '../components/global/PublicPage';
import ScrollToTop from '../components/global/ScrollToTop';
import ProtectedPage from '../components/global/ProtectedPage';

const RootRoutes = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const resetScroll = () => {
    let containerElement;
    try {
      containerElement = document?.getElementsByClassName('app-scroll-container')?.[0];
      if (containerElement) {
        containerElement.scrollTop = 0;
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    resetScroll();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {routes.map((route) => {
          const { path, Element, isProtected, isShared, isIndexUrl, disableSideNav, rightFeed } =
            route;
          if (isProtected) {
            return (
              <Route
                key={path}
                element={
                  <PrivateLayout
                    seo={route?.seo}
                    rightFeed={rightFeed}
                    disableSideNav={disableSideNav}
                  />
                }>
                <Route
                  path={path}
                  index={isIndexUrl}
                  element={
                    <ProtectedPage flagCheck={route?.flagCheck}>
                      <Element />
                    </ProtectedPage>
                  }
                />
              </Route>
            );
          } else if (isShared) {
            return (
              <Route
                key={path}
                element={
                  isAuth ? (
                    <PrivateLayout
                      seo={route?.seo}
                      rightFeed={rightFeed}
                      disableSideNav={disableSideNav}
                    />
                  ) : (
                    <PublicLayout seo={route?.seo} />
                  )
                }>
                <Route
                  path={path}
                  index={isIndexUrl}
                  element={
                    isAuth ? (
                      <ProtectedPage flagCheck={route?.flagCheck}>
                        <Element />
                      </ProtectedPage>
                    ) : (
                      <PublicPage>
                        <Element />
                      </PublicPage>
                    )
                  }
                />
              </Route>
            );
          } else {
            return (
              <Route element={<PublicLayout seo={route?.seo} />} key={path}>
                <Route
                  index={isIndexUrl}
                  path={path}
                  element={
                    <PublicPage>
                      <Element />
                    </PublicPage>
                  }
                />
              </Route>
            );
          }
        })}
        <Route element={isAuth ? <PrivateLayout /> : <PublicLayout />}>
          <Route path="*" element={<NotFound isAuth={isAuth} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoutes;
