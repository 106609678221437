import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { tempActions } from '../../../store/reducers/tempReducer';
import ArticleSkeleton from '../user-feed/ArticleSkeleton';
import ArticleItem from '../user-feed/ArticleItem';
import { fetchProfile } from '../../../api/profile';

const TimelineArticlesTab = ({ username }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [caughtUp, setCaughtUp] = useState(false);
  const tempState = useSelector((state) => state.temp);
  const timeline = useSelector((state) => state.timeline);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [query, setQuery] = useState({ offset: 0, limit: 10, filter: 'ARTICLES' });

  const fetchArticles = async (reset) => {
    await fetchProfile(username, 'timeline', query, reset);
    dispatch(tempActions.controller({ isFetching: false }));
    setLoading(false);
  };

  useEffect(() => {
    dispatch(tempActions.controller({ shouldLoadMore: false }));
    fetchArticles(true);
    setIsInitialMount(false);

    return () => setIsInitialMount(true);
  }, []);

  useEffect(() => {
    if (isInitialMount) return;
    dispatch(tempActions.controller({ isFetching: true }));
    fetchArticles();
  }, [query]);

  const loadMoreArticles = () => {
    const reachedEnd =
      timeline?.totalTimelineArticles / query?.limit > timeline?.currentArticlesOffset + 1
        ? false
        : true;
    if (reachedEnd) {
      setCaughtUp(true);
      dispatch(tempActions.controller({ isFetching: false }));
      return;
    }
    setQuery((prev) => ({ ...prev, offset: timeline?.currentArticlesOffset + 1 }));
  };

  useEffect(() => {
    if (!tempState.shouldLoadMore) return;
    loadMoreArticles();
  }, [tempState.shouldLoadMore]);

  return (
    <Stack width="100%" pt={2} spacing={2} alignItems="center">
      {loading && timeline?.timelineArticles?.length === 0
        ? [...Array(5)].map((_, index) => <ArticleSkeleton key={index} />)
        : timeline?.timelineArticles?.map((article, index) => (
            <ArticleItem key={index} article={article} from="timeline" />
          ))}
      {caughtUp && timeline?.timelineArticles?.length > 0 ? (
        <Typography p={1} fontSize={14} color="textSecondary">
          You&apos;re all caught up
        </Typography>
      ) : (
        !loading && timeline?.timelineArticles?.length > 0 && <ArticleSkeleton />
      )}
      {!loading && timeline?.timelineArticles?.length === 0 && (
        <Typography p={1} fontSize={14} color="textSecondary">
          No articles found
        </Typography>
      )}
    </Stack>
  );
};

export default TimelineArticlesTab;
