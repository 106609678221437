import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import HelperText from '../../../widgets/HelperText';
import { editFullProfileValidationSchema } from '../../../validations/profile';
import { AddPhotoAlternateTwoTone, DeleteTwoTone } from '@mui/icons-material';
import { deleteFileByUrl, uploadFile } from '../../../api/file';
import { createProfile } from '../../../api/profile';
import Button from '../../../widgets/Button';

const UserProfileForm = ({ data, onRefresh, onClose }) => {
  const [file, setFile] = useState();
  const [focus, setFocus] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageMode, setImageMode] = useState('GLOBAL');
  const [removeImage, setRemoveImage] = useState(false);

  const resolver = yupResolver(editFullProfileValidationSchema);
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImageMode('LOCAL');
    } else {
      setImageMode('GLOBAL');
    }
  };

  const handleImageRemove = () => {
    setFile(null);
    setImageMode('LOCAL');
    setRemoveImage(true);
  };

  const onSubmit = async (formdata) => {
    setLoading(true);
    const {
      bio,
      salutation,
      firstname,
      middlename,
      lastname,
      email,
      occupation,
      organization,
      designation,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      contact,
    } = formdata;
    let image = data.image;

    if (removeImage) {
      const fileRemoved = await deleteFileByUrl(data.image);
      if (fileRemoved) {
        image = null;
      }
    }

    if (file) {
      const result = await uploadFile(file, 'image');
      if (result?.url) {
        image = result.url;
        await deleteFileByUrl(data.image);
      }
    }

    const formData = {
      bio,
      salutation,
      firstname,
      middlename,
      lastname,
      email,
      occupation,
      organization,
      designation,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      contact,
      image,
    };
    const isProfileUpdated = await createProfile(formData, true);
    if (isProfileUpdated) {
      await onRefresh();
      onClose();
    }
    setLoading(false);
  };

  return (
    <Stack
      pb={3}
      w="100vw"
      height="100vh"
      bgcolor="#fff"
      overflow="auto"
      component="form"
      alignItems="center"
      position="relative"
      onSubmit={handleSubmit(onSubmit)}>
      <Stack
        px={2}
        pt={2}
        top={0}
        left={0}
        zIndex={5}
        spacing={2}
        width="100%"
        bgcolor="#fff"
        position="fixed">
        <Stack direction="row" justifyContent="end" spacing={1}>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            Close
          </Button>
          <Button type="submit" variant="contained" loading={loading}>
            Save Changes
          </Button>
        </Stack>
        <Divider />
      </Stack>

      {/* Profile Image */}
      <Box pt={12}>
        <Stack width="100%" alignItems="center" justifyContent="center">
          <Box position="relative">
            <Box
              zIndex={1}
              width={320}
              height={320}
              component="img"
              borderRadius={2}
              border="2px solid rgba(0,0,0,0.2)"
              src={
                imageMode === 'GLOBAL'
                  ? data?.image || '/assets/images/avatar.png'
                  : imageMode === 'LOCAL'
                  ? file
                    ? URL.createObjectURL(file)
                    : '/assets/images/avatar.png'
                  : '/assets/images/avatar.png'
              }
            />
            <Stack
              spacing={1}
              sx={{
                top: 0,
                opacity: 0,
                width: '320px',
                height: '320px',
                borderRadius: 2,
                cursor: 'pointer',
                alignItems: 'center',
                position: 'absolute',
                justifyContent: 'center',
                transition: '0.2s ease',
                bgcolor: 'rgba(0,0,0,0.6)',
                ':hover': { opacity: 1 },
              }}>
              <Button
                variant="outlined"
                startIcon={<AddPhotoAlternateTwoTone />}
                sx={{ bgcolor: 'rgba(255,255,255,0.7)', ':hover': { bgcolor: '#fff' } }}
                onClick={() => document.getElementById('hiddenFileInput')?.click()}>
                change
              </Button>
              <Button
                color="error"
                variant="outlined"
                onClick={handleImageRemove}
                startIcon={<DeleteTwoTone />}
                sx={{ bgcolor: 'rgba(255,255,255,0.7)', ':hover': { bgcolor: '#fff' } }}>
                Remove
              </Button>
            </Stack>
          </Box>
        </Stack>

        <input
          type="file"
          id="hiddenFileInput"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </Box>

      <Stack
        mx="auto"
        spacing={2}
        sx={{ mt: 1 }}
        alignItems="center"
        justifyContent="center"
        width={{ md: 650, xs: 310 }}>
        <FormControl fullWidth error={errors?.bio?.message}>
          <TextField
            rows={1}
            multiline
            label="Bio"
            size="small"
            variant="standard"
            {...register('bio')}
            onBlur={() => setFocus('')}
            error={errors?.bio?.message}
            onFocus={() => setFocus('bio')}
            placeholder="Hi, I'm a member of ISBM."
            InputLabelProps={{ shrink: watchAllFields.bio || focus === 'bio' }}
          />
          <HelperText>{errors?.bio?.message}</HelperText>
        </FormControl>
      </Stack>

      {/* Basic Information */}
      <Stack
        mt={2}
        mx="auto"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        width={{ md: 650, xs: 310 }}>
        <Typography fontSize={18} fontWeight={500}>
          Basic Information
        </Typography>
        <Stack direction="row" spacing={2} width="100%">
          <FormControl sx={{ width: 120 }} error={errors?.salutation?.message}>
            <InputLabel size="small" error={errors?.salutation?.message}>
              Prefix
            </InputLabel>
            <Select
              size="small"
              label="Prefix"
              {...register('salutation')}
              onBlur={() => setFocus('')}
              defaultValue={data?.salutation}
              error={errors?.salutation?.message}
              onFocus={() => setFocus('salutation')}
              InputLabelProps={{ shrink: watchAllFields.salutation || focus === 'salutation' }}>
              <MenuItem value="Dr.">Dr.</MenuItem>
              <MenuItem value="Mr.">Mr.</MenuItem>
              <MenuItem value="Ms.">Ms.</MenuItem>
              <MenuItem value="Mrs.">Mrs.</MenuItem>
            </Select>
            <HelperText allowOverflow={!errors?.firstname?.message}>
              {errors?.salutation?.message}
            </HelperText>
          </FormControl>

          <FormControl fullWidth error={errors?.firstname?.message}>
            <TextField
              size="small"
              label="First Name"
              placeholder="John/Jane"
              {...register('firstname')}
              onBlur={() => setFocus('')}
              error={errors?.firstname?.message}
              onFocus={() => setFocus('firstname')}
              InputLabelProps={{ shrink: watchAllFields.firstname || focus === 'firstname' }}
            />
            <HelperText>{errors?.firstname?.message}</HelperText>
          </FormControl>
        </Stack>

        <FormControl fullWidth error={errors?.middlename?.message}>
          <TextField
            size="small"
            label="Middle Name"
            placeholder="Michael/Emily"
            {...register('middlename')}
            error={errors?.middlename?.message}
            onBlur={() => setFocus('')}
            onFocus={() => setFocus('middlename')}
            InputLabelProps={{ shrink: watchAllFields.middlename || focus === 'middlename' }}
          />
          <HelperText>{errors?.middlename?.message}</HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.lastname?.message}>
          <TextField
            size="small"
            label="Last Name"
            placeholder="Doe"
            {...register('lastname')}
            onBlur={() => setFocus('')}
            error={errors?.lastname?.message}
            onFocus={() => setFocus('lastname')}
            InputLabelProps={{ shrink: watchAllFields.lastname || focus === 'lastname' }}
          />
          <HelperText>{errors?.lastname?.message}</HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.occupation?.message}>
          <InputLabel size="small" error={errors?.occupation?.message}>
            Occupation
          </InputLabel>
          <Select
            size="small"
            label="Occupation"
            {...register('occupation')}
            onBlur={() => setFocus('')}
            defaultValue={data?.occupation}
            error={errors?.occupation?.message}
            onFocus={() => setFocus('occupation')}
            InputLabelProps={{ shrink: watchAllFields.occupation || focus === 'occupation' }}>
            <MenuItem value="Student">Student</MenuItem>
            <MenuItem value="Employed">Employed</MenuItem>
            <MenuItem value="Retired">Retired</MenuItem>
          </Select>
          <HelperText>{errors?.occupation?.message}</HelperText>
        </FormControl>

        <Stack direction="row" spacing={2} width="100%">
          <FormControl fullWidth error={errors?.organization?.message}>
            <TextField
              size="small"
              placeholder="XYZ Institute"
              onBlur={() => setFocus('')}
              {...register('organization')}
              error={errors?.organization?.message}
              onFocus={() => setFocus('organization')}
              label={watchAllFields?.occupation === 'Student' ? 'Institution' : 'Organization'}
              InputLabelProps={{ shrink: watchAllFields.organization || focus === 'organization' }}
            />
            <HelperText allowOverflow={!errors?.designation?.message}>
              {errors?.organization?.message}
            </HelperText>
          </FormControl>

          <FormControl fullWidth error={errors?.designation?.message}>
            <TextField
              size="small"
              {...register('designation')}
              onBlur={() => setFocus('')}
              error={errors?.designation?.message}
              onFocus={() => setFocus('designation')}
              label={watchAllFields?.occupation === 'Student' ? 'Major' : 'Designation'}
              InputLabelProps={{ shrink: watchAllFields.designation || focus === 'designation' }}
              placeholder={
                watchAllFields?.occupation === 'Student' ? 'Microbiology' : 'Research Associate'
              }
            />
            <HelperText>{errors?.designation?.message}</HelperText>
          </FormControl>
        </Stack>
      </Stack>

      <Stack sx={{ my: 2 }} width={{ md: 650, xs: 310 }}>
        <Divider />
      </Stack>

      {/* Contact Information */}
      <Stack
        mx="auto"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        width={{ md: 650, xs: 310 }}>
        <Typography fontSize={18} fontWeight={500}>
          Contact Information
        </Typography>
        <FormControl fullWidth error={errors?.addressLine1?.message}>
          <TextField
            size="small"
            label="Address"
            onBlur={() => setFocus('')}
            placeholder="Street Address"
            {...register('addressLine1')}
            error={errors?.addressLine1?.message}
            onFocus={() => setFocus('addressLine1')}
            InputLabelProps={{ shrink: watchAllFields.addressLine1 || focus === 'addressLine1' }}
          />
          <HelperText>{errors?.addressLine1?.message}</HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.addressLine2?.message}>
          <TextField
            size="small"
            label="Address Line 2"
            onBlur={() => setFocus('')}
            {...register('addressLine2')}
            error={errors?.addressLine2?.message}
            onFocus={() => setFocus('addressLine2')}
            placeholder="Street Address Line 2 (optional)"
            InputLabelProps={{ shrink: watchAllFields.addressLine2 || focus === 'addressLine2' }}
          />
          <HelperText>{errors?.addressLine2?.message}</HelperText>
        </FormControl>

        <Stack direction="row" spacing={2} width="100%">
          <FormControl fullWidth error={errors?.city?.message}>
            <TextField
              size="small"
              label="City"
              {...register('city')}
              placeholder="Montana City"
              onBlur={() => setFocus('')}
              error={errors?.city?.message}
              onFocus={() => setFocus('city')}
              InputLabelProps={{ shrink: watchAllFields.city || focus === 'city' }}
            />
            <HelperText allowOverflow={!errors?.state?.message}>{errors?.city?.message}</HelperText>
          </FormControl>

          <FormControl fullWidth error={errors?.state?.message}>
            <TextField
              size="small"
              placeholder="Montana"
              label="State/Province"
              {...register('state')}
              onBlur={() => setFocus('')}
              error={errors?.state?.message}
              onFocus={() => setFocus('state')}
              InputLabelProps={{ shrink: watchAllFields.state || focus === 'state' }}
            />
            <HelperText>{errors?.state?.message}</HelperText>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2} width="100%">
          <FormControl fullWidth error={errors?.zipCode?.message}>
            <TextField
              size="small"
              placeholder="AM59634"
              label="Postal/Zip Code"
              {...register('zipCode')}
              onBlur={() => setFocus('')}
              error={errors?.zipCode?.message}
              onFocus={() => setFocus('zipCode')}
              InputLabelProps={{ shrink: watchAllFields.zipCode || focus === 'zipCode' }}
            />
            <HelperText allowOverflow={!errors?.country?.message}>
              {errors?.zipCode?.message}
            </HelperText>
          </FormControl>

          <FormControl fullWidth error={errors?.country?.message}>
            <TextField
              size="small"
              label="Country"
              {...register('country')}
              placeholder="United States"
              onBlur={() => setFocus('')}
              error={errors?.country?.message}
              onFocus={() => setFocus('country')}
              InputLabelProps={{ shrink: watchAllFields.country || focus === 'country' }}
            />
            <HelperText>{errors?.country?.message}</HelperText>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserProfileForm;
