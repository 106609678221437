import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { membershipTypes } from '../../../constants/membership-type';
import Button from '../../../widgets/Button';

const MembershipType = ({ data, setData, next, back, isLoading }) => {
  const [country, setCountry] = useState('');
  const tempState = useSelector((state) => state.temp);

  useEffect(() => {
    fetch(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const userCountry = data.country_name;
        if (userCountry?.toUpperCase() === 'BANGLADESH') {
          setCountry(userCountry?.toUpperCase());
          if (tempState?.membershipType) {
            setData({
              membershipType: membershipTypes[userCountry?.toUpperCase()].find(
                (item) => item.type === tempState?.membershipType,
              ),
            });
          }
          if (tempState?.membershipType) {
            setData({
              membershipType: membershipTypes['OTHERS'].find(
                (item) => item.type === tempState?.membershipType,
              ),
            });
          }
        } else {
          setCountry('OTHERS');
          if (tempState?.membershipType) {
            setData({
              membershipType: membershipTypes['OTHERS'].find(
                (item) => item.type === tempState?.membershipType,
              ),
            });
          }
        }
      })
      .catch((error) => {
        console.error('Error getting location:', error);
        setCountry('OTHERS');
      });
  }, []);

  const handleMembershipTypeSelect = (membershipType) =>
    setData({ membershipType: membershipType });

  return (
    <Box>
      {!country ? (
        <Stack p={5} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack
          mt={10}
          width="100%"
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center">
          {membershipTypes[country].map((membershipType) => (
            <Stack
              p={2}
              m={1}
              width={300}
              minHeight={340}
              borderRadius={2}
              alignItems="center"
              key={membershipType.type}
              justifyContent="space-between"
              sx={{
                color: '#272739',
                cursor: 'pointer',
                textAlign: 'center',
                backgroundColor: '#e8e8e8',
                transition: 'all 0.1s ease',
                '&:hover': { backgroundColor: '#4562f7', color: '#fff' },
                border:
                  data?.membershipType.type === membershipType.type ? '3px solid #4562f7' : 'unset',
              }}
              onClick={() => handleMembershipTypeSelect(membershipType)}>
              <Stack alignItems="center">
                <Box width={50} component="img" src="/assets/images/award.png" borderRadius={2} />
                <Typography variant="h6" fontWeight={700}>
                  {membershipType.title}
                </Typography>
                <Typography fontSize="0.9rem">{membershipType.description}</Typography>
              </Stack>
              <Typography variant="h3" fontWeight={700} pb={2}>
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(membershipType.price)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack mt={10} direction="row" spacing={1} justifyContent="center">
        <Button variant="outlined" onClick={back}>
          Back
        </Button>
        <Button variant="contained" onClick={next} disabled={!data || !country} loading={isLoading}>
          Proceed to Payment
        </Button>
      </Stack>
    </Box>
  );
};

export default MembershipType;
