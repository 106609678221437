import request from './request';

export const uploadFile = async (file, field) => {
  const formData = new FormData();
  formData.append(field, file, file.name);
  const response = await request.postWithFile('/files', formData);
  if (response?.status === 200) {
    return response?.data || null;
  } else {
    return null;
  }
};

export const updateFile = async (uid, file, field) => {
  const formData = new FormData();
  formData.append(field, file, file.name);
  const response = await request.patchWithFile(`/files/${uid}`, formData);
  if (response?.status === 200) {
    return response?.data || null;
  } else {
    return null;
  }
};

export const deleteFile = async (uid) => {
  const response = await request.delete(`/files/${uid}`);
  if (response?.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const deleteFileByUrl = async (url) => {
  const response = await request.post('/files/delete-by-url', { url });
  if (response?.status === 200) {
    return true;
  } else {
    return false;
  }
};
