import { Box, Dialog, DialogContent, Menu, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Avatar from '../../../widgets/Avatar';
import word from '../../../utils/word';
import Button from '../../../widgets/Button';
import {
  GroupTwoTone,
  InsertEmoticonTwoTone,
  KeyboardArrowDownTwoTone,
  LockTwoTone,
  PublicTwoTone,
} from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPostValidationSchema } from '../../../validations/post';
import { useForm, useWatch } from 'react-hook-form';
import { createPost } from '../../../api/post';
import { useSelector } from 'react-redux';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const PostCreationModal = ({ isOpen, onClose }) => {
  const [privacy, setPrivacy] = useState('PUBLIC');
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [emojiDisabled, setEmojiDisabled] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handlePrivacyOptions = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const privacyOptions = [
    { label: 'Public', value: 'PUBLIC', icon: <PublicTwoTone /> },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: <LockTwoTone />,
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: <GroupTwoTone />,
    },
  ];

  const resolver = yupResolver(createPostValidationSchema);
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, mode: 'onChange' });

  const body = useWatch({ control, name: 'body', defaultValue: '' });

  const handleEmojiSelect = (emoji) => {
    setValue('body', body + emoji.native);
  };

  const sharePost = async (data) => {
    const res = await createPost({ ...data, privacy });
    if (res?.status === 200) {
      reset({ body: '' });
      onClose();
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={isSubmitting ? null : onClose}>
      <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
        <Stack direction="row" spacing={0.5} alignItems="start" width="100%">
          <Box flex={1}>
            <Avatar
              src={user.image}
              label={word.displayInitials(user?.firstname, user?.lastname)}
            />
          </Box>
          <Stack alignItems="start" width="100%">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#303030',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: { xs: 180, sm: 450 },
              }}>
              {word.displayName(user?.firstname, user?.lastname)}
            </Typography>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              sx={{ fontSize: 11 }}
              onClick={handlePrivacyOptions}
              endIcon={<KeyboardArrowDownTwoTone />}
              startIcon={privacyOptions.find((option) => option.value === privacy)?.icon}>
              {privacyOptions.find((option) => option.value === privacy)?.label}
            </Button>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
              <Stack px={1} spacing={1} alignItems="start">
                {privacyOptions.map((option) => (
                  <Button
                    fullWidth
                    size="small"
                    key={option.value}
                    startIcon={option.icon}
                    color={option.value === privacy ? 'primary' : 'secondary'}
                    sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                    onClick={() => {
                      setPrivacy(option.value);
                      handleClose();
                    }}>
                    {option.label}
                  </Button>
                ))}
              </Stack>
            </Menu>
          </Stack>
        </Stack>
        <Stack mt={2}>
          <TextField
            autoFocus
            multiline
            fullWidth
            minRows={3}
            maxRows={5}
            size="small"
            placeholder="Aa"
            {...register('body')}
            error={!!errors.body}
            helperText={errors.body?.message}
          />
          {!emojiPicker && (
            <InsertEmoticonTwoTone
              onClick={
                !emojiDisabled
                  ? () => {
                      setEmojiDisabled(true);
                      setTimeout(() => {
                        setEmojiPicker(true);
                      }, 500);
                      setEmojiDisabled(false);
                    }
                  : null
              }
              sx={{ my: 1, fontSize: 20, cursor: 'pointer', display: { xs: 'none', md: 'block' } }}
            />
          )}
          <Stack my={1}>
            {emojiPicker && (
              <Picker
                data={data}
                onEmojiSelect={handleEmojiSelect}
                onClickOutside={() => {
                  if (emojiPicker) {
                    setEmojiPicker(false);
                  }
                }}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button color="error" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              loading={isSubmitting}
              disabled={body?.length < 1}
              onClick={handleSubmit(sharePost)}>
              Share
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PostCreationModal;
