import React from 'react';
import {
  Box,
  Card,
  Chip,
  List,
  Stack,
  colors,
  Divider,
  Tooltip,
  Typography,
  ListItemButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Avatar from '../../widgets/Avatar';
import { USER_PROFILE } from '../../constants/router-urls';
import memberNavUrls from '../../constants/member-nav-urls';
import { HourglassTopTwoTone } from '@mui/icons-material';
import word from '../../utils/word';

const NotificationBadge = ({ minimized }) => {
  return (
    <Box
      borderRadius="50%"
      position="absolute"
      bgcolor="error.main"
      width={minimized ? 6 : 8}
      height={minimized ? 6 : 8}
      top={minimized ? 0 : '50%'}
      right={minimized ? -2 : -12}
      sx={{ transform: minimized ? 'translate(0%, 0%)' : 'translate(0%, -50%)' }}
    />
  );
};

const PrivateNavbar = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const connection = useSelector((state) => state.connection);

  const isNavItemSelected = (basePath) => {
    return location.pathname.includes(basePath);
  };

  const newRequestCount = connection.connectionRequests?.filter((req) => req.isNew)?.length;

  return (
    <>
      <Stack
        px={1}
        py={3}
        spacing={2}
        width="100%"
        height="100%"
        alignItems="center"
        position="relative"
        justifyContent="space-between"
        display={{ xs: 'none', lg: 'flex' }}
        borderRight="1px solid rgba(0,0,0,0.2)">
        <Card
          to={`${USER_PROFILE}/${user?.username}`}
          component={user?.flags?.isVisible ? Link : undefined}
          sx={{
            mx: 1,
            boxShadow: 0,
            width: '100%',
            textDecoration: 'none',
            border: '1px solid #e0e0e0',
            ':hover': { bgcolor: colors.grey[100] },
          }}>
          <Stack p={1} spacing={1} width="100%" direction="row" alignItems="end">
            <Avatar
              src={user?.image}
              label={word.displayInitials(user?.firstname, user?.lastname)}
            />
            <Stack spacing={0} flex={1}>
              <Typography
                sx={{ whiteSpace: 'nowrap', lineHeight: 1, fontWeight: 700, textAlign: 'right' }}>
                {word.displayName(user?.firstname, user?.lastname)}
              </Typography>
              <Typography sx={{ fontSize: 12, textAlign: 'right' }}>{user?.email}</Typography>
            </Stack>
          </Stack>
        </Card>
        <Box width="100%">
          <Divider />
        </Box>
        <Stack width="100%" flex={1}>
          <List>
            {memberNavUrls.map((item, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={
                  item.unpublished
                    ? '#'
                    : isNavItemSelected(item.basePath)
                    ? location.pathname
                    : item.exactPath
                }
                sx={{
                  borderRadius: '0 5px 5px 0',
                  color: item.unpublished
                    ? colors.grey[400]
                    : isNavItemSelected(item.basePath)
                    ? 'primary.main'
                    : 'unset',
                  borderLeft: item.unpublished
                    ? 'none'
                    : isNavItemSelected(item.basePath)
                    ? '3px solid #4562f7'
                    : 'none',
                  pointerEvents: item.unpublished ? 'none' : 'unset',
                }}>
                <Stack
                  width="100%"
                  direction="row"
                  position="relative"
                  alignItems="center"
                  justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    {item.icon}
                    {item.unpublished && (
                      <Chip
                        size="small"
                        label="Coming Soon"
                        sx={{ transform: 'scale(0.8, 0.8)' }}
                      />
                    )}
                  </Stack>
                  <Typography fontSize={14} fontWeight={700}>
                    {item.label}
                  </Typography>
                  {item.label === 'Connections' && newRequestCount > 0 && <NotificationBadge />}
                </Stack>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      </Stack>

      <Stack
        px={1}
        py={3}
        spacing={2}
        width="100%"
        height="100%"
        alignItems="center"
        position="relative"
        justifyContent="space-between"
        borderRight="1px solid rgba(0,0,0,0.2)"
        display={{ lg: 'none', sm: 'flex', xs: 'none' }}>
        <Tooltip title={user?.flags?.isVisible ? 'profile' : ''} arrow placement="right">
          <Box
            sx={{ textDecoration: 'none' }}
            to={`${USER_PROFILE}/${user?.username}`}
            component={user?.flags?.isVisible ? Link : 'div'}>
            <Avatar
              src={user?.image}
              label={word.displayInitials(user?.firstname, user?.lastname)}
            />
          </Box>
        </Tooltip>
        <Box width="100%">
          <Divider />
        </Box>
        <Stack width="100%" flex={1}>
          <List>
            {memberNavUrls.map((item, index) => (
              <Tooltip
                arrow
                key={index}
                placement="right"
                title={`${item.label}${item.unpublished ? ' - coming soon' : ''}`}>
                <ListItemButton
                  component={Link}
                  to={
                    item.unpublished
                      ? '#'
                      : isNavItemSelected(item.basePath)
                      ? location.pathname
                      : item.exactPath
                  }
                  sx={{
                    borderRadius: '0 5px 5px 0',
                    color: item.unpublished
                      ? colors.grey[400]
                      : isNavItemSelected(item.basePath)
                      ? 'primary.main'
                      : 'unset',
                    borderLeft: item.unpublished
                      ? 'none'
                      : isNavItemSelected(item.basePath)
                      ? '3px solid #4562f7'
                      : 'none',
                    cursor: item.unpublished ? 'default' : 'pointer',
                  }}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    position="relative"
                    justifyContent="space-between">
                    {item.icon}
                    {item.unpublished && <HourglassTopTwoTone sx={{ fontSize: 12 }} />}
                    {item.label === 'Connections' && newRequestCount > 0 && (
                      <NotificationBadge minimized={true} />
                    )}
                  </Stack>
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
        </Stack>
      </Stack>
    </>
  );
};

export default PrivateNavbar;
