import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { CircularProgress, Stack, Typography, colors } from '@mui/material';
import { requestMailResendForEmailVerification, verifyEmail } from '../../api/auth';
import Button from '../../widgets/Button';
import debounce from '../../utils/debounce';
import notify from '../../utils/notify';
import { SIGNIN, USER_HOME } from '../../constants/router-urls';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

const VerifyEmail = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const [alternateState, setAlternateState] = useState('');
  const [requestingResend, setRequestingResend] = useState(false);

  const [email, setEmail] = useState('');

  const { token } = useParams();
  const [verState, setVerState] = useState('LOADING');

  useEffect(() => {
    if (location?.state?.alternateState) {
      setAlternateState(location?.state.alternateState);
    }
    if (location?.state?.email) {
      setEmail(location?.state?.email);
    }
  }, [location]);

  useEffect(() => {
    if (!token || alternateState) {
      setVerState('ERROR');
      return;
    }

    (async () => {
      const { isSuccessful, data } = await verifyEmail(token);
      if (isSuccessful) {
        setVerState('SUCCESS');
      } else {
        setEmail(data?.email);
        setVerState('FAILURE');
      }
    })();
  }, [token]);

  const handleResendEmail = async () => {
    const isSuccessful = await requestMailResendForEmailVerification(email);
    if (isSuccessful) {
      setAlternateState('RESEND_SUCCESS');
    }
    setRequestingResend(false);
  };

  const renderContent = () => {
    if (alternateState) {
      switch (alternateState) {
        case 'SIGNUP_SUCCESS':
          return (
            <Stack alignItems="start">
              <Typography fontSize={18} fontWeight={500} color="#7FB77E">
                ✅ Signup successful!
              </Typography>
              <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
                Please check your email for a verification link.
              </Typography>
              <Typography mb={2} fontSize={16} color={colors.grey[700]}>
                If you don&apos;t see the email, please check your spam folder as well.
              </Typography>
              <Typography mb={1} fontSize={14} color={colors.grey[700]}>
                Didn&apos;t receive the email yet?
              </Typography>
              <Button
                variant="contained"
                loading={requestingResend}
                onClick={() => {
                  setRequestingResend(true);
                  debounce(handleResendEmail, 10000)();
                }}>
                Resend Email
              </Button>
            </Stack>
          );
        case 'RESEND_SUCCESS':
          return (
            <Stack alignItems="start">
              <Typography fontSize={18} fontWeight={500} color="#7FB77E">
                ✅ Email resent!
              </Typography>
              <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
                Please check your email again for a verification link.
              </Typography>
              <Typography mb={2} fontSize={16} color={colors.grey[700]}>
                If you don&apos;t see the email, please check your spam folder as well.
              </Typography>
              <Typography mb={1} fontSize={14} color={colors.grey[700]}>
                Didn&apos;t receive the email yet?
              </Typography>
              <Button
                variant="contained"
                loading={requestingResend}
                onClick={() => {
                  setRequestingResend(true);
                  debounce(handleResendEmail, 10000)();
                }}>
                Resend Email
              </Button>
            </Stack>
          );
        case 'SIGNIN_VERIFY_EMAIL':
          return (
            <Stack alignItems="start">
              <Typography fontSize={18} fontWeight={500} color="error">
                Verify your email address❗️
              </Typography>
              <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
                Please check your email for a verification link.
              </Typography>
              <Typography mb={2} fontSize={16} color={colors.grey[700]}>
                If you don&apos;t see the email, please check your spam folder as well.
              </Typography>
              <Typography mb={1} fontSize={14} color={colors.grey[700]}>
                Didn&apos;t receive the email yet?
              </Typography>
              <Button
                variant="contained"
                loading={requestingResend}
                onClick={() => {
                  /* check if email is present in state and is a valid email.
                    There is a possibility that the user might have attempted login with username.
                    */
                  if (!email || !yup.string().email().isValidSync(email)) {
                    notify.info(
                      'Please login again. You should login with your email to complete the verification process.',
                    );
                    return;
                  }
                  setRequestingResend(true);
                  debounce(handleResendEmail, 10000)();
                }}>
                Resend Email
              </Button>
            </Stack>
          );
        default:
          return (
            <Stack alignItems="start">
              <Typography fontSize={18} fontWeight={500} color="error">
                Something went wrong❗️
              </Typography>
              <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
                Please try again later.
              </Typography>
            </Stack>
          );
      }
    }

    switch (verState) {
      case 'LOADING':
        return (
          <Stack alignItems="start">
            <CircularProgress />
            <Typography mt={1} fontSize={18} fontWeight={500} color="primary">
              Verifying...
            </Typography>
            <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
              Please wait a while, do not refresh the page or close the tab.
            </Typography>
          </Stack>
        );
      case 'SUCCESS':
        return (
          <Stack alignItems="start">
            <Typography fontSize={18} fontWeight={500} color="#7FB77E">
              ✅ Email verification successful!
            </Typography>
            <Typography mt={0.5} mb={1} fontSize={16} color={colors.grey[700]}>
              Please login to access your account.
            </Typography>
            <Button variant="contained" component={Link} to={SIGNIN}>
              Sign in
            </Button>
          </Stack>
        );
      case 'FAILURE':
        return (
          <Stack alignItems="start">
            <Typography fontSize={18} fontWeight={500} color="#7FB77E">
              ❌ Email verification failed!
            </Typography>
            <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
              The verrification link is either expired or invalid.
            </Typography>
            <Typography mb={1} fontSize={16} color={colors.grey[700]}>
              Please click the Resend Email button below and try again
            </Typography>
            <Button
              variant="contained"
              loading={requestingResend}
              onClick={() => {
                setRequestingResend(true);
                debounce(handleResendEmail, 10000)();
              }}>
              Resend Email
            </Button>
          </Stack>
        );
      case 'ERROR':
        return (
          <Stack alignItems="start">
            <Typography mt={1} fontSize={18} fontWeight={500} color="error">
              Something went wrong❗️
            </Typography>
            <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
              Please check if you are visiting the correct verification link.
            </Typography>
            <Typography fontSize={16} color={colors.grey[700]}>
              If you think this is a mistake, please try again. If you still face issues, please
              contact us.
            </Typography>
          </Stack>
        );
      default:
        return (
          <Stack alignItems="start">
            <Typography fontSize={18} fontWeight={500} color="error">
              Something went wrong❗️
            </Typography>
            <Typography mt={0.5} fontSize={16} color={colors.grey[700]}>
              Please try again later.
            </Typography>
          </Stack>
        );
    }
  };

  if (isAuth) {
    return <Navigate to={USER_HOME} replace={true} />;
  }

  return (
    <Stack
      p={2}
      width="100%"
      height="auto"
      minHeight="75vh"
      alignItems="center"
      justifyContent="center">
      {renderContent()}
    </Stack>
  );
};

export default VerifyEmail;
