import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import ContactSection from '../../components/home/ContactSection';

const ContactUs = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  if (isAuth) {
    return (
      <Box
        width="100%"
        margin="auto"
        minWidth={300}
        maxWidth={{ xs: '100vw', sm: 'calc(100vw - 70px)', md: 900 }}>
        <ContactSection />
      </Box>
    );
  }

  return <ContactSection secondary />;
};

export default ContactUs;
