import React, { useState } from 'react';
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  CircleTwoTone,
  DeleteForeverTwoTone,
  DriveFileRenameOutlineTwoTone,
  MoreHoriz,
  OpenInNewTwoTone,
  VerticalAlignBottomTwoTone,
  VerticalAlignTopTwoTone,
} from '@mui/icons-material';
import Button from '../../../widgets/Button';
import { Box, IconButton, Menu, Stack, Typography } from '@mui/material';
import { deleteCertificate, editCertificate } from '../../../api/profile';
import AlertDialog from '../../global/AlertDialog';
import CertificateForm from './CertificateForm';

const CertificateCard = ({ isAuth, data, setData, isFirst, isLast }) => {
  const [isSubmitting, setIsSubmitting] = useState('');
  const [isEditState, setIsEditState] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMoreOptions = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleEdit = () => {
    setIsEditState(true);
    handleClose();
  };

  const handleReorder = async (action) => {
    setIsSubmitting(action);
    const res = await editCertificate({ certificateId: data.id }, action);
    if (res.status === 200) {
      setData(res.updatedCertificates, 'reorder-certificate');
      handleClose();
    }
    setIsSubmitting('');
  };

  const handleDelete = async () => {
    setIsSubmitting('delete');
    const res = await deleteCertificate(data.id);
    if (res.status === 200) {
      setData(data.id, 'delete-certificate');
      handleClose();
    }
    setIsSubmitting('');
  };

  return isEditState ? (
    <CertificateForm
      editMode={true}
      values={data}
      setData={setData}
      onClose={() => setIsEditState(false)}
    />
  ) : (
    <Box width="100%" py={1}>
      <AlertDialog
        title="Confirm Delete❓"
        isOpen={deleteDialog}
        onResolved={handleDelete}
        onClose={() => setDeleteDialog(false)}
        resolveLoading={isSubmitting === 'delete'}
        message="Are you sure you want to delete?"
      />
      <Stack width="100%" direction="row" justifyContent="space-between" alignItems="start">
        {!isAuth && <CircleTwoTone sx={{ pt: 0.6 }} />}
        <Stack width={isAuth ? '80%' : '90%'}>
          <Typography fontSize={18} fontWeight={700}>
            {data?.title}
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            {data?.institution}
          </Typography>
          {data?.url && (
            <Box alignSelf="start">
              <Button
                fullWidth
                component="a"
                color="primary"
                target="_blank"
                href={data?.url}
                rel="noopener noreferrer"
                disabled={isSubmitting !== ''}
                endIcon={<OpenInNewTwoTone />}
                sx={{ p: 0, textTransform: 'capitalize', justifyContent: 'flex-start' }}>
                Visit Website
              </Button>
            </Box>
          )}
          <Typography mt={0.5} fontSize={14} fontWeight={400}>
            {data?.details}
          </Typography>
        </Stack>
        <Box>
          {isAuth && (
            <IconButton size="small" onClick={handleMoreOptions}>
              <MoreHoriz />
            </IconButton>
          )}
        </Box>
      </Stack>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Stack px={1} spacing={1} alignItems="start">
          <Button
            fullWidth
            size="small"
            color="secondary"
            onClick={handleEdit}
            disabled={isSubmitting !== ''}
            startIcon={<DriveFileRenameOutlineTwoTone />}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            Edit
          </Button>
          {!isFirst && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              loading={isSubmitting === 'up'}
              startIcon={<ArrowUpwardTwoTone />}
              onClick={() => handleReorder('up')}
              disabled={isSubmitting !== '' && isSubmitting !== 'up'}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Move Up
            </Button>
          )}
          {!isLast && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              loading={isSubmitting === 'down'}
              startIcon={<ArrowDownwardTwoTone />}
              onClick={() => handleReorder('down')}
              disabled={isSubmitting !== '' && isSubmitting !== 'down'}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Move Down
            </Button>
          )}
          {!isFirst && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              loading={isSubmitting === 'top'}
              onClick={() => handleReorder('top')}
              startIcon={<VerticalAlignTopTwoTone />}
              disabled={isSubmitting !== '' && isSubmitting !== 'top'}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Move to top
            </Button>
          )}
          {!isLast && (
            <Button
              fullWidth
              size="small"
              color="secondary"
              loading={isSubmitting === 'bottom'}
              onClick={() => handleReorder('bottom')}
              startIcon={<VerticalAlignBottomTwoTone />}
              disabled={isSubmitting !== '' && isSubmitting !== 'bottom'}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              Move to bottom
            </Button>
          )}
          <Button
            fullWidth
            size="small"
            color="error"
            disabled={isSubmitting !== ''}
            startIcon={<DeleteForeverTwoTone />}
            onClick={() => {
              handleClose();
              setDeleteDialog(true);
            }}
            sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
            Delete
          </Button>
        </Stack>
      </Menu>
    </Box>
  );
};

export default CertificateCard;
