import React from 'react';
import parse from 'html-react-parser';
import { Alert, Box } from '@mui/material';

import PageWrapper from '../../components/global/PageWrapper';
import FeedTabs from '../../components/user/user-feed/FeedTabs';
import PostCreationBox from '../../components/user/user-feed/PostCreationBox';
import { useSelector } from 'react-redux';
import dateTime from '../../utils/dateTime';
import Button from '../../widgets/Button';
import { Link } from 'react-router-dom';
import { SELECT_PLAN, USER_SETTINGS } from '../../constants/router-urls';

const UserFeed = () => {
  const user = useSelector((state) => state.auth.user);

  const renderExpiryMessage = () => {
    const diff = dateTime.remainingTime(user?.expiryDate);
    if (diff < 0) {
      if (diff === -1) return `has expired <b>${diff * -1} day</b> ago`;
      return `has expired <b>${diff * -1} days</b> ago`;
    } else if (diff === 0) {
      return 'is expiring <b>today</b>';
    } else {
      if (diff === 1) return `is expiring in <b>${diff} day</b>`;
      return `is expiring in <b>${diff} days</b>`;
    }
  };

  const renderAlert = () => {
    if (
      user?.membershipStatus === 'ACTIVE' &&
      user?.paymentStatus === 'PAID' &&
      dateTime.remainingTime(user?.expiryDate) < 30
    ) {
      return (
        <Box pb={2}>
          <Alert
            severity="warning"
            sx={{ width: '100%' }}
            action={
              <Button
                size="small"
                color="warning"
                component={Link}
                to={SELECT_PLAN}
                variant="contained">
                Renew
              </Button>
            }>
            Your membership {parse(renderExpiryMessage())}. Please renew your membership to continue
            enjoying the benefits as an ISBM member.
          </Alert>
        </Box>
      );
    }
  };

  const renderPasswordAlert = () => {
    if (!user?.flags?.isOAuth && user?.flags?.shouldResetPassword) {
      return (
        <Box pb={2}>
          <Alert
            severity="warning"
            sx={{ width: '100%' }}
            action={
              <Button
                size="small"
                color="warning"
                component={Link}
                to={USER_SETTINGS}
                variant="contained">
                Reset
              </Button>
            }>
            You are using a system-generated password. To keep your account safe, please reset your
            password soon.
          </Alert>
        </Box>
      );
    }
  };

  return (
    <PageWrapper isAuth>
      <Box
        width="100%"
        margin="auto"
        minWidth={300}
        maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 600 }}>
        {renderAlert()}
        {renderPasswordAlert()}
        <PostCreationBox />
        <FeedTabs />
      </Box>
    </PageWrapper>
  );
};

export default UserFeed;
