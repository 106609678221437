import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';
import SocketIO from './SocketIO';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocketContext must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider = ({ children }) => {
  const token = useSelector((state) => state.auth.token);

  // Create a single socket instance using io.connect only once
  const socket = io(process.env.REACT_APP_SERVER_URL || '', {
    auth: { token },
    transports: ['websocket', 'polling'],
    secure: true,
    forceNew: true,
    reconnection: true,
    reconnectionAttempts: 5,
    randomizationFactor: 0.5,
    reconnectionDelay: 3000,
    reconnectionDelayMax: 5000,
  });

  return (
    <SocketContext.Provider value={socket}>
      <SocketIO>{children}</SocketIO>
    </SocketContext.Provider>
  );
};
