import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router';

import UserFlagCheck from './UserFlagCheck';
import { updateAuthState } from '../../api/auth';
import { SIGNIN } from '../../constants/router-urls';
import { authActions } from '../../store/reducers/authReducer';

const ProtectedPage = ({ children, flagCheck }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuth) {
      (async () => {
        await updateAuthState();
      })();
    }
  }, []);

  useEffect(() => {
    if (!isAuth) {
      setTimeout(() => {
        dispatch(authActions.reset());
      }, 1000);
    }
  }, [isAuth]);

  return isAuth ? (
    flagCheck ? (
      <UserFlagCheck>{children}</UserFlagCheck>
    ) : (
      children
    )
  ) : (
    <Navigate to={SIGNIN} state={{ from: location.pathname }} />
  );
};

export default ProtectedPage;
