import React, { useRef, useState } from 'react';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Stack,
  Button,
  Avatar,
  Container,
  Accordion,
  Typography,
  IconButton,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
  colors,
} from '@mui/material';

import { howYouCanHelp } from '../../constants/placeholder/support';
import { primaryButtonStyle, sectionHeaderStyle } from '../../constants/styles';
import DevSign from '../../components/global/DevSign';
import { useNavigate } from 'react-router-dom';
import { CONTACT } from '../../constants/router-urls';

const SupportISBM = () => {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [showDonateModal, setShowDonateModal] = useState(false);

  const handleScroll = () => {
    try {
      const content = contentRef.current;
      if (content) {
        content.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showDonateModal}
        onClose={() => setShowDonateModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack mt={2} spacing={2} alignItems="center">
            <DevSign />
            <Typography fontSize={18} fontWeight={500} color={colors.grey[700]} textAlign="center">
              Meanwhile you can make a contribution to ISBM by communicating with us through the
              contact page.
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowDonateModal(false);
                navigate(CONTACT);
              }}>
              Contact Us?
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/support/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            We Appreciate Your Support
          </Typography>
          <Typography maxWidth={450} color="grey.400" textAlign="center">
            Being a non-profit organization, we rely on the support of our members and volunteers to
            keep our missions ongoing. We are always looking for new members to join our team and
            help us grow.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Container sx={{ mt: 10 }} maxWidth="lg" ref={contentRef}>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h4" fontWeight={700} textAlign="center">
            How You Can Help
          </Typography>
          <Typography maxWidth={720} textAlign="center">
            ISBM has ample opportunities to serve. By actively engaging with ISBM you can contribute
            to the mission of promoting and advancing the microbiology community and science,
            supporting the development of robust next-generation scientists and educators, raising
            awareness about communicable diseases, and creating a field of microbiology-based
            economy. Explore and find the options that fit your experience and interest.
          </Typography>
        </Stack>
        <Stack
          mt={4}
          width="100%"
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center">
          {howYouCanHelp.map((item, index) => (
            <Accordion key={item.title} sx={{ maxWidth: 650 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar sx={{ width: 24, height: 24, fontSize: '0.8rem', bgcolor: '#ED6C02' }}>
                    {index + 1}
                  </Avatar>
                  <Typography variant="h6" fontWeight={600}>
                    {item.title}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Stack
          p={3}
          my={10}
          spacing={3}
          width="100%"
          color="#fff"
          borderRadius={2}
          bgcolor="#16213E"
          alignItems="center"
          justifyConetnt="center">
          <Typography {...sectionHeaderStyle}>Donate & Support</Typography>
          <Typography textAlign="center" maxWidth={650} sx={{ pb: 3 }}>
            ISBM promotes and advances the microbial sciences by fostering and disseminating
            high-impact research, recognizing extraordinary achievements in our community, and
            advocating for evidence-based health policies. Your donation supports our critical work.
          </Typography>
          <Button {...primaryButtonStyle} onClick={() => setShowDonateModal(true)}>
            Make A Contribution
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default SupportISBM;
