import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, FormControl, Stack, TextField } from '@mui/material';

import HelperText from '../../../widgets/HelperText';
import { createProfileContactDetailsValidationSchema } from '../../../validations/profile';

const ContactDetails = ({ data, setData, back, next }) => {
  const [focus, setFocus] = useState('');

  const resolver = yupResolver(createProfileContactDetailsValidationSchema);
  const {
    watch,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });

  const watchAllFields = watch();
  const handleContactChange = (data) => setValue('contact', data);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onBackardSubmit = () => {
    setData(watchAllFields);
    back();
  };

  const onForwardSubmit = (data) => {
    setData(data);
    next();
  };

  return (
    <Stack
      mx="auto"
      spacing={2}
      height="100%"
      sx={{ mt: 5 }}
      minHeight="60vh"
      component="form"
      alignItems="center"
      justifyContent="center"
      width={{ md: 650, xs: 310 }}>
      <FormControl fullWidth error={errors?.addressLine1?.message}>
        <TextField
          size="small"
          label="Address"
          onBlur={() => setFocus('')}
          placeholder="Street Address"
          {...register('addressLine1')}
          error={errors?.addressLine1?.message}
          onFocus={() => setFocus('addressLine1')}
          InputLabelProps={{ shrink: watchAllFields.addressLine1 || focus === 'addressLine1' }}
        />
        <HelperText>{errors?.addressLine1?.message}</HelperText>
      </FormControl>

      <FormControl fullWidth error={errors?.addressLine2?.message}>
        <TextField
          size="small"
          label="Address Line 2"
          onBlur={() => setFocus('')}
          {...register('addressLine2')}
          error={errors?.addressLine2?.message}
          onFocus={() => setFocus('addressLine2')}
          placeholder="Street Address Line 2 (optional)"
          InputLabelProps={{ shrink: watchAllFields.addressLine2 || focus === 'addressLine2' }}
        />
        <HelperText>{errors?.addressLine2?.message}</HelperText>
      </FormControl>

      <Stack direction="row" spacing={2} width="100%">
        <FormControl fullWidth error={errors?.city?.message}>
          <TextField
            size="small"
            label="City"
            {...register('city')}
            placeholder="Montana City"
            onBlur={() => setFocus('')}
            error={errors?.city?.message}
            onFocus={() => setFocus('city')}
            InputLabelProps={{ shrink: watchAllFields.city || focus === 'city' }}
          />
          <HelperText allowOverflow={!errors?.state?.message}>{errors?.city?.message}</HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.state?.message}>
          <TextField
            size="small"
            placeholder="Montana"
            label="State/Province"
            {...register('state')}
            onBlur={() => setFocus('')}
            error={errors?.state?.message}
            onFocus={() => setFocus('state')}
            InputLabelProps={{ shrink: watchAllFields.state || focus === 'state' }}
          />
          <HelperText>{errors?.state?.message}</HelperText>
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={2} width="100%">
        <FormControl fullWidth error={errors?.zipCode?.message}>
          <TextField
            size="small"
            placeholder="AM59634"
            label="Postal/Zip Code"
            {...register('zipCode')}
            onBlur={() => setFocus('')}
            error={errors?.zipCode?.message}
            onFocus={() => setFocus('zipCode')}
            InputLabelProps={{ shrink: watchAllFields.zipCode || focus === 'zipCode' }}
          />
          <HelperText allowOverflow={!errors?.country?.message}>
            {errors?.zipCode?.message}
          </HelperText>
        </FormControl>

        <FormControl fullWidth error={errors?.country?.message}>
          <TextField
            size="small"
            label="Country"
            {...register('country')}
            placeholder="United States"
            onBlur={() => setFocus('')}
            error={errors?.country?.message}
            onFocus={() => setFocus('country')}
            InputLabelProps={{ shrink: watchAllFields.country || focus === 'country' }}
          />
          <HelperText>{errors?.country?.message}</HelperText>
        </FormControl>
      </Stack>

      <FormControl fullWidth error={errors?.contact?.message}>
        <Alert severity="info" sx={{ mb: 2, fontSize: 12 }}>
          Contact number is only visible to you by default, you can change this from account
          settings anytime. It is required to verify your account.
        </Alert>
        <MuiPhoneNumber
          size="small"
          label="Contact"
          variant="outlined"
          defaultCountry={'us'}
          value={data?.contact}
          defaultValue={data?.contact}
          onChange={handleContactChange}
          error={errors?.contact?.message}
          InputLabelProps={{ shrink: true }}
          regions={['america', 'europe', 'asia', 'oceania', 'africa']}
        />
        <HelperText>{errors?.contact?.message}</HelperText>
      </FormControl>

      <Stack direction="row" justifyContent="flex-end" width="100%" spacing={2}>
        <Button variant="outlined" onClick={onBackardSubmit}>
          Previous
        </Button>
        <Button variant="contained" onClick={handleSubmit(onForwardSubmit)}>
          next
        </Button>
      </Stack>
    </Stack>
  );
};

export default ContactDetails;
