import React, { useRef } from 'react';
import { ArrowDownward, ArrowForwardIosTwoTone } from '@mui/icons-material';
import { Box, Container, Divider, IconButton, Stack, Typography, colors } from '@mui/material';
import events from '../../constants/placeholder/events';
import Button from '../../widgets/Button';
import dateTime from '../../utils/dateTime';
import { Link } from 'react-router-dom';
import { MEETING_EVENTS } from '../../constants/router-urls';

const MeetingEvents = () => {
  const contentRef = useRef(null);

  const handleScroll = () => {
    try {
      const content = contentRef.current;
      if (content) {
        content.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const EventCard = ({ event }) => {
    return (
      <Stack
        p={2}
        width="100%"
        boxShadow={1}
        direction="row"
        flexWrap="wrap"
        borderRadius={2}
        alignItems="center"
        sx={{ textDecoration: 'none' }}
        to={`${MEETING_EVENTS}/${event?.slug}`}
        component={event?.isFinalized ? Link : undefined}>
        <Typography
          m={1}
          flex={1}
          fontSize={18}
          fontWeight={500}
          minWidth={{ xs: 240, md: 450 }}
          color={event?.isFinalized ? '#128CCD' : 'secondary.main'}>
          {event?.title}
          {event?.startDate && `: ${dateTime.render(event?.startDate, 'MMM YYYY')}`}
        </Typography>
        {event?.isFinalized && (
          <Button
            size="small"
            color="secondary"
            variant="contained"
            sx={{ minWidth: 130, m: 1 }}
            endIcon={<ArrowForwardIosTwoTone />}>
            View Details
          </Button>
        )}
      </Stack>
    );
  };

  return (
    <Box>
      <Stack
        width="100%"
        minHeight="100vh"
        position="relative"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/assets/images/meeting/cover.jpg'})`,
        }}>
        <Stack
          px={3}
          flex={1}
          spacing={3}
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,0.6)">
          <Typography
            color="white"
            maxWidth={650}
            fontWeight={700}
            textAlign="center"
            fontSize={{ lg: '2.6rem', xs: '2rem' }}>
            Meetings & Events on ISBM
          </Typography>
          <Typography maxWidth={450} color="grey.400" textAlign="center">
            Check out what is happening on ISBM right here. All the latest meetings and events are
            updated under this section.
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f7f7f7',
              ':hover': {
                backgroundColor: '#ffffff',
              },
            }}
            onClick={handleScroll}>
            <ArrowDownward sx={{ fill: '#171720' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Container sx={{ my: 10 }} maxWidth="lg" ref={contentRef}>
        <Typography fontSize={{ xs: 18, md: 24 }} fontWeight={600} color={colors.grey[700]}>
          Recent Meetings & Events
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack spacing={2}>
          {events
            .filter((item) => item?.isFinished)
            .map((item) => (
              <EventCard key={item.id} event={item} />
            ))}
        </Stack>

        <Typography mt={10} fontSize={{ xs: 18, md: 24 }} fontWeight={600} color={colors.grey[700]}>
          Upcoming Meetings & Events
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack spacing={2}>
          {events
            .filter((item) => !item?.isFinished)
            .map((item) => (
              <EventCard key={item.id} event={item} />
            ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default MeetingEvents;
