import { toast } from 'react-hot-toast';

const config = {
  duration: 3.5 * 1000,
  position: 'bottom-right',
};

const styles = {
  fontSize: 12,
  fontWeight: 600,
  borderRadius: 5,
};

export default {
  success: (msg) =>
    toast(msg, {
      ...config,
      id: msg,
      icon: '✅',
      style: {
        ...styles,
        border: '1px solid #7FB77E',
      },
    }),

  error: (msg) =>
    toast(msg, {
      ...config,
      id: msg,
      icon: '❌',
      style: {
        ...styles,
        border: '1px solid #FF5858',
      },
    }),

  warning: (msg) =>
    toast(msg, {
      ...config,
      id: msg,
      icon: '⚠️',
      style: {
        ...styles,
        border: '1px solid #F1A661',
      },
    }),

  info: (msg) =>
    toast(msg, {
      ...config,
      id: msg,
      icon: 'ℹ️',
      style: {
        ...styles,
        border: '1px solid #5882ff',
      },
    }),

  custom: (element, customConfig) => toast(() => element, { ...config, ...customConfig }),
};
