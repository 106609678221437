import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { AddPhotoAlternateTwoTone } from '@mui/icons-material';

const ProfileImage = ({ data, setData, back, next }) => {
  const [file, setFile] = useState();
  const [mode, setMode] = useState('GLOBAL');

  const handleChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setMode('LOCAL');
    } else {
      setMode('GLOBAL');
    }
  };

  const handleNext = () => {
    setData({ ...data, image: file });
    next();
  };

  return (
    <Box>
      <Stack mt={10} width="100%" alignItems="center" justifyContent="center">
        <Box position="relative">
          <Box
            zIndex={1}
            width={320}
            height={320}
            component="img"
            borderRadius={2}
            border="2px solid rgba(0,0,0,0.2)"
            src={
              mode === 'GLOBAL'
                ? data?.image || '/assets/images/avatar.png'
                : mode === 'LOCAL'
                ? file
                  ? URL.createObjectURL(file)
                  : '/assets/images/avatar.png'
                : '/assets/images/avatar.png'
            }
          />
          <Stack
            sx={{
              top: 0,
              opacity: 0,
              width: '320px',
              height: '320px',
              borderRadius: 2,
              cursor: 'pointer',
              alignItems: 'center',
              position: 'absolute',
              justifyContent: 'center',
              transition: '0.2s ease',
              bgcolor: 'rgba(0,0,0,0.6)',
              ':hover': { opacity: 1 },
            }}>
            <Button
              variant="outlined"
              startIcon={<AddPhotoAlternateTwoTone />}
              sx={{ bgcolor: 'rgba(255,255,255,0.7)', ':hover': { bgcolor: '#fff' } }}
              onClick={() => document.getElementById('hiddenFileInput')?.click()}>
              change
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Stack mt={10} direction="row" spacing={1} justifyContent="center">
        <Button variant="outlined" onClick={back}>
          Back
        </Button>
        <Button variant="contained" onClick={handleNext}>
          Next
        </Button>
      </Stack>
      <input type="file" id="hiddenFileInput" style={{ display: 'none' }} onChange={handleChange} />
    </Box>
  );
};

export default ProfileImage;
