import React, { useState } from 'react';
import {
  Box,
  Menu,
  Card,
  Stack,
  colors,
  Tooltip,
  // TextField,
  // IconButton,
  Typography,
  ListItemButton,
  // InputAdornment,
} from '@mui/material';
import {
  // ForumTwoTone,
  SettingsTwoTone,
  // FeedbackTwoTone,
  ChevronRightTwoTone,
  // ManageSearchTwoTone,
  // NotificationsTwoTone,
  ContactSupportTwoTone,
  KeyboardArrowUpTwoTone,
  PowerSettingsNewTwoTone,
  KeyboardArrowDownTwoTone,
  HourglassTopTwoTone,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import AlertDialog from './AlertDialog';
import { signout } from '../../api/auth';
import Avatar from '../../widgets/Avatar';
import { brandColors } from '../../constants/colors';
import {
  CONTACT,
  INDEX,
  // USER_HOME,
  USER_PROFILE,
  USER_SETTINGS,
} from '../../constants/router-urls';
import memberNavUrls from '../../constants/member-nav-urls';
import word from '../../utils/word';

const NotificationBadge = () => {
  return (
    <Box
      top={0}
      width={6}
      height={6}
      borderRadius="50%"
      position="absolute"
      bgcolor="error.main"
      sx={{ transform: 'translate(100%, 0%)' }}
    />
  );
};

const PrivateNavbar = () => {
  const location = useLocation();
  const [loggingOut, setLoggingOut] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const connection = useSelector((state) => state.connection);

  const isNavItemSelected = (basePath) => {
    return location.pathname.includes(basePath);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleAccountMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const accountMenuOptions = [
    {
      label: 'Settings & Privacy',
      icon: <SettingsTwoTone />,
      link: USER_SETTINGS,
    },
    {
      label: 'Help & Support',
      icon: <ContactSupportTwoTone />,
      link: CONTACT,
    },
    // {
    //   label: 'Give Feedback',
    //   icon: <FeedbackTwoTone />,
    //   link: '#',
    // },
  ];

  const handleLogout = async () => {
    setLoggingOut(true);
    await signout();
    setLoggingOut(false);
  };

  const newRequestCount = connection.connectionRequests?.filter((req) => req.isNew)?.length;

  return (
    <React.Fragment>
      {/* LOGOUT DIALOG */}
      <AlertDialog
        title="Logout❓"
        isOpen={logoutDialog}
        onResolved={handleLogout}
        resolveLoading={loggingOut}
        onClose={() => setLogoutDialog(false)}
        message="Are you sure you want to logout?"
      />
      {/* TITLE BAR (ALWAYS VISIBLE) */}
      <Stack
        px={2}
        top={0}
        left={0}
        width="100%"
        zIndex={100}
        height="50px"
        bgcolor="#fff"
        direction="row"
        position="sticky"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="0px 2px 2px rgba(0,0,0,0.2)">
        <Stack
          flex={1}
          to={INDEX}
          spacing={1}
          // to={USER_HOME}
          direction="row"
          component={Link}
          alignItems="center"
          justifyContent="start"
          sx={{ textDecoration: 'none' }}>
          <Box component="img" src="/assets/images/logo.png" height="40px" />
          <Typography variant="h5" fontWeight="bold" color={brandColors.secondary}>
            ISBM
          </Typography>
        </Stack>

        {/* <TextField
          size="small"
          placeholder="search"
          // onBlur={() => setSearchBoxWidth(180)}
          // onFocus={() => setSearchBoxWidth(240)}
          sx={{
            flex: 1,
            maxWidth: 600,
            transition: 'all 0.3s ease',
            display: { xs: 'none', sm: 'flex' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ManageSearchTwoTone sx={{ width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        /> */}

        <Stack flex={1} spacing={1} direction="row" justifyContent="end" alignItems="center">
          {/* CONVERSATIONS */}
          {/* <Tooltip title="conversations" placement="bottom" arrow>
            <IconButton sx={{ ':hover': { bgcolor: colors.grey[100] } }}>
              <ForumTwoTone />
            </IconButton>
          </Tooltip> */}
          {/* NOTIFICATIONS */}
          {/* <Tooltip title="notifications" placement="bottom" arrow>
            <IconButton sx={{ ':hover': { bgcolor: colors.grey[100] } }}>
              <NotificationsTwoTone />
            </IconButton>
          </Tooltip> */}
          {/* ACCOUNTS MENU */}
          <Tooltip title="account" placement="bottom" arrow>
            <Box position="relative" sx={{ cursor: 'pointer' }} onClick={handleAccountMenu}>
              <Avatar
                size={40}
                src={user?.image}
                label={word.displayInitials(user?.firstname, user?.lastname)}
              />
              {anchorEl ? (
                <KeyboardArrowUpTwoTone
                  sx={{
                    right: 0,
                    bottom: 0,
                    color: '#fff',
                    fontSize: '16px',
                    borderRadius: '50%',
                    position: 'absolute',
                    bgcolor: colors.grey[900],
                  }}
                />
              ) : (
                <KeyboardArrowDownTwoTone
                  sx={{
                    right: 0,
                    bottom: 0,
                    color: '#fff',
                    fontSize: '16px',
                    borderRadius: '50%',
                    position: 'absolute',
                    bgcolor: colors.grey[900],
                  }}
                />
              )}
            </Box>
          </Tooltip>
          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <Stack p={2} spacing={2}>
              <Card
                onClick={handleClose}
                to={`${USER_PROFILE}/${user?.username}`}
                component={user?.flags?.isVisible ? Link : 'div'}
                sx={{
                  boxShadow: 0,
                  textDecoration: 'none',
                  border: '1px solid #e0e0e0',
                  ':hover': { bgcolor: colors.grey[100] },
                }}>
                <Stack p={1} spacing={1} direction="row" alignItems="center" minWidth={300}>
                  <Avatar
                    src={user?.image}
                    label={word.displayInitials(user?.firstname, user?.lastname)}
                  />

                  <Stack spacing={0}>
                    <Typography sx={{ whiteSpace: 'nowrap', lineHeight: 1, fontWeight: 700 }}>
                      {word.displayName(user?.firstname, user?.lastname)}
                    </Typography>
                    {user?.flags?.isVisible && (
                      <Typography sx={{ fontSize: 12 }}>View Profile</Typography>
                    )}
                  </Stack>
                </Stack>
              </Card>
              {accountMenuOptions.map((option, index) => (
                <Stack
                  p={1}
                  spacing={1}
                  key={index}
                  direction="row"
                  borderRadius={1}
                  component={Link}
                  to={option.link}
                  onClick={handleClose}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: colors.grey[900],
                    ':hover': { bgcolor: colors.grey[100] },
                  }}>
                  {option.icon}
                  <Typography sx={{ fontSize: 14, flex: 1 }}>{option.label}</Typography>
                  <ChevronRightTwoTone />
                </Stack>
              ))}
              <Stack
                p={1}
                spacing={1}
                direction="row"
                borderRadius={1}
                onClick={() => setLogoutDialog(true)}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  ':hover': { bgcolor: colors.red[50] },
                }}>
                <PowerSettingsNewTwoTone color="error" />
                <Typography color="error" sx={{ fontSize: 14, flex: 1 }}>
                  Logout
                </Typography>
              </Stack>
            </Stack>
          </Menu>
        </Stack>
      </Stack>

      {/* NAVBAR (ONLY VISIBLE ON SCREEN WIDTH SMALLER THAN 600px)*/}
      <Stack
        top={50}
        left={0}
        zIndex={100}
        height="50px"
        direction="row"
        position="sticky"
        justifyContent="space-between"
        bgcolor={brandColors.secondary}
        display={{ xs: 'flex', sm: 'none' }}
        boxShadow="0px 2px 2px rgba(0,0,0,0.2)">
        {memberNavUrls.map((item, index) => (
          <Tooltip
            arrow
            key={index}
            placement="bottom"
            title={`${item.label}${item.unpublished ? ' - coming soon' : ''}`}>
            <ListItemButton
              component={Link}
              to={
                item.unpublished
                  ? '#'
                  : isNavItemSelected(item.basePath)
                  ? location.pathname
                  : item.exactPath
              }
              sx={{
                color: item.unpublished
                  ? colors.grey[400]
                  : isNavItemSelected(item.basePath)
                  ? 'primary.main'
                  : '#fff',
                borderBottom: item.unpublished
                  ? 'none'
                  : isNavItemSelected(item.basePath)
                  ? '3px solid #4562f7'
                  : 'none',
              }}>
              <Stack
                flex={1}
                direction="row"
                position="relative"
                alignItems="center"
                justifyContent="center">
                {item.icon}
                {item.unpublished && <HourglassTopTwoTone sx={{ fontSize: 12 }} />}
                {item.label === 'Connections' && newRequestCount > 0 && <NotificationBadge />}
              </Stack>
            </ListItemButton>
          </Tooltip>
        ))}
      </Stack>
    </React.Fragment>
  );
};

export default PrivateNavbar;
