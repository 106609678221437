import React, { useEffect, useRef, useState } from 'react';
import {
  ThumbUp,
  MoreHoriz,
  LockTwoTone,
  GroupTwoTone,
  ReportTwoTone,
  PublicTwoTone,
  ThumbUpTwoTone,
  BookmarkTwoTone,
  LockResetTwoTone,
  DeleteForeverTwoTone,
  CancelPresentationTwoTone,
  DriveFileRenameOutlineTwoTone,
  KeyboardArrowDownTwoTone,
} from '@mui/icons-material';
import {
  Menu,
  Stack,
  colors,
  Divider,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogContent,
  TextField,
} from '@mui/material';

import Avatar from '../../../widgets/Avatar';
import Button from '../../../widgets/Button';
import dateTime from '../../../utils/dateTime';
import { addRemovePostLike, deletePost, updatePost } from '../../../api/post';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPostValidationSchema } from '../../../validations/post';
import { useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { USER_PROFILE } from '../../../constants/router-urls';
import AlertDialog from '../../global/AlertDialog';
import word from '../../../utils/word';

const PostItem = ({ post }) => {
  const user = useSelector((state) => state.auth.user);

  const postBodyRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [privacy, setPrivacy] = useState(post?.privacy);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPostEditingModal, setShowPostEditingModal] = useState(false);
  const [showPrivacyEditingModal, setShowPrivacyEditingModal] = useState(false);

  const [postOptionsAnchorEl, setPostOptionsAnchorEl] = useState(null);
  const postOptionsOpen = Boolean(postOptionsAnchorEl);
  const handlePostOptions = (event) => setPostOptionsAnchorEl(event.currentTarget);
  const handlePostOptionsClose = () => setPostOptionsAnchorEl(null);

  const [privacyOptionsAnchorEl, setPrivacyOptionsAnchorEl] = useState(null);
  const privacyOptionsOpen = Boolean(privacyOptionsAnchorEl);
  const handlePrivacyOptions = (event) => setPrivacyOptionsAnchorEl(event.currentTarget);
  const handlePrivacyOptionsClose = () => setPrivacyOptionsAnchorEl(null);

  const postBody = post?.body;
  const isAuthor = post?.author?.id === user?.id;
  const isLiked = post?.likes?.find((like) => like?.id === user?.id);

  const checkOverflow = () => {
    try {
      const postBodyElement = postBodyRef?.current;
      if (!postBodyElement) {
        setIsOverflowing(false);
        return;
      }

      const postBodyHeight = postBodyElement.offsetHeight;
      const lineHeight = parseInt(window.getComputedStyle(postBodyElement).lineHeight);
      const maxLines = 10;
      const maxHeight = lineHeight * maxLines;

      setIsOverflowing(postBodyHeight > maxHeight);
    } catch (err) {
      console.error(err);
      setIsOverflowing(false);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [postBodyRef, postBody]);

  useEffect(() => {
    checkOverflow();
  }, []);

  const privacyOptions = [
    {
      label: 'Public',
      value: 'PUBLIC',
      icon: { base: <PublicTwoTone />, small: <PublicTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Only Me',
      value: 'PRIVATE',
      icon: { base: <LockTwoTone />, small: <LockTwoTone sx={{ height: 14, width: 14 }} /> },
    },
    {
      label: 'Connections',
      value: 'CONNECTIONS',
      icon: { base: <GroupTwoTone />, small: <GroupTwoTone sx={{ height: 14, width: 14 }} /> },
    },
  ];

  const resolver = yupResolver(createPostValidationSchema);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting: isEditing },
  } = useForm({ resolver, mode: 'onChange' });

  const body = useWatch({ control, name: 'body', defaultValue: '' });

  const handleLike = async () => {
    setIsLikeLoading(true);
    await addRemovePostLike(post?.uid);
    setIsLikeLoading(false);
  };

  const onPostUpdate = async (data) => {
    const res = await updatePost(post?.uid, {
      privacy,
      body: data?.body,
    });
    if (res?.status === 200) {
      setShowPostEditingModal(false);
      setShowPrivacyEditingModal(false);
    }
  };

  const onPostDelete = async () => {
    setIsDeleteLoading(true);
    await deletePost(post?.uid);
    setIsDeleteLoading(false);
  };

  return (
    <Stack
      p={2}
      spacing={1}
      width="100%"
      bgcolor="#fff"
      borderRadius={1}
      border="1px solid rgba(0,0,0,0.1)">
      {/* post delete alert */}
      <AlertDialog
        title="Delete❓"
        isOpen={showDeleteAlert}
        onResolved={onPostDelete}
        resolveLoading={isDeleteLoading}
        onClose={() => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this post?"
      />

      {/* post edit dialog */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPostEditingModal}
        onClose={isEditing ? null : () => setShowPostEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack direction="row" spacing={0.5} alignItems="start" width="100%">
            <Box flex={1}>
              <Avatar
                src={user.image}
                label={word.displayInitials(user?.firstname, user?.lastname)}
              />
            </Box>
            <Stack alignItems="start" width="100%">
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: '#303030',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: { xs: 180, sm: 450 },
                }}>
                {word.displayName(user?.firstname, user?.lastname)}
              </Typography>
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                sx={{ fontSize: 11 }}
                onClick={handlePrivacyOptions}
                endIcon={<KeyboardArrowDownTwoTone />}
                startIcon={privacyOptions.find((option) => option.value === privacy)?.icon?.base}>
                {privacyOptions.find((option) => option.value === privacy)?.label}
              </Button>
              <Menu
                open={privacyOptionsOpen}
                anchorEl={privacyOptionsAnchorEl}
                onClose={handlePrivacyOptionsClose}>
                <Stack px={1} spacing={1} alignItems="start">
                  {privacyOptions.map((option) => (
                    <Button
                      fullWidth
                      size="small"
                      key={option.value}
                      startIcon={option.icon.base}
                      color={option.value === privacy ? 'primary' : 'secondary'}
                      sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                      onClick={() => {
                        setPrivacy(option.value);
                        handlePrivacyOptionsClose();
                      }}>
                      {option.label}
                    </Button>
                  ))}
                </Stack>
              </Menu>
            </Stack>
          </Stack>
          <Stack mt={2} spacing={2}>
            <TextField
              autoFocus
              multiline
              fullWidth
              minRows={3}
              maxRows={5}
              size="small"
              placeholder="Aa"
              {...register('body')}
              error={!!errors.body}
              helperText={errors.body?.message}
            />
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                color="error"
                disabled={isEditing}
                onClick={() => setShowPostEditingModal(false)}>
                Cancel
              </Button>
              <Button
                loading={isEditing}
                onClick={handleSubmit(onPostUpdate)}
                disabled={(body === post?.body || body?.length < 1) && privacy === post?.privacy}>
                Update
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* privacy edit modal */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showPrivacyEditingModal}
        onClose={isEditing ? null : () => setShowPrivacyEditingModal(false)}>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <Stack px={1} spacing={1} alignItems="start">
            {privacyOptions.map((option) => (
              <Button
                fullWidth
                size="small"
                key={option.value}
                startIcon={option.icon.base}
                color={option.value === privacy ? 'primary' : 'secondary'}
                sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}
                onClick={() => {
                  setPrivacy(option.value);
                }}>
                {option.label}
              </Button>
            ))}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="error"
              disabled={isEditing}
              onClick={() => setShowPrivacyEditingModal(false)}>
              Cancel
            </Button>
            <Button
              loading={isEditing}
              disabled={privacy === post?.privacy}
              onClick={handleSubmit(onPostUpdate)}>
              Update
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Stack direction="row" alignItems="start" spacing={1}>
          <Box
            sx={{ textDecoration: 'none' }}
            to={`${USER_PROFILE}/${user.username}`}
            component={user?.flags?.isVisible ? Link : 'div'}>
            <Avatar
              src={post?.author?.image}
              label={word.displayInitials(post?.author?.firstname, post?.author?.lastname)}
            />
          </Box>
          <Stack>
            <Typography
              to={`${USER_PROFILE}/${post?.author?.username}`}
              component={user?.flags?.isVisible ? Link : 'div'}
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#303030',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                width: { xs: 160, sm: 450 },
              }}>
              {word.displayName(post?.author?.firstname, post?.author?.lastname)}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography fontSize={12} fontWeight={400} color={colors.grey[700]}>
                {dateTime.render(post?.createdAt)}
              </Typography>
              {privacyOptions.find((option) => option.value === post?.privacy)?.icon?.small || (
                <PublicTwoTone />
              )}
            </Stack>
          </Stack>
        </Stack>
        {isAuthor && (
          <IconButton size="small" onClick={handlePostOptions}>
            <MoreHoriz />
          </IconButton>
        )}
        <Menu
          open={postOptionsOpen}
          anchorEl={postOptionsAnchorEl}
          onClose={handlePostOptionsClose}>
          <Stack px={1} spacing={1} alignItems="start">
            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                isAuthor
                  ? () => {
                      handlePostOptionsClose();
                      setPrivacy(post?.privacy);
                      setValue('body', postBody);
                      setShowPostEditingModal(true);
                    }
                  : handlePostOptionsClose
              }
              startIcon={
                isAuthor ? <DriveFileRenameOutlineTwoTone /> : <CancelPresentationTwoTone />
              }
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {isAuthor ? 'Edit Post' : 'Hide Post'}
            </Button>

            <Button
              fullWidth
              size="small"
              color="secondary"
              onClick={
                isAuthor
                  ? () => {
                      handlePostOptionsClose();
                      setPrivacy(post?.privacy);
                      setValue('body', postBody);
                      setShowPrivacyEditingModal(true);
                    }
                  : handlePostOptionsClose
              }
              startIcon={isAuthor ? <LockResetTwoTone /> : <BookmarkTwoTone />}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {isAuthor ? 'Edit Privacy' : 'Save Post'}
            </Button>

            <Button
              fullWidth
              size="small"
              color="error"
              onClick={
                isAuthor
                  ? () => {
                      handlePostOptionsClose();
                      setShowDeleteAlert(true);
                    }
                  : handlePostOptionsClose
              }
              startIcon={isAuthor ? <DeleteForeverTwoTone /> : <ReportTwoTone />}
              sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
              {isAuthor ? 'Delete Post' : 'Report Post'}
            </Button>
          </Stack>
        </Menu>
      </Stack>
      <Box
        sx={{
          width: '100%',
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
        }}
        onClick={isOverflowing ? () => setIsExpanded(!isExpanded) : null}>
        <pre>
          <Typography
            ref={postBodyRef}
            sx={{
              top: 0,
              left: 0,
              opacity: 0,
              position: 'absolute',
              pointerEvents: 'none',
              fontSize: 14,
              fontWeight: 400,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              wordBreak: 'break-word',
              WebkitBoxOrient: 'vertical',
            }}>
            {postBody}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              wordBreak: 'break-word',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: isExpanded ? 'unset' : 10,
            }}>
            {postBody}
          </Typography>
        </pre>
        {isOverflowing && (
          <Typography fontSize={14} fontWeight={600} color={colors.grey[600]}>
            Read {isExpanded ? 'Less' : 'More'}
          </Typography>
        )}
      </Box>
      <Divider />
      <Stack direction="row">
        <Button
          sx={{ textTransform: 'capitalize' }}
          color={isLiked ? 'primary' : 'secondary'}
          onClick={isLikeLoading ? null : handleLike}
          startIcon={isLiked ? <ThumbUp /> : <ThumbUpTwoTone />}>
          ({post?.likes?.length || 0}) Like
        </Button>
      </Stack>
    </Stack>
  );
};

export default PostItem;
