export default [
  {
    title: 'ISBM Research Award',
    description:
      // eslint-disable-next-line quotes
      "ISBM Research Award is the Society's highest award for seminal achievements in research which is awarded during its annual conference. Please contact us for more information.",
    amount: 500,
    extra: 'Complimentary Registration to the ISBM Annual Conference & Certificate',
    numberOfAward: 1,
    frequency: 'Annual',
    eligibility: 'Must be an ISBM member working in any field of microbiology',
  },
  {
    title: 'Early Career Researcher Award',
    description:
      'This award is given to encourage and recognize new researchers in the broad field of microbiological sciences.',
    amount: 500,
    extra: 'Complimentary Registration to the ISBM Annual Conference & Certificate',
    numberOfAward: 1,
    frequency: 'Annual',
    eligibility: 'Must be an ISBM member working in any field of microbiology',
  },
  {
    title: 'Post-Doctoral Research Award',
    amount: 300,
    extra: 'Certificate',
    numberOfAward: 1,
    frequency: 'Annual – Determined at the ISBM Annual Conference',
    eligibility: 'Post-Doctoral Fellow Must be an ISBM member conducting',
  },
  {
    title: 'Graduate Student Awards',
    amount: 300,
    extra: 'Complimentary Registration to the ISBM Annual Conference & Certificate',
    numberOfAward: 1,
    frequency: 'Annual',
    eligibility:
      'Enrolled M.Sc. or Ph.D. students conducting microbiology-related research or study',
  },
  {
    title: 'Undergraduate Student Award',
    description:
      'To recognize outstanding promise in an undergraduate student for early career contributions to microbiology and to encourage continued interest in microbiology and student engagement with the ISBM.',
    amount: 250,
    extra: 'Complimentary Registration to the ISBM Annual Conference & Certificate',
    numberOfAward: 3,
    frequency: 'Annual',
    eligibility: 'Full-time Undergraduate Student Enrolled with an ISBM membership.',
  },
  {
    title: 'Women in Microbiology Award',
    description:
      'Women in Microbiology Award is given to Bangladesh-affiliated female microbiologists for their outstanding contribution to research and education. Please contact us for more information.',
    amount: 500,
    extra: 'Certificate',
    numberOfAward: 1,
    frequency: 'Annual',
  },
  {
    title: 'ISBM Travel Award',
    description:
      'Travel award with a certificate will be given to outstanding posters or oral presenters from Bangladesh (students/postdocs/faculty members/scientists involved in microbiology-related research and education) who will be attending the ASM Microbe this year. ',
    amount: 500,
    extra: 'Certificate',
    numberOfAward: 2,
    frequency: 'Annual',
  },
  {
    title: 'ISBM Award for Exemplary Contributions to Education',
    description:
      'The ISBM Award for Exemplary Contributions to Education recognizes an individual who, at the undergraduate and/or graduate/professional levels, demonstrates an exceptional contribution to the teaching of Microbiology or related science with a demonstrated commitment to student learning, pedagogical engagement, and teaching innovation. Nominees should also demonstrate a commitment to mentoring and advancing the careers of diverse students.',
    amount: 500,
    extra: 'Certificate',
    numberOfAward: 1,
    frequency: 'Annual',
  },
];
