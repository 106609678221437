import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Box, Tab, Tabs, colors } from '@mui/material';
import { ViewTimelineTwoTone, SourceTwoTone } from '@mui/icons-material';

import TabLabel from '../../widgets/TabLabel';
import PageWrapper from '../../components/global/PageWrapper';
import UserBio from '../../components/user/user-profile/UserBio';
import DetailsTab from '../../components/user/user-profile/DetailsTab';
import TimelineTab from '../../components/user/user-profile/TimelineTab';

import { USER_PROFILE } from '../../constants/router-urls';
import { timelineActions } from '../../store/reducers/timelineReducer';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, tab } = useParams();
  const [activeTab, setActivetab] = useState(0);
  const [isTabsDisabled, setIsTabsDisabled] = useState(false);
  const timeline = useSelector((state) => state.timeline);
  const user = useSelector((state) => state.auth.user);
  const isAuth = user?.username === username;

  useEffect(() => {
    if (timeline?.username !== username) {
      dispatch(timelineActions.reset());
      dispatch(timelineActions.controller({ username }));
    }
  }, [username]);

  /* tabs handling logic */
  const tabs = [
    {
      label: 'Details',
      icon: <SourceTwoTone />,
      element: <DetailsTab isAuth={isAuth} username={username} />,
    },
    {
      label: 'Timeline',
      icon: <ViewTimelineTwoTone />,
      element: <TimelineTab isAuth={isAuth} username={username} />,
    },
  ];

  useEffect(() => {
    if (tab) {
      const index = tabs.findIndex(
        (t) => t?.label?.replace(/\s+/g, '-')?.toLowerCase() === tab?.toLowerCase(),
      );
      if (index !== -1) {
        setActivetab(index);
      } else {
        navigate(`${USER_PROFILE}/${username}/details`, { replace: true });
      }
    } else {
      navigate(`${USER_PROFILE}/${username}/details`, { replace: true });
    }
  }, [tab]);
  const currentTab = tabs[activeTab].element;

  const handleTabChange = (event, value) => {
    navigate(
      `${USER_PROFILE}/${username}/${tabs[value]?.label?.replace(/\s+/g, '-')?.toLowerCase()}`,
    );
  };
  /* tabs handling logic */

  return (
    <PageWrapper isAuth>
      <Box
        width="100%"
        margin="auto"
        minWidth={300}
        maxWidth={{ xs: 'calc(100vw - 35px)', sm: 'calc(100vw - 105px)', md: 600 }}>
        <UserBio isAuth={isAuth} disableTabs={setIsTabsDisabled} />
        {!isTabsDisabled && (
          <Stack mb={2} width="100%" bgcolor={colors.grey[300]} borderRadius="0 0 4px 4px">
            <Tabs
              centered
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRadius: '0 0 5px 5px' }}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{ textTransform: 'capitalize', color: colors.grey[800] }}
                  label={<TabLabel label={tab.label} startIcon={tab.icon} />}
                />
              ))}
            </Tabs>
          </Stack>
        )}
        {!isTabsDisabled && currentTab}
      </Box>
    </PageWrapper>
  );
};

export default UserProfile;
