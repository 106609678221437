import React from 'react';

// import OfferSection from '../../components/home/OfferSection';
import AboutSection from '../../components/home/AboutSection';
import Testimonials from '../../components/home/Testimonials';
import CareerSection from '../../components/home/CareerSection';
import LandingSection from '../../components/home/LandingSection';
import ContactSection from '../../components/home/ContactSection';
import MembershipSection from '../../components/home/MembershipSection';

const Home = () => {
  return (
    <React.Fragment>
      {/* <OfferSection /> */}
      <LandingSection />
      <AboutSection />
      <CareerSection />
      <MembershipSection />
      <Testimonials />
      <ContactSection />
    </React.Fragment>
  );
};

export default Home;
