import React from 'react';
import { Box } from '@mui/material';

const PageWrapper = ({ children, isAuth }) => {
  return (
    <Box
      width="100%"
      pb={{ lg: 3, xs: 2 }}
      px={{ lg: 3, xs: 2 }}
      pt={{ lg: isAuth ? '20px' : '80px', xs: isAuth ? '10px' : '60px' }}>
      {children}
    </Box>
  );
};

export default PageWrapper;
